import Icon from './Icon';

const Minus = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" fill="#04F8DA" stroke="#04F8DA"/>
      <path d="M7 12L17 12" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
      </svg>
    </Icon>
  );
};

export default Minus;