import { processHolidayDate } from 'utils';

export const HolidayCalendarEditableFields = [
  {
    key: 'holidayDate',
    processCellData: processHolidayDate,
    fieldType: 'DATE',
    isValid: true,
    value: ''
  },
  {
    key: 'holidayName',
    fieldType: 'TEXT',
    isValid: true,
    value: ''
  },
  {
    key: 'exchangeStatus',
    fieldType: 'SELECT',
    isValid: true,
    value: ''
  },
  {
    key: 'startTime',
    fieldType: 'TIME',
    isValid: true,
    value: '',
    validateWith: 'exchangeStatus'
  },
  {
    key: 'closeTime',
    fieldType: 'TIME',
    isValid: true,
    value: '',
    validateWith: 'exchangeStatus'
  }
];