import { processAmount, processDollarAmount, processReconStatus } from 'utils';

export const TransactionHistoryHeaders = {
  columns: [
    {
      key: 'startDate',
      label: 'Start date',
      withTimestamp: true,
    },
    {
      key: 'endDate',
      label: 'End date',
      withTimestamp: true,
    },
    {
      key: 'fredToDW',
      label: 'Fred to DW',
      processCellData: processAmount,
      labelCellStyles: {
        textAlign: 'right'
      },
      dataCellStyles: {
        textAlign: 'right'
      }
    },
    {
      key: 'fredFromDW',
      label: 'Fred from DW',
      processCellData: processAmount,
      labelCellStyles: {
        textAlign: 'right'
      },
      dataCellStyles: {
        textAlign: 'right',
      }
    },
    {
      key: 'fredNetAmount',
      label: 'Fred net',
      processCellData: processDollarAmount,
      labelCellStyles: {
        textAlign: 'right'
      },
      dataCellStyles: {
        textAlign: 'right'
      }
    },
    {
      key: 'dwNetAmount',
      label: 'Dw net',
      processCellData: processDollarAmount,
      labelCellStyles: {
        textAlign: 'right'
      },
      dataCellStyles: {
        textAlign: 'right'
      }
    },
    {
      key: 'exinityAmount',
      label: 'Exinity ME',
      isEditableColumn: true,
      processCellData: processDollarAmount,
      labelCellStyles: {
        textAlign: 'right'
      },
      dataCellStyles: {
        textAlign: 'right'
      }
    },
    {
      key: 'status',
      label: 'Status'
    }
  ],
  statusData: [
    {
      key: 'status',
      type: 'oval',
      valid: ['Normal|DORMANT', 'Discrepancy >|ALERT_V2', 'Resolved >|VERIFIED'],
      processStatus: processReconStatus
    }
  ],
  statusActions: ['RESOLVED', 'DISCREPANCY'],
  sortableColumns: ['startDate', 'endDate', 'fredToDW', 'fredFromDW', 'fredNetAmount', 'dwNetAmount', 'exinityAmount'],
  dateColumns: ['startDate', 'endDate'],
  editableColumns: ['exinityAmount'],
  signValueKeys: ['fredNetAmount', 'dwNetAmount', 'exinityAmount'],
  actions: [
    {
      key: 'COMMENT',
      action: () => {}
    }
  ]
};