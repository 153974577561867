import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';

const CssContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#FFF',
  marginTop: '7%',
  [theme.breakpoints.down('xl')]: {
    padding: 0
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '100% !important',
    paddingLeft: '4%',
    paddingRight: '4%'
  },
  [theme.breakpoints.between('sm', 'xl')]: {
    maxWidth: '100% !important',
    paddingLeft: '4%',
    paddingRight: '4%'
  }
}));

export default CssContainer;