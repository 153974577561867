
export const TransfersStatusFilters = [
  {
    parentKey: 'source',
    title: 'Source',
    type: 'RADIO',
    filters: [
      {
        key: 'EQUITY_DRIVEWEALTH',
        label: 'Cash equities',
        isChecked: false,
        isDisabled: false
      },
      {
        key: 'CFD_METATRADER5',
        label: 'CFD',
        isChecked: false,
        isDisabled: false
      },
      {
        key: 'CRYPTO_METATRADER5',
        label: 'Crypto',
        isChecked: false,
        isDisabled: false
      },
      {
        key: 'WALLET',
        label: 'Wallet',
        isChecked: false,
        isDisabled: false
      }
    ]
  },
  {
    parentKey: 'destination',
    title: 'Destination',
    type: 'RADIO',
    filters: [
      {
        key: 'EQUITY_DRIVEWEALTH',
        label: 'Cash equities',
        isChecked: false,
        isDisabled: false
      },
      {
        key: 'CFD_METATRADER5',
        label: 'CFD',
        isChecked: false,
        isDisabled: false
      },
      {
        key: 'CRYPTO_METATRADER5',
        label: 'Crypto',
        isChecked: false,
        isDisabled: false
      },
      {
        key: 'WALLET',
        label: 'Wallet',
        isChecked: false,
        isDisabled: false
      }
    ]
  },
  {
    parentKey: 'status',
    title: 'Transaction status',
    filters: [
      {
        key: 'PENDING',
        label: 'Requested',
        isChecked: false,
        isDisabled: true
      },
      {
        key: 'CREDIT_ERROR',
        label: 'Credit error',
        isChecked: false,
        isDisabled: true
      },
      {
        key: 'APPROVED',
        label: 'Credited',
        isChecked: false,
        isDisabled: true
      },
      {
        key: 'DW_CLEARED',
        label: 'DW cleared',
        isChecked: false,
        isDisabled: true
      },
      {
        key: 'DW_PENDING',
        label: 'DW pending',
        isChecked: false,
        isDisabled: true
      },
      {
        key: 'DW_REJECTED',
        label: 'DW rejected',
        isChecked: false,
        isDisabled: true
      },
      {
        key: 'SUCCESSFUL',
        label: 'Successful',
        isChecked: false,
        isDisabled: true
      },
      {
        key: 'REQUEST_FAILED',
        label: 'Failed',
        isChecked: false,
        isDisabled: true
      }
    ]
  },
];