
export const ClientCashAdjustmentsHeaders = {
  columns:  [
    {
      key: 'tranWhen',
      label: 'Date',
      withTimestamp: true,
      format: 'MM.DD.YYYY',
      isUTC: true
    },
    {
      key: 'transactionType',
      label: 'Transaction Type'
    },
    {
      key: 'symbol',
      label: 'Symbol'
    },
    {
      key: 'amount',
      label: 'Value'
    },
    
  ],
  dateColumns: ['tranWhen'],
  filterAlign: 'right'
};