import { styled } from '@mui/material/styles';
import Box from '@mui/material/Grid';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid #F7F3F3`,
  borderRadius: '50%',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  height: 80,
  width: 80,
  padding: 66.5
}));

const CssIconAvatar = ({ avatar, ...rest }: any) => {
  return (
    <StyledBox {...(avatar && {sx: {backgroundImage: `url(${avatar})`} })} />
  );
};

export default CssIconAvatar;