/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Select, FormControl, MenuItem, SelectChangeEvent, InputLabel } from '@mui/material';
import { GlobalContext } from 'context';

interface FieldData {
  name?: string
  label: string
  value: string
  key?: number
  labelVariant?: any
  valueSx?: any
  boxSx?: any
  variant?: any
  multiselect?: boolean
  isDisabled?: boolean
  menu: any
  from?: string
  permissionCheck?: boolean
  noEdit?: boolean
  onChange?: (
    event: SelectChangeEvent<unknown>,
    name?: string
  ) => void
  onScroll?: any
};

const StyledLabel = styled(Typography)(({ theme }) => ({
  lineHeight: 2,
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: '.875rem'
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: '#02B9A2',
  fontSize: '1.25rem',
  '&:before': {
    borderBottomColor: theme.palette.divider
  },
  '&.MuiOutlinedInput-root': {
    marginTop: 8,
    width: 217,
    borderRadius: 30,
    '& .MuiSelect-outlined': {
      padding: 5.13
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  '& .MuiSelect-multiple': {
    fontSize: '0.875rem',
    color: theme.palette.primary.main,
    width: '85%',
    marginLeft: 5
  },
  '&.standard': {
    color: '#000'
  }
}));

const SelectField = ({ name, label, value, labelVariant = 'body1', variant, valueSx, boxSx, menu, multiselect, isDisabled, from, onChange, onScroll , permissionCheck, noEdit}: FieldData) => {
  const { reportBatchIds, setReportBatchIds, isReportBatchIdDeleted, setIsReportBatchIdDeleted, isReportFilterApplied, setIsReportFilterApplied, isFilterCleared } = useContext(GlobalContext);
  const [values, setValues] = useState<any>([]);

  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const value = event?.target?.value;

    setValues(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    if (from === 'POP_IN_FILTER') {
      if (isFilterCleared) {
        setValues([]);
      } else if (isReportBatchIdDeleted || isReportFilterApplied) {
        setValues(reportBatchIds);
        
        if (isReportBatchIdDeleted === true) {
          setIsReportBatchIdDeleted(false)
        }

        if (isReportFilterApplied === true) {
          setIsReportFilterApplied(false)
        }
      } else {
        setReportBatchIds(values);
      }
    }
  }, [values, isReportBatchIdDeleted, isFilterCleared]);

  return (
    <Box sx={boxSx}>
      <StyledLabel variant={labelVariant}>{label}</StyledLabel>
      <FormControl variant={variant ? variant : 'standard'} sx={{width: '100%', top: 3}}>
        {multiselect && <InputLabel>Select report batch</InputLabel>}
        <StyledSelect
          {...(multiselect && {multiple: true, value: values, renderValue:(selected: any) => selected.join(', '), onChange: handleChange})}
          {...(!multiselect && {value: value})}
          {...(onChange && {onChange: (event) => onChange(event, name)})}
          {...(isDisabled && {disabled: true})}
          {...(onScroll && {MenuProps: {
            PaperProps: {onScroll: onScroll},
            style: { maxHeight: 250 }
          }})}
          {...(!permissionCheck && !noEdit && {disabled: true})}
          {...(noEdit && {className: 'standard'})}
        >
          {menu.map((m: any, i: number) => <MenuItem value={m.value} key={i}>{m.displayName}</MenuItem>)}
        </StyledSelect>
      </FormControl>
    </Box>
  );
};

export default SelectField;