import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: 580
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 700,
  borderBottom: `2.5px solid ${theme.palette.primary.light}`,
  '&:visited': {
    color: theme.palette.primary.main
  }
}));

interface RestrictedScreenProps {
  containerSx?: any
}

const RestrictedScreen = (props: RestrictedScreenProps) => {
  const { setIsPermissionGranted }  = useContext(GlobalContext);
  const { containerSx } = props;
  const containerStyles = {
    height: '100vh',
    backgroundColor: '#04F8DA0D', 
    mt: 0, 
    display: 'flex', 
    justifyContent: 'center', 
    flexDirection: 'column'
  };

  const onLinkClick = () => {
    setIsPermissionGranted(false);
  };

  return (
    <CssContainer sx={{...containerStyles, ...containerSx}}>
      <StyledBox>
        <CssTitle variant='h4' sx={{fontStyle: 'normal', fontWeight: 300, fontSize: '3.438rem', lineHeight: '100%', mb: 3}}>Oops. 🥺 You are not  supposed to see this...</CssTitle>
        <CssTitle variant='body-1' sx={{fontSize: '1.25rem', fontWeight: 400, lineHeight: 1.2}}>Please let your line manger knows if you need access to this page. Meanwhile, let me take you back to the <StyledLink to='/' onClick={onLinkClick}>Dashboard</StyledLink>.</CssTitle>
      </StyledBox>
    </CssContainer>
  );
};

export default RestrictedScreen;