/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import { Grid } from '@mui/material';
import ViewField from 'components/ViewField';

const PepInfo = (props: any) => {
  const {pepType, key, data, fieldData, permissionCheck, onFieldEdit, onFieldChangeValue, onFieldHover} = props;

  return (
    <Fragment key={key}>
      <Grid item xs={12} sx={{pt: '0 !important', mb: 3}}>
        <ViewField key={key} label={''} value={pepType} {...(permissionCheck && (fieldData.isEdit === false || fieldData.isEdit === true) && {edit: fieldData.isEdit, onEdit: onFieldEdit, isEditHover: true, onInputChangeValue: onFieldChangeValue, onToggleHover: onFieldHover, isFieldHover: fieldData.isFieldHover, fieldType: fieldData.fieldType})} name={fieldData.section} permissionCheck={permissionCheck} />
      </Grid>
      {pepType === 'HIGH_LEVEL_PUBLIC_OFFICIAL' &&
        <Grid item xs={12} sx={{pt: '0 !important', mb: 3}}>
          <ViewField label={'Names'} value={data.publicOfficialName || '-'} />
        </Grid>
      }
      {pepType === 'PTC_DIRECTOR_OR_SHAREHOLDER' && <>
        <Grid item xs={6} sx={{pt: '0 !important', mb: 3}}>
          <ViewField label={'Company name'} value={data.plcName || '-'} />
        </Grid>
        <Grid item xs={6} sx={{pt: '0 !important', mb: 3}}>
          <ViewField label={'Ticker symbol'} value={data.plcTickerSymbol || '-'} />
        </Grid>
      </>}
    </Fragment>
  );
};

export default PepInfo;