
export const OrdersFilters = [
  {
    parentKey: 'orderStatus',
    title: 'Status',
    filters: [
      {
        key: 'NEW',
        label: 'New',
        isChecked: false
      },
      {
        key: 'PARTIAL_FILL',
        label: 'Partial fill',
        isChecked: false
      },
      {
        key: 'WORKING',
        label: 'Working',
        isChecked: false
      },
      {
        key: 'CANCELED',
        label: 'Canceled',
        isChecked: false
      },
      {
        key: 'REJECTED',
        label: 'Rejected',
        isChecked: false
      },
      {
        key: 'FILLED',
        label: 'Filled',
        isChecked: false
      },
      
    ]
  },
  {
    parentKey: 'orderSide',
    title: 'Side',
    filters: [
      {
        key: 'BUY',
        label: 'Buy',
        isChecked: false
      },
      {
        key: 'SELL',
        label: 'Sell',
        isChecked: false
      },
    ]
  }
];