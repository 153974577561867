import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Box, IconButton, useMediaQuery } from '@mui/material';
import CssBox from 'components/CssBox';
import CssTitle from 'components/CssTitle';
import Plus from 'components/icons/Plus';
import Caret from 'components/icons/Caret';

const StyledFlexBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 4,
  [theme.breakpoints.between('xs', 'sm')]: {
    borderBottom: 0,
    width: '100%',
    paddingBottom: 0
  }
}));

const Notes = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  return (
    <CssBox>
      <StyledFlexBox>
        <CssTitle variant='h6' sx={{fontSize: '1rem'}}>Notes</CssTitle>
        <IconButton sx={{ width: 20, height: 30 }}>
          {isMobile ? <Caret /> : <Plus />}
        </IconButton>
      </StyledFlexBox>
      {!isMobile && <Grid container>
        <Grid item xs={12}>
        </Grid>
      </Grid>}
    </CssBox>
  );
};

export default Notes;