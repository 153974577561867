import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { GlobalContext } from 'context';
import Layout from 'components/Layout';
import AdminLayout from 'components/AdminLayout';
import Admin from 'modules/Admin';
import { isAdminRole } from 'utils';

const PrivateRoute = () => {
  const { userRole } = useContext(GlobalContext);

  return (
    <>
      {isAdminRole(userRole) ? 
        <AdminLayout>
          <Admin />
        </AdminLayout> :
        <Layout>
          <Outlet />
        </Layout>
      }
    </>
  );
};

export default PrivateRoute;