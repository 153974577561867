import Keycloak from 'keycloak-js';

class CustomKeycloak extends Keycloak {
  async customUpdateToken(minValidity: number): Promise<any> {
    const updated = await this.updateToken(minValidity);

    return updated ? {
      token: this.token,
      refreshToken: this.refreshToken
    } : { token: '', refreshToken: '' }
  }
}

export default CustomKeycloak;