import Icon from './Icon';

const Comments = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.59182 15.8042C2.34396 10.2867 6.40306 4.25 12.3609 4.25H12.6823C17.1379 4.25 20.7499 7.86201 20.7499 12.3176C20.7499 17.2785 16.7284 21.3 11.7676 21.3H3.94731C3.62905 21.3 3.34545 21.0991 3.23982 20.7989C3.13419 20.4987 3.22954 20.1645 3.47769 19.9652L5.44918 18.3819C5.53538 18.3127 5.56587 18.1951 5.52416 18.0927L4.59182 15.8042Z" fill="white" stroke="#04F8DA" strokeLinejoin="round"/>
      <path d="M12.5801 12.1611H15.3555V13.3574H12.5801V16.502H11.3086V13.3574H8.5332V12.1611H11.3086V9.25586H12.5801V12.1611Z" fill="#04F8DA"/>
      </svg>
    </Icon>
  );
};

export default Comments;