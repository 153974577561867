import Icon from './Icon';

const CalendarBlack = ({ className, direction, sx }: any) => {
  return (
    <Icon className={className} direction={direction} sx={sx}>
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.13546 3.1665C6.13546 2.83858 5.86962 2.57275 5.54171 2.57275C5.21379 2.57275 4.94796 2.83858 4.94796 3.1665V4.60451C3.76594 4.77162 2.83213 5.70653 2.67405 6.89908L2.60553 7.41601C2.59391 7.50364 2.58281 7.59133 2.57221 7.67907C2.54409 7.91179 2.72744 8.11442 2.96186 8.11442H16.0382C16.2726 8.11442 16.4559 7.91179 16.4278 7.67907C16.4172 7.59133 16.4061 7.50364 16.3945 7.416L16.326 6.89908C16.1679 5.70654 15.2341 4.77164 14.0521 4.60452V3.1665C14.0521 2.83858 13.7863 2.57275 13.4584 2.57275C13.1305 2.57275 12.8646 2.83858 12.8646 3.1665V4.48671C10.626 4.28735 8.37408 4.28735 6.13546 4.48671V3.1665Z" fill="black"/>
      <path d="M16.5812 9.6784C16.5742 9.46748 16.3999 9.30192 16.1889 9.30192H2.81114C2.6001 9.30192 2.42581 9.46748 2.41888 9.6784C2.37185 11.1095 2.45878 12.5435 2.67944 13.9616C2.84649 15.0351 3.71864 15.8597 4.79985 15.9663L5.74431 16.0594C8.24206 16.3057 10.758 16.3057 13.2557 16.0594L14.2002 15.9663C15.2814 15.8597 16.1535 15.0351 16.3206 13.9616C16.5413 12.5435 16.6282 11.1095 16.5812 9.6784Z" fill="black"/>
      </svg>
    </Icon>
  );
};

export default CalendarBlack;