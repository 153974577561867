/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Typography, Box, useMediaQuery } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import Scrollbar from 'smooth-scrollbar';
import ViewField from 'components/ViewField';
import CssButton from 'components/CssButton';
import Save from 'components/icons/Save';
import PepInfo from '../PepInfo';
import PepInfoForm from '../PepInfoForm';
import { GlobalContext } from 'context';
import { checkPermissions } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { processClientInfoData } from 'utils';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100vh',
  paddingTop: 52,
  [theme.breakpoints.between('xs', 'sm')]: {
    height: 'auto'
  }
}));

const StyledSubmitBox = styled(Box)(({ theme }) => ({
  paddingTop: 20,
  paddingBottom: 26,
  position: 'absolute',
  bottom: 0,
  width: '100%',
  backgroundColor: '#FFFFFFCC'
}));

interface PayloadObject {
  [key: string]: any
}

const ProfileInfo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { clientProfileDetails, clientProfileInfo, setClientProfileInfo, userPermissions, pepPayloadData, setPepPayloadData } = useContext(GlobalContext);
  const [data, setData] = useState<any | null>(null);
  const [permissionCheck, setPermissionCheck] = useState<boolean>(false);
  const [activeSubmit, setActiveSubmit] = useState<boolean>(false);
  const { getCountriesApi, getEmploymentIndustriesApi, getEmploymentPositionsApi, updateClientProfileApi, getClientProfileApi } = useRequests();
  const getCountries = useApi(getCountriesApi);
  const getEmploymentIndustries = useApi(getEmploymentIndustriesApi);
  const getEmploymentPositions = useApi(getEmploymentPositionsApi);
  const updateClientProfile = useApi(updateClientProfileApi);
  const getClientProfile = useApi(getClientProfileApi);
  const [clientId, setClientId] = useStorage<number | null>('clientId', null);
  const [countries, setCountries] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [positions, setPositions] = useState([]);
  const [payload, setPayload] = useState<PayloadObject>({});
  const [profileInfoData, setProfileInfoData] = useState<any | null>(null);
  const [removeEmploymentPayload, setRemoveEmploymentPayload] = useState<boolean>(false);
  const [pepForm, setPepForm] = useState<boolean>(false);
  const [taxCountriesIDs, setTaxCountriesIDs] = useState<string[]>([]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('The field cannot be empty')
      .min(3, 'Minimum 3 characters')
      .email('Wrong email format')
      .trim(),
    phone: Yup.string()
      .required('The field cannot be empty')
      .min(8, 'Minimum 8 characters')
      .max(15, 'Max 15 characters')
      .matches(/^[0-9]+$/, 'Phone number should be numbers')
      .trim(),
    dob: Yup.string()
      .test('dob', 'Date of birth must be over 18 to invest with Nemo', function (value: any, ctx) {
        const dob = new Date(value);
        const validDate = new Date();
        const valid = validDate.getFullYear() - dob.getFullYear() >= 18;
        return !valid ? ctx.createError() : valid; 
      }),
    address: Yup.string()
      .required('The field cannot be empty')
      .trim(),
    city: Yup.string()
      .required('The field cannot be empty')
      .max(24, 'Max 24 characters')
      .matches(/^[a-zA-Z0-9 ]+$/, 'The field shouldn\'t have special characters')
      .trim(),
    province: Yup.string()
      .optional()
      .max(24, 'Max 24 characters')
      .matches(/^[a-zA-Z0-9 ]*$/, 'The field shouldn\'t have special characters')
      .trim(),
    postcode: Yup.string()
      .optional()
      .matches(/^[a-zA-Z0-9 ]+$/, 'The field shouldn\'t have special characters')
      .trim()
      // Add any other validation rules you need
  });

  const validateField = async (name: string, value: any, index?: number, pickValueName?: string) => {
    try {
      await validationSchema.validateAt(name, { [name]: value });
      // Clear any existing error for the field
    } catch (error: any) {
      // Set the error message for the field
      return {error: error.message, name};
    }
  };

  const onFieldEdit = (event: any, name: string, index?: number) => {
    setActiveSubmit(true);
    if (name === 'pep') {
      setPepForm(true);
    }
    const editProfileData = data.map((p: any) => {
      if (p.section) {
        if (p.rootField) {
          if (p.rootField.name === name) {
            p.rootField.isEdit = !p.rootField.isEdit;
          }
        }
        if (p.fields) {
          p.fields = p.fields.map((f: any) => {
            if (f.name === name && f.fieldIndex === index) {
              f.isEdit = !f.isEdit;
            }
            return f;
          });
        }
        if (p.section === name) {
          p.isEdit = !p.isEdit;
        }
      } else {
        if (p.name === name) {
          p.isEdit = !p.isEdit;
        }
      }
      return p;
    })

    setData(editProfileData);
  };

  const onFieldHover = (name: string, index?: number) => {
    const hoverProfileData = data.map((p: any) => {
      if (p.section) {
        if (p.rootField) {
          if (p.rootField.name === name) {
            p.rootField.isFieldHover = !p.rootField.isFieldHover;
          }
        }
        if (p.fields) {
          p.fields = p.fields.map((f: any) => {
            if (f && f.name === name && f.fieldIndex === index) {
              f.isFieldHover = !f.isFieldHover;
            }
            return f;
          });
        }
        if (p.section === name) {
          p.isFieldHover = !p.isFieldHover;
        }
      } else {
        if (p.name === name) {
          p.isFieldHover = !p.isFieldHover;
        }
      }
      return p;
    });
    setData(hoverProfileData);
  };

  const onFieldChangeValue = async(event: any, name: string, inputData: any, index?: number, isDate?: boolean, pickValueName?: string) => {
      if (inputData) {
        setTaxCountriesIDs((ids: string[]) => _.uniq(ids.concat(inputData.id)));
      }
      try {
        const newData = await Promise.all(data.map(async (p: any) => {
        if (p.section) {
          if (p.rootField) {
            if (p.rootField.name === name) {
              p.rootField.value = event.target.value;
              payloadBuilder(name, isDate ? moment(event).format('yyyy-MM-DD') : p.rootField.value, index, pickValueName);
              const errorRes = await validateField(name, isDate ? moment(event).format('yyyy-MM-DD') : event.target.value, index, pickValueName);
              if(errorRes && (name in validationSchema.fields)) {
                p.rootField.fieldError = errorRes.error;
                payLoadCleaner(name);
              } else {
                p.rootField.fieldError = '';
              }
            }
          }
          if (p.fields) {
            p.fields = await Promise.all(p.fields.map(async (f: any) => {
              if (f.name === name && f.fieldIndex === index) {
                f.value = event.target.value;
                payloadBuilder(name, isDate ? moment(event).format('yyyy-MM-DD') : f.value, index, pickValueName);
                const errorRes = await validateField(name, isDate ? moment(event).format('yyyy-MM-DD') : event.target.value, index, pickValueName);
                if(errorRes && (name in validationSchema.fields)) {
                  f.fieldError = errorRes.error;
                  payLoadCleaner(name);
                } else {
                  f.fieldError = '';
                }
              }
              return f;
            }));
          }
          if (p.section === name) {
            p.value = event.target.value;
            payloadBuilder(name, isDate ? moment(event).format('yyyy-MM-DD') : p.value, index, pickValueName);
            const errorRes = await validateField(name, isDate ? moment(event).format('yyyy-MM-DD') : event.target.value, index, pickValueName);
            if(errorRes && (name in validationSchema.fields)) {
              p.fieldError = errorRes.error;
              payLoadCleaner(name);
            } else {
              p.fieldError = '';
            }
          }
        } else {
          if (p.name === name) {
            p.value = isDate ? moment(event).format('MM.DD.yyyy') : event.target.value;
            payloadBuilder(name, isDate ? moment(event).format('yyyy-MM-DD') : p.value, index, pickValueName);

            if (p.name === 'phone') {
              if (pickValueName === 'callingCode') {
                p[`${pickValueName}Value`] = event.target.value;
              }
              if (pickValueName === 'phone') {
                p[`${pickValueName}Value`] = event.target.value;
              }
            } else if (p.name === 'address') {
              if (pickValueName === 'address.line1') {
                p[`${pickValueName}Value`] = event.target.value;
              }
              if (pickValueName === 'address.line2') {
                p[`${pickValueName}Value`] = event.target.value;
              }
            } else if (p.name === 'status') {
              if (['RETIRED', 'NOT_EMPLOYED', 'STUDENT'].includes(event.target.value)) {
                setData(data.map((d: any) => {
                  if (['industry', 'employer', 'position'].includes(d.name)) {
                    d.isDisabled = true;
                  }
                }));
              } else {
                setData(data.map((d: any) => {
                  if (['industry', 'employer', 'position'].includes(d.name)) {
                    d.isDisabled = false;
                  }
                }));
              }
            }

            const errorRes = await validateField(name, isDate ? moment(event).format('yyyy-MM-DD') : event.target.value, index, pickValueName);
            if(errorRes && (name in validationSchema.fields)) {
              p.fieldError = errorRes.error;
              payLoadCleaner(name);
            } else {
              p.fieldError = '';
            }
          }
        }
        return p;
      }));
      setData((prevState: any) => newData);
    } catch (error) {
      setData((prevState: any) => prevState);
    }
  };

  const payLoadCleaner = (name: string) => {
    setPayload((prevState: any) => {
      if (prevState[name]) {
        delete prevState[name];
      }

      return prevState;
    });
  };

  const payloadBuilder = (name: string, value: any, index?: number, pickValueName?: string) => {
    profileInfoData.map((p: any) => {
      if (p.section) {
        if (p.rootField) {
          if (p.rootField.name === name && p.rootField.value !== value && !payload[p.rootField.name]) {
            payload[p.rootField.name] = value;
          } else if (p.rootField.name === name && p.rootField.value !== value) {
            payload[p.rootField.name] = value;
          } else if (p.rootField.name === name && p.rootField.value === value && payload[p.rootField.name]) {
            delete payload[p.rootField.name];
          }
        }
        if (p.section === 'taxCountries' && ['countryShortName', 'tin'].includes(name)) {
          if (!payload['taxCountries']) {
            payload['taxCountries'] = [];
          }
          const updatedTaxCountries = clientProfileInfo.taxCountries.map((f: any, i: number) => {
            if(taxCountriesIDs.includes(f.id)) {
              if (!f.tin) {
                f.isTinExists = false;
              } else if (f.tin) {
                f.isTinExists = true;
              }
              if (i === index && f[`${name}`] !== value) {
                f[`${name}`] = value;
              }
              return f;
            }
          });
          payload['taxCountries'] = _.compact(updatedTaxCountries);
        } else {
          if (p.fields) {
            p.fields = p.fields.map((f: any) => {
              if (f && f.name === name && f.fieldIndex === index && f.value !== value && !payload[f.name]) {
                payload[f.name] = value;
              } else if (f && f.name === name && f.fieldIndex === index && f.value !== value) {
                payload[f.name] = value;
              }
            });
          }
          if (p.section === name && p.value !== value && !payload[p.section]) {
            payload[p.section] = value;
          } else if (p.section === name && p.value !== value) {
            payload[p.section] = value;
          } else if (p.section === name && p.value === value && payload[p.section]) {
            delete payload[p.section];
          }
        }
      } else {
        if (['city', 'province', 'postcode', 'address'].includes(name) && !payload['address']) {
          payload['address'] = {};
        }
        if (['employer', 'industry', 'position', 'status', 'annualIncome', 'netWorth'].includes(name) && !payload['employment']) {
          payload['employment'] = {};
        }
        if (p.name === name && p.value !== value && !payload[p.name]) {
          if (p.name === 'phone') {
            if (pickValueName === 'callingCode') {
              payload[`${pickValueName}`] = parseInt(value);
            }
            if (pickValueName === 'phone') {
              payload[`${pickValueName}`] = parseInt(value);
            }
          } else if (p.name === 'address') {
            if (pickValueName === 'address.line1') {
              payload['address']['line1'] = value;
            }
            if (pickValueName === 'address.line2') {
              payload['address']['line2'] = value;
            }
          } else if (p.name === 'city') {
            payload['address']['city'] = value;
          } else if (p.name === 'province') {
            payload['address']['province'] = value;
          } else if (p.name === 'postcode') {
            payload['address']['postcode'] = value;
          } else if (p.name === 'status') {
            payload['employment']['status'] = value;
            if (['RETIRED', 'NOT_EMPLOYED', 'STUDENT'].includes(value)) {
              setRemoveEmploymentPayload(true);
            } else {
              setRemoveEmploymentPayload(false);
            }
          } else if (p.name === 'industry') {
            payload['employment']['industry'] = value;
          } else if (p.name === 'employer') {
            payload['employment']['employer'] = value;
          } else if (p.name === 'position') {
            payload['employment']['position'] = value;
          } else if (p.name === 'annualIncome') {
            const annualIncomeValueArr = value.split('-');
            if (!payload['employment']['annualIncome']) {
              payload['employment']['annualIncome'] = {};
            }
            payload['employment']['annualIncome']['min'] = parseInt(annualIncomeValueArr[0]);
            payload['employment']['annualIncome']['max'] = parseInt(annualIncomeValueArr[1]);
          } else if (p.name === 'netWorth') {
            const netWorthValueArr = value.split('-');
            if (!payload['employment']['netWorth']) {
              payload['employment']['netWorth'] = {};
            }
            payload['employment']['netWorth']['min'] = parseInt(netWorthValueArr[0]);
            payload['employment']['netWorth']['max'] = parseInt(netWorthValueArr[1]);
          } else {
            payload[p.name] = value;
          }
        } else if (p.name === name && p.value !== value) {
          if (p.name === 'phone') {
            if (pickValueName === 'callingCode') {
              payload[`${pickValueName}`] = parseInt(value);
            }
            if (pickValueName === 'phone') {
              payload[`${pickValueName}`] = parseInt(value);
            }
          } else if (p.name === 'address') {
            if (pickValueName === 'address.line1') {
              payload['address']['line1'] = value;
            }
            if (pickValueName === 'address.line2') {
              payload['address']['line2'] = value;
            }
          } else if (p.name === 'city') {
            payload['address']['city'] = value;
          } else if (p.name === 'province') {
            payload['address']['province'] = value;
          } else if (p.name === 'postcode') {
            payload['address']['postcode'] = value;
          } else if (p.name === 'status') {
            payload['employment']['status'] = value;
            if (['RETIRED', 'NOT_EMPLOYED', 'STUDENT'].includes(value)) {
              setRemoveEmploymentPayload(true);
            } else {
              setRemoveEmploymentPayload(false);
            }
          } else if (p.name === 'industry') {
            payload['employment']['industry'] = value;
          } else if (p.name === 'employer') {
            payload['employment']['employer'] = value;
          } else if (p.name === 'position') {
            payload['employment']['position'] = value;
          } else if (p.name === 'annualIncome') {
            const annualIncomeValueArr = value.split('-');
            if (!payload['employment']['annualIncome']) {
              payload['employment']['annualIncome'] = {};
            }
            payload['employment']['annualIncome']['min'] = parseInt(annualIncomeValueArr[0]);
            payload['employment']['annualIncome']['max'] = parseInt(annualIncomeValueArr[1]);
          } else if (p.name === 'netWorth') {
            const netWorthValueArr = value.split('-');
            if (!payload['employment']['netWorth']) {
              payload['employment']['netWorth'] = {};
            }
            payload['employment']['netWorth']['min'] = parseInt(netWorthValueArr[0]);
            payload['employment']['netWorth']['max'] = parseInt(netWorthValueArr[1]);
          } else {
            payload[p.name] = value;
          }
        } else if (p.name === name && p.value === value && payload[p.name]) {
          delete payload[p.name];
        }
      }
    });

    setPayload(payload);
  };

  const onCancel = () => {
    setActiveSubmit(false);
    setPepForm(false);
    setPayload({});
    setPepPayloadData(null);
    setTaxCountriesIDs([]);
    setData((prevData: any) => data.map((p: any) => {
      if (p.section) {
        if (p.rootField) {
          p.rootField.isEdit = false;
          p.rootField.isFieldHover = false;
          p.rootField.fieldError = '';
        }
        if (p.fields) {
          p.fields = p.fields.map((f: any) => {
            f.isEdit = false;
            f.isFieldHover = false;
            f.fieldError = '';
            if ((f && (f.name === 'residentCountryCode' || f.name === 'nationality' || f.name === 'countryShortName') && f.fieldType === 'SELECT')) {
              f.dropdownList = _.map(countries, (c: any) => {
                return {value: c.shortName, label: c.name}
              });
            } else if (f && (f.name === 'phone' && f.fieldType === 'SELECT_TEXT')) {
              f.dropdownList = _.map(countries, (c: any) => {
                return {value: c.callingCode, label: `+${c.callingCode}`}
              });
            }
            return f;
          });
        }
        p.isEdit = false;
        p.isFieldHover = false;
        p.fieldError = '';
      } else {
        p.isEdit = false;
        p.isFieldHover = false;
        p.fieldError = '';

        if ((p.name === 'residentCountryCode' || p.name === 'nationality') && p.fieldType === 'SELECT') {
          p.dropdownList = _.map(countries, (c: any) => {
            return {value: c.shortName, label: c.name}
          });
        } else if (p.name === 'phone' && p.fieldType === 'SELECT_TEXT') {
          p.dropdownList = _.map(countries, (c: any) => {
            return {value: c.callingCode, label: c.callingCode}
          });
        }
      }
      return p;
    }));
  };

  const onSubmit = () => {
    updateClientProfile.request(clientProfileInfo.uid, payload).then((res) => {
      if (res.status === 200) {
        setActiveSubmit(false);
        setPepForm(false);
        setPayload({});
        setPepPayloadData(null);
        getClientProfile.request(clientId).then((res) => {
          if (res.status === 200) {
            setClientProfileInfo(res.data);
          }
        });
        const resetProfileData = data.map((p: any) => {
          if (p.section) {
            if (p.rootField) {
              p.rootField.isEdit = false;
              p.rootField.isFieldHover = false;
              p.rootField.fieldError = '';
            }
            if (p.fields) {
              p.fields = p.fields.map((f: any) => {
                f.isEdit = false;
                f.isFieldHover = false;
                f.fieldError = '';
                return f;
              });
            }
          } else {
            p.isEdit = false;
            p.isFieldHover = false;
            p.fieldError = '';
          }
          return p;
        })
        setData(resetProfileData);

        enqueueSnackbar('Personal info has been updated successfully.', {
          variant: 'successAlert'
        });
      }
    }).catch(err => {
      enqueueSnackbar(err.response.data.error.message, {
        variant: 'errorAlert'
      });
    });
  };

  const checkMarketPermissions = () => {
    setPermissionCheck(checkPermissions(userPermissions, 'CLIENTS'));
  };

  useEffect(() => {
    if (!_.isEmpty(pepPayloadData)) {
      const pepPayload = pepPayloadData;
      if (pepPayload.pepRelevance.length === 1 && pepPayload.pepRelevance[0]  === 'NONE') {
        pepPayload.plcName = '';
        pepPayload.plcTickerSymbol = '';
        pepPayload.publicOfficialName = '';
      }
      const requestPayload = {...payload, ...pepPayload};
      setPayload(requestPayload);
    }
  }, [pepPayloadData]);

  useEffect(() => {
    if (removeEmploymentPayload) {
      const p = payload;
      if (!_.isEmpty(p) && p.employment) {
        if (['RETIRED', 'NOT_EMPLOYED', 'STUDENT'].includes(p.employment.status)) {
          p.employment.industry = '';
          p.employment.employer = '';
          p.employment.position = '';
        }
      }
      setPayload(p);
    }
  }, [removeEmploymentPayload]);

  useEffect(() => {
    const processedData = processClientInfoData(clientProfileInfo, clientProfileDetails);
    setData(_.merge([], data, processedData));
    setProfileInfoData(processedData);

    if (!isMobile) {
      Scrollbar.init(document.querySelector('.profile-info') as HTMLElement, {
        damping : 0.07,
      });
    }

    checkMarketPermissions();
  }, [clientProfileInfo]);

  useEffect(() => {
    getCountries.request(undefined, 250).then((res) => {
      if (res.status === 200) {
        setCountries(res.data);
      }
    });
    getEmploymentIndustries.request(1, 100).then((res) => {
      if (res.status === 200) {
        setIndustries(res.data);
      }
    });
    getEmploymentPositions.request(1, 100).then((res) => {
      if (res.status === 200) {
        setPositions(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (countries.length) {
      const countryProfileData = data.map((p: any) => {
        if (p.fields) {
          p.fields = p.fields.map((f: any) => {
            if ((f.name === 'residentCountryCode' || f.name === 'nationality' || f.name === 'countryShortName') && f.fieldType === 'SELECT') {
              f.dropdownList = _.map(countries, (c: any) => {
                return {value: c.shortName, label: c.name}
              });
            } else if (f.name === 'phone' && f.fieldType === 'SELECT_TEXT') {
              f.dropdownList = _.map(countries, (c: any) => {
                return {value: c.callingCode, label: `+${c.callingCode}`}
              });
            }
            return f;
          });
        } else {
          if ((p.name === 'residentCountryCode' || p.name === 'nationality') && p.fieldType === 'SELECT') {
            p.dropdownList = _.map(countries, (c: any) => {
              return {value: c.shortName, label: c.name}
            });
          } else if (p.name === 'phone' && p.fieldType === 'SELECT_TEXT') {
            p.dropdownList = _.map(countries, (c: any) => {
              return {value: c.callingCode, label: c.callingCode}
            });
          }
        }
        return p;
      })
      setData(countryProfileData);
    }
  }, [countries]);

  useEffect(() => {
    if (industries.length) {
      const industriesProfileData = data.map((p: any) => {
        if (p.name === 'industry' && p.fieldType === 'SELECT') {
          p.dropdownList = _.map(industries, (c: any) => {
            return {value: c.uid, label: c.name}
          });
        }
        return p;
      })
      setData(industriesProfileData);
    }
  }, [industries]);

  useEffect(() => {
    if (positions.length) {
      const positionsProfileData = data.map((p: any) => {
        if (p.name === 'position' && p.fieldType === 'SELECT') {
          p.dropdownList = _.map(positions, (c: any) => {
            return {value: c.uid, label: c.name}
          });
        }
        return p;
      })
      setData(positionsProfileData);
    }
  }, [positions]);

  return (
    <StyledBox className='profile-info'>
      <Grid container spacing={3} sx={{pb: 10}}>
        {data && data.length && data.map((item: any, index: number) => (
          <Fragment key={index}>
            {item && item.label && 
              <Grid item xs={isMobile ? 12 : (item.fullWidth ? 12 : 6)} sx={{pt: '0 !important', mb: 3.625, ...(item.wrapElement && {maxWidth: '100% !important', flexBasis: '100% !important'})}} key={index}>
                <ViewField key={index} label={item.label} value={item.value} verified={item.verified?.value} {...(item.showEllipses && {ellipses: true})} {...(item.wrapElement && {boxSx: {maxWidth: '48.5%'}})} {...(item.conversion && {conversion: item.conversion})} {...(item.valueSx && {valueSx: item.valueSx})} {...(item.isCountry && {isCountry: item.isCountry})} {...(item.pickLable && {pickLable: true})} {...(permissionCheck && (item.isEdit === false || item.isEdit === true) && {edit: item.isEdit, ...(item.isDisabled && {isDisabled: item.isDisabled}), onEdit: onFieldEdit, isEditHover: true, onInputChangeValue: onFieldChangeValue, onToggleHover: onFieldHover, isFieldHover: item.isFieldHover, fieldType: item.fieldType, ...((item.fieldError && {fieldError: item.fieldError})), ...((item.fieldType === 'SELECT' || item.fieldType === 'SELECT_TEXT') && {dropdownList: item.dropdownList}), ...((item.name === 'phone' || item.name === 'address') && {inputData: item})})} permissionCheck={permissionCheck} name={item.name} />
              </Grid>
            }

            <>
              {item && item.section && 
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} sx={isMobile ? {pt: '8px !important'} : {}}>
                    <Typography variant='h6' sx={isMobile ? {fontSize: '.875rem'} : {}}>{item.title}</Typography>
                  </Grid>
                  {item.rootField && 
                    <Grid container item xs={12} spacing={3}>
                      <Grid item xs={isMobile ? 12 : 6} sx={{pt: '0 !important', mb: 3}}>
                        {item.rootField.label && <ViewField key={index} label={item.rootField.label} value={item.rootField.value} {...(item.showEllipses && {ellipses: true})} {...(item.rootField.isBoolean && {isBoolean: true})} {...(permissionCheck && (item.rootField.isEdit === false || item.rootField.isEdit === true) && {edit: item.rootField.isEdit, onEdit: onFieldEdit, isEditHover: true, onInputChangeValue: onFieldChangeValue, onToggleHover: onFieldHover, isFieldHover: item.rootField.isFieldHover, fieldType: item.rootField.fieldType, ...((item.rootField.fieldType === 'SELECT' || item.rootField.fieldType === 'SELECT_TEXT') && {dropdownList: item.rootField.dropdownList})})} permissionCheck={permissionCheck} name={item.rootField.name} />}
                      </Grid>
                    </Grid>
                  }
                  {item.fields && item.fields.map((f: any, i: number) => (
                    <Fragment key={i}>
                      {f && f.label && f.name !== 'id' && 
                        <Grid item xs={isMobile ? 12 : 6} sx={{pt: '0 !important', mb: 3}}>
                          <ViewField key={i} label={item[f.label] ? item[f.label] : f.label} value={f.value} {...(item.showEllipses && {ellipses: true})} {...(f.isCountry && {isCountry: f.isCountry})} {...(permissionCheck && (f.isEdit === false || f.isEdit === true) && {edit: f.isEdit, onEdit: onFieldEdit, isEditHover: true, onInputChangeValue: onFieldChangeValue, onToggleHover: onFieldHover, isFieldHover: f.isFieldHover, fieldType: f.fieldType, ...((f.fieldType === 'SELECT' || f.fieldType === 'SELECT_TEXT') && {dropdownList: f.dropdownList})})} inputData={f} permissionCheck={permissionCheck} name={f.name} fieldIndex={f.fieldIndex} {...(item.isFieldsEditable && {labelStyles: {lineHeight: 1.5, minHeight: 42}})} />
                        </Grid>
                      }
                    </Fragment>
                  ))}
                  {item.section === 'pep' && !pepForm && item.renderBy && item.renderByField.length && item.renderByField.map((r: any, i: number) => (
                    <PepInfo pepType={r} key={i} data={clientProfileInfo} fieldData={item} permissionCheck={permissionCheck} onFieldEdit={onFieldEdit} onFieldChangeValue={onFieldChangeValue} onFieldHover={onFieldHover} />
                  ))}
                  {item.section === 'pep' && pepForm && <PepInfoForm data={clientProfileInfo}  />}
                </Grid>
              }
            </>
          </Fragment>
        ))}
      </Grid>
      {activeSubmit && <StyledSubmitBox>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <CssButton variant='outlined' className={'btn-submit-cancel'} sx={{width: '12%', mr: 1, ml: 0, pt: .8, pb: .8, '&:hover': {backgroundColor: '#E5E5E5'}}} onClick={onCancel}>Cancel</CssButton>
            <CssButton variant='contained' className={'active'} sx={{width: '20%', mr: 0, ml: 1, pt: .5, pb: .5, boxShadow: 'none', '&:hover': {backgroundColor: '#04F8DA', boxShadow: 'none'}}} onClick={onSubmit} startIcon={<Save sx={{top: 4}} />} {...(_.isEmpty(payload) && {disabled: true})}>Save changes</CssButton>
          </Grid>
        </Grid>
      </StyledSubmitBox>}
    </StyledBox>
  );
};

export default ProfileInfo;