/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid } from '@mui/material';
import CssContainer from 'components/CssContainer';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssAccordion from 'components/CssAccordion';
import CssButton from 'components/CssButton';
import Filters from 'components/Filters';
import { ClientCashAdjustmentsHeaders } from 'constants/client-cash-adjustments-table-head-columns';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const CashAdjustments = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { filterDates, setFilterDates, isFilterCleared, setIsFilterCleared, isUpdatePage, setIsUpdatePage, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay } = useContext(GlobalContext);
  const { getClientCashAdjustmentsApi } = useRequests();
  const getClientCashAdjustments = useApi(getClientCashAdjustmentsApi);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [clientId] = useStorage<number | null>('clientId', null);
  const [openFiltersScreen, setOpenFiltersScreen] = useState(false);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onClearFilters = () => {
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFiltersCleared(true);
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setIsFilterCleared(true);
  };

  const onApply = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    
    if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setIsDateFilterApplied(true);
    } else {
      setIsDateFilterApplied(false);
    }
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  useEffect(() => {
    if (isFiltersCleared) {
      setEnableButtons(true);
    } else if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [isFiltersCleared, filterDates]);

  useEffect(() => {
    getClientCashAdjustments.request(clientId, page + 1, rowsPerPage).then((res: any) => {
      if (res.status === 200) {
        const { totalcount } = res.headers;
        setTotalCount(parseInt(totalcount));
        setData(res.data);
        setIsDateFilterApplied(false);

        if (isFilterApplied) {
          enqueueSnackbar('Filters have been applied successfully.', {
            variant: 'successWithUndo',
            onUndo: onClearFilters
          });
          setIsFilterApplied(false);
        }
      }
      if (isUpdatePage) setIsUpdatePage(false);
      if (isFilterCleared) setIsFilterCleared(false);
      if (isFiltersCleared) setIsFiltersCleared(false);
    });
  }, [page, rowsPerPage]);

  return (
    <CssContainer sx={{p: '0 !important', mt: 0}}>
      <CssTable
        headers={ClientCashAdjustmentsHeaders}
        totalCount={totalCount}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onFilters={handleFilters}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: {md: 6, xs: 0} }}
        rowClassName={'no-row-click'}
      />
      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          <CssAccordion title='Date' isExpand={filterDates && filterDates.fromDate && filterDates.toDate}>
            <Filters from={'POP_IN_FILTER'} fromLabel={'From'} toLabel={'To'} />
          </CssAccordion>

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
    </CssContainer>
  );
};

export default CashAdjustments;