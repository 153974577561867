
export const WithdrawalReportsHeaders = {
  columns:  [
    {
      key: 'batchNo',
      label: 'Report ID'
    },
    {
      key: 'createdAt',
      label: 'Report date',
      withTimestamp: true
    }
  ],
  sortableColumns: ['batchNo', 'createdAt'],
  dateColumns: ['createdAt'],
  searchColumns: ['batchNo'],
  actions: [
    {
      key: 'DOWNLOAD',
      action: () => {}
    }
  ],
  actionCellStyles: {
    width: '62%'
  },
  paginationColSpan: 5,
  filterAlign: 'right'
};