import Icon from './Icon';

const Download = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4225 4.0625C12.4555 4.0625 11.6488 4.80154 11.5644 5.76486C11.3878 7.77896 11.3431 9.80205 11.4304 11.8212C11.325 11.8282 11.2196 11.8356 11.1143 11.8433L9.25237 11.979C8.06231 12.0657 7.39706 13.3927 8.03953 14.3982C9.40975 16.5426 11.1753 18.4068 13.2421 19.8915L13.988 20.4273C14.5928 20.8617 15.4074 20.8617 16.0122 20.4273L16.7581 19.8915C18.8249 18.4068 20.5904 16.5426 21.9607 14.3982C22.6031 13.3927 21.9379 12.0657 20.7478 11.979L18.8859 11.8433C18.7806 11.8356 18.6752 11.8282 18.5697 11.8212C18.6571 9.80204 18.6124 7.77896 18.4358 5.76485C18.3514 4.80154 17.5447 4.0625 16.5777 4.0625H13.4225ZM13.352 12.6434C13.2098 10.4094 13.2364 8.16772 13.4314 5.9375H16.5688C16.7638 8.16772 16.7904 10.4094 16.6482 12.6434C16.6323 12.8939 16.7174 13.1403 16.8846 13.3274C17.0518 13.5146 17.287 13.627 17.5377 13.6393C17.9419 13.6592 18.3459 13.6839 18.7496 13.7133L20.1018 13.8119C18.9024 15.5785 17.4005 17.1213 15.6642 18.3687L15.0001 18.8457L14.336 18.3687C12.5996 17.1213 11.0978 15.5785 9.89837 13.8119L11.2506 13.7133C11.6543 13.6839 12.0583 13.6592 12.4625 13.6393C12.7131 13.627 12.9484 13.5146 13.1156 13.3274C13.2827 13.1403 13.3679 12.8939 13.352 12.6434Z" fill={fill || "black"} />
      <path d="M7.1875 21.25C7.1875 20.7322 6.76777 20.3125 6.25 20.3125C5.73223 20.3125 5.3125 20.7322 5.3125 21.25V23.75C5.3125 24.9581 6.29188 25.9375 7.5 25.9375H22.5C23.7081 25.9375 24.6875 24.9581 24.6875 23.75V21.25C24.6875 20.7322 24.2678 20.3125 23.75 20.3125C23.2322 20.3125 22.8125 20.7322 22.8125 21.25V23.75C22.8125 23.9226 22.6726 24.0625 22.5 24.0625H7.5C7.32741 24.0625 7.1875 23.9226 7.1875 23.75V21.25Z" fill={fill || "black"} />
      </svg>
    </Icon>
  );
};

export default Download;