
export const InstrumentsProfileDetails = [
  {
    name: "instrumentType",
    label: "Instrument type",
    value: ""
  },
  {
    name: "instrumentSubType",
    label: "2nd instrument type",
    value: ""
  },
  {
    name: "tags",
    label: "Tags",
    value: "",
    fullWidth: true,
    isEdit: false,
    isAutocomplete: true
  },
  {
    section: "nemoApp",
    title: "Nemo app",
    fields: [
      {
        name: "instrumentDisplayName",
        label: "Common name",
        value: "",
        isEdit: false
      },
      {
        name: "hiddenSearchName",
        label: "Hidden search name",
        value: "",
        fullWidth: true,
        isEdit: false
      },
      {
        name: "internalStatus",
        label: "Internal status",
        value: "",
        fieldType: "select",
        menuItems: [
          {
            value: "ACTIVE",
            displayName: "Visible"
          },
          {
            value: "INACTIVE",
            displayName: "Disable"
          }
        ]
      },
      {
        name: "beta",
        label: "Beta volatility",
        value: ""
      },
      {
        name: "dividendExdate",
        label: "Dividend - ExDate",
        value: "",
        isEdit: false,
        isDate: true
      },
      {
        name: "dividendPaymentDate",
        label: "Dividend - PmtDate",
        value: "",
        isEdit: false,
        isDate: true
      }
    ]
  },
  {
    section: "drivewealth",
    title: "Drivewealth",
    fields: [
      {
        name: "sector",
        label: "Sector",
        value: "",
        wrapElement: true
      },
      {
        name: "priorClose",
        label: "Prior Close",
        value: ""
      },
      {
        name: "open",
        label: "Open",
        value: ""
      },
      {
        name: "lastTrade",
        label: "Last trade",
        value: "",
        wrapElement: true
      },
      {
        name: "high",
        label: "Day high",
        value: ""
      },
      {
        name: "low",
        label: "Day low",
        value: ""
      },
      {
        name: "fiftyTwoWeekHighPrice",
        label: "52 week high",
        value: ""
      },
      {
        name: "fiftyTwoWeekLowPrice",
        label: "52 week low",
        value: ""
      },
      {
        name: "cumulativeVolume",
        label: "Today Volume",
        value: ""
      },
      {
        name: "volumeMovingAverage10Day",
        label: "Avg 10 day volume",
        value: ""
      },
      {
        name: "volumeMovingAverage25Day",
        label: "Avg 25 day volume",
        value: ""
      },
      {
        name: "volumeMovingAverage50Day",
        label: "Avg 50 day volume",
        value: ""
      },
      {
        name: "marketCap",
        label: "Company value",
        value: "",
        wrapElement: true
      },
      {
        name: "peRatio",
        label: "PE ratio",
        value: ""
      },
      {
        name: "earningsPerShare",
        label: "EPS",
        value: ""
      },
      {
        name: "dividendYield",
        label: "Div yield",
        value: ""
      },
      {
        name: "dividend",
        label: "Dividend",
        value: ""
      },
      {
        name: "dataProvider",
        label: "Data provider",
        value: ""
      },
      {
        name: "volume",
        label: "Volume",
        value: ""
      },
      {
        name: "sharesOutstanding",
        label: "Shares outstanding",
        value: ""
      },
      {
        name: "timeLastUpdate",
        label: "Time last update",
        value: "",
        isTime: true
      },
      {
        name: "bookValuePerShare",
        label: "Book value per share",
        value: ""
      },
      {
        name: "cashFlowPerShare",
        label: "Cash flow per share",
        value: ""
      },
      {
        name: "operatingIncome",
        label: "Operating income",
        value: ""
      },
      {
        name: "pbRatio",
        label: "PBRatio",
        value: ""
      },
      {
        name: "priceMovingAverage50Day",
        label: "50D - moving avg",
        value: ""
      },
      {
        name: "priceMovingAverage150Day",
        label: "150D - moving avg",
        value: ""
      },
      {
        name: "priceMovingAverage200Day",
        label: "200D - moving avg",
        value: ""
      },
      {
        name: "roe",
        label: "Roe",
        value: ""
      }
    ]
  },
  {
    section: "refinitiv",
    title: "Refinitiv",
    fields: [
      {
        name: "currentPrice",
        label: "Current Price",
        value: ""
      },
      {
        name: "marketCap",
        label: "Market Cap",
        value: ""
      },
      {
        name: "volume",
        label: "Volume",
        value: ""
      },
      {
        name: "averageVolume",
        label: "Average Volume",
        value: ""
      },
      {
        name: "fiftyTwoWeekLow",
        label: "52-week low",
        value: ""
      },
      {
        name: "fiftyTwoWeekHigh",
        label: "52-week high",
        value: ""
      },
      {
        name: "priceToEarnings",
        label: "Price to earnings",
        value: "",
        wrapElement: true
      },
      {
        name: "projectedProfit",
        label: "Projected profit",
        value: ""
      },
      {
        name: "projectedSales",
        label: "Projected sales",
        value: ""
      },
      {
        name: "ttmRevenue",
        label: "TTM revenue",
        value: ""
      },
      {
        name: "employees",
        label: "Employees",
        value: ""
      },
      {
        name: "quarterlyCashFlowPerShare",
        label: "Quarterly cash flow per share",
        value: ""
      },
      {
        name: "longTermGrowthRate",
        label: "Long term growth rate",
        value: ""
      },
      {
        name: "netIncomeAvailableToCommonShareholders",
        label: "Net income available to common shareholders",
        value: ""
      },
      {
        name: "priceToBookValue",
        label: "Price to book value",
        value: ""
      },
      {
        name: "consensusRecommendation",
        label: "Consensus recommendation",
        value: ""
      },
      {
        name: "grossMargin",
        label: "Gross Margin",
        value: ""
      },
      {
        name: "projectedInterimEPS",
        label: "Projected interim EPS",
        value: ""
      },
      {
        name: "priceToSales",
        label: "Price to sales",
        value: ""
      },
      {
        name: "projectedDividendPerShare",
        label: "Projected dividend per share",
        value: ""
      },
      {
        name: "projectedInterimSales",
        label: "Projected interim sales",
        value: ""
      },
      {
        name: "ttmEBITDA",
        label: "TTM EBITDA",
        value: ""
      },
      {
        name: "dividendamount",
        label: "Dividend amount",
        value: ""
      },
      {
        name: "dividendYield",
        label: "Dividend Yield",
        value: "",
        isPercentage: true
      },
      {
        name: "roe",
        label: "Return on equity",
        value: ""
      },
      {
        name: "cashFlowPerShare",
        label: "Cash flow per share",
        value: ""
      },
      {
        name: "revenuePerShare",
        label: "Revenue per share",
        value: ""
      },
      {
        name: "earningsPerShare",
        label: "Earnings per share(EPS)",
        value: ""
      },
      {
        name: "projectedEPS",
        label: "Projected EPS",
        value: ""
      },
      {
        name: "projectedPE",
        label: "Projected PE",
        value: ""
      },
      {
        name: "oneYearTargetEstimate",
        label: "1 year target estimate",
        value: ""
      }
    ]
  }
];