import { processRiskRating, processScreening, processIdVerification } from 'utils';

export const DashboardHeaders = {
  columns:  [
    {
      key: 'registrationDate',
      label: 'Registration date',
      dataCellStyles: {
        paddingRight: 0,
        width: 100
      }
    },
    {
      key: 'uid',
      label: 'Client ID',
      dataCellStyles: {
        paddingRight: 0
      }
    },
    {
      key: 'firstName',
      label: 'First name',
      dataCellStyles: {
        paddingRight: 0
      }
    },
    {
      key: 'lastName',
      label: 'Last name',
      dataCellStyles: {
        paddingRight: 0
      }
    },
    {
      key: 'email',
      label: 'Email',
      dataCellStyles: {
        paddingRight: 0
      }
    },
    {
      key: 'riskRating',
      label: 'Risk rating',
      dataCellStyles: {
        paddingRight: 0
      }
    },
    {
      key: 'screening',
      label: 'Screening',
      dataCellStyles: {
        paddingRight: 0
      }
    },
    {
      key: 'idVerification',
      label: 'ID verification',
      dataCellStyles: {
        paddingRight: 0
      }
    }
  ],
  searchColumns: ['firstName', 'lastName', 'email', 'uid'],
  sortableColumns: ['registrationDate', 'riskRating', 'screening'],
  statusData: [
    {
      key: 'riskRating',
      type: 'ellipse',
      valid: ['Low|VERIFIED', 'High|WARNING', 'Very high|ALERT', 'Medium|REVIEW'],
      processStatus: processRiskRating
    },
    {
      key: 'screening',
      type: 'ellipse',
      valid: ['No adverse matches|VERIFIED', 'Possible adverse matches|WARNING', 'Definite adverse matches|ALERT'],
      ellipseStyles: {
        width: 9
      },
      processStatus: processScreening
    },
    {
      key: 'idVerification',
      type: 'oval',
      valid: ['Resubmission required|NEW', 'Verified|VERIFIED', 'Manual review required|WARNING', 'Rejected >|DEFAULT', 'Disqualified|TRANSPARENT'],
      processStatus: processIdVerification
    }
  ],
  statusActions: ['REJECTED'],
  dateColumns: ['registrationDate'],
  mobileColumns: [
    {
      key: 'registrationDate'
    },
    {
      key: 'uid'
    },
    {
      key: 'riskRating',
      labelStyles: {
        width: '37px !important'
      },
      dataCellStyles: {
        textAlign: 'center'
      }
    },
    {
      key: 'screening',
      labelStyles: {
        textAlign: 'center'
      },
      dataCellStyles: {
        textAlign: 'center'
      }
    }
  ],
  ellipseColumns: ['firstName', 'lastName']
};