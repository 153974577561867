import Icon from './Icon';

const LogoInitial = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="33" height="54" viewBox="0 0 33 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.6484 26.3789H14.3984V38H8.53906V9.5625H27.0547V14.3086H14.3984V21.6523H25.6484V26.3789Z" fill="white"/>
      <path d="M8 8H33L8 33V8Z" fill="#04F8DA"/>
      <rect x="14" y="14" width="14" height="8" fill="black"/>
      <rect x="9" width="54" height="9" transform="rotate(90 9 0)" fill="black"/>
      </svg>
    </Icon>
  );
};

export default LogoInitial;