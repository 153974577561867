import { processTransfersStatus, processSourceDestination } from 'utils';

export const TransfersHeaders = {
  columns: [
    {
      key: 'transferId',
      label: 'Transfer ID',
      dataCellStyles: {
        wordBreak: 'break-all',
        '& span': {
          width: 130,
          display: 'block'
        }
      }
    },
    {
      key: 'transactionDate',
      label: 'Date of transaction',
      withTimestamp: true,
      dataCellStyles: {
        width: 155
      }
    },
    {
      key: 'txnStatusLastUpdatedAt',
      label: 'Status updated time',
      withTimestamp: true,
      dataCellStyles: {
        width: 155
      }
    },
    {
      key: 'source',
      label: 'Source',
      dataCellStyles: {
        wordBreak: 'break-all',
        width: 120,
        '& span': {
          width: 90,
          display: 'block'
        }
      },
      processCellData: processSourceDestination,
    },
    {
      key: 'destination',
      label: 'Destination',
      processCellData: processSourceDestination
    },
    {
      key: 'amount',
      label: 'USD amount'
    },
    {
      key: 'clientId',
      label: 'Client ID',
      isLink: true
    },
    {
      key: 'clientName',
      label: 'Name',
      isLink: true,
      dataCellStyles: {
        width: 200
      }
    },
    {
      key: 'transferStatus',
      label: 'Transfer status'
    }
  ],
  sortableColumns: ['transferId', 'transactionDate', 'txnStatusLastUpdatedAt'],
  statusData: [
    {
      key: 'transferStatus',
      type: 'oval',
      valid: ['Failed|ALERT', 'Pending|WARNING', 'Successful|VERIFIED', 'Credited|VERIFIED', 'Approved|VERIFIED', 'Credit error|ALERT', 'Requested|REVIEW', 'DW cleared|ALERT', 'DW rejected|ALERT', 'DW pending|ALERT'],
      processStatus: processTransfersStatus
    }
  ],
  dateColumns: ['transactionDate', 'txnStatusLastUpdatedAt'],
  searchColumns: ['transferId', 'clientId', 'clientName']
};
