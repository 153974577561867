import Icon from './Icon';

const CalendarMenu = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.4583 3.92773C8.9588 3.92773 9.36455 4.33348 9.36455 4.83398V6.84904C12.7814 6.54475 16.2185 6.54475 19.6354 6.84904V4.83398C19.6354 4.33348 20.0411 3.92773 20.5416 3.92773C21.0421 3.92773 21.4479 4.33348 21.4479 4.83398V7.02884C23.252 7.28393 24.6772 8.71088 24.9185 10.5311L25.0231 11.3201C25.463 14.6385 25.425 18.0029 24.9103 21.3106C24.6553 22.9492 23.3241 24.2077 21.6739 24.3705L20.2323 24.5126C16.42 24.8886 12.5799 24.8886 8.76754 24.5126L7.32599 24.3705C5.67572 24.2077 4.34455 22.9492 4.08958 21.3106C3.57488 18.0029 3.53689 14.6385 3.97677 11.3201L4.08135 10.5311C4.32263 8.71086 5.74792 7.2839 7.55205 7.02883V4.83398C7.55205 4.33348 7.95779 3.92773 8.4583 3.92773ZM8.9962 8.70404C12.6565 8.34308 16.3434 8.34308 20.0036 8.70404L21.0977 8.81193C22.1451 8.91522 22.9834 9.72597 23.1217 10.7692L23.2263 11.5582C23.2628 11.8339 23.2959 12.1098 23.3255 12.3861H5.67437C5.70395 12.1098 5.73701 11.8339 5.77355 11.5582L5.87813 10.7692C6.01642 9.72597 6.85479 8.91522 7.90211 8.81193L8.9962 8.70404ZM5.5303 14.1986C5.41167 16.48 5.52855 18.77 5.88053 21.032C6.00842 21.8538 6.67612 22.4851 7.50387 22.5667L8.94542 22.7089C12.6395 23.0732 16.3604 23.0732 20.0544 22.7089L21.496 22.5667C22.3237 22.4851 22.9914 21.8538 23.1193 21.032C23.4713 18.77 23.5882 16.48 23.4696 14.1986H5.5303Z" fill="currentColor"/>
      </svg>
    </Icon>
  );
};

export default CalendarMenu;