/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { getFirstLetters, formatDate } from 'utils';
import ScreenDialog from 'components/ScreenDialog';
import CssButton from 'components/CssButton';
import CssAvatar from 'components/CssAvatar';
import Plus from 'components/icons/Plus';
import Minus from 'components/icons/Minus';

interface CommentDialogProps {
  open: boolean
  data: any
  permissions: boolean
  onCommentsScreenClose: (event: {},
    reason: 'backdropClick') => void
  onAddComment: (value: string, setValue: any) => void
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  height: '166px !important',
  border: `1px solid ${theme.palette.divider}`,
  padding: 15,
  fontFamily: 'Roboto',
  lineHeight: '1rem',
  fontSize: '0.875rem',
  fontWeight: 400,
  marginTop: 40,
  '&:focus-visible': {
    border: `1px solid ${theme.palette.primary.light}`,
    outline: `1px solid ${theme.palette.primary.light}`
  }
}));

const StyledTypographyComment = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '.875rem',
  '&.comment-details': {
    fontSize: '.75rem',
    color: theme.palette.secondary.light
  }
}));

const CommentDialog = (props: CommentDialogProps) => {
  const { open, data, permissions, onCommentsScreenClose, onAddComment } = props;
  const [showCommentsField, setShowCommentsField] = useState<boolean>(false);
  const [commentsValue, setCommentsValue] = useState<string>('');

  const onDisplayCommentField = () => {
    setShowCommentsField(!showCommentsField);
  };

  const onChangeComments = (event: any) => {
    setCommentsValue(event.target.value);
  };

  return (
    <ScreenDialog
      title={'Comments'}
      hideCloseBtn={false}
      disableEscapeKeyDown={true}
      open={open}
      onScreenClose={(event: {},
        reason: 'backdropClick') => {
        setShowCommentsField(false);
        onCommentsScreenClose(event, reason);
      }}
      className='comments-dialog'
    >
      <StyledBox sx={{mt: 9, ml: 6.25, mr: 6.25}}>
        <Typography variant='body2' sx={{fontWeight: 500, fontSize: '1rem'}}>Comments</Typography>
        {permissions && <IconButton sx={{ width: 20, height: 20 }} onClick={onDisplayCommentField}>
          {showCommentsField ? <Minus /> : <Plus />}
        </IconButton>}
      </StyledBox>
      <Box sx={{m: 6.25, mt: 0}}>
        {showCommentsField && <>
          <StyledTextareaAutosize
            maxRows={10}
            value={commentsValue}
            onChange={onChangeComments}
          />
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'right', mt: 2.5, mb: 2 }}>
              <CssButton sx={{width: '28.572%', mr: 0, ml: 1}} onClick={() => onAddComment(commentsValue, setCommentsValue)} {...(commentsValue !== '' ? {className: 'active', variant: 'contained'} : {variant: 'outlined', disabled: true})}>Add</CssButton>
            </Grid>
          </Grid>
        </>}
        {data.length > 0 && <Grid container sx={{ mt: 5 }}>
          {data.map((item: any, index: number) => (
            <Grid item xs={12} key={index} sx={{mb: 4.375}}>
              <StyledTypographyComment>{item.comment}</StyledTypographyComment>
              <Grid container sx={{ mt: 1.25 }}>
                {item.commentedBy ? 
                  <Grid item xs={1.3}>
                    <CssAvatar sx={{ width: 28, height: 28, fontSize: '.875rem', backgroundColor: '#C4C4C4', mt: .5 }}>{getFirstLetters(item.commentedBy)}</CssAvatar>
                  </Grid> :
                  <>{item.commentedByEmail &&
                    <Grid item xs={1.3}>
                      <CssAvatar sx={{ width: 28, height: 28, fontSize: '.875rem', backgroundColor: '#C4C4C4', mt: .5 }}>{getFirstLetters(item.commentedByEmail)}</CssAvatar>
                    </Grid>
                  }</>
                }
                <Grid item xs={10.7}>
                  {item.commentedBy ? 
                    <StyledTypographyComment className='comment-details'>{item.commentedBy}</StyledTypographyComment>  :
                  <>{item.commentedByEmail &&
                    <StyledTypographyComment className='comment-details'>{item.commentedByEmail}</StyledTypographyComment>
                  }</>}
                  <StyledTypographyComment className='comment-details'>{formatDate(item.createdAt)}</StyledTypographyComment>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>}
      </Box>
    </ScreenDialog>
  );
};

export default CommentDialog;