import Icon from './Icon';

const Check = ({ className, direction, fill, sx }: any) => {
  return (
    <Icon className={className} direction={direction} sx={sx}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="12.3694" cy="12" rx="11.8734" ry="12" fill={fill || "#D9D9D9"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.3363 7.96967C18.6261 8.26256 18.6261 8.73744 18.3363 9.03033L11.4101 16.0303C11.1203 16.3232 10.6505 16.3232 10.3607 16.0303L6.4029 12.0303C6.1131 11.7374 6.1131 11.2626 6.4029 10.9697C6.6927 10.6768 7.16256 10.6768 7.45236 10.9697L10.8854 14.4393L17.2868 7.96967C17.5766 7.67678 18.0465 7.67678 18.3363 7.96967Z" fill="white"/>
      </svg>
    </Icon>
  );
};

export default Check;