/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, MouseEvent, ChangeEvent, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, FormControlLabel, FormGroup, Grid } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import CssContainer from 'components/CssContainer';
import Filters from 'components/Filters';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssAccordion from 'components/CssAccordion';
import CssCheckbox from 'components/CssCheckbox';
import CssButton from 'components/CssButton';
import CssAutocomplete from 'components/CssAutocomplete';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { Order, setAccordionExpandByFilters, checkPermissions } from 'utils';
import { TravelRuleReportsHeaders } from 'constants/travel-rule-reports-table-head-columns';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 5,
}));

const TravelRules = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { travelRuleReportsFilters, setTravelRuleReportsFilters, searchQuery, isSearchClosed, isUpdatePage, setIsUpdatePage, filterDates, setFilterDates, isFilterCleared, setIsFilterCleared, reportBatchIds, setReportBatchIds, setIsReportFilterApplied, isChangeReportBatchIds, setIsChangeReportBatchIds, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, userPermissions } = useContext(GlobalContext);
  const { getReportsApi, getReportDownloadApi, getReportsListApi } = useRequests();
  const getReports = useApi(getReportsApi);
  const getReportDownload = useApi(getReportDownloadApi);
  const getReportsList = useApi(getReportsListApi);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFiltersScreen, setOpenFiltersScreen] = useState(false);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [filterQuery, setFilterQuery] = useState('');
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
  const [reportBatchMenu, setReportBatchMenu] = useState<any>([]);
  const [reportBatchMenuPage, setReportBatchMenuPage] = useState(0);
  const [reportBatchMenuLimit] = useState(50);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onClearFilters = () => {
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFiltersCleared(true);
    setFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setTravelRuleReportsFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIsFilterCleared(true);
    setReportBatchIds([]);
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>, key: string, index: number) => {
    const selectedFilters = _.find(travelRuleReportsFilters, (o) => o.parentKey === key).filters;
    const newFilters = selectedFilters.map((item: any) => {
      if (item.key === event.target.value) {
        item.isChecked = event.target.checked;
      }

      return item;
    });

    setTravelRuleReportsFilters((csf: any) => _.map(csf, (c) => {
      if (c.parentKey === key) {
        c.filters = newFilters;
      }
      return c;
    }));
  };

  const onReportBatchScroll = (event: any) => {
    const element = event.target;

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setReportBatchMenuPage(reportBatchMenuPage + 1);
    }
  };

  const onApply = () => {
    let query = '';
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    _.map(travelRuleReportsFilters, (o: any) => {
      _.map(o.filters, (f: any) => {
        if (f.isChecked) {
          query += `&${o.parentKey}=${f.key}`;
        }
      });
    });

    if (reportBatchIds.length > 0) {
      _.map(reportBatchIds, (b: any) => {
        query += `&batchNo=${b.value}`;
      });

      setIsReportFilterApplied(true);
    }

    if(query) {
      setFilterQuery(query);
    }
    if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setIsDateFilterApplied(true);
    }
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const onReportDownload = (d: any) => {
    getReportDownload.request(d.id).then((res) => {
      if (res.status === 200) {
        const link = document.createElement('a');
        link.href = res.data.downloadUrl;
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  const checkReportTravelRulePermissions = () => {
    return checkPermissions(userPermissions, 'REPORTS');
  };

  useEffect(() => {
    const newFilters = _.map(travelRuleReportsFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(true);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setEnableButtons(false);
    } else if (isChangeReportBatchIds === true) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [travelRuleReportsFilters, isFiltersCleared, filterDates, isChangeReportBatchIds]);

  useEffect(() => {
    if (!searchQuery) {
      setPage(isUpdatePage ? 0 : page);
      getReports.request('TRAVEL_RULES', page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, filterDates.fromDate, filterDates.toDate, filterQuery).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);
          setIsDateFilterApplied(false);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
        if (isFilterCleared) setIsFilterCleared(false);
        if (isFiltersCleared) setIsFiltersCleared(false);
        if (isChangeReportBatchIds) setIsChangeReportBatchIds(false);
      });
    } else if (searchQuery !== '' && (!isSearchClosed || location.state.isNotification)) {
      setPage(isUpdatePage ? 0 : page);
      getReports.request('TRAVEL_RULES', page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, filterDates.fromDate, filterDates.toDate, filterQuery, searchQuery).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);
          setIsDateFilterApplied(false);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
        if (isFilterCleared) setIsFilterCleared(false);
        if (isFiltersCleared) setIsFiltersCleared(false);
        if (isChangeReportBatchIds) setIsChangeReportBatchIds(false);
      });
    }
  }, [page, rowsPerPage, order, orderBy, searchQuery, filterQuery, isFilterCleared, isFiltersCleared, isDateFilterApplied, location]);

  useEffect(() => {
    getReportsList.request('TRAVEL_RULES', reportBatchMenuPage + 1, reportBatchMenuLimit).then((res) => {
      if (res.status === 200 && res.data.length > 0) {
        setReportBatchMenu((rps: any) => {
          let o: string[] = [];
          _.map(res.data, (item, i) => {
            if (!o.includes(item.batchNo)) {
              o.push(item.batchNo);
            }
          });

          rps = rps.concat(_.map(o, (item, i) => {
            return {value: item, displayName: item};
          }));

          const rpsByUniq = _.uniqBy(rps, 'value');
          const rpsOrderBy = _.orderBy(rpsByUniq, ['displayName'], ['desc']);

          return rpsOrderBy;
        });
      }
    });
  }, [reportBatchMenuPage]);

  return (
    <CssContainer sx={{p: '0 !important', mt: 0}}>
      <CssTable
        headers={TravelRuleReportsHeaders}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        onFilters={handleFilters}
        isDesktopFilters={true}
        sx={{ mt: '0 !important' }}
        rowActions={{
          onDownloadAction: onReportDownload
        }}
        permissionCheck={checkReportTravelRulePermissions()}
        highlightText={searchQuery}
        rowClassName={'no-row-click'}
      />
      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          <CssAccordion title='Date' isExpand={filterDates && filterDates.fromDate && filterDates.toDate}>
            <Filters from={'POP_IN_FILTER'} fromLabel={'From'} toLabel={'To'} />
          </CssAccordion>

           <CssAccordion title='Report batch' isExpand={reportBatchIds.length > 0}>
            <CssAutocomplete label='Select report batch' placeholder='Select report batch' options={reportBatchMenu} onScroll={onReportBatchScroll} />
          </CssAccordion>

          {travelRuleReportsFilters && travelRuleReportsFilters.map((item: any, index: number) => (
            <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
              <FormGroup>
                {item.filters.map((filter: any, i: number) => (
                  <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                ))}
              </FormGroup>
            </CssAccordion>
          ))}

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
    </CssContainer>
  );
};

export default TravelRules;