import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const CssEllipse = styled(Box)(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: 10,
  position: 'relative',
  top: 6,
  marginRight: 10,
  '&.ALERT': {
    backgroundColor: '#FF4534'
  },
  '&.WARNING': {
    backgroundColor: '#FF802C'
  },
  '&.REVIEW': {
    backgroundColor: '#FFC32A'
  },
  '&.VERIFIED': {
    backgroundColor: '#02B9A2'
  },
  '&.CIRCLE': {
    backgroundColor: '#FFF',
    border: '1px solid #04F8DA'
  },
  '&.COMMENT_VERIFIED': {
    backgroundColor: '#04F8DA'
  },
  '&.FIN_OPS': {
    backgroundColor: '#00CCCC'
  },
  '&.COMPLIANCE': {
    backgroundColor: '#2A48EA'
  },
  '&.ACCOUNT_OPENING': {
    backgroundColor: '#FF34A2'
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    margin: 'auto',
    top: 3,
    width: 13,
    height: 13
  }
}));

export default CssEllipse;