import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const StyledTitle = styled(Typography)({
  fontFamily: 'Roboto'
});

const StyledWithUnderline = styled(Grid)(({ theme }) => ({
  borderBottom: `3px solid ${theme.palette.primary.light}`,
  display: 'inline-block',
  marginTop: 24,
  '& .MuiTypography-root': {
    fontFamily: 'Roboto Medium'
  }
}));

const CssTitle = ({ underline, ...rest }: any) => {
  return (
    <>
      {underline ? 
        <StyledWithUnderline>
          <StyledTitle {...rest} />
        </StyledWithUnderline> :
        <StyledTitle {...rest} />
      }
    </>
  );
};

export default CssTitle;