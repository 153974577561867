import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import App from './App';
import GlobalContextProvider from 'context';
import reportWebVitals from './reportWebVitals';
import Auth from 'services/auth';
import UnAuthenticated from 'modules/UnAuthenticated';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderApp = (props: any) => root.render(
    <React.StrictMode>
      <GlobalContextProvider>
        <CssBaseline />
        {(props && props.errors && props.errors.isError) ?
          <UnAuthenticated errors={props.errors} /> :
          <App />
        }
      </GlobalContextProvider>
    </React.StrictMode>
  );

Auth.initKeycloak((props: any) => renderApp(props));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// SRide or QuickRide
reportWebVitals();
