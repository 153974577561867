import { MouseEvent } from 'react';
import { Box, IconButton } from '@mui/material';
import Arrow from 'components/icons/Arrow';
import Skip from 'components/icons/Skip';

interface PaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const Pagination = (props: PaginationProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 7.9 }} className='pagination-actions'>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        <Skip direction={'left'} fill={page === 0 ? '#C4C4C4' : ''} className='first-page' />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        sx={{height: 25, ml: 2, mr: 2}}
      >
        <Arrow direction={'left'} fill={page === 0 ? '#C4C4C4' : ''} className='previous-page' />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        sx={{height: 25, mr: 2}}
      >
        <Arrow fill={page >= Math.ceil(count / rowsPerPage) - 1 ? '#C4C4C4' : ''} className='next-page' />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
        sx={{pr: 0}}
      >
        <Skip fill={page >= Math.ceil(count / rowsPerPage) - 1 ? '#C4C4C4' : ''} className='last-page' />
      </IconButton>
    </Box>
  );
};

export default Pagination;