import { useState } from 'react';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

const useApi = (apiFunc: Function) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const request = async (...args: any) => {
    setLoading(true);

    try {
      const isJavaScriptProtocol = /<[^>]+>/g;
      let dataScanCount = 0;
      for (let i = 0; i < args.length; i++) {
        if (_.isObject(args[i])) {
          Object.keys(args[i]).forEach((item) => {
            if (isJavaScriptProtocol.test(args[i][item])) {
              console.error('Error: Invalid data', args[i][item]);
              dataScanCount++;
            }
          });
        } else if (isJavaScriptProtocol.test(args[i])) {
          console.error('Error: Invalid data', args[i]);
          dataScanCount++;
        }
      }

      if (dataScanCount === 0) {
        const result = await apiFunc(...args);
        setData(result ? result.data : { data: {} });
        return result;
      } else {
        enqueueSnackbar('Invalid data, please try again.', {
          variant: 'xssErrorAlert'
        });
        return {status: 500};
      }
    } catch (err: any) {
      setError(err.message || 'Unexpected Error!');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request
  };
};

export default useApi;