
export const ClientProfileDetails = [
  {
    name: "firstName",
    label: "First Name",
    value: "",
    showEllipses: true,
    isEdit: false,
    isFieldHover: false,
    fieldType: "TEXT"
  },
  {
    name: "lastName",
    label: "Last Name",
    value: "",
    showEllipses: true,
    isEdit: false,
    isFieldHover: false,
    fieldType: "TEXT"
  },
  {
    name: "email",
    label: "Email",
    value: "",
    verified: {value: false, verifiedFrom: "emailVerified" },
    isEdit: false,
    isFieldHover: false,
    fieldType: "TEXT"
  },
  {
    name: "phone",
    label: "Mobile",
    value: "",
    callingCodeValue: "",
    phoneValue: "",
    separator: " ",
    pickValuesFrom: ["callingCode", "phone"],
    isEdit: false,
    isFieldHover: false,
    fieldType: "SELECT_TEXT",
    dropdownList: []
  },
  {
    name: "birthPlace",
    label: "Place of birth",
    value: "",
    isEdit: false,
    isFieldHover: false,
    fieldType: "TEXT"
  },
  {
    name: "dob",
    label: "Date of birth",
    value: "",
    isDate: true,
    isEdit: false,
    isFieldHover: false,
    fieldType: "DATE"
  },
  {
    name: "gender",
    label: "Gender",
    value: "",
    wrapElement: true,
    conversion: "LOWERCASE",
    valueSx: {textTransform: "capitalize"},
    isEdit: false,
    isFieldHover: false,
    fieldType: "SELECT",
    dropdownList: [{value: "MALE", label: "Male"}, {value: "FEMALE", label: "Female"}]
  },
  {
    name: "nationality",
    label: "Nationality",
    value: "",
    isEdit: false,
    isFieldHover: false,
    isCountry: true,
    fieldType: "SELECT",
    dropdownList: []
  },
  {
    name: "residentCountryCode",
    label: "Country of residence",
    value: "",
    isEdit: false,
    isFieldHover: false,
    isCountry: true,
    fieldType: "SELECT",
    dropdownList: []
  },
  {
    name: "address",
    label: "Residential address",
    value: "",
    "address.line1Value": "",
    "address.line2Value": "",
    fullWidth: true,
    separator: ", ",
    pickValuesFrom: ["address.line1", "address.line2"],
    isEdit: false,
    isFieldHover: false,
    fieldType: "TEXT_TEXT"
  },
  {
    name: "city",
    label: "City",
    value: "",
    pickValuesFrom: ["address.city"],
    isEdit: false,
    isFieldHover: false,
    fieldType: "TEXT"
  },
  {
    name: "province",
    label: "Province",
    value: "",
    pickValuesFrom: ["address.province"],
    isEdit: false,
    isFieldHover: false,
    fieldType: "TEXT"
  },
  {
    name: "postcode",
    label: "Postal Code",
    value: "",
    pickValuesFrom: ["address.postcode"],
    isEdit: false,
    isFieldHover: false,
    fieldType: "TEXT"
  },
  {
    section: "taxCountries",
    title: "Tax Information",
    rootField: {
      name: "isUsTaxpayer",
      label: "US citizen or dual citizen and/or a US tax resident",
      value: "",
      isEdit: false,
      isFieldHover: false,
      fieldType: "SELECT",
      dropdownList: [{value: true, label: "Yes"}, {value: false, label: "No"}],
      isBoolean: true
    },
    countryShortName: "Country",
    tin: "Tax ID",
    fields: []
  },
  {
    section: "employment",
    title: "Occupation",
    isEdit: false,
    isFieldHover: false,
    fieldType: "TEXT"
  },
  {
    name: "status",
    label: "Employment Status",
    value: "",
    pickValuesFrom: ["employment.status"],
    isEdit: false,
    isFieldHover: false,
    fieldType: "SELECT",
    pickLable: true,
    dropdownList: [
      { value: "EMPLOYED", label: "Employed" },
      { value: "SELF_EMPLOYED", label: "Self-employed" },
      { value: "RETIRED", label: "Retired" },
      { value: "NOT_EMPLOYED", label: "Not employed" },
      { value: "STUDENT", label: "Student" }
    ]
  },
  {
    name: "industry",
    label: "Industry/Sector",
    value: "",
    pickValuesFrom: ["employment.industry"],
    isEdit: false,
    isFieldHover: false,
    isDisabled: false,
    fieldType: "SELECT",
    dropdownList: []
  },
  {
    name: "employer",
    label: "Employer Name",
    value: "",
    pickValuesFrom: ["employment.employer"],
    isEdit: false,
    isFieldHover: false,
    isDisabled: false,
    fieldType: "TEXT"
  },
  {
    name: "position",
    label: "Position",
    value: "",
    pickValuesFrom: ["employment.position"],
    isEdit: false,
    isFieldHover: false,
    isDisabled: false,
    fieldType: "SELECT",
    dropdownList: []
  },
  {
    name: "annualIncome",
    label: "Annual Income",
    value: "",
    separator: "-",
    pickValuesFrom: ["employment.annualIncome.min", "employment.annualIncome.max"],
    isEdit: false,
    isFieldHover: false,
    fieldType: "SELECT",
    pickLable: true,
    dropdownList: [
      { value: "0-50000", label: "$0 - $50000" },
      { value: "50000-100000", label: "$50000 - $100000" },
      { value: "100000-150000", label: "$100000 - $150000" },
      { value: "150000+", label: "$150000 +" },
    ]
  },
  {
    name: "netWorth",
    label: "Net Worth",
    value: "",
    separator: "-",
    pickValuesFrom: ["employment.netWorth.min", "employment.netWorth.max"],
    isEdit: false,
    isFieldHover: false,
    fieldType: "SELECT",
    pickLable: true,
    dropdownList: [
      { value: "0-50000", label: "$0 - $50000" },
      { value: "50000-100000", label: "$50000 - $100000" },
      { value: "100000-150000", label: "$100000 - $150000" },
      { value: "150000-300000", label: "$150000 - $300000" },
      { value: "300000-500000", label: "$300000 - $500000" },
      { value: "500000+", label: "$500000 +" },
    ]
  },
  {
    name: "isProfessionalSubscriber",
    label: "Professional Subscriber",
    value: "",
    pickValuesFrom: ["isProfessionalSubscriber"],
    isEdit: false,
    isFieldHover: false,
    fieldType: "SELECT",
    dropdownList: [{value: true, label: "Yes"}, {value: false, label: "No"}]
  },
  {
    section: "pep",
    title: "PEP/Connected persons",
    renderBy: "pepRelevance",
    renderByField: [],
    isEdit: false,
    isFieldHover: false,
    fieldType: "CHECKBOX",
    dropdownList: [
      { value: "PTC_DIRECTOR_OR_SHAREHOLDER", label: "A director or 10% shareholder of a publicly traded corporation" },
      { value: "BROKERAGE_FIRM_EMPLOYEE", label: "Employed by a brokerage firm or securities exchange" },
      { value: "HIGH_LEVEL_PUBLIC_OFFICIAL", label: "A current or former Politically Exposed Person or Public Official" },
      { value: "NONE", label: "None of these apply to me" }
    ]
  },
  {
    section: "appropriatenessTest",
    title: "Appropriateness test",
    isFieldsEditable: true,
    fields: []
  }
];