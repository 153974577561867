import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '1rem',
  borderRadius: 30,
  margin: '0 5.5px',
  boxShadow: 'none',
  '&.active': {
    '&.MuiButton-contained': {
      border: `3px solid ${theme.palette.primary.light}`,
      lineHeight: 1.438,
      fontWeight: 500,
    },
    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: 'transparent'
    }
  }
}));

const StyledFilterButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '1rem',
  borderRadius: 30,
  position: 'absolute',
  width: 'auto',
  marginLeft: '0 !important',
  '&.MuiButton-contained': {
    border: `3px solid ${theme.palette.primary.light}`,
    lineHeight: 1.438,
    fontWeight: 500,
  },
  '&.MuiButton-outlined': {
    color: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
    lineHeight: 1.813,
  }
}));

const CssButton = ({ isFilter, ...rest }: any) => {
  return (
    <>
      {isFilter && <StyledFilterButton {...rest} />}
      {!isFilter && <StyledButton {...rest} />}
    </>
    
  );
};

export default CssButton;