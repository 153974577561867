import Icon from './Icon';

const Search = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9814 19.3071C14.6685 21.9604 9.81924 21.7515 6.74826 18.6806C3.45322 15.3855 3.45322 10.0432 6.74826 6.74814C10.0433 3.45309 15.3856 3.45309 18.6807 6.74814C21.7517 9.81912 21.9605 14.6684 19.3072 17.9812L25.7517 24.4258C26.1179 24.7919 26.1179 25.3855 25.7517 25.7516C25.3856 26.1177 24.792 26.1177 24.4259 25.7516L17.9814 19.3071ZM8.07409 17.3547C5.51127 14.7919 5.51127 10.6368 8.07409 8.07397C10.6369 5.51115 14.792 5.51115 17.3549 8.07397C19.9158 10.6349 19.9177 14.7858 17.3605 17.3491C17.3586 17.351 17.3567 17.3528 17.3548 17.3547C17.353 17.3566 17.3511 17.3585 17.3492 17.3604C14.786 19.9176 10.635 19.9157 8.07409 17.3547Z" fill={fill || 'black'}/>
      </svg>
    </Icon>
  );
};

export default Search;