/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, MouseEvent, ChangeEvent } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { useMediaQuery, Box, Typography, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import CssTable from 'components/CssTable';
import Transactions from 'components/Transactions';
import ScreenDialog from 'components/ScreenDialog';
import CheckTick from 'components/icons/CheckTick';
import FilterButton from 'components/FilterButton';
import CssButton from 'components/CssButton';
import RestrictedScreen from 'components/RestrictedScreen';
import ButtonGroup from 'components/ButtonGroup';
import Transfers from 'components/Transfers';
import Referrals from 'components/Referrals';
import { DashboardHeaders } from 'constants/dashboard-table-head-columns';
import { DashboardAccountOpeningHeaders } from 'constants/dashboard-acc-table-head-columns';
import { Order } from 'utils';
import { GlobalContext } from 'context';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import RoleFilters from './RoleFilters';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledTitleWrapperBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between'
}));


const Dashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { searchQuery, isSearchClosed, dashboardSelectedFilter, isUpdatePage, setIsUpdatePage, userPermissions, isSubmodulePermissionGranted, setIsSubmodulePermissionGranted, selectedButtonType, setSelectedButtonType } = useContext(GlobalContext);
  const { getDashboardClientsApi, updateKycStatusApi } = useRequests();
  const getDashboardClients = useApi(getDashboardClientsApi);
  const updateKycStatus = useApi(updateKycStatusApi);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('registrationDate');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isFinOps, setIsFinOps] = useState<boolean>(false);
  const [openIdVerificationStatusScreen, setOpenIdVerificationStatusScreen] = useState<boolean>(false);
  const [kycApplicantId, setKycApplicantId] = useState<string | number>('');

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  type Params = {
    page: number,
    limit: number,
    q?: string,
    sort?: number,
    sortBy?: string,
    groupName?: string
  }

  const getClientsCall = (params: Params) => {
    const { page, limit, q, sort, sortBy, groupName } = params;
    getDashboardClients.request(page + 1, limit, q, sort, sortBy, groupName).then((res) => {
      if (res.status === 200) {
        const { totalcount } = res.headers;
        setTotalCount(parseInt(totalcount));
        setData(res.data);
      }
      if (isUpdatePage) setIsUpdatePage(false);
    });
  };

  const onManualIdVerificationUpdate = (data: any) => {
    setKycApplicantId(data.kycApplicantId);
    onIdVerificationStatusScreenOpen();
  };

  const onIdVerificationStatusScreenOpen = () => {
    setOpenIdVerificationStatusScreen(true);
  };

  const onIdVerificationStatusScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenIdVerificationStatusScreen(false);
  };

  const onStatusUpdateConfirm = () => {
    updateKycStatus.request(kycApplicantId, {
      groupName: dashboardSelectedFilter,
      status: 'DIS_QUALIFIED'
    }).then((res) => {
      if (res.status === 200) {
        getClientsCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, ...(searchQuery && {q: searchQuery}), ...(dashboardSelectedFilter && {groupName: dashboardSelectedFilter}) });
        setKycApplicantId('');
      }
      setOpenIdVerificationStatusScreen(false);
      enqueueSnackbar('Client\'s status has been updated successfully.', {
        variant: 'successAlert'
      });
    });
  };

  const getGroupName = (selectedFilter: string) => {
    if (selectedFilter === 'DASHBOARD_FIN_OPS') {
      return 'FIN_OPS';
    } else if (selectedFilter === 'DASHBOARD_ACC') {
      return 'ACCOUNT_OPENING';
    } else if (selectedFilter === 'DASHBOARD_COMP') {
      return 'COMPLIANCE';
    } 
  };

  const onPaymentsType = (e: MouseEvent<HTMLDivElement>) => {
    const type = (e.target as HTMLDivElement).dataset.filter;
    setSelectedButtonType(type);
  };

  const getTransactionsView = () => {
    if (selectedButtonType === 'TRANSFERS_MAIN' ) {
      return <Transfers from={'FINOPS'} />;
    } else if (selectedButtonType === 'REFERRAL_GIFTS') {
      return <Referrals from={'FINOPS'} />
    } else {
      return <Transactions from={'FINOPS'} />;
    }
  };

  useEffect(() => {
    if (!selectedButtonType) {
      setSelectedButtonType('DEPOSIT');
    }
  }, [selectedButtonType]);

  useEffect(() => {
    if (userPermissions.length <= 0) return;
    if (userPermissions.length && !_.find(userPermissions, (up) => up.resourceName === dashboardSelectedFilter).isVisible) {
      setIsSubmodulePermissionGranted(true);
    } else {
      setIsSubmodulePermissionGranted(false);
      if (dashboardSelectedFilter === 'DASHBOARD_FIN_OPS') {
        setIsFinOps(true);
      } else {
        setIsFinOps(false);
        if (!searchQuery) {
          getClientsCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, ...(dashboardSelectedFilter && {groupName: getGroupName(dashboardSelectedFilter)}) });
        } else if (searchQuery !== '' && (!isSearchClosed || location.state.isNotification)) {
          setPage(isUpdatePage ? 0 : page);
          getClientsCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, q: searchQuery, ...(dashboardSelectedFilter && {groupName: getGroupName(dashboardSelectedFilter)}) });
        }
      }
    }
  }, [page, rowsPerPage, order, orderBy, searchQuery, isSearchClosed, dashboardSelectedFilter, isUpdatePage, userPermissions, location]);

  return (
    <CssContainer>
      {isMobile ? 
        <CssTitle variant='subtitle2'>Dashboard</CssTitle>
       : 
        <StyledTitleWrapperBox>
          <CssTitle variant='h4' underline>Dashboard</CssTitle> 
          <RoleFilters />
        </StyledTitleWrapperBox>
      }

      {isSubmodulePermissionGranted ? <RestrictedScreen containerSx={{height: '60vh', mt: 3}} /> : <>

      {isFinOps ? <>
          <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
            <ButtonGroup variant='light' data={[
              {
                label: 'Deposits',
                type: 'DEPOSIT',
                onClick: onPaymentsType
              },
              {
                label: 'Withdrawals',
                type: 'WITHDRAW',
                onClick: onPaymentsType
              },
              {
                label: 'Transfers',
                type: 'TRANSFERS_MAIN',
                onClick: onPaymentsType
              },
              {
                label: 'Gifts',
                type: 'REFERRAL_GIFTS',
                onClick: onPaymentsType
              }
            ]} />
          </Box>
          {getTransactionsView()}
        </> :
        <CssTable
          headers={dashboardSelectedFilter === 'DASHBOARD_COMP' ? DashboardHeaders : DashboardAccountOpeningHeaders}
          totalCount={totalCount}
          data={data}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          highlightText={searchQuery}
          onRequestSort={handleRequestSort}
          onTablePageChange={handleChangePage}
          onTableRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ mt: {md: 3, xs: 0} }}
          rowClassName={'no-row-click'}
          {...(dashboardSelectedFilter === 'DASHBOARD_COMP' && { onStatusAction: onManualIdVerificationUpdate, from: 'DASHBOARD'})}
        />
      }

      {!isFinOps && dashboardSelectedFilter === 'DASHBOARD_COMP' && <ScreenDialog
        title={'Transaction status'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openIdVerificationStatusScreen}
        onScreenClose={onIdVerificationStatusScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>ID Verification status</Typography>
        </StyledBox>

        <Box sx={{pl: 4, pr: 4}}>
          <FilterButton className={'active btn-filter btn-flat custom btn-transaction-status'} filter='DIS_QUALIFIED' onClick={() => {}}>Disqualified <CheckTick /></FilterButton>

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5, justifyContent: 'space-between', display: 'flex' }}>
              <CssButton variant='outlined' sx={{width: '35%', mr: 1, ml: 0}} onClick={onIdVerificationStatusScreenClose}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '35%', mr: 0, ml: 1}} onClick={onStatusUpdateConfirm} className='active'>Confirm</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>}
      </>}
    </CssContainer>
  );
};

export default Dashboard;