import { processHolidayDate } from 'utils';

export const HolidayCalendarHeaders = {
  columns:  [
    {
      key: 'holidayDate',
      label: 'Year',
      labelStyles: {
        width: 90
      },
      processCellData: processHolidayDate,
      fieldHeaderType: 'DROPDOwN',
      fieldType: 'DATE'
    },
    {
      key: 'holidayName',
      label: 'Holiday',
      fieldType: 'TEXT'
    },
    {
      key: 'exchangeStatus',
      label: 'Exchange status',
      labelStyles: {
        width: 110
      },
      fieldType: 'SELECT'
    },
    {
      key: 'startTime',
      label: 'Start time',
      labelStyles: {
        width: 110
      },
      fieldType: 'TIME'
    },
    {
      key: 'closeTime',
      label: 'Close time',
      fieldType: 'TIME'
    }
  ],
  timeColumns: ['startTime', 'closeTime']
};