import Icon from './Icon';

const Calendar = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7 3.25C7.41421 3.25 7.75 3.58579 7.75 4V5.66763C10.5777 5.4158 13.4223 5.4158 16.25 5.66763V4C16.25 3.58579 16.5858 3.25 17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V5.81644C19.243 6.02754 20.4226 7.20847 20.6222 8.71484L20.7088 9.36779C21.0728 12.1141 21.0414 14.8984 20.6154 17.6359C20.4044 18.9919 19.3028 20.0335 17.937 20.1681L16.744 20.2858C13.589 20.5969 10.411 20.5969 7.25593 20.2858L6.06292 20.1681C4.69718 20.0335 3.59552 18.9919 3.38451 17.6359C2.95855 14.8984 2.92711 12.1141 3.29115 9.36779L3.3777 8.71484C3.57738 7.20845 4.75693 6.02751 6.25 5.81643V4C6.25 3.58579 6.58579 3.25 7 3.25ZM7.44516 7.2028C10.4744 6.90408 13.5256 6.90408 16.5548 7.2028L17.4602 7.2921C18.327 7.37757 19.0208 8.04854 19.1352 8.91194L19.2218 9.56489C19.252 9.793 19.2794 10.0214 19.3039 10.25H4.69606C4.72055 10.0214 4.7479 9.79301 4.77814 9.5649L4.86469 8.91194C4.97914 8.04854 5.67296 7.37757 6.53971 7.2921L7.44516 7.2028ZM4.57683 11.75C4.47865 13.6381 4.57538 15.5332 4.86667 17.4052C4.97251 18.0854 5.52509 18.6078 6.21013 18.6754L7.40314 18.793C10.4603 19.0945 13.5397 19.0945 16.5968 18.793L17.7898 18.6754C18.4748 18.6078 19.0274 18.0854 19.1333 17.4052C19.4246 15.5332 19.5213 13.6381 19.4231 11.75H4.57683Z" fill={fill || 'black'}/>
      </svg>
    </Icon>
  );
};

export default Calendar;