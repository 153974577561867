import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const StyledTextField = styled(TextField)({
  borderRadius: '30px',
  color: '#000',
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    color: '#000'
  },
  '& fieldset': {
    borderColor: '#000'
  }
});

const StyledInputAdornment = styled(InputAdornment)({
  '& .MuiTypography-root': {
    color: '#000',
    fontWeight: '600',
    fontSize: '.9rem'
  }
});

const CssInputField = ({ adornment, label, ...rest }: any) => {
  return (
    <>
      {adornment ? 
        <StyledTextField {...rest} 
          InputProps={{
            startAdornment: <StyledInputAdornment position='start'>{ label }</StyledInputAdornment>,
          }}
        /> :
        <StyledTextField {...rest} />
      }
    </>
  );
};

export default CssInputField;