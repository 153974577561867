
export const TransactionsStatusFilters = [
  {
    parentKey: 'status',
    title: 'Status',
    filters: [
      {
        key: 'APPROVED_BY_PAYMENT_PROVIDER',
        label: 'Approved',
        isChecked: false
      },
      {
        key: 'FAILED',
        label: 'Failed',
        isChecked: false
      },
      {
        key: 'RECONCILED',
        label: 'Reconciled',
        isChecked: false
      },
      {
        key: 'UNRECEIVED',
        label: 'Unreceived',
        isChecked: false
      },
      {
        key: 'SUCCESSFUL',
        label: 'Credited',
        isChecked: false
      },
      {
        key: 'CREDIT_ERROR',
        label: 'Credit error',
        isChecked: false
      },
      {
        key: 'WITHDRAWAL_REQUESTED',
        label: 'Requested',
        isChecked: false
      },
      {
        key: 'WITHDRAWAL_REQUEST_INIT',
        label: 'Pending review',
        isChecked: false
      },
      {
        key: 'DW_CLEARED',
        label: 'DW cleared',
        isChecked: false
      },
      {
        key: 'DW_REJECTED',
        label: 'DW rejected',
        isChecked: false
      },
      {
        key: 'QUEUED',
        label: 'Queued',
        isChecked: false
      },
      {
        key: 'CANCELLED',
        label: 'Cancelled',
        isChecked: false
      }
    ]
  },
  {
    parentKey: 'paymentProviderName',
    title: 'Payment provider',
    filters: [
      {
        key: 'Nuvei',
        label: 'Nuvei',
        isChecked: false
      },
      {
        key: 'Leantech',
        label: 'Lean',
        isChecked: false
      }
    ]
  },
  {
    parentKey: 'paymentMethod',
    title: 'Method',
    filters: [
      {
        key: 'APPLE_PAY',
        label: 'Apple pay',
        isChecked: false
      },
      {
        key: 'BANK_TRANSFER',
        label: 'Bank transfer',
        isChecked: false
      },
      {
        key: 'CARD_TRANSFER',
        label: 'Card transfer',
        isChecked: false
      },
      {
        key: 'GOOGLE_PAY',
        label: 'Google pay',
        isChecked: false
      }
    ]
  }
];