import Icon from './Icon';

const User = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4.6875C12.4112 4.6875 10.3125 6.78617 10.3125 9.375C10.3125 11.9638 12.4112 14.0625 15 14.0625C17.5888 14.0625 19.6875 11.9638 19.6875 9.375C19.6875 6.78617 17.5888 4.6875 15 4.6875Z" fill="currentColor" />
      <path d="M10 16.5625C7.41116 16.5625 5.3125 18.6612 5.3125 21.25V22.7354C5.3125 23.6769 5.99485 24.4797 6.92409 24.6314C12.2726 25.5046 17.7274 25.5046 23.0759 24.6314C24.0051 24.4797 24.6875 23.6769 24.6875 22.7354V21.25C24.6875 18.6612 22.5888 16.5625 20 16.5625H19.5739C19.3433 16.5625 19.1141 16.599 18.8949 16.6706L17.813 17.0238C15.9852 17.6207 14.0148 17.6207 12.187 17.0238L11.1051 16.6706C10.8859 16.599 10.6567 16.5625 10.4261 16.5625H10Z" fill="currentColor" />
      </svg>
    </Icon>
  );
};

export default User;