import { styled } from '@mui/material/styles';
import { Box, Typography, Button, Link, IconButton } from '@mui/material';
import RightCaret from 'components/icons/RightCaret';
import Notification from 'components/icons/Notification';
import ActiveNotification from 'components/icons/ActiveNotification';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '18px 14px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#04F8DA0D'
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&.count-members': {
    marginTop: 2,
    marginRight: 30,
    fontSize: '.75rem',
    paddingTop: 2,
    width: 70
  }
}));

const StyledIconButton = styled(IconButton)({
  height: 28,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&.notification': {
    marginRight: 20,
    top: 3,
    width: 25
  }
});

const LinkModule = ({ record, name, onModuleClick, count, onClickMembers, onNotificationClick }: any) => {
  return (
    <StyledBox onClick={onModuleClick}>
      <StyledTypography>{name}</StyledTypography>
      <Box sx={{display: 'flex'}}>
        {onNotificationClick && record && <StyledIconButton
          color='inherit'
          aria-label='open drawer'
          onClick={(event: any) => {
            event.stopPropagation();
            onNotificationClick(record.roleId, !record.isNotificationEnabled);
          }}
          edge='start'
          className='notification'
        >
          {record.isNotificationEnabled ? <ActiveNotification /> : <Notification />}
        </StyledIconButton>}
        {count && !onClickMembers && <StyledTypography className='count-members'>{count} {count === '1' || count === '0' ? 'member': 'members'}</StyledTypography>}
        {count && onClickMembers && <Button onClick={onClickMembers} component={Link}>
          <StyledTypography className='count-members'>{count} {count === '1' || count === '0' ? 'member': 'members'}</StyledTypography>
        </Button>}
        <RightCaret />
      </Box>
    </StyledBox>
  );
};

export default LinkModule;