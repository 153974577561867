
export const ClientStatusFilters = [
  {
    parentKey: 'status',
    title: 'Status',
    filters: [
      {
        key: 'REGISTERED',
        label: 'Registered',
        isChecked: false
      },
      {
        key: 'REGFULL',
        label: 'Regfull',
        isChecked: false
      },
      {
        key: 'APPROVED',
        label: 'Approved',
        isChecked: false
      },
      {
        key: 'FUNDED',
        label: 'Funded',
        isChecked: false
      },
      {
        key: 'TRADED',
        label: 'Traded',
        isChecked: false
      },
      {
        key: 'DECLINED',
        label: 'Declined',
        isChecked: false
      },
      {
        key: 'DORMANT',
        label: 'Dormant',
        isChecked: false
      },
    ]
  },
  {
    parentKey: 'riskRating',
    title: 'Risk rating',
    filters: [
      {
        key: 'LOW',
        label: 'Low',
        isChecked: false
      },
      {
        key: 'MEDIUM',
        label: 'Medium',
        isChecked: false
      },
      {
        key: 'HIGH',
        label: 'High',
        isChecked: false
      },
      {
        key: 'VERY_HIGH',
        label: 'Very high',
        isChecked: false
      }
    ]
  },
  {
    parentKey: 'idVerification',
    title: 'ID verification',
    filters: [
      {
        key: 'VERIFIED',
        label: 'Verified',
        isChecked: false
      },
      {
        key: 'REJECTED',
        label: 'Rejected',
        isChecked: false
      },
      {
        key: 'RESUBMISSION_REQUIRED',
        label: 'Resubmission required',
        isChecked: false
      },
      {
        key: 'MANUAL_REVIEW_REQUIRED',
        label: 'Manual review required',
        isChecked: false
      },
      {
        key: 'PENDING',
        label: 'Pending',
        isChecked: false
      },
      {
        key: 'DIS_QUALIFIED',
        label: 'Disqualified',
        isChecked: false
      },
    ]
  }
];