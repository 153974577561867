/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, MouseEvent, useRef, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, Slide, Paper, Grid, InputAdornment, IconButton, Divider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import FilterButton from 'components/FilterButton';
import CheckTick from 'components/icons/CheckTick';
import CssTitle from 'components/CssTitle';
import CssInputField from 'components/CssInputField';
import Calendar from 'components/icons/Calendar';
import Caret from 'components/icons/Caret';
import CssButton from 'components/CssButton';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.date-filters-wrapper': {
    '& .btn-filter': {
      paddingTop: 8,
      paddingBottom: 8,
      borderLeft: 0,
      borderRight: 0,
      '&:first-child': {
        borderTop: '1px solid #E5E5E5'
      }
    }
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '70vh',
  boxShadow: 'none',
  borderRadius: 0,
  display: 'none',
  backgroundColor: theme.palette.background.default,
  '&.active': {
    display: 'block'
  }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    padding: 18,
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiInput-root': {
      fontSize: '1.25rem',
      marginTop: 15
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0 !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0 !important'
    }
  }
}));

const DateRangeFilters = (props: any) => {
  const { onCancel, device } = props;
  const { setDateRange, setDefaultFilterDates, dateSelectedFilter, setDateSelectedFilter } = useContext(GlobalContext);
  const [checked, setChecked] = useState(false);
  const containerRef = useRef(null);
  const [valueFrom, setValueFrom] = useState<string | null>(null);
  const [valueTo, setValueTo] = useState<string | null>(null);
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  const [minDate, setMinDate] = useState('');

  const handleFilter = (e: MouseEvent<HTMLDivElement>) => {
    const filter = (e.target as HTMLDivElement).dataset.filter;
    setDateSelectedFilter(filter || 'all');

    if (filter === 'custom') {
      openCustomDates();
    }
  };

  const openCustomDates = () => {
    setChecked((prev) => !prev);
  };

  const handleChangeFrom = (newValue: Date | null) => {
    const value = moment(newValue).format('MM.DD.yyyy');
    setValueFrom(value);
    setMinDate(value);

    if (valueTo && value > valueTo) {
      setValueTo(null);
    }
  };

  const handleChangeTo = (newValue: Date | null) => {
    const value = moment(newValue).format('MM.DD.yyyy');
    setValueTo(value);
  };

  const onApply = () => {
    if (dateSelectedFilter === 'custom') {
      setDefaultFilterDates({ 
        fromDate: moment(valueFrom).format('yyyy-MM-DD'),
        toDate: moment(valueTo).format('yyyy-MM-DD')
      });
    } else {
      if (dateSelectedFilter && dateSelectedFilter !== 'all') {
        setValueFrom(moment().subtract(parseInt(dateSelectedFilter),'d').format('MM.DD.yyyy'));
        setValueTo(moment().format('MM.DD.yyyy'));
        setDefaultFilterDates({
          fromDate: moment().subtract(parseInt(dateSelectedFilter),'d').format('yyyy-MM-DD'),
          toDate: moment().format('yyyy-MM-DD')
        });
      } else {
        setDefaultFilterDates({ fromDate: '', toDate: '' });
      }
    }
    setDateRange(`${valueFrom} - ${valueTo}`);
  };

  const handleOpenFrom = () => setOpenFrom(true);
  const handleCloseFrom = () => setOpenFrom(false);
  const handleOpenTo = () => setOpenTo(true);
  const handleCloseTo = () => setOpenTo(false);

  useEffect(() => {
    if (dateSelectedFilter && device === 'DESKTOP') {
      onApply();
    }
  }, [dateSelectedFilter]);

  return (
    <StyledBox ref={containerRef} sx={{position: 'relative'}} {...(device === 'DESKTOP' && {className: 'date-filters-wrapper'})}>
      <Stack direction='column' sx={{ display: 'flex' }}>
        <FilterButton className={dateSelectedFilter === 'all' ? 'active btn-filter btn-flat' : 'btn-filter btn-flat'} filter='all' onClick={handleFilter}>All <CheckTick /></FilterButton>
        <FilterButton className={dateSelectedFilter === '7days' ? 'active btn-filter btn-flat' : 'btn-filter btn-flat'} filter='7days' onClick={handleFilter}>Last 7 days <CheckTick /></FilterButton>
        <FilterButton className={dateSelectedFilter === '30days' ? 'active btn-filter btn-flat' : 'btn-filter btn-flat'} filter='30days' onClick={handleFilter}>Last 30 days <CheckTick /></FilterButton>

        {!device && <FilterButton className={dateSelectedFilter === 'custom' ? 'active btn-filter btn-flat custom' : 'btn-filter btn-flat custom'} filter='custom' onClick={handleFilter}>Custom <Caret /></FilterButton>}
      </Stack>

      {!device && <Slide direction='up' in={checked} container={containerRef.current}>
        <StyledPaper {...(checked ? {className: 'active'} : {className: ''})}>
          <FilterButton className={dateSelectedFilter === 'custom' ? 'active btn-filter btn-flat custom-active' : 'btn-filter btn-flat custom-active'} filter='custom' onClick={handleFilter}>Custom <Caret /></FilterButton>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
              <StyledGrid item xs={12}>
                <CssTitle variant='body1'>From</CssTitle>
                <MobileDatePicker
                  label='From'
                  inputFormat='MM.dd.yyyy'
                  value={valueFrom}
                  open={openFrom}
                  onOpen={handleOpenFrom}
                  onClose={handleCloseFrom}
                  onChange={handleChangeFrom}
                  renderInput={(params) => <CssInputField
                    {...params}
                    size='small'
                    variant='standard'
                    placeholder='Select a date'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton edge='end' onClick={handleOpenFrom}>
                            <Calendar />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />}
                />
              </StyledGrid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <StyledGrid item xs={12}>
                <CssTitle variant='body1'>To</CssTitle>
                <MobileDatePicker
                  label='To'
                  inputFormat='MM.dd.yyyy'
                  value={valueTo}
                  open={openTo}
                  onOpen={handleOpenTo}
                  onClose={handleCloseTo}
                  onChange={handleChangeTo}
                  minDate={new Date(minDate)}
                  renderInput={(params) => <CssInputField
                    {...params}
                    size='small'
                    variant='standard'
                    placeholder='Select a date'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton edge='end' onClick={handleOpenTo}>
                            <Calendar />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />}
                />
              </StyledGrid>
            </Grid>
          </LocalizationProvider>
        </StyledPaper>
      </Slide>}
      {!device && <StyledGrid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
        <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={() => onCancel()}>Cancel</CssButton>
        <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={() => onApply()}>Apply</CssButton>
      </StyledGrid>}
    </StyledBox>
  );
};

export default DateRangeFilters;