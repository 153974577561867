/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, InputAdornment, IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import CssTitle from './CssTitle';
import CssInputField from './CssInputField';
import TimePicker from './TimePicker';
import { GlobalContext } from 'context';
import Calendar from './icons/Calendar';

interface DateTimeFiltersProps {
  from?: string
  fromLabel?: string
  toLabel?: string
  id?: string
  minFromDate?: string
}

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    height: 54,
    display: 'flex',
    paddingBottom: 10,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .btn-filter': {
      width: 140,
      textAlign: 'center',
      '&.all': {
        width: 72
      }
    }
  }
}));

const DateTimeFilters = (props: DateTimeFiltersProps) => {
  const { fromLabel, toLabel, id, minFromDate } = props;
  const { paymentFilterDates, setPaymentFilterDates } = useContext(GlobalContext);
  const [valueFrom, setValueFrom] = useState<string | null>(null);
  const [valueTo, setValueTo] = useState<string | null>(null);
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  const [minDate, setMinDate] = useState('');

  const handleChangeFrom = (newValue: Date | null) => {
    const value = moment(newValue).format('MM.DD.yyyy');
    setValueFrom(value);
    setMinDate(value);

    if (valueTo && value > valueTo) {
      setValueTo(null);
    }

    let filterData = paymentFilterDates; 

    if (id) {
      const v = paymentFilterDates && paymentFilterDates[`${id}ToDate`];

      filterData = {...filterData, 
        [`${id}FromDate`]: moment(value).format('yyyy-MM-DD'),
        [`${id}FromDateError`]: false,
        [`${id}ToDate`]: v ? moment(v).format('yyyy-MM-DD') : '',
        [`${id}ToDateError`]: v ? false : true
      }
    } else {
      const v = paymentFilterDates && paymentFilterDates.toDate;

      filterData = {...filterData, 
        fromDate: moment(value).format('yyyy-MM-DD'),
        isFromDateError: false,
        toDate: v ? moment(v).format('yyyy-MM-DD') : '',
        isToDateError: v ? false : true
      };
    }
    setPaymentFilterDates(filterData);
  };

  const handleChangeTo = (newValue: Date | null) => {
    const value = moment(newValue).format('MM.DD.yyyy');
    setValueTo(value);

    let filterData = paymentFilterDates;

    if (id) {
      const v = paymentFilterDates && paymentFilterDates[`${id}FromDate`];

      filterData = {...filterData, 
        [`${id}FromDate`]: v ? moment(v).format('yyyy-MM-DD') : '',
        [`${id}FromDateError`]: v ? false : true,
        [`${id}ToDate`]: moment(value).format('yyyy-MM-DD'),
        [`${id}ToDateError`]: false
      }
    } else {
      const v = paymentFilterDates && paymentFilterDates.fromDate;

      filterData = {...filterData,
        fromDate: v ? moment(v).format('yyyy-MM-DD') : '',
        isFromDateError: v ? false : true,
        toDate: moment(value).format('yyyy-MM-DD'),
        isToDateError: false
      };
    }

    setPaymentFilterDates(filterData);
  };

  const handleOpenFrom = () => setOpenFrom(true);
  const handleCloseFrom = () => setOpenFrom(false);
  const handleOpenTo = () => {
    const v = (paymentFilterDates && paymentFilterDates.fromDate) || valueFrom;
    setMinDate(moment(v).format('MM.DD.yyyy'));
    setOpenTo(true);
  };
  const handleCloseTo = () => setOpenTo(false);

  useEffect(() => {
    if (paymentFilterDates &&  paymentFilterDates.fromDate === '' && paymentFilterDates.toDate === '') {
      setValueFrom(null);
      setValueTo(null);
      setMinDate('');
    }
  }, [paymentFilterDates]);

  return (
    <StyledBox sx={{ mt: {md: 1, xs: 3}, mb: {md: 1.25, xs: 0} }} className='filters-wrapper'>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {!id && <Box sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}>
          {fromLabel && <CssTitle variant={'body1'} sx={{mb: .5}}>{fromLabel}</CssTitle>}
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <DatePicker
              label='From'
              inputFormat='MM.dd.yyyy'
              value={(paymentFilterDates && paymentFilterDates.fromDate) || null}
              open={openFrom}
              onOpen={handleOpenFrom}
              onClose={handleCloseFrom}
              onChange={handleChangeFrom}
              renderInput={(params) => <CssInputField
                {...params}
                size='small'
                {...(paymentFilterDates && paymentFilterDates.isFromDateError) && {error: true}}
                sx={{width: '50%', mb: 2.5}}
                InputProps={{
                  placeholder: 'From',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton edge='end' sx={{ top: 3 }} onClick={handleOpenFrom}>
                        <Calendar />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />}
            />
            <TimePicker pickerType='fromDate' {...(id && {id: id})} />
          </Box>

          {toLabel && <CssTitle variant={'body1'} sx={{mb: .5}}>{toLabel}</CssTitle>}
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <DatePicker
              label='To'
              inputFormat='MM.dd.yyyy'
              value={(paymentFilterDates && paymentFilterDates.toDate) || null}
              open={openTo}
              onOpen={handleOpenTo}
              onClose={handleCloseTo}
              onChange={handleChangeTo}
              minDate={new Date(minDate)}
              renderInput={(params) => <CssInputField
                {...params}
                size='small'
                {...(paymentFilterDates && paymentFilterDates.isToDateError) && {error: true}}
                sx={{width: '50%'}}
                InputProps={{
                  placeholder: '',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton edge='end' sx={{ top: 3 }} onClick={handleOpenTo}>
                        <Calendar />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />}
            />
            <TimePicker pickerType='toDate' {...(id && {id: id})} />
          </Box>
        </Box>}

        {id && <Box sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}>
          {fromLabel && <CssTitle variant={'body1'} sx={{mb: .5}}>{fromLabel}</CssTitle>}
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <DatePicker
              label='From'
              inputFormat='MM.dd.yyyy'
              value={(paymentFilterDates && paymentFilterDates[`${id}FromDate`]) || null}
              open={openFrom}
              {...(minFromDate && {minDate: new Date(minFromDate)})}
              onOpen={handleOpenFrom}
              onClose={handleCloseFrom}
              onChange={handleChangeFrom}
              renderInput={(params) => <CssInputField
                {...params}
                size='small'
                {...(paymentFilterDates && paymentFilterDates[`${id}FromDateError`]) && {error: true}}
                sx={{width: '50%', mb: 2.5}}
                InputProps={{
                  placeholder: 'From',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton edge='end' sx={{ top: 3 }} onClick={handleOpenFrom}>
                        <Calendar />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />}
            />
            <TimePicker pickerType='fromDate' {...(id && {id: id})} />
          </Box>

          {toLabel && <CssTitle variant={'body1'} sx={{mb: .5}}>{toLabel}</CssTitle>}
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <DatePicker
              label='To'
              inputFormat='MM.dd.yyyy'
              value={(paymentFilterDates && paymentFilterDates[`${id}ToDate`]) || null}
              open={openTo}
              onOpen={handleOpenTo}
              onClose={handleCloseTo}
              onChange={handleChangeTo}
              minDate={new Date(minDate)}
              renderInput={(params) => <CssInputField
                {...params}
                size='small'
                {...(paymentFilterDates && paymentFilterDates[`${id}ToDateError`]) && {error: true}}
                sx={{width: '50%'}}
                InputProps={{
                  placeholder: '',
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton edge='end' sx={{ top: 3 }} onClick={handleOpenTo}>
                        <Calendar />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />}
            />
            <TimePicker pickerType='toDate' {...(id && {id: id})} />
          </Box>
        </Box>}
      </LocalizationProvider>
    </StyledBox>
  );
};

export default DateTimeFilters;