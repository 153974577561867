/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import CssContainer from 'components/CssContainer';
import CssBox from 'components/CssBox';
import CssIconAvatar from 'components/CssIconAvatar';
import CssChip from 'components/CssChip';
import UpArrow from 'components/icons/UpArrow';
import ViewField from 'components/ViewField';
import SelectField from 'components/SelectField';
import CssTitle from 'components/CssTitle';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { processMarketConditions, processMarketStatus } from 'utils';
import { GlobalContext } from 'context';
import { checkPermissions } from 'utils';

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  lineHeight: '32px',
  marginRight: 28,
  fontSize: '1rem',
  '&.label': {
    fontWeight: 700,
    marginRight: 12
  },
  '&:last-child': {
    marginRight: 0
  },
  '&.ALERT': {
    color: '#FF5C4E'
  },
  '&.VERIFIED': {
    color: '#02B9A3'
  },
  '&.REVIEW': {
    color: '#FFAE00'
  },
  '&.WARNING': {
    color: '#FF802C'
  }
}));

const MarketProfile = () => {
  const { instrumentsProfileDetails, userPermissions } = useContext(GlobalContext);
  const { getInstrumentDetailsApi, updateInstrumentDetailsApi, getRefinitivInfoApi } = useRequests();
  const getInstrumentDetails = useApi(getInstrumentDetailsApi);
  const updateInstrumentDetails = useApi(updateInstrumentDetailsApi);
  const getRefinitivInfo = useApi(getRefinitivInfoApi);
  const [instrumentId] = useStorage<number | null>('instrumentId', null);
  const [instrumentSymbol] = useStorage<number | null>('instrumentSymbol', null);
  const [instrumentDetailsData, setInstrumentDetailsData] = useState<any>(null);
  const [instrumentRefinitivData, setInstrumentRefinitivData] = useState<any>(null);
  const [data, setData] = useState<any | null>(null);
  const validMarketCondition = ['TRADING_HALT|REVIEW', 'NORMAL|VERIFIED'];
  const validMarketStatus = ['ACTIVE|VERIFIED', 'HALTED|REVIEW', 'CLOSE_ONLY|ALERT'];
  const [permissionCheck, setPermissionCheck] = useState<boolean>(false);

  const onInternalStatusChange = (event: SelectChangeEvent<unknown>) => {
    updateInstrumentDetails.request(instrumentId, {internalStatus: event.target.value}).then((res) => {
      if (res.status === 200) {
        const profile = data.map((p: any) => {
          if (p.section === 'nemoApp') {
            p.fields = p.fields.map((f: any) => {
              if (f.name === 'internalStatus') {
                f.value = event.target.value;
              }
              return f;
            });
          }
          return p;
        })
        setData(profile);
      }
    });
  };

  const onFieldEdit = (event: any, name: string) => {
    const profile = data.map((p: any) => {
      if (p.fields) {
        p.fields = p.fields.map((f: any) => {
          if (f.name === name) {
            f.isEdit = true;
          }
          return f;
        });
      } else {
        if (p.name === name) {
          p.isEdit = true;
        }
      }
      return p;
    })
    setData(profile);
  };

  const onFieldUpdate = (value: string, name: string) => {
    updateInstrumentDetails.request(instrumentId, {[name]: value}).then((res) => {
      if (res.status === 200) {
        const profile = data.map((p: any) => {
          if (p.fields) {
            p.fields = p.fields.map((f: any) => {
              if (f.name === name) {
                f.value = value;
                f.isEdit = false;
              }
              return f;
            });
          } else {
            if (p.name === name) {
              p.value = value;
              p.isEdit = false;
            }
          }
          return p;
        })
        setData(profile);
      }
    });
  };

  const onTagsBlur = (name: string, value: any) => {
    const profile = data.map((p: any) => {
      if (p.fields) {
        p.fields = p.fields.map((f: any) => {
          if (f.name === name) {
            f.isEdit = value ? true : false;
          }
          return f;
        });
      } else {
        if (p.name === name) {
          const edit = (_.isArray(value) ? (value.length ? true : false ) : (value ? true : false));
          p.isEdit = edit;
        }
      }
      return p;
    });
    setData(profile);
  };

  const checkMarketPermissions = () => {
    setPermissionCheck(checkPermissions(userPermissions, 'MARKETS'));
  };

  useEffect(() => {
    if (!userPermissions.length) return;
    getInstrumentDetails.request(instrumentId).then((res) => {
      if (res.status === 200) {
        setInstrumentDetailsData(res.data);
      }
    });
    getRefinitivInfo.request(instrumentSymbol).then((res) => {
      if (res.status === 200) {
        setInstrumentRefinitivData(res.data);
      }
    });
    checkMarketPermissions();
  }, [userPermissions]);

  useEffect(() => {
    const profile = instrumentsProfileDetails.map((item: any, index: number) => {
      if (item.name && instrumentDetailsData && instrumentDetailsData[item.name]) {
        if (typeof instrumentDetailsData[item.name] === 'boolean') {
          item.value = instrumentDetailsData[item.name] === true ? 'Yes' : 'No';
        } else {
          item.value = instrumentDetailsData[item.name];
        }
      } else if (item.section && item.fields) {
        if (item.section === 'refinitiv') {
          item.fields = item.fields.map((f: any, i: number) => {
            if (f?.name && instrumentRefinitivData && instrumentRefinitivData[f.name]) {
              if (typeof instrumentRefinitivData[f.name] === 'boolean') {
                f.value = instrumentRefinitivData[f.name] === true ? 'Yes' : 'No';
              } else if (f.isDate && moment(new Date(instrumentRefinitivData[f.name])).isValid()) {
                if (f.isTime) {
                  f.value = moment(instrumentRefinitivData[f.name]).format('HH.mm.ss');
                } else {
                  f.value = moment(instrumentRefinitivData[f.name]).format('MM.DD.YYYY');
                }
              } else {
                f.value = instrumentRefinitivData[f.name];
              }
            }
            return f;
          });
        } else {
          item.fields = item.fields.map((f: any, i: number) => {
            if (f?.name && instrumentDetailsData && instrumentDetailsData[f.name]) {
              if (typeof instrumentDetailsData[f.name] === 'boolean') {
                f.value = instrumentDetailsData[f.name] === true ? 'Yes' : 'No';
              } else if (f.isDate && moment(new Date(instrumentDetailsData[f.name])).isValid()) {
                if (f.isTime) {
                  f.value = moment(instrumentDetailsData[f.name]).format('HH.mm.ss');
                } else {
                  f.value = moment(instrumentDetailsData[f.name]).format('MM.DD.YYYY');
                }
              } else {
                f.value = instrumentDetailsData[f.name];
              }
            }
            return f;
          });
        }
      }
      return item;
    });
    setData(profile);
  }, [instrumentDetailsData, instrumentRefinitivData]);

  return (
    <CssContainer>
      <CssBox>
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={10.5}>
            <Grid container>
              <Grid item xs={1.9}>
                <CssIconAvatar avatar={instrumentDetailsData?.instrumentImageUrl} />
              </Grid>
              <Grid item xs={10.1} sx={{ pt: 2.5 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <StyledTypography variant='body2' className='label'>Company name</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.instrumentLongName}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Symbol</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.symbol}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>RIC code</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.ricCode}</StyledTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTypography variant='body2' className='label'>Bid</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.bid && <>
                      <UpArrow sx={{mr: .5, top: 1}} fill={'#000000DE'} /> ${instrumentDetailsData?.bid}
                    </>}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Offer</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.ask && <>
                      <UpArrow sx={{mr: .5, top: 1}} fill={'#000000DE'} /> ${instrumentDetailsData?.ask}
                    </>}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Chg</StyledTypography>
                    <StyledTypography variant='body2' className={`${Math.sign(instrumentDetailsData?.changeValue) === -1 ? 'ALERT' : 'VERIFIED'}`}>{instrumentDetailsData?.changeValue}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Chg %</StyledTypography>
                    <StyledTypography variant='body2' className={`${Math.sign(instrumentDetailsData?.changePercentage) === -1 ? 'ALERT' : 'VERIFIED'}`}>{instrumentDetailsData?.changePercentage}%</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Prior close</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.priorClose}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Close</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.close}</StyledTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTypography variant='body2' className='label'>Exchange</StyledTypography>
                    <StyledTypography variant='body2'>{instrumentDetailsData?.exchange}</StyledTypography>
                    <StyledTypography variant='body2' className='label'>Market conditions</StyledTypography>
                    {instrumentDetailsData?.marketCondition && <CssChip label={processMarketConditions(instrumentDetailsData?.marketCondition)} className={_.split(_.find(validMarketCondition, (v) => v.includes(instrumentDetailsData?.marketCondition)), '|')[1]} sx={{ mr: 4.5 }} />}
                    <StyledTypography variant='body2' className='label'>Market status</StyledTypography>
                    {instrumentDetailsData?.tradingVenueStatus && <CssChip label={processMarketStatus(instrumentDetailsData?.tradingVenueStatus)} className={_.split(_.find(validMarketStatus, (v) => v.includes(instrumentDetailsData?.tradingVenueStatus)), '|')[1]} />}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container sx={{mt: 5.875, mb: 5.875}}>
              <Grid item xs={12}>
                <StyledTypography variant='body2' sx={{fontSize: '0.875rem'}}>Company description</StyledTypography>
                <StyledTypography variant='body2' sx={{fontSize: '1.125rem', mt: 1, fontWeight: 300, lineHeight: '27px'}}>{instrumentDetailsData?.description}</StyledTypography>
              </Grid>
            </Grid>
            <Grid container sx={{mt: 5.875, mb: 5.875, pr: 15}} spacing={3}>
              {data && data.map((item: any, index: number) => (
                <Fragment key={index}>
                  {item.label && 
                    <Grid item xs={(item.fullWidth ? 12 : 6)} sx={{pt: '0 !important', mb: 3}} key={index}>
                      {item.fieldType === 'select' ? 
                        <SelectField key={index} label={item.label} value={item.value} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} {...(permissionCheck && (item.isEdit === false || item.isEdit === true) && {edit: item.isEdit})} menu={item.menuItems} onChange={onInternalStatusChange} permissionCheck={permissionCheck} />
                        :
                        <ViewField key={index} label={item.label} value={item.value} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} {...(permissionCheck && (item.isEdit === false || item.isEdit === true) && {edit: item.isEdit, onEdit: onFieldEdit, onUpdate: onFieldUpdate})} name={item.name} {...(item.isAutocomplete && {enableAutocomplete: item.isAutocomplete, helperText: 'Please press ENTER to create tags.', onAutocompleteBlur: onTagsBlur})} permissionCheck={permissionCheck} />
                      }
                    </Grid>
                  }
                  <>
                    {item.section && 
                      <Grid container item xs={12} spacing={3}>
                        <Grid item xs={12} sx={{mt: 4.375, mb: 3.5, pt: '0 !important'}}>
                          <CssTitle variant='h6' underline>{item.title}</CssTitle>
                        </Grid>
                        {item.fields && item.fields.map((f: any, i: number) => (
                          <Fragment key={i}>
                            {f.label && 
                              <Grid item xs={(f.fullWidth ? 12 : 6)} sx={{pt: '0 !important', mb: 3, ...(f.wrapElement && {maxWidth: '100% !important', flexBasis: '100% !important'})}}>
                                {f.fieldType === 'select' ? 
                                  <SelectField key={i} label={item[f.label] ? item[f.label] : f.label} value={f.value} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} {...(f.wrapElement && {boxSx: {maxWidth: '48.5%'}})} {...(permissionCheck && (f.isEdit === false || f.isEdit === true) && {edit: f.isEdit})} menu={f.menuItems} onChange={onInternalStatusChange} permissionCheck={permissionCheck} />
                                  :
                                  <ViewField key={i} label={item[f.label] ? item[f.label] : f.label} value={f.value} labelVariant='body2' valueSx={{fontSize: '1.25rem', fontWeight: '400', fontStyle: 'normal'}} {...(f.wrapElement && {boxSx: {maxWidth: '48.5%'}})} {...(permissionCheck && (f.isEdit === false || f.isEdit === true) && {edit: f.isEdit, onEdit: onFieldEdit, onUpdate: onFieldUpdate})} name={f.name} {...(f.isDate && {isDate: f.isDate})} {...(f.isPercentage && {isPercentage: true})} permissionCheck={permissionCheck} />
                                }
                              </Grid>
                            }
                          </Fragment>
                        ))}
                      </Grid>
                    }
                  </>
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CssBox>
    </CssContainer>
  );
};

export default MarketProfile;