import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

const CssChip = styled(Chip)(({ theme }) => ({
  fontSize: '0.875rem',
  height: '26px',
  '& .MuiChip-label:first-letter': {
    textTransform: 'capitalize'
  },
  '&.ALERT': {
    backgroundColor: '#FF5C4E1A',
    color: '#FF5C4E'
  },
  '&.ALERT_V2': {
    backgroundColor: '#FF802C1A',
    color: '#FF4534'
  },
  '&.VERIFIED': {
    backgroundColor: '#02B9A20F',
    color: '#02B9A2'
  },
  '&.REVIEW': {
    backgroundColor: '#FFAE001A',
    color: '#FFAE00'
  },
  '&.WARNING': {
    backgroundColor: '#FF802C1A',
    color: '#FF802C'
  },
  '&.NEW': {
    backgroundColor: '#FF34A21A',
    color: '#FF34A2'
  },
  '&.DEFAULT': {
    backgroundColor: '#F7F3F3',
    color: '#5B5959'
  },
  '&.TRANSPARENT': {
    backgroundColor: 'transparent',
    color: '#5B5959',
    border: '1px solid #5B5959'
  },
  '&.TRADED': {
    backgroundColor: '#004A4126',
    color: '#004A41'
  },
  '&.DECLINED': {
    backgroundColor: '#F7F3F3',
    color: '#5B5959'
  },
  '&.FUNDED': {
    backgroundColor: '#01948221',
    color: '#019482'
  },
  '&.DORMANT': {
    backgroundColor: '#F7F3F3',
    color: '#949494'
  },
  '&.ROLE': {
    backgroundColor: '#F7F3F3',
    color: '#5B5959'
  },
  '&.report-chip': {
    textTransform: 'lowercase',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'transparent',
    fontSize: '0.75rem',
    marginRight: 5,
    marginTop: 10,
    maxWidth: 'fit-content',

    '& .MuiChip-deleteIcon': {
      height: 25,
      '& svg': {
        width: 16
      }
    }
  },
  '&.tag-chip': {
    fontSize: '0.75rem',
    marginRight: 6
  }
}));

export default CssChip;