/* eslint-disable react-hooks/exhaustive-deps */
import { useState, MouseEvent, ChangeEvent, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import CssFlexBox from 'components/CssFlexBox';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssAccordion from 'components/CssAccordion';
import CssButton from 'components/CssButton';
import DateTimeFilters from 'components/DateTimeFilters';
import Download from 'components/icons/Download';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { Order, checkPermissions } from 'utils';
import { WalletSnapshotHeaders } from 'constants/wallet-reports-table-head-columns';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledIconButton = styled(IconButton)({
  top: '-10px',
  '&:hover': {
    backgroundColor: 'transparent'
  }
});

const WalletSnapshot = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { searchQuery, isSearchClosed, isUpdatePage, setIsUpdatePage, isFilterCleared, setIsFilterCleared, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, paymentFilterDates, setPaymentFilterDates, userPermissions } = useContext(GlobalContext);
  const { getWalletSnapshotApi, getWalletSnapshotDownloadApi } = useRequests();
  const getWalletSnapshot = useApi(getWalletSnapshotApi);
  const getWalletSnapshotDownload = useApi(getWalletSnapshotDownloadApi);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFiltersScreen, setOpenFiltersScreen] = useState(false);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState([]);
  const downloadFileHeaders = [
    { label: 'Client ID', key: 'customerId' },
    { label: 'First name', key: 'firstName' },
    { label: 'Last name', key: 'lastName' },
    { label: 'Wallet snapshot USD', key: 'walletBalance' }
  ];
  const downloadFilename = `reports-wallet-snapshot-${moment().format('MM-DD-yyyy')}`;

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onClearFilters = () => {
    setPaymentFilterDates({ fromDate: '', toDate: '', statusUpdatedFromDate: '',
    statusUpdatedToDate: '' });
    setIsFiltersCleared(true);
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setIsFilterCleared(true);
  };

  const onApply = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);

    if (paymentFilterDates && (paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate)) {
      setIsDateFilterApplied(true);
    }
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const convertUtc = (value: string) => {
    return moment(value, 'YYYY-MM-DD h:m:s A').utc(false).format('YYYY-MM-DD HH:mm:ss');
  };

  const checkReportsPermissions = () => {
    return checkPermissions(userPermissions, 'REPORTS');
  };

  type DownloadParams = {
    q?: string,
    fromDate?: string,
    toDate?: string,
  }

  const getWalletSnapshotDownloadCall = (params: DownloadParams) => {
    const { q, fromDate, toDate } = params;

    getWalletSnapshotDownload.request(q, fromDate, toDate).then((res) => {
      if (res.status === 200) {
        setDownloadData(res.data);
        setIsDownloadEnabled(true);
      }
    });
  };

  useEffect(() => {
    if (isFiltersCleared) {
      setEnableButtons(true);
    } else if (paymentFilterDates && (paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate)) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [isFiltersCleared, paymentFilterDates]);

  useEffect(() => {
    const statusUpdatedFromDate = paymentFilterDates['statusUpdatedFromDate'] && paymentFilterDates['statusUpdatedFromDateTime'] ? convertUtc(`${paymentFilterDates['statusUpdatedFromDate']} ${paymentFilterDates['statusUpdatedFromDateTime']}`) : (paymentFilterDates['statusUpdatedFromDate'] && !paymentFilterDates['statusUpdatedFromDateTime'] ? `${paymentFilterDates['statusUpdatedFromDate']}` : '');

    const statusUpdatedToDate = paymentFilterDates['statusUpdatedToDate'] && paymentFilterDates['statusUpdatedToDateTime'] ? convertUtc(`${paymentFilterDates['statusUpdatedToDate']} ${paymentFilterDates['statusUpdatedToDateTime']}`) : (paymentFilterDates['statusUpdatedToDate'] && !paymentFilterDates['statusUpdatedToDateTime'] ? `${paymentFilterDates['statusUpdatedToDate']}` : '');

    if (!searchQuery) {
      getWalletSnapshot.request(page + 1, rowsPerPage, statusUpdatedFromDate, statusUpdatedToDate).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);
          setIsDateFilterApplied(false);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isFilterCleared) setIsFilterCleared(false);
        if (isFiltersCleared) setIsFiltersCleared(false);
      });

      getWalletSnapshotDownloadCall({ fromDate: statusUpdatedFromDate, toDate: statusUpdatedToDate });
    } else if (searchQuery !== '' && !isSearchClosed) {
      setPage(isUpdatePage ? 0 : page);
      getWalletSnapshot.request(page + 1, rowsPerPage, statusUpdatedFromDate, statusUpdatedToDate, searchQuery).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);
          setIsDateFilterApplied(false);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
        if (isFilterCleared) setIsFilterCleared(false);
        if (isFiltersCleared) setIsFiltersCleared(false);
      });
      getWalletSnapshotDownloadCall({ q: searchQuery, fromDate: statusUpdatedFromDate, toDate: statusUpdatedToDate });
    }
  }, [page, rowsPerPage, order, orderBy, searchQuery, isFilterCleared, isFiltersCleared, isDateFilterApplied]);

  return (
    <CssContainer>
      <CssFlexBox sx={{justifyContent: 'space-between'}}>
        <CssTitle variant='h4' underline>Wallet Snapshot</CssTitle> 
        {isDownloadEnabled && checkReportsPermissions() ? <CSVLink data={downloadData} headers={downloadFileHeaders} filename={downloadFilename} style={{position: 'relative', top: 39, width: 30, height: 30}}>
          <Download />
        </CSVLink> :
        <StyledIconButton disabled style={{position: 'relative', top: 40, width: 30, height: 30}}>
          <Download fill={'#E5E5E5'} />
        </StyledIconButton>}
      </CssFlexBox>
      <CssTable
        headers={WalletSnapshotHeaders}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        onFilters={handleFilters}
        isDesktopFilters={true}
        sx={{ mt: {md: 3, xs: 0} }}
        highlightText={searchQuery}
        rowClassName={'no-row-click'}
      />
      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          <CssAccordion title='Date' isExpand={true}>
            <DateTimeFilters fromLabel={'From'} toLabel={'To'} id='statusUpdated' minFromDate={'2024-01-22'} />
          </CssAccordion>

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
    </CssContainer>
  );
};

export default WalletSnapshot;