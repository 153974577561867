import Icon from './Icon';

const ChartPie = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.125 4.41424C8.21931 4.85631 4.375 8.97921 4.375 14C4.375 19.3157 8.68426 23.625 14 23.625C16.7083 23.625 19.155 22.5073 20.9049 20.7055L13.45 14.6805C13.2445 14.5144 13.125 14.2643 13.125 14V4.41424ZM14.875 4.41424V13.125H23.5858C23.1697 8.5075 19.4925 4.83034 14.875 4.41424ZM23.5858 14.875H16.4747L22.0057 19.3451C22.8763 18.0441 23.4377 16.5197 23.5858 14.875ZM2.625 14C2.625 7.71776 7.71776 2.625 14 2.625C20.2822 2.625 25.375 7.71776 25.375 14C25.375 16.7081 24.4276 19.1972 22.8469 21.1506C20.7629 23.726 17.5737 25.375 14 25.375C7.71776 25.375 2.625 20.2822 2.625 14Z" fill="currentColor"/>
      </svg>
    </Icon>
  );
};

export default ChartPie;