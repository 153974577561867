
export const TravelRuleReportsFilters = [
  {
    parentKey: 'txnType',
    title: 'Type',
    filters: [
      {
        key: 'DEPOSIT',
        label: 'Deposits',
        isChecked: false
      },
      {
        key: 'WITHDRAWAL',
        label: 'Withdrawals',
        isChecked: false
      }
    ]
  }
];