import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const IconBox = styled(Box)({
  position: 'relative',

  '&.left': {
    transform: 'rotate(180deg)',
  },
  '&.download': {
    top: 4,
    left: 4,
    '& svg': {
      width: 20,
      height: 20
    }
  },
  '&.download-disabled': {
    top: 4,
    '& svg': {
      width: 20,
      height: 20
    }
  }
});

const Icon = ({ className, direction, sx, children }: any) => {
  return (
    <IconBox className={`icon ${className || ''} ${direction || ''}`} sx={sx || {}}>
      {children}
    </IconBox>
  );
};

export default Icon;