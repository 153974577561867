import Icon from './Icon';

const Filters = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.3535 3.75H1C0.585786 3.75 0.25 3.41421 0.25 3C0.25 2.58579 0.585786 2.25 1 2.25H10.3535C10.68 1.09575 11.7412 0.25 13 0.25C14.2588 0.25 15.32 1.09575 15.6465 2.25H17C17.4142 2.25 17.75 2.58579 17.75 3C17.75 3.41421 17.4142 3.75 17 3.75H15.6465C15.32 4.90425 14.2588 5.75 13 5.75C11.7412 5.75 10.68 4.90425 10.3535 3.75ZM11.75 3C11.75 2.30964 12.3096 1.75 13 1.75C13.6904 1.75 14.25 2.30964 14.25 3C14.25 3.69036 13.6904 4.25 13 4.25C12.3096 4.25 11.75 3.69036 11.75 3Z" fill="#5B5959"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.64648 11.75H17C17.4142 11.75 17.75 11.4142 17.75 11C17.75 10.5858 17.4142 10.25 17 10.25H7.64648C7.32002 9.09575 6.25878 8.25 5 8.25C3.74122 8.25 2.67998 9.09575 2.35352 10.25H1C0.585787 10.25 0.25 10.5858 0.25 11C0.25 11.4142 0.585787 11.75 1 11.75H2.35352C2.67998 12.9043 3.74122 13.75 5 13.75C6.25878 13.75 7.32002 12.9043 7.64648 11.75ZM3.75 11C3.75 10.3096 4.30964 9.75 5 9.75C5.69036 9.75 6.25 10.3096 6.25 11C6.25 11.6904 5.69036 12.25 5 12.25C4.30964 12.25 3.75 11.6904 3.75 11Z" fill="#5B5959"/>
      </svg>
    </Icon>
  );
};

export default Filters;