import React, { useContext, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, useMediaQuery } from '@mui/material';
import Header from './Header';
import CssDrawer from './CssDrawer';
import RestrictedScreen from './RestrictedScreen';
import { GlobalContext } from 'context';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import Auth from 'services/auth';
import { isAdminRole } from 'utils';

const Layout = ({ children }: any) => {
  const { isPermissionGranted, userRole, setUserInfo, setUserPermissions } = useContext(GlobalContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { getPermissionsByRoleApi } = useRequests();
  const getPermissionsByRole = useApi(getPermissionsByRoleApi);

  const callProfile = useCallback(async () => {
    const profile = await Auth.getProfile().then((p) => p).catch(() => {
      console.log('Failed to load user profile');
      return null;
    });
    setUserInfo(profile);
  }, []);

  useEffect(() => {
    if (userRole && !isAdminRole(userRole)) {
      getPermissionsByRole.request(Auth.getRole()).then((res) => {
        if (res.status === 200) {
          setUserPermissions(res.data);
        }
      });
    }
  }, [userRole]);

  useEffect(() => {
    callProfile();
  }, [callProfile]);

  return (
    <Box>
      <Grid container>
        {!isMobile && <Grid item xs={.64}>
          <CssDrawer variant='permanent' />
        </Grid>}
        {isPermissionGranted ? 
          <Grid item xs={!isMobile ? 11.36 : 12}>
            <RestrictedScreen />
          </Grid> : 
          <Grid item xs={!isMobile ? 11.36 : 12}>
            {userRole && !isAdminRole(userRole) && <Header />}
            {children}
          </Grid>
        }
      </Grid>
    </Box>
  );
};

export default Layout;