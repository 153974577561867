import React from 'react';
import { styled } from '@mui/material/styles';
import { SnackbarContent } from 'notistack';

interface ErrorSnackbarProps {
  message: string
}

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  borderRadius: 15,
  padding: 14,
  fontSize: 14,
  backgroundColor: '#FF5C4E1A',
  boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.15)',
}));

const ErrorSnackbar = React.forwardRef<HTMLDivElement, ErrorSnackbarProps>((props, ref) => {
  const {
    message,
    ...other
  } = props;

  return (
    <StyledSnackbarContent ref={ref} role='alert' {...other} className={'error'}>
      {message}
    </StyledSnackbarContent>
  )
});

export default ErrorSnackbar;