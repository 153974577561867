import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Box, IconButton, useMediaQuery, Typography } from '@mui/material';
import CssBox from 'components/CssBox';
import CssTitle from 'components/CssTitle';
import Caret from 'components/icons/Caret';
import { GlobalContext } from 'context';

const StyledFlexBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 6,
  marginBottom: 28,
  [theme.breakpoints.between('xs', 'sm')]: {
    borderBottom: 0,
    width: '100%',
    paddingBottom: 0
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  lineHeight: '25.2px',
  '&.label': {
    fontWeight: 700,
    width: 103,
    marginRight: 24
  }
}));

const LegalEntity = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { legalEntity } = useContext(GlobalContext);

  return (
    <CssBox>
      <StyledFlexBox>
        <CssTitle variant='h6' sx={{fontSize: '1rem'}}>Legal entity</CssTitle>
        {isMobile && <IconButton sx={{ width: 20, height: 30 }}>
          <Caret />
        </IconButton>}
      </StyledFlexBox>
      {!isMobile && legalEntity && <Grid container>
        <Grid item xs={12}>
          <StyledTypography variant='body2' className='label'>Brand</StyledTypography>
          <StyledTypography variant='body2'>{legalEntity.brand}</StyledTypography>
        </Grid>
        <Grid item xs={12}>
          <StyledTypography variant='body2' className='label'>Broker</StyledTypography>
          <StyledTypography variant='body2'>{legalEntity.marketingCompany}</StyledTypography>
        </Grid>
        <Grid item xs={12}>
          <StyledTypography variant='body2' className='label'>Regulatory body</StyledTypography>
          <StyledTypography variant='body2'>{legalEntity.regulatoryBody}</StyledTypography>
        </Grid>
      </Grid>}
    </CssBox>
  );
};

export default LegalEntity;