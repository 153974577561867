import { Box, Grid } from '@mui/material';
import CssAdminDrawer from './CssAdminDrawer';

const AdminLayout = ({ children }: any) => {

  return (
    <Box>
      <Grid container>
        <Grid item xs={.64}>
          <CssAdminDrawer variant='permanent' />
        </Grid>
        <Grid item xs={11.36}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminLayout;