import { PaletteMode } from '@mui/material';

export const getTheme = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light' && {
      primary: {
        main: '#000000',
        light: '#04F8DA',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF',
        light: '#949494',
        contrastText: '#000000'
      },
      background: {
        default: '#FFFFFF'
      },
      text: {
        primary: '#000000',
        secondary: '#5B5959'
      },
      success: {
        main: '#04F8DA',
        light: '#04F8DA'
      },
    }),
    /**
      TODO: If we implement theming we need to update the colors accordingly. By default 'light' mode is set for the app
    */ 
    ...(mode === 'dark' && {
      primary: {
        main: '#1e0e31',
        light: '#FFFFFF',
        contrastText: '#FFFFFF'
      },
      background: {
        default: '#1e0e31',
        light: '#131124',
        dark: '#4d1f51'
      }
    }),
    divider: '#E5E5E5'
  },
  typography: {
    h4: {
      fontWeight: 500,
      fontSize: '2.25rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    button: {
      fontWeight: 400
    }
  }
});
