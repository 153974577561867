/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import { getTheme } from 'theme';
import './App.css';
import Authenticate from 'components/Authenticate';
import SuccessWithUndoSnackbar from 'components/SuccessWithUndoSnackbar';
import CustomSnackbar from 'components/CustomSnackbar';
import SuccessSnackbar from 'components/SuccessSnackbar';
import ErrorSnackbar from 'components/ErrorSnackbar';
import XssErrorSnackbar from 'components/XssErrorSnackbar';
import Notifications from 'components/Notifications';
import Routes from 'routes';
import Auth from 'services/auth';
import { GlobalContext } from 'context';
import { isAdminRole } from 'utils';

declare module 'notistack' {
  interface VariantOverrides {
    successWithUndo: {
      onUndo?: () => void
    },
    successAlert: true,
    errorAlert: true,
    xssErrorAlert: true,
    defaultAlert: true
  }
}

const App = () => {
  const theme = createTheme(getTheme('light'));
  const { setAuth, userRole, setUserRole } = useContext(GlobalContext);
  
  const callAuthDecode = useCallback(async () => {
    const decode = Auth.tokenDecode();
    setAuth(decode);
    setUserRole(Auth.getRole());
  }, []);

  useEffect(() => {
    callAuthDecode();
  }, [callAuthDecode]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider 
        maxSnack={3}
        autoHideDuration={5000}
        Components={{
          successWithUndo: SuccessWithUndoSnackbar,
          successAlert: SuccessSnackbar,
          errorAlert: ErrorSnackbar,
          xssErrorAlert: XssErrorSnackbar,
          defaultAlert: CustomSnackbar
        }}
      >
        <Router>
          <Notifications>
            <Authenticate>
              <Routes isAdmin={isAdminRole(userRole)} />
            </Authenticate>
          </Notifications>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
