import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { MenuItem, FormControl, Select } from '@mui/material';
import Time from './icons/Time';
import { GlobalContext } from 'context';
import { capitalizeFirstLetter } from 'utils';

interface TimeOption {
  value: string
  label: string
};

interface TimePickerProps {
  pickerType: string
  id?: string
}

const StyledSelect = styled(Select)({
  borderRadius: '30px',
  padding: 0,
  color: '#000',
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    color: '#000',
  },
  '& .MuiSelect-select': {
    padding: '6px 12px'
  },
  '& .MuiSelect-icon': {
    right: 12,
    top: 6
  },
  '& fieldset': {
    borderColor: '#000',
    '& legend': {
      display: 'none'
    }
  }
});

const TimePicker = (props: TimePickerProps) => {
  const { pickerType, id } = props;
  const { paymentFilterDates, setPaymentFilterDates } = useContext(GlobalContext);
  const [time, setTime] = useState<string>('');

  const handleTimeChange = (event: any) => {
    setTime(event.target.value);
    let timeFiltersData = paymentFilterDates;

    if (pickerType && id) {
      timeFiltersData = {...timeFiltersData, [`${id}${capitalizeFirstLetter(pickerType)}Time`]: event.target.value};
    } else {
      timeFiltersData = {...timeFiltersData, [`${pickerType}Time`]: event.target.value}
    }
    setPaymentFilterDates(timeFiltersData);
  };

  const generateTimeOptions = (): TimeOption[] => {
    const options: TimeOption[] = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const amPm = hour < 12 ? 'AM' : 'PM';
        const hour12 = hour === 12 ? 12 : hour % 12;
        const formattedHour = hour12.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        const timeString = `${formattedHour === '00' ? '12' : formattedHour}:${formattedMinute} ${amPm}`;
        options.push({ value: timeString, label: timeString });
      }
    }
    return options;
  };

	return (
    <FormControl sx={{width: '50%', top: 3, ml: 1.5}}>
      <StyledSelect
        defaultValue=''
        value={time ? time : ((paymentFilterDates && id) ? paymentFilterDates[`${id}${capitalizeFirstLetter(pickerType)}Time`] : paymentFilterDates[`${pickerType}Time`])}
        label='Time'
        onChange={handleTimeChange}
        IconComponent={Time}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: 4,
              '& li:hover': {
                backgroundColor: '#04f8da0d'
              },
              '& .Mui-selected': {
                backgroundColor: '#04f8da0d'
              }
            }
          }
        }}
      >
        {generateTimeOptions().map(({ value, label }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default TimePicker;
