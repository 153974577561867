import Icon from './Icon';

const Plus = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.4999 12C23.4999 18.3562 18.4021 23.5 12.1245 23.5C5.84689 23.5 0.749023 18.3562 0.749023 12C0.749023 5.64379 5.84689 0.5 12.1245 0.5C18.4021 0.5 23.4999 5.64379 23.4999 12Z" fill="#04F8DA" stroke="#04F8DA"/>
      <path d="M11.875 17V7" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M6.92703 12L16.8232 12" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
      </svg>
    </Icon>
  );
};

export default Plus;