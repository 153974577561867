import React, { MouseEvent, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  Box,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  TextField,
  useMediaQuery
} from '@mui/material';
import { Info, Delete } from '@mui/icons-material';
import _ from 'lodash';
import moment from 'moment';
import { GlobalContext } from 'context';
import CssChip from './CssChip';
import CssEllipse from './CssEllipse';
import Add from './icons/Add';
import InlineClose from './icons/InlineClose';
import EditPencil from './icons/EditPencil';
import Check from './icons/Check';

interface HeadCell {
  key: string
  label: string
  info?: string
  fieldHeaderType?: string
};

interface StatusData {
  key: string
  type: string
  valid: string[]
};

interface TableProps {
  headers: any
  data: any
  dropdownList?: any
  dropdownSelected?: any
  sx?: any
  isHover?: boolean
  rowActions?: any
  isTableEditable?: boolean
  statusDropdownList?: any
  onChange?: (
    event: SelectChangeEvent<unknown>,
  ) => void
  onAdd?: (event: MouseEvent, actionType: string) => void
}

const StyledCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 500,
  borderBottomColor: theme.palette.primary.main,
  lineHeight: 0,
  [theme.breakpoints.between('xs', 'sm')]: {
    padding: '14px 0 16px 16px',
    '& .MuiTableSortLabel-root': {
      display: 'block',
      position: 'relative',
      top: 12
    },
    '& .MuiTableSortLabel-icon': {
      display: 'block',
      textAlign: 'center'
    }
  }
}));

const StyledLabelTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontFamily: 'Roboto',
  color: theme.palette.text.secondary,
  [theme.breakpoints.between('xs', 'sm')]: {
    display: 'block',
    width: 68,
    height: 20,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const StyledRow = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root': {
    padding: '14px 0 14px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .MuiButtonBase-root': {
      display: 'none',
      padding: 0,
      '& .icon': {
        height: 18,
        '&.check': {
          '& svg': {
            height: '19px',
            top: '-4px'
          }
        },
        '& svg': {
          height: 18,
          position: 'relative',
          top: '-5px'
        }
      }
    }
  },
  '&.on-hover:hover': {
    backgroundColor: '#C4C4C426',
    '& .MuiTableCell-root': {
      '& .MuiButtonBase-root': {
        display: 'inline-flex'
      }
    }
  },
  '&.active': {
    '& .MuiTableCell-root': {
      '& .MuiButtonBase-root': {
        display: 'inline-flex'
      }
    }
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    '& .MuiTableCell-root': {
      padding: '16px 0 16px 16px'
    }
  }
}));

const StyledTypography = styled(Typography)({
  '&.ALERT': {
    color: '#FF4534'
  },
  '&.WARNING': {
    color: '#FF802C'
  },
  '&.REVIEW': {
    color: '#FFC32A'
  },
  '&.VERIFIED': {
    color: '#02B9A2'
  }
});

const StyledEllipse = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  [theme.breakpoints.between('xs', 'sm')]: {
    textAlign: 'center',
    '& .MuiTypography-root': {
      display: 'none'
    }
  }
}));

const StyledInfoIcon = styled(Info)(({ theme }) => ({
  marginLeft: 8,
  top: 4,
  position: 'relative',
  '&.info': {
    color: theme.palette.text.secondary
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: '#5B5959',
  fontSize: '0.875rem',
  '&:before, &:after, &:hover': {
    borderBottom: 0
  },
  '&.MuiInput-underline:hover': {
    borderBottom: 0
  },
  '& .MuiSelect-select': {
    padding: '11.5px 14px'
  }
}));

const StyledIconButton = styled(IconButton)({
  padding: 0,
  height: 24,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '& .icon': {
    height: 24
  }
});

const StyledRowIconButton = styled(IconButton)({
  marginRight: 2,
  marginLeft: 2,
  '&.inline-close': {
    width: 20
  },
  '&.delete': {
    width: 18,
    '& svg': {
      height: 20,
      width: 20,
      top: '-1px',
      position: 'relative',
      color: '#949494',
      '&:hover': {
        color: '#FC4C49'
      }
    }
  },
  '&.edit-icon': {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& svg': {
      height: 14,
      width: 14,
      color: '#949494',
      '&:hover': {
        color: '#04F8DA'
      }
    }
  }
});

const StyledTextField = styled (TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '10px 14px'
  }
}));

const CssBasicTable = ({ headers, data, dropdownList, dropdownSelected, sx, isHover, rowActions, isTableEditable, statusDropdownList, onChange, onAdd }: TableProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { isRowAdd, holidayListFields } = useContext(GlobalContext);

  const getChip = (o: any, s: any) => {
    const status = _.split(_.find(s.valid, (v) => v.includes(s.processStatus ? s.processStatus.call({}, o) : o)), '|')[1];

    if (s.type === 'oval') {
      return <CssChip label={s.processStatus ? s.processStatus.call({}, o) : (s.conversion && s.conversion === 'UPPERCASE' ? o.toUpperCase() : o.toLowerCase())} className={status} />
    } else if (s.type === 'ellipse') {
      return <StyledEllipse>
        <CssEllipse className={status} sx={{...processSx(s.key, 'ellipseStyles')}} /><StyledTypography variant='body2' className={status}>{s.processStatus ? s.processStatus.call({}, o) : o}</StyledTypography>
      </StyledEllipse>
    }
    
    return <StyledTypography variant='body2' className={status}>{o}</StyledTypography>;
  };

  const getProcessData = (o: any, key: string, r: any) => {
    if (o && _.findIndex(headers.statusData, (s: StatusData) => s.key === key) !== -1) {
      return getChip(o, _.find(headers.statusData, (s) => s.key === key));
    } else if (o && moment(new Date(o)).isValid() && headers.dateColumns && headers.dateColumns.includes(key)) {
      return moment(o).format('MM.DD.YYYY');
    } else if (o && moment(o, 'HH:mm:ss').isValid() && headers.timeColumns && headers.timeColumns.includes(key)) {
      return (isTableEditable) ? <>
          {r.isEditing ? <StyledTextField variant='outlined' value={r[key]} type={'time'} onChange={(event) => rowActions.onChange.call({}, event, key, r)} {...(!r[key] && {error: true})} /> : moment(o, 'HH:mm:ss').format('HH:mm')}
        </> : moment(o, 'HH:mm:ss').format('HH:mm');
    } else if (headers.columns && _.findIndex(headers.columns, (s: any) => (s.processCellData && s.key === key)) !== -1) {
      const c = _.find(headers.columns, (s) => s.processCellData);
      return (isTableEditable) ? <>
        {r.isEditing ? <StyledTextField variant='outlined' {...(_.findIndex(headers.columns, (s: any) => (s.fieldType === 'DATE')) !== -1 && {type: 'date', value: moment(r[key]).utc().format('yyyy-MM-DD'), InputProps: {
        inputProps: { min: moment().format('yyyy-MM-DD') }
        }})} onChange={(event) => rowActions.onChange.call({}, event, key, r)} {...(!r[key] && {error: true})} /> : c.processCellData.call({}, o)}
      </> : c.processCellData.call({}, o);
    }
    const value = ((isTableEditable) ? <>
      {r.isEditing ? <>
        {_.findIndex(headers.columns, (s: any) => (s.fieldType === 'SELECT' && s.key === key)) !== -1 ? <FormControl variant={'outlined'} sx={{width: '80%'}} {...(!r[key] && {error: true})}>
          <StyledSelect
            onChange={(event) => rowActions.onChange.call({}, event, key, r)}
            value={r[key]}
          >
            {_.map(statusDropdownList, (s: any, i: number) => (<MenuItem value={s} key={i}>{s}</MenuItem>)
            )}
          </StyledSelect>
        </FormControl> : <StyledTextField variant='outlined' value={r[key]} onChange={(event) => rowActions.onChange.call({}, event, key, r)} {...((_.findIndex(headers.columns, (s: any) => (s.fieldType === 'TIME' && s.key === key)) !== -1) ? {type: 'time', ...(!r[key] && r['exchangeStatus'] === 'OPEN' && {error: true})} :  {...(!r[key] && {error: true})})} />}</> : o}
    </> : o) || '-';

    return value;
  };

  const getFields = (c: any) => {
    if (c.fieldType === 'DATE') {
      return <StyledTextField variant='outlined' type='date' {...(c.isValid === false && {error: true})} onChange={(event) => rowActions.onChange.call({}, event, c.key)} InputProps={{
        inputProps: { min: moment().format('yyyy-MM-DD') }
      }} />;
    } else if (c.fieldType === 'SELECT') {
      return <FormControl variant={'outlined'} sx={{width: '80%'}} {...(c.isValid === false && {error: true})}>
        <StyledSelect
          onChange={(event) => rowActions.onChange.call({}, event, c.key)}
          value={c.value}
        >
          {_.map(statusDropdownList, (s: any, i: number) => (<MenuItem value={s} key={i}>{s}</MenuItem>)
          )}
        </StyledSelect>
      </FormControl>;
    } else if (c.fieldType === 'TIME') {
      return <StyledTextField variant='outlined' type='time' {...(c.isValid === false && {error: true})} onChange={(event) => rowActions.onChange.call({}, event, c.key)} />;
    }

    return <StyledTextField variant='outlined' {...(c.isValid === false && {error: true})} onChange={(event) => rowActions.onChange.call({}, event, c.key)} />;
  };

  const processSx = (key: string, styles: string) => {
    if (isMobile && headers.mobileColumns && _.findIndex(headers.mobileColumns, (s: any) => s.key === key) !== -1) {
      const o = _.find(headers.mobileColumns, (s) => s.key === key);
      return o[styles] || {};
    } else if (headers.statusData && _.findIndex(headers.statusData, (s: any) => s.key === key) !== -1) {
      const o = _.find(headers.statusData, (s) => s.key === key);
      return o[styles] || {};
    } else if (headers.columns && _.findIndex(headers.columns, (s: any) => s.key === key) !== -1) {
      const o = _.find(headers.columns, (s) => s.key === key);
      return o[styles] || {};
    }

    return {};
  };

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            {headers && headers.columns && headers.columns.map((o: HeadCell, index: number) => (
              <StyledCell
                size='small'
                key={index}
              >
                {o.fieldHeaderType === 'DROPDOwN' && <FormControl variant={'standard'} sx={{width: '100%'}}>
                  <StyledSelect
                    {...(onChange && {onChange: onChange})}
                    value={dropdownSelected}
                  >
                    {dropdownList.map((d: any, i: number) => <MenuItem value={d} key={i}>{d}</MenuItem>)}
                  </StyledSelect>
                </FormControl>}
                {!o.fieldHeaderType && <StyledLabelTypography variant='body2' sx={{...processSx(o.key, 'labelStyles')}}>{o.label}{o.info && <Tooltip title={o.info}><StyledInfoIcon fontSize='small' className='info' /></Tooltip>}</StyledLabelTypography>}
              </StyledCell>
            ))}
            {onAdd && 
              <StyledCell size='small' align={'center'} sx={{pr: 0}}>
                <Tooltip title='Add'>
                  <StyledIconButton
                    onClick={(event) => onAdd.call({}, event, 'ADD')}
                    aria-label='add'
                  >
                    <Add />
                  </StyledIconButton>
                </Tooltip>
              </StyledCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {isRowAdd && onAdd && <StyledRow className='active'>
            {holidayListFields.map((f: any, i: number) => (
              <TableCell key={i}>{getFields(f)}</TableCell>
            ))}
            <TableCell align='center' sx={{width: 100}}>
              <Tooltip title='Create'>
                <StyledRowIconButton onClick={(event) => onAdd.call({}, event, 'CREATE')}><Check className={'check'} fill={'#04F8DA'} /></StyledRowIconButton>
              </Tooltip>
              <Tooltip title='Cancel'>
                <StyledRowIconButton className='inline-close' onClick={(event) => onAdd.call({}, event, 'CANCEL')}><InlineClose fill={'#FC4C49'} /></StyledRowIconButton>
              </Tooltip>
            </TableCell>
          </StyledRow>}
          {data && data.length > 0 && data.map((r: any, index: number) => (
            <StyledRow key={index} {...(isHover && !r.isEditing ? {className: 'on-hover'} : {className: 'active'})}>
              {headers.columns.map((h: any, i: number) => (
                <TableCell key={i} sx={{...processSx(h.key, 'dataCellStyles')}}>{getProcessData(r[h.key], h.key, r)}</TableCell>
              ))}
              {!_.isEmpty(rowActions) && 
                <TableCell align={'center'} sx={{width: 100}}>
                  {rowActions.onEdit && <>
                      {r.isEditing ? <>
                        <Tooltip title='Update'>
                          <StyledRowIconButton onClick={() => rowActions.onEdit.call({}, 'UPDATE', r)}><Check className={'check'} fill={'#04F8DA'} /></StyledRowIconButton>
                        </Tooltip>
                        <Tooltip title='Cancel'>
                          <StyledRowIconButton className='inline-close' onClick={() => rowActions.onEdit.call({}, 'EDIT_CANCEL', r)}><InlineClose fill={'#FC4C49'} /></StyledRowIconButton>
                        </Tooltip>
                      </> : <>{moment().isSameOrBefore(r.holidayDate, 'day') ? <Tooltip title='Edit'><StyledRowIconButton className='edit-icon' onClick={() => rowActions.onEdit.call({}, 'ON_EDIT', r)}><EditPencil /></StyledRowIconButton></Tooltip> : null}</>}
                    </>
                  }
                  {rowActions.onDelete && !r.isEditing && (moment().isSameOrBefore(r.holidayDate, 'day')) &&
                    <Tooltip title='Delete'><StyledRowIconButton className='delete' onClick={() => rowActions.onDelete.call({}, r)}><Delete /></StyledRowIconButton></Tooltip>
                  }
                </TableCell>
              }
            </StyledRow>
          ))}
          
          {data && !data.length && (
            <TableRow>
              <TableCell colSpan={isMobile ? headers.mobileColumns.length : headers.columns.length} align='center'>No records found.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CssBasicTable;