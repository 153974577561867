import React, { MouseEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Breadcrumbs, Typography, Link, Stack } from '@mui/material';
import _ from 'lodash';
import { GlobalContext } from 'context';

interface LinkData {
  name: string
  url?: string
  key: number
  data?: any
};

interface BreadcrumbsProps {
  linkStack: LinkData[]
};

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  color: theme.palette.primary.main
}));

const CssBreadcrumbs = ({ linkStack }: BreadcrumbsProps) => {
  const navigate = useNavigate();
  const { setClientProfileDetails, setClientProfileInfo } = useContext(GlobalContext);

  const handleClick = (event: MouseEvent<HTMLAnchorElement>, item: any) => {
    event.preventDefault();

    setClientProfileDetails((cpds: any) => _.map(cpds, (i) => {
      if (i.value) {
        i.value = '';
      } else if (i.section && i.fields) {
        i.fields = i.fields.map((f: any, i: number) => {
          if (f?.value) {
            f.value = '';
          }
          return f;
        });
      } else if (i.rootField) {
        i.rootField.value = '';
      } else if (i.section === 'pep') {
        i.renderByField = [];
      }
      return i;
    }));
    setClientProfileInfo(null);

    if (item.data) {
      navigate(`${item.url}`, {state: item.data});
    } else {
      navigate(`${item.url}`);
    }
  };

  const breadcrumbs = linkStack.map((item, i) => {
    return (
      <div key={i}>
        {item.url && <Link underline='hover' key={item.key} href={item.url} onClick={(event: MouseEvent<HTMLAnchorElement>)=> handleClick(event, item)}>
          {item.name}
        </Link>}
        {!item.url && <Typography key={item.key}>
          {item.name}
        </Typography>}
      </div>
    );
  });

  return (
    <Stack spacing={1}>
      <StyledBreadcrumbs
        separator={'>'}
        aria-label='breadcrumb'
      >
        {breadcrumbs}
      </StyledBreadcrumbs>
    </Stack>
  );
};

export default CssBreadcrumbs;
