/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Autocomplete } from '@mui/material';
import CssCheckbox from './CssCheckbox';
import { GlobalContext } from 'context';
import { getReportBatchIdSelectionLimit } from 'utils';

interface AutocompleteProps {
  label: string
  placeholder: string
  options: any
  isDisabled?: boolean
  isGeneric?: boolean
  onScroll?: any
}

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
}));

const CssAutocomplete = (props: AutocompleteProps) => {
  const { label, placeholder, options, isDisabled, isGeneric, onScroll } = props;
  const { reportBatchIds, setReportBatchIds, isReportBatchIdDeleted, setIsReportBatchIdDeleted, isReportFilterApplied, setIsReportFilterApplied, isFilterCleared, setIsChangeReportBatchIds, autocompleteIds, setAutocompleteIds, isAutocompleteIdDeleted, setIsAutocompleteIdDeleted, isAutocompleteFilterApplied, setIsAutocompleteFilterApplied, setIsChangeAutocompleteIds } = useContext(GlobalContext);
  const [values, setValues] = useState<any>([]);
  const [isError, setIsError] = useState<boolean>(false);

  const handleChange = (event: any, selectedItems: any) => {
    if (getReportBatchIdSelectionLimit() > 0) {
      if (selectedItems.length <= getReportBatchIdSelectionLimit()) {
        if (isGeneric) {
          setIsChangeAutocompleteIds(true);
        } else {
          setIsChangeReportBatchIds(true);
        }
        setValues(selectedItems);
        setIsError(false);
      } else {
        setIsError(true);
      }
    } else {
      if (isGeneric) {
        setIsChangeAutocompleteIds(true);
      } else {
        setIsChangeReportBatchIds(true);
      }
      setValues(selectedItems);
    }
  };

  useEffect(() => {
    if (isFilterCleared) {
      setValues([]);
    } else if (isGeneric) {
      if (isAutocompleteIdDeleted || isAutocompleteFilterApplied) {
        setValues(autocompleteIds);
        
        if (isAutocompleteIdDeleted === true) {
          setIsAutocompleteIdDeleted(false);
        }
  
        if (isAutocompleteFilterApplied === true) {
          setIsAutocompleteFilterApplied(false);
        }
      } else {
        setAutocompleteIds(values);
      }
    } else {
      if (isReportBatchIdDeleted || isReportFilterApplied) {
        setValues(reportBatchIds);
        
        if (isReportBatchIdDeleted === true) {
          setIsReportBatchIdDeleted(false);
        }

        if (isReportFilterApplied === true) {
          setIsReportFilterApplied(false);
        }
      } else {
        setReportBatchIds(values);
      }
    }
  }, [values, isAutocompleteIdDeleted, isReportBatchIdDeleted, isFilterCleared]);

  return (
    <StyledAutocomplete
      multiple
      options={options}
      disableCloseOnSelect
      {...(isDisabled && {disabled: true})}
      {...(onScroll && {ListboxProps: {
        onScroll: onScroll,
        sx: {
          overflowY: 'scroll',
          maxHeight: 170
        }
      }})}
      value={values}
      onChange={(event, value) => handleChange(event, value)}
      getOptionLabel={(option: any) => option.displayName}
      renderOption={(props, option: any, { selected }) => (
        <li {...props} key={option.value}>
          <CssCheckbox checked={selected} />
          {option.displayName}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} {...(isError && {error: true, helperText: `Report batch ID selection should not exceed ${getReportBatchIdSelectionLimit()}`})} />
      )}
    />
  );
}

export default CssAutocomplete;
