import Icon from './Icon';

const ClipboardAlt = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5626 5.32863L20.5793 5.45525C22.926 5.74751 24.6875 7.74199 24.6875 10.1068V23.2186C24.6875 25.5147 22.9772 27.4513 20.6987 27.735C16.9142 28.2064 13.0858 28.2064 9.30134 27.735C7.02282 27.4513 5.3125 25.5147 5.3125 23.2186V10.1068C5.3125 7.74199 7.07399 5.74751 9.42068 5.45525L10.4374 5.32863C10.8401 3.87757 12.1707 2.8125 13.75 2.8125H16.25C17.8293 2.8125 19.1599 3.87757 19.5626 5.32863ZM10.3125 7.23367L9.65241 7.31587C8.2444 7.49123 7.1875 8.68792 7.1875 10.1068V23.2186C7.1875 24.5688 8.19322 25.7075 9.53307 25.8744C13.1637 26.3266 16.8363 26.3266 20.4669 25.8744C21.8068 25.7075 22.8125 24.5688 22.8125 23.2186V10.1068C22.8125 8.68792 21.7556 7.49123 20.3476 7.31587L19.6875 7.23367V8.75C19.6875 9.26777 19.2678 9.6875 18.75 9.6875H11.25C10.7322 9.6875 10.3125 9.26777 10.3125 8.75V7.23367ZM12.1875 6.25C12.1875 5.38706 12.8871 4.6875 13.75 4.6875H16.25C17.1129 4.6875 17.8125 5.38705 17.8125 6.25V7.8125H12.1875V6.25Z" fill="currentColor" />
      <path d="M19.6875 14.6875C19.6875 14.1697 19.2678 13.75 18.75 13.75H11.25C10.7322 13.75 10.3125 14.1697 10.3125 14.6875C10.3125 15.2053 10.7322 15.625 11.25 15.625H18.75C19.2678 15.625 19.6875 15.2053 19.6875 14.6875Z" fill="currentColor" />
      <path d="M18.4375 18.4375C18.4375 17.9197 18.0178 17.5 17.5 17.5H11.25C10.7322 17.5 10.3125 17.9197 10.3125 18.4375C10.3125 18.9553 10.7322 19.375 11.25 19.375H17.5C18.0178 19.375 18.4375 18.9553 18.4375 18.4375Z" fill="currentColor" />
      </svg>
    </Icon>
  );
};

export default ClipboardAlt;