import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledFilterButton = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: 30,
  padding: 3,
  height: 'fit-content',
  '&.btn-flat': {
    borderRadius: 0,
    borderTop: 0,
    borderBottomColor: theme.palette.divider,
    paddingTop: 32,
    paddingBottom: 32,
    '& .icon': {
      display: 'none'
    },
    '&.custom .icon': {
      display: 'inline-flex'
    },
    '& .inner-button': {
      borderRadius: 0,
      display: 'flex',
      justifyContent: 'space-between'
    },
    '&.active': {
      backgroundColor: `${theme.palette.primary.light}0D`,
      borderBottomColor: theme.palette.divider,
      '& .inner-button': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        fontWeight: 400
      },
      '& .icon': {
        display: 'inline-flex'
      },
      '&.custom-active .icon': {
        transform: 'rotate(180deg)'
      },
    },
    '&.btn-transaction-status': {
      paddingTop: 8.5,
      paddingBottom: 8.5,
      border: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& .icon': {
        display: 'none'
      },
      '&.active .icon': {
        display: 'inline-flex'
      },
      '& .inner-button': {
        color: theme.palette.primary.main,
        fontWeight: 400
      }
    },
    '&.btn-review-status': {
      paddingTop: 8.5,
      paddingBottom: 8.5,
      border: 0,
      borderBottom: `1px solid #F7F3F3`,
      '& .icon': {
        display: 'none'
      },
      '&.active .icon': {
        display: 'inline-flex'
      },
      '& .inner-button': {
        color: theme.palette.primary.main,
        fontWeight: 400
      }
    }
  },
  '& .inner-button': {
    color: theme.palette.secondary.light,
  },
  '&.active': {
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.light,
    '&.btn-transaction-status': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&.btn-review-status': {
      borderBottomColor: '#F7F3F3',
    },
    '& .inner-button': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: 500
    }
  },
  '&.light': {
    border: 0,
    borderRadius: 5,
    '&.active': {
      border: 0,
      backgroundColor: `#02B9A20F`,
      borderRadius: 5,
      '& .inner-button': {
        background: `#02B9A20F`,
        borderRadius: 5,
        color: theme.palette.primary.main
      }
    }
  }
}));

const StyledButton = styled(Box)(({ theme }) => ({
  borderRadius: 30,
  padding: '6px 20px',
}));

const FilterButton = ({ active, children, filter, ...rest }: any) => {
  return (
    <>
      <StyledFilterButton {...rest}>
        <StyledButton data-filter={filter} className='inner-button'>{children}</StyledButton>
      </StyledFilterButton>
    </>
  );
};

export default FilterButton;