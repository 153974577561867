
export const GiftsStatusFilters = [
  {
    parentKey: 'status',
    title: 'Status',
    filters: [
      {
        key: 'APPROVED',
        label: 'Approved',
        isChecked: false
      },
      {
        key: 'PENDING',
        label: 'Pending',
        isChecked: false
      },
      {
        key: 'REJECTED',
        label: 'Rejected',
        isChecked: false
      },
      {
        key: 'FAILED',
        label: 'Failed',
        isChecked: false
      },
    ]
  },
  {
    parentKey: 'rewardType',
    title: 'Reason',
    filters: [
      {
        key: 'REFERRAL',
        label: 'Referral',
        isChecked: false
      },
      {
        key: 'DEPOSIT_PROMOTIONAL',
        label: 'Deposit Promotional',
        isChecked: false
      }
    ]
  },
];