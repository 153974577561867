import { useContext } from 'react';
import axios from 'axios';
import Auth from 'services/auth';
import { GlobalContext } from 'context';

interface Props {
  config: any
}

const useAxios = ({ config }: Props) => {
  const { setIsPermissionGranted } = useContext(GlobalContext);
  const instance = (service: string) => {
    let url = '';
    if (`${process.env.REACT_APP_IS_API_GATEWAY_ENABLED}` === 'true') {
      url = `${process.env.REACT_APP_API_GATEWAY_URL}/${process.env.REACT_APP_API_GATEWAY_VERSION}`;
    } else {
      url = `${process.env.REACT_APP_FRED_SERVICE_API}/${process.env.REACT_APP_FRED_SERVICE_API_VERSION}`;

      if (service === 'MONEY_MANAGEMENT') {
        url = `${process.env.REACT_APP_MONEY_MANAGEMENT_API}/${process.env.REACT_APP_MONEY_MANAGEMENT_API_VERSION}`;
      } else if (service === 'MARKET_SERVICE') {
        url = `${process.env.REACT_APP_MARKET_SERVICE_API}/${process.env.REACT_APP_MARKET_SERVICE_API_VERSION}`;
      } else if (service === 'REPORTS_SERVICE') {
        url = `${process.env.REACT_APP_REPORTS_SERVICE_API}/${process.env.REACT_APP_REPORTS_SERVICE_API_VERSION}`;
      } else if (service === 'ONBOARDING') {
        url = `${process.env.REACT_APP_ONBOARDING_SERVICE_API}/${process.env.REACT_APP_ONBOARDING_SERVICE_API_VERSION}`;
      } else if (service === 'MASTER') {
        url = `${process.env.REACT_APP_MASTER_SERVICE_API}/${process.env.REACT_APP_MASTER_SERVICE_API_VERSION}`;
      }
    }

    const axiosInstance = axios.create({
      ...(config || {}),
      baseURL: url,
      headers: {
        ...(config?.headers || {}),
        'Content-Type': 'application/json',
        Authorization: Auth.isLoggedIn() ? `Bearer ${Auth.getToken()}` : undefined,
      },
    });

    axiosInstance.interceptors.request.use(
      (req: any) => req,
      (error: any) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (res: any) => res,
      async (error: any) => {
        const originalRequest = error.config;
        let token = null;
        if ((error.response.status === 401 || error.response.data.code === 401) && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            await Auth.updateToken(async (res: any) => {
              token = res.token || null;
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
            });
            if (!token) {
              return Auth.doLogin();
            }
            return instance(originalRequest);
          } catch (err) {
            return Promise.reject(err);
          }
        } else if (error.response.status === 422) {
          return Promise.reject(error);
        } else if (error.response.status === 403) {
          setIsPermissionGranted(true);
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  };

  return { 
    fredAxiosInstance: instance('FRED_SERVICE'),
    moneyAxiosInstance: instance('MONEY_MANAGEMENT'),
    marketAxiosInstance: instance('MARKET_SERVICE'),
    reportsAxiosInstance: instance('REPORTS_SERVICE'),
    onboardingAxiosInstance: instance('ONBOARDING'),
    masterAxiosInstance: instance('MASTER')
  };
};

export default useAxios;