/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box } from '@mui/material';
import CssTabs from 'components/CssTabs';
import DateRangeFilters from './DateRangeFilters';
import KycFilters from './KycFilters';

const MobileFilters = (props: any) => {
  const { onCancel, device } = props;
  const tabs = [
    {
      label: 'Date range',
      value: 1,
      tabPanelComponent: <DateRangeFilters onCancel={onCancel} />
    },
    {
      label: 'KYC',
      value: 2,
      tabPanelComponent: <KycFilters onCancel={onCancel} />
    }
  ];

  return (
    <Box sx={{mt: device === 'DESKTOP' ? 0 : 4}}>
      {device ? <DateRangeFilters {...(!device && {onCancel: onCancel})} {...(device && {device: device})} /> : 
        <CssTabs tabs={tabs} sx={{'& .MuiTabs-flexContainer': { justifyContent: 'space-between', ml: 6, mr: 6 }}} />
      }
    </Box>
  );
};

export default MobileFilters;