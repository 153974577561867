/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, MouseEvent, ReactElement, Ref, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, SelectChangeEvent, Dialog, DialogActions, DialogContent, DialogContentText, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import moment from 'moment';
import _ from 'lodash';
import { GlobalContext } from 'context';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import CssContainer from 'components/CssContainer';
import CssBox from 'components/CssBox';
import CssBasicTable from 'components/CssBasicTable';
import CssButton from 'components/CssButton';
import CssSnackbar from 'components/CssSnackbar';
import { HolidayCalendarHeaders } from 'constants/holiday-calendar-table-head-columns';
import { formatTime, processHolidayDate, checkPermissions } from 'utils';

const Transition = forwardRef((
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) => {
  return <Slide direction='up' ref={ref} {...props} />;
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  lineHeight: '32px',
  marginRight: 32,
  fontSize: '1rem',
  '&.label': {
    fontWeight: 700,
    marginRight: 12
  },
  '&:last-child': {
    marginRight: 0
  },
  '&.ALERT': {
    color: '#FF5C4E'
  },
  '&.VERIFIED': {
    color: '#02B9A3'
  },
  '&.REVIEW': {
    color: '#FFAE00'
  },
  '&.WARNING': {
    color: '#FF802C'
  }
}));

const ExchangeTypography = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  marginRight: 30
}));

const ExchangeHolidays = () => {
  const { setIsRowAdd, holidayListFields, setHolidayListFields, userPermissions } = useContext(GlobalContext);
  const { 
    getHolidayListByExchangeApi, 
    postHolidayForExchangeApi,
    updateHolidayForExchangeApi,
    deleteHolidayForExchangeApi
  } = useRequests();
  const getHolidayListByExchange = useApi(getHolidayListByExchangeApi);
  const postHolidayForExchange = useApi(postHolidayForExchangeApi);
  const updateHolidayForExchange = useApi(updateHolidayForExchangeApi);
  const deleteHolidayForExchange = useApi(deleteHolidayForExchangeApi);
  const [initialHolidayListData, setInitialHolidayListData] = useStorage<any[]>('initialHolidayListData', []);
  const [data, setData] = useState<any>(null);
  const [yearsArray, setYearsArray] = useState<any[]>([]);
  const [yearSelected, setYearSelected] = useState<any>(moment().year());
  const [holidayListByYear, setHolidayListByYear] = useState<any[]>([]);
  const [statusArray, setStatusArray] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [deleteRecordName, setDeleteRecordName] = useState<string>('');
  const [deleteRecordDate, setDeleteRecordDate] = useState<string>('');
  const [deleteRecordHolidayId, setDeleteRecordHolidayId] = useState<string | number>('');
  const [confirmBoxType, setConfirmBoxType] = useState<string>('');
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<string>('');

  const onYearChange = (event: SelectChangeEvent<unknown>) => {
    setYearSelected(event.target.value);
  };

  const getHolidayListCall = () => {
    getHolidayListByExchange.request(1).then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });
  };

  const resetHolidayListFields = () => {
    setHolidayListFields(_.map(holidayListFields, (f) => {
      if (f.value !== '' || f.isValid === false) {
        f.isValid = true;
        f.value = ''
      }

      return f;
    }));
  };

  const onRowEdit = (actionType: string, r?: any) => {
    setSelectedRecord(r);
    if (actionType === 'ON_EDIT') {
      onRowEditChange(actionType, r);
    } else if (actionType === 'EDIT_CANCEL' || actionType === 'UPDATE') {
      setConfirmBoxType(actionType);
      setOpenConfirmDialog(true);
    }
  };

  const onRowEditChange = (actionType: string, r?: any) => {
    if (actionType === 'ON_EDIT') {
      setHolidayListByYear((hls) => _.map(holidayListByYear, (h) => {
        if (h.holidayId === r.holidayId) {
          h.isEditing = true;
        }
        return h;
      }));
      const row = _.find(holidayListByYear, (h) => h.holidayId === r.holidayId);

      setHolidayListFields((hlf: any) => _.map(hlf, (h) => {
        if (h.key in row) {
          h.value = row[h.key];
        }
        return h;
      }));
      setIsEditing(true);
    } else if (actionType === 'EDIT_CANCEL') {
      const holidayData = window.localStorage.getItem('initialHolidayListData');
      const jsonData = JSON.parse(holidayData || '{}');
      setHolidayListByYear((hlby) => _.map(!_.isEmpty(jsonData) ? jsonData : hlby, (h) => {
        if (h.holidayId === selectedRecord?.holidayId) {
          h.isEditing = false;
        }
        return h;
      }));
      resetHolidayListFields();
      setIsEditing(false);
      setOpenConfirmDialog(false);
    } else if (actionType === 'UPDATE') {
      const fields = _.map(holidayListFields, (f) => {
        if (f.validateWith) {
          const o = _.find(holidayListFields, (hl) => hl.key === f.validateWith);
          
          if (o.value === 'CLOSE') {
            f.isValid = true;
          } else if (!f.value) {
            f.isValid = false;
          } else {
            f.isValid = true;
          }
        } else if (!f.value) {
          f.isValid = false;
        } else {
          f.isValid = true;
        }

        return f;
      });
    
      const unfilledFields = _.filter(holidayListFields, (h: any) => h.isValid === false);
      if (unfilledFields.length === 0) {
        const payload: any = {};
        _.map(fields, (f) => {
          payload[f.key] = f.key === 'startTime' || f.key === 'closeTime' ? moment(f.value, 'HH:mm:ss').format('HH:mm') : f.value;
        });
        payload.exchangeId = 1;

        updateHolidayForExchange.request(selectedRecord?.holidayId, payload).then((res) => {
          if (res.status === 200) {
            resetHolidayListFields();
            getHolidayListCall();
            setIsEditing(false);
            setOpenConfirmDialog(false);
            setSnackbarMessage('Holiday data updated successfully');
            setOpenSnackbar(true);
          }
        }).catch((err) => {
          console.log(err);
          setOpenConfirmDialog(false);
          setSnackbarSeverity('ERROR');
          setSnackbarMessage(err.response.data.error.message);
          setOpenSnackbar(true);
        });
      }
    }
  };

  const onRowDelete = (r: any) => {
    setConfirmBoxType('DELETE');
    setDeleteRecordHolidayId(r.holidayId);
    setDeleteRecordName(`${r.holidayName}`);
    setDeleteRecordDate(`${processHolidayDate(r.holidayDate)}`);
    setOpenConfirmDialog(true);
  };

  const onRowAdd = (event: MouseEvent, actionType: string) => {
    if (actionType === 'ADD') {
      setIsRowAdd(true);
    } else if (actionType === 'CANCEL') {
      resetHolidayListFields();
      setIsRowAdd(false);
    } else if (actionType === 'CREATE') {
      const fields = _.map(holidayListFields, (f) => {
        if (f.validateWith) {
          const o = _.find(holidayListFields, (hl) => hl.key === f.validateWith);

          if (o.value === 'CLOSE') {
            f.isValid = true;
          } else if (o.value === 'OPEN' && f.value !== '') {
            f.isValid = true;
          } else {
            f.isValid = false;
          }
        } else if (f.value === '') {
          f.isValid = false;
        } else {
          f.isValid = true;
        }

        return f;
      });

      const unfilledFields = _.filter(holidayListFields, (h: any) => h.isValid === false);
      setHolidayListFields(fields);
      
      if (unfilledFields.length === 0) {
        const payload: any = {};

        _.map(fields, (f) => {
          payload[f.key] = f.value;
        });
        payload.exchangeId = 1;

        postHolidayForExchange.request(payload).then((res) => {
          if (res.status === 200) {
            resetHolidayListFields();
            getHolidayListCall();
            setIsRowAdd(false);
            setSnackbarMessage('Holiday data created successfully');
            setOpenSnackbar(true);
          }
        }).catch((err) => {
          console.log(err);
          setSnackbarSeverity('ERROR');
          setSnackbarMessage(err.response.data.error.message);
          setOpenSnackbar(true);
        });
      }
    }
  };

  const onFieldChange = (event: any, key: string, r?: any) => {
    setHolidayListFields((hls: any) => _.map(hls, (h) => {
      if (h.key === key) {
        h.value = event.target.value;
      }

      return h;
    }));

    if (isEditing) {
      setHolidayListByYear((hlby) => _.map(hlby, (h) => {
        if (h.holidayId === r.holidayId) {
          h[key] = event.target.value;
        }
        return h;
      }));
    }
  };

  const handleConfirmDialogClose = (event: {},
  reason: 'backdropClick') => {
    if (reason === 'backdropClick') return true;
    setOpenConfirmDialog(false);
  };

  const onCancel = () => {
    setOpenConfirmDialog(false);
  };

  const onRecordConfirmed = () => {
    if (confirmBoxType === 'DELETE') {
      deleteHolidayForExchange.request(deleteRecordHolidayId).then((res) => {
        if (res.status === 200) {
          getHolidayListCall();
          setOpenConfirmDialog(false);
          setSnackbarMessage('Holiday data deleted successfully');
          setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.log(err);
        setOpenConfirmDialog(false);
        setSnackbarSeverity('ERROR');
        setSnackbarMessage(err.response.data.error.message);
        setOpenSnackbar(true);
      });
    } else {
      onRowEditChange(confirmBoxType);
    }
  };

  const onSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarSeverity('');
    setSnackbarMessage('');
  };

  const checkHolidayExchangePermissions = () => {
    return checkPermissions(userPermissions, 'EXCHANGE_HOLIDAY');
  };

  useEffect(() => {
    //TODO: this may need to handle passing dynamic exchange id's
    getHolidayListCall();
  }, []);

  useEffect(() => {
    const yearsArr = _.map(data?.holidays, (item: any) => {
      return moment(item.holidayDate).year();
    });

    setYearsArray(_.uniq(yearsArr));
    setStatusArray(['OPEN', 'CLOSE']);
  }, [data]);

  useEffect(() => {
    const holidayList = _.filter(data?.holidays, (item: any) => moment(item.holidayDate).year() === yearSelected);
    const holidays = _.map(holidayList, (h) => { h.isEditing = false; return h; });

    setHolidayListByYear(holidays);
    setInitialHolidayListData(holidays);
  }, [data, yearSelected]);

  return (
    <CssContainer>
      <CssBox>
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={7}>
            <ExchangeTypography variant='h4'>{data?.exchange?.exchange}</ExchangeTypography>
            <StyledTypography variant='body2' className='label'>Long name</StyledTypography>
            <StyledTypography variant='body2'>{data?.exchange?.exchangeName}</StyledTypography>
            <StyledTypography variant='body2' className='label'>Country code</StyledTypography>
            <StyledTypography variant='body2'>{data?.exchange?.countryCode}</StyledTypography>
            <StyledTypography variant='body2' className='label'>Local timezone</StyledTypography>
            <StyledTypography variant='body2'>{data?.exchange?.localTimezone}</StyledTypography>
            <StyledTypography variant='body2' className='label'>Daily start time</StyledTypography>
            <StyledTypography variant='body2'>{formatTime(data?.exchange?.dailyStartTime, 'HH:mm')}</StyledTypography>
            <StyledTypography variant='body2' className='label'>Daily close time</StyledTypography>
            <StyledTypography variant='body2'>{formatTime(data?.exchange?.dailyCloseTime, 'HH:mm')}</StyledTypography>
          </Grid>
        </Grid>
      </CssBox>
      <CssBasicTable
        headers={HolidayCalendarHeaders}
        data={holidayListByYear}
        dropdownList={yearsArray}
        dropdownSelected={yearSelected}
        onChange={onYearChange}
        sx={{ mt: {md: 3, xs: 0} }}
        {...(checkHolidayExchangePermissions() && {
          onAdd: onRowAdd,
          rowActions: {
            onEdit: onRowEdit,
            onDelete: onRowDelete,
            onChange: onFieldChange
          },
          isTableEditable: true,
          isHover: true
        })}
        statusDropdownList={statusArray}
      />

      <Dialog
        open={openConfirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleConfirmDialogClose}
        aria-describedby='alert-dialog-slide-description'
        disableEscapeKeyDown={true}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {confirmBoxType === 'EDIT_CANCEL' && <span>Are you sure want to discard the changes?</span>}
            {confirmBoxType === 'UPDATE' && <span>Are you sure want to update the changes?</span>}
            {confirmBoxType === 'DELETE' && <span>Are you sure want to delete, <b>{deleteRecordName} -  {deleteRecordDate}</b>?</span>} 
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{pb: 3, pr: 3, pt: 2}}>
          <CssButton variant='outlined' sx={{mr: 1, ml: 0}} onClick={onCancel}>No</CssButton>
          <CssButton variant='contained' sx={{mr: 0, ml: 1}} onClick={onRecordConfirmed}>Yes</CssButton>
        </DialogActions>
      </Dialog>
      <CssSnackbar open={openSnackbar} message={snackbarMessage} severityType={snackbarSeverity} onClose={onSnackbarClose} />
    </CssContainer>
  );
};

export default ExchangeHolidays;