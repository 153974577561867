/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, Button } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useFirebase from 'hooks/firebase-hook';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { GlobalContext } from 'context';
import { getTimeElapsed, formatMessagePayloadStatus, isAdminRole, checkPermissions } from 'utils';

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: 15,
  backgroundColor: '#04F8DA0D'
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginTop: 10,
  fontSize: '0.75rem',
  lineHeight: 1.2
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#4021F5',
  textTransform: 'capitalize',
  paddingTop: 0,
  paddingBottom: 0
}));

const Notifications = ({ children }: any) => {
  const navigate = useNavigate();
  const { userRole, fcmToken, setHighlightNotificationRecords, setDashboardSelectedFilter, reportTabValue, setReportTabValue, searchQuery, setSearchQuery, notificationFilterQuery, setNotificationFilterQuery, userPermissions, setRecordCount } = useContext(GlobalContext);
  const { requestForToken, onMessageListener } = useFirebase();
  const { getNotificationsSubscribeApi } = useRequests();
  const getNotificationsSubscribe = useApi(getNotificationsSubscribeApi);
  const [notification, setNotification] = useState<any>(null);
  const notify = () =>  toast(<ToastDisplay/>);

  const ToastDisplay = () => {
    return (
      <StyledBox>
        <Grid container>
          <Grid item xs={10}>
            {notification && notification.messagePayload && <Typography variant='body1'>
              <b>{notification.messagePayload.actionType}</b> {notification.messagePayload.message} <b>{formatMessagePayloadStatus(notification.messagePayload.status)}</b>
              {notification.resourceFilter && notification.resourceFilter.includes('withdrawals') && ` (${notification.resourceFilter})`}
            </Typography>}
            {notification && notification.createdAt && <StyledSubtitle variant="subtitle2" color="textSecondary">
              {getTimeElapsed(new Date(notification.createdAt))}
            </StyledSubtitle>}
          </Grid>
          <Grid item xs={2}>
            <StyledButton size='small' onClick={() => onNotificationClicked(notification)} sx={{mt: 4}}>View</StyledButton>
          </Grid>
        </Grid>
      </StyledBox>
    );
  };

  const onNotificationClicked = (mPayload: any) => {
    let route = '';
    setHighlightNotificationRecords(true);
    if (['DASHBOARD_COMP', 'DASHBOARD_ACC', 'DASHBOARD_FIN_OPS'].includes(mPayload.resourceName)) {
      route = '/';
      setDashboardSelectedFilter(mPayload.resourceName);

      if (reportTabValue) {
        setReportTabValue(null);
      }

      if (mPayload.resourceFilter.includes('withdrawals')) {
        if (searchQuery !== '') {
          setSearchQuery('');
        }
        setNotificationFilterQuery(`&type=WITHDRAW&batchNo=${mPayload.resourceFilter}`);
      } else if (mPayload.resourceFilter) {
        if (notificationFilterQuery !== '') {
          setRecordCount(null);
          setNotificationFilterQuery('');
        }
        setSearchQuery(mPayload.resourceFilter);
      }
    } else if (['REPORTS_TRAVEL_RULES'].includes(mPayload.resourceName)) {
      route = '/reports';
      setReportTabValue(2);
    }
    if (route) {
      navigate(route, {state: {isNotification: true}});
    }
  };

  const checkNotificationPermissions = () => {
    return checkPermissions(userPermissions, 'NOTIFICATIONS', true);
  };

  useEffect(() => {
    onMessageListener().then((payload: any) => {
      setNotification(JSON.parse(payload?.data?.eventPayload));  
    }).catch((err) => console.log('failed: ', err));
  }, [onMessageListener]);

  useEffect(() => {
    if (notification) {
      notify();
    }
  }, [notification]);

  useEffect(() => {
    if (!fcmToken) {
      requestForToken();
    }
  }, [requestForToken]);

  useEffect(() => {
    if (userRole && !isAdminRole(userRole) && fcmToken && checkNotificationPermissions()) {
      getNotificationsSubscribe.request({roles: [userRole], fcmDeviceToken: fcmToken});
    }
  }, [userRole, fcmToken]);

  return (
    <>
      <Toaster 
        position='top-right'
        toastOptions={{
          style: {
            background: '#04F8DA0D',
            marginTop: 95,
            marginRight: 55
          }
        }}
      />
      {children}
    </>
  );
};

export default Notifications;
