import Icon from './Icon';

const BackArrow = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.7879 10.6629C14.154 10.2968 14.154 9.7032 13.7879 9.33709C13.4218 8.97097 12.8282 8.97097 12.4621 9.33709L7.46209 14.3371C7.27903 14.5201 7.1875 14.7601 7.1875 15C7.1875 15.1271 7.2128 15.2483 7.25864 15.3589C7.30439 15.4694 7.3722 15.573 7.46209 15.6629L12.4621 20.6629C12.8282 21.029 13.4218 21.029 13.7879 20.6629C14.154 20.2968 14.154 19.7032 13.7879 19.3371L10.3883 15.9375H22.5C23.0178 15.9375 23.4375 15.5178 23.4375 15C23.4375 14.4822 23.0178 14.0625 22.5 14.0625H10.3883L13.7879 10.6629Z" fill="#000000"/>
      </svg>
    </Icon>
  );
};

export default BackArrow;