import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import CssContainer from 'components/CssContainer';
import BrandNemo from 'components/icons/BrandNemo';
import CssAvatar from 'components/CssAvatar';
import CssChip from 'components/CssChip';
import LinkModule from 'components/LinkModule';
import Roles from './components/Roles';
import Members from './components/Members';
import { GlobalContext } from 'context';
import { getFirstLetters } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '1rem',
  '&.user-details': {
    marginTop: 16
  }
}));

const Admin = () => {
  const { userInfo } = useContext(GlobalContext);
  const { getBrandsApi } = useRequests();
  const getBrands = useApi(getBrandsApi);
  const [brandId, setBrandId] = useState('');
  const [showComponent, setShowComponent] = useState('DEFAULT');

  const handleSwitch = (name: string) => {
    setShowComponent(name);
  };

  useEffect(() => {
    getBrands.request().then((res) => {
      if (res.status === 200) {
        setBrandId(res.data[0].id);
      }
    })
  }, []);

  return (
    <CssContainer sx={{width: 650, pr: '0 !important', pl: '0 !important', mt: 0}}>
      <SwitchTransition mode='out-in'>
        <CSSTransition
          key={showComponent}
          classNames='fade'
          timeout={500}
        >
          <>
          {showComponent === 'DEFAULT' && 
            <Grid container sx={{mt: 12}}>
              <Grid item xs={12} justifyContent={'end'} sx={{ display: 'flex' }}>
                <BrandNemo />
              </Grid>
              <Grid item xs={12} sx={{mt: 3}}>
                <Grid container>
                  <Grid item xs={1.25}>
                    {userInfo && <CssAvatar sx={{ width: 50, height: 50, fontSize: '1.25rem', backgroundColor: '#C4C4C4', mt: .5, pt: .25 }}>{getFirstLetters(userInfo.email)}</CssAvatar>}
                  </Grid>
                  <Grid item xs={10.75}>
                    {userInfo && <StyledTypography className='user-details'>{userInfo.email}</StyledTypography>}
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{display: 'flex', mt: 2, ml: 8.75}}>
                  <StyledTypography>Role</StyledTypography>
                  <CssChip label='Super Admin' sx={{ml: 1}} />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{mt: 5, borderTop: `1px solid #E5E5E5`}}>
                <LinkModule name={'Roles'} onModuleClick={() => handleSwitch('ROLES')} />
                <LinkModule name={'Members'} onModuleClick={() => handleSwitch('MEMBERS')} />
              </Grid>
            </Grid>
          }
          {showComponent === 'ROLES' && 
            <Roles brandId={brandId} onClickBack={() => handleSwitch('DEFAULT')} />
          }
          {showComponent === 'MEMBERS' && 
            <Members brandId={brandId} onClickBack={() => handleSwitch('DEFAULT')} />
          }
          </>
        </CSSTransition>
      </SwitchTransition>
    </CssContainer>
  );
};

export default Admin;