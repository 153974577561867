import Icon from './Icon';

const Eye = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 10.25C11.2574 10.25 10.25 11.2574 10.25 12.5C10.25 13.7426 11.2574 14.75 12.5 14.75C13.7426 14.75 14.75 13.7426 14.75 12.5C14.75 11.2574 13.7426 10.25 12.5 10.25Z" fill="#00CCCC"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 6C9.88223 6 7.52801 7.05139 5.83162 8.35335C4.98232 9.00519 4.28544 9.72913 3.79649 10.4337C3.31686 11.1248 3 11.8515 3 12.5C3 13.1485 3.31686 13.8752 3.79649 14.5663C4.28544 15.2709 4.98232 15.9948 5.83162 16.6466C7.52801 17.9486 9.88223 19 12.5 19C15.1178 19 17.472 17.9486 19.1684 16.6466C20.0177 15.9948 20.7146 15.2709 21.2035 14.5663C21.6831 13.8752 22 13.1485 22 12.5C22 11.8515 21.6831 11.1248 21.2035 10.4337C20.7146 9.72913 20.0177 9.00519 19.1684 8.35335C17.472 7.05139 15.1178 6 12.5 6ZM8.75 12.5C8.75 10.4289 10.4289 8.75 12.5 8.75C14.5711 8.75 16.25 10.4289 16.25 12.5C16.25 14.5711 14.5711 16.25 12.5 16.25C10.4289 16.25 8.75 14.5711 8.75 12.5Z" fill="#00CCCC"/>
      </svg>
    </Icon>
  );
};

export default Eye;