import Icon from './Icon';

const InlineClose = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill={ fill || "#949494"} />
      <path d="M15.3245 15.5711L8.25342 8.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M8.25364 15.5711L15.3247 8.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
      </svg>
    </Icon>
  );
};

export default InlineClose;