import { forwardRef } from 'react';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const CssDialog = ({ open, onScreenClose, children, maxWidth, ...rest }: any) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth || 'xs'}
      open={open}
      onClose={onScreenClose}
      TransitionComponent={Transition}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export default CssDialog;