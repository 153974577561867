/* eslint-disable react-hooks/exhaustive-deps */
import { useState, ChangeEvent, MouseEvent, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid, FormGroup, FormControlLabel } from '@mui/material';
import { TransactionsHeaders, TransactionsWithdrawalHeaders, FinopsTransactionsWithdrawalHeaders } from 'constants/transactions-table-head-columns';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssAccordion from 'components/CssAccordion';
import CssCheckbox from 'components/CssCheckbox';
import CssButton from 'components/CssButton';
import DateTimeFilters from 'components/DateTimeFilters';
import FilterButton from 'components/FilterButton';
import CssAutocomplete from 'components/CssAutocomplete';
import CssDialog from 'components/CssDialog';
import ViewField from 'components/ViewField';
import CheckTick from 'components/icons/CheckTick';
import { Order, setAccordionExpandByFilters, checkPermissions, getQueryMap } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 5,
}));

const Transactions = (props: any) => {
  const { from } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { transactionsStatusFilters, setTransactionsStatusFilters, finopsTransactionsStatusFilters, setFinopsTransactionsStatusFilters, paymentFilterDates, setPaymentFilterDates, isFilterCleared, setIsFilterCleared, searchQuery, setSearchQuery, isSearchClosed, setIsSearchClosed, setEnableSearchField, isUpdatePage, setIsUpdatePage, indeterminateCheck, setIndeterminateCheck, reportBatchIds, setReportBatchIds, setIsReportFilterApplied, setCheckboxList, selectedAllChecks, setSelectedAllChecks, isChangeReportBatchIds, setIsChangeReportBatchIds, setTransactionsDownloadData, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, enableSelectAllCheckbox, setEnableSelectAllCheckbox, checkedTxnIds, setCheckedTxnIds, uncheckedTxnIds, setUncheckedTxnIds, fifoReportBatchId, setFifoReportBatchId, isSelectAllCheckboxClicked, setIsSelectAllCheckboxClicked, mostAppearedTransactionStatus, setMostAppearedTransactionStatus, checkedTransactionStatus, setCheckedTransactionStatus, userPermissions, setTransactionsDownloadParams, notificationFilterQuery, setNotificationFilterQuery, highlightNotificationRecords, setHighlightNotificationRecords, recordCount, setRecordCount, selectedButtonType, transactionFilterQuery, setTransactionFilterQuery } = useContext(GlobalContext);
  const { getTransactionsApi, getTransactionsDownloadApi, getFinOpsTransactionsApi, getTransactionsByClientIdApi, updateTransactionsStatusApi, updateSingleTransactionStatusApi, getWithdrawalReportsApi, getTransactionsCountByBatchIdApi, getUpdateInprogressTxnsApi, updateManualReviewStatusApi } = useRequests();
  const getTransactions = useApi(getTransactionsApi);
  const getTransactionsDownload = useApi(getTransactionsDownloadApi);
  const getFinOpsTransactions = useApi(getFinOpsTransactionsApi);
  const getTransactionsByClientId = useApi(getTransactionsByClientIdApi);
  const updateTransactionsStatus = useApi(updateTransactionsStatusApi);
  const updateSingleTransactionStatus = useApi(updateSingleTransactionStatusApi);
  const getWithdrawalReports = useApi(getWithdrawalReportsApi);
  const getTransactionsCountByBatchId = useApi(getTransactionsCountByBatchIdApi);
  const getUpdateInprogressTxns = useApi(getUpdateInprogressTxnsApi);
  const updateManualReviewStatus = useApi(updateManualReviewStatusApi);
  const [clientId, setClientId] = useStorage<number | null>('clientId', null);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('txnCreatedAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openFiltersScreen, setOpenFiltersScreen] = useState(false);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [filterType, setFilterType] = useState<string[]>([]);
  const [openTransactionStatusScreen, setOpenTransactionStatusScreen] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [isEnableCheckbox, setIsEnableCheckbox] = useState(false);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState<boolean>(false);
  const [reportBatchMenu, setReportBatchMenu] = useState<any>([]);
  const [isUpdatedStatus, setIsUpdatedStatus] = useState<boolean>(false);
  const [activeTransactionStatus, setActiveTransactionStatus] = useState<any>('SUCCESSFUL');
  const [batchLength, setBatchLength] = useState<number>(0);
  const [selectedRecordsLength, setSelectedRecordsLength] = useState<number>(0);
  const [batchIds, setBatchIds] = useState<string>('');
  const [reportBatchMenuPage, setReportBatchMenuPage] = useState(0);
  const [reportBatchMenuLimit] = useState(50);
  const [enableUpdatedConfirmButton, setEnableUpdatedConfirmButton] = useState<boolean>(false);
  const [includeTxnNumbers, setIncludeTxnNumbers] = useState<string[]>([]);
  const [updateInprogressTxns, setUpdateInprogressTxns] = useState<string[]>([]);
  const [openNameCheckStatusScreen, setOpenNameCheckStatusScreen] = useState<boolean>(false);
  const [activeReviewStatus, setActiveReviewStatus] = useState<any>('APPROVED');
  const [customerBankAccountID, setCustomerBankAccountID] = useState<number | null>(null);
  const [bankAccountClientName, setBankAccountClientName] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  // PN-7746 : Added this state variable to hold the transaction record data
  const [transactionRecord, setTransactionRecord] = useState<any>(null);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onTransactionStatusScreenOpen = () => {
    setOpenTransactionStatusScreen(true);
  };

  const onTransactionStatusScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenTransactionStatusScreen(false);
  };

  const onClearFilters = () => {
    setPaymentFilterDates({ fromDate: '', toDate: '', statusUpdatedFromDate: '',
    statusUpdatedToDate: '' });
    setIsFiltersCleared(true);
    setTransactionFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    if (from === 'FINOPS') {
      setFinopsTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
        c.filters = _.map(c.filters, (f: any) => {
          if (f.isChecked) {
            f.isChecked = false;
          }
          return f;
        });
        return c;
      }));
    } else {
      setTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
        c.filters = _.map(c.filters, (f: any) => {
          if (f.isChecked) {
            f.isChecked = false;
          }
          return f;
        });
        return c;
      }));
    }
    setIsFilterCleared(true);
    setReportBatchIds([]);
    setFilterType([]);
    setUncheckedTxnIds([]);
    setCheckedTxnIds([]);
    setIsSelectAllCheckboxClicked(false);
    setCheckedTransactionStatus('');
    setEnableSelectAllCheckbox(false);
  };

  const onApply = () => {
    let query = '';
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    setUncheckedTxnIds([]);
    setCheckedTxnIds([]);
    setSelectedAllChecks(false);
    setIndeterminateCheck(false);
    setIsSelectAllCheckboxClicked(false);
    setCheckedTransactionStatus('');
    setTransactionsDownloadData(null);
    setTransactionsDownloadParams(null);

    if (notificationFilterQuery !== '') {
      setRecordCount(null);
      setNotificationFilterQuery('');
    }
    if (highlightNotificationRecords && (searchQuery !== '')) {
      setSearchQuery('');
      setHighlightNotificationRecords(false);
    }

    if (from === 'FINOPS') {
      _.map(finopsTransactionsStatusFilters, (o: any) => {
        _.map(o.filters, (f: any) => {
          if (f.isChecked) {
            query += `&${o.parentKey}=${f.key}`;
            if (f.key === 'WITHDRAWAL_REQUESTED') {
              query += '&status=DW_PENDING&status=DW_APPROVED'
            } else if (f.key === 'APPROVED_BY_PAYMENT_PROVIDER') {
              query += '&status=APPROVED_BY_PAYMENT_PROVIDER&status=ACCEPTED_BY_BANK&status=PENDING_WITH_BANK'
            }
          }
        });
      });
    } else {
      _.map(transactionsStatusFilters, (o: any) => {
        _.map(o.filters, (f: any) => {
          if (f.isChecked) {
            query += `&${o.parentKey}=${f.key}`;
            if (f.key === 'WITHDRAWAL_REQUESTED') {
              query += '&status=DW_PENDING&status=DW_APPROVED'
            } else if (f.key === 'APPROVED_BY_PAYMENT_PROVIDER') {
              query += '&status=APPROVED_BY_PAYMENT_PROVIDER&status=ACCEPTED_BY_BANK&status=PENDING_WITH_BANK'
            }
          }
        });
      });
    }

    if (reportBatchIds.length > 0) {
      _.map(reportBatchIds, (b: any) => {
        query += `&batchNo=${b.value}`;
      });

      setIsReportFilterApplied(true);
    }

    if (!highlightNotificationRecords && query) {
      setTransactionFilterQuery(query);
    }
    if (paymentFilterDates && (paymentFilterDates.fromDate && paymentFilterDates.toDate) || (paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate)) {
      setIsDateFilterApplied(true);
    }
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>, key: string, index: number) => {
    const selectedFilters = _.find(from === 'FINOPS' ? finopsTransactionsStatusFilters : transactionsStatusFilters, (o) => o.parentKey === key).filters;
    const newFilters = selectedFilters.map((item: any) => {
      if (item.key === event.target.value) {
        item.isChecked = event.target.checked;

        if (item.key === 'DEPOSIT') {
          if (item.isChecked) {
            setFilterType(fs => fs.concat('DEPOSIT'));
          } else {
            setFilterType(fs => _.filter(fs, (f) => f === 'WITHDRAW'));
          }
        } else if (item.key === 'WITHDRAW') {
          if (item.isChecked) {
            setFilterType(fs => fs.concat('WITHDRAW'));
          } else {
            setFilterType(fs => _.filter(fs, (f) => f === 'DEPOSIT'));
          }
        }
      }

      return item;
    });

    if (from === 'FINOPS') {
      setFinopsTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
        if (c.parentKey === key) {
          c.filters = newFilters;
        }
        return c;
      }));
    } else {
      setTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
        if (c.parentKey === key) {
          c.filters = newFilters;
        }
        return c;
      }));
    }
  };

  const onWithdrawalUpdate = (record: any) => {
    const onlyChecked = _.compact(_.map(data, (cl: any) => { 
      if (cl.isChecked) {
        return cl.txnNumber;
      }
    }));
    setTransactionStatus(record.status);
    setTransactionRecord(record);
    if (record.status === 'QUEUED') {
      setActiveTransactionStatus('SUCCESSFUL');
    }
    
    if (record.isChecked) {
      setEnableUpdatedConfirmButton(false);
      if (record.batchNo) {
        getTransactionsCountByBatchId.request(record.batchNo).then((res) => {
          if (res.status === 200) {
            setBatchIds(record.batchNo);
            setBatchLength(res.data.totalCount);
            if (!onlyChecked.length) {
              setSelectedRecordsLength(1);
            } else {
              if (reportBatchIds.length === 1) {
                if (isSelectAllCheckboxClicked && !uncheckedTxnIds.length) {
                  const selectedCount = record.status === 'WITHDRAWAL_REQUESTED' ? res.data.dwCleared : res.data.queued;
                  setSelectedRecordsLength(selectedCount);
                } else if (uncheckedTxnIds.length) {
                  const selectedCount = record.status === 'WITHDRAWAL_REQUESTED' ? res.data.dwCleared : res.data.queued;
                  setSelectedRecordsLength(selectedCount - uncheckedTxnIds.length);
                } else {
                  setSelectedRecordsLength(checkedTxnIds.length);
                }
              } else {
                setSelectedRecordsLength(checkedTxnIds.length);
              }
            }
            onTransactionStatusScreenOpen();
          }
        });
        setIncludeTxnNumbers((itns) => onlyChecked);
      } else {
        setEnableUpdatedConfirmButton(true);
        setBatchIds('');
        onTransactionStatusScreenOpen();
      }
    } else {
      const ids = _.uniq(_.compact(_.map(data, (d) => { 
        if (onlyChecked.includes(d.txnNumber)) {
          return d.batchNo;
        }
      })));
      const bl = (_.compact(_.map(data, (d) => {
        if(ids.includes(d.batchNo)) {
          return d;
        }
      }))).length;
      setSelectedRecordsLength(onlyChecked.length);
      setBatchLength(bl);
      setBatchIds(ids.join(', '));
      // PN-7746: changed boolean value from true to false
      setEnableUpdatedConfirmButton(false);
    }
    onTransactionStatusScreenOpen();
  };

  const onFilterButton = (e: MouseEvent<HTMLDivElement>) => {
    const filter = (e.target as HTMLDivElement).dataset.filter;
    setActiveTransactionStatus(filter);
  };

  const onStatusUpdateConfirm = () => {
    let params: any = {
      reportBatchId: reportBatchIds.length === 1 ? reportBatchIds[0].value : batchIds,
      status: activeTransactionStatus,
      currentStatus: transactionStatus
    }

    if (reportBatchIds.length > 1 && includeTxnNumbers.length) {
      params.includetxnNumbers = includeTxnNumbers;
    } if (reportBatchIds.length === 1 && (uncheckedTxnIds.length >= includeTxnNumbers.length)) {
      params.includetxnNumbers = includeTxnNumbers;
    } else if (uncheckedTxnIds.length) {
      const excludeTxnNumbers = _.map(uncheckedTxnIds, (u) => u.txnNumber);
      params.excludetxnNumbers = excludeTxnNumbers;
    } else if (checkedTxnIds.length) {
      const includeTxnNumbersArray = _.map(checkedTxnIds, (u) => u.txnNumber);
      params.includetxnNumbers = includeTxnNumbersArray;
    }

    // PN-7746: Added this funtionality to make single transaction status update
    if (!params.reportBatchId) {
      delete params.reportBatchId;
      params.type = 'WITHDRAW';

      updateSingleTransactionStatus.request(transactionRecord.txnNumber, params).then((res) => {
        if (res.status === 200) {
          setIsUpdatedStatus(true);
          setOpenTransactionStatusScreen(false);
          setUncheckedTxnIds([]);
          setCheckedTxnIds([]);
          setIncludeTxnNumbers([]);
          setSelectedAllChecks(false);
          setIndeterminateCheck(false);
          setTransactionRecord(null);
        }
      });
    } else {
      updateTransactionsStatus.request(params).then((res) => {
        if (res.status === 200) {
          setIsUpdatedStatus(true);
          setOpenTransactionStatusScreen(false);
          setUncheckedTxnIds([]);
          setCheckedTxnIds([]);
          setIncludeTxnNumbers([]);
          setSelectedAllChecks(false);
          setIndeterminateCheck(false);
        }
      });
    }
  };

  const onTransactionCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    let status = '';
    let batchNo = '';
    let checkboxListItems = _.map(data, (item: any) => {
      if (item.txnNumber === event.target.id) {
        if (!item.isChecked) {
          item.isChecked = true;
          if (reportBatchIds.length === 1) {
            if (isSelectAllCheckboxClicked) {
              setUncheckedTxnIds((utis: any[]) =>_.filter(utis, (u) => u.txnNumber !== event.target.id));
            } else {
              setCheckedTxnIds((ctis: any[]) => ctis.concat({'txnNumber': item.txnNumber, 'batchNo': item.batchNo}));
            }
          } else if (reportBatchIds.length > 1) {
            setCheckedTxnIds((ctis: any[]) => ctis.concat({'txnNumber': item.txnNumber, 'batchNo': item.batchNo}));
          } else {
            setCheckedTxnIds((ctis: any[]) => ctis.concat({'txnNumber': item.txnNumber, 'batchNo': item.batchNo}));
          }
        } else {
          item.isChecked = false;
          if (reportBatchIds.length === 1) {
            if (isSelectAllCheckboxClicked) {
              setUncheckedTxnIds((utis: any[]) => utis.concat({'txnNumber': item.txnNumber, 'batchNo': item.batchNo}));
            } else {
              setCheckedTxnIds((ctis: any[]) =>_.filter(ctis, (c) => c.txnNumber !== event.target.id));
            }
          } else if (reportBatchIds.length > 1) {
            setCheckedTxnIds((ctis: any[]) =>_.filter(ctis, (c) => c.txnNumber !== event.target.id));
          } else {
            setCheckedTxnIds((ctis: any[]) =>_.filter(ctis, (c) => c.txnNumber !== event.target.id));
          }
        }
        status = item.status;
        batchNo = item.batchNo;
        return item;
      }

      return item;
    });

    setFifoReportBatchId(batchNo);
    setCheckedTransactionStatus(status);

    const checkedLikedItems = (_.filter(data, (d) => {
      if (d.isChecked && status) {
        return d;
      }
    }));

    if (checkedLikedItems.length && status === 'QUEUED') {
      checkboxListItems = _.map(checkboxListItems, (l: any) => {
        if (l.status === 'WITHDRAWAL_REQUESTED') {
          l.isDisabled = true;
        }
        if (l.batchNo !== batchNo) {
          l.isDisabled = true;
        }
        return l;
      });
    } else if (checkedLikedItems.length && status === 'WITHDRAWAL_REQUESTED') {
      checkboxListItems = _.map(checkboxListItems, (l: any) => {
        if (l.status === 'QUEUED') {
          l.isDisabled = true;
        }
        if (l.batchNo !== batchNo) {
          l.isDisabled = true;
        }
        return l;
      });
    } else {
      checkboxListItems = _.map(checkboxListItems, (l: any) => {
        if (updateInprogressTxns.length && updateInprogressTxns.includes(l.batchNo)) {
          l.isDisabled = true;
        } else {
          l.isDisabled = false;
        }
        return l;
      });
    }

    const onlyChecked = _.filter(data, (cl: any) => cl.isChecked);
    if (onlyChecked.length && (data.length > onlyChecked.length)) {
      setIndeterminateCheck(true);
      setSelectedAllChecks(false);
    } else if (onlyChecked.length && (data.length === onlyChecked.length)) {
      setIndeterminateCheck(false);
      setSelectedAllChecks(true);
    } else {
      setIndeterminateCheck(false);
      setSelectedAllChecks(false);
    }
    setData(checkboxListItems);
  };

  const onTransactionsSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    let queuedStatus = [];
    let dwClearedStatus = [];
    if (event.target.checked) {
      let firstItem: any = null;
      setData(_.map(data, (item: any) => {
        item.isChecked = true;
        firstItem = data[0];
        if (item.status === 'QUEUED') {
          queuedStatus.push(item.status);
        }
        if (item.status === 'WITHDRAWAL_REQUESTED') {
          dwClearedStatus.push(item.status);
        }
        return item;
      }));
      setSelectedAllChecks(true);
      setIsSelectAllCheckboxClicked(true);
      if (queuedStatus.length > dwClearedStatus.length) {
        setMostAppearedTransactionStatus('QUEUED');
      } else if (queuedStatus.length < dwClearedStatus.length) {
        setMostAppearedTransactionStatus('WITHDRAWAL_REQUESTED');
      } else {
        setMostAppearedTransactionStatus(firstItem?.status);
      }
    } else {
      setData(_.map(data, (item: any) => {
        item.isChecked = false;
        return item;
      }));
      setUncheckedTxnIds([]);
      setCheckedTxnIds([]);
      setSelectedAllChecks(false);
      setIsSelectAllCheckboxClicked(false);
    }
    setIndeterminateCheck(false);
  };

  const onReportBatchScroll = (event: any) => {
    const element = event.target;

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setReportBatchMenuPage(reportBatchMenuPage + 1);
    }
  };

  type DownloadParams = {
    q?: string,
    fromDate?: string,
    toDate?: string,
    statusUpdatedFromDate? : string,
    statusUpdatedToDate? : string,
    filterQuery?: string
  }

  const getTransactionsDownloadCall = (params: DownloadParams) => {
    const { q, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery } = params;

    const data = params;
    delete data.filterQuery;
    if (filterQuery) {
      const filters = _.omitBy(getQueryMap(filterQuery), _.isNil);
      setTransactionsDownloadParams({...data, ...filters});
    } else {
      setTransactionsDownloadParams(data);
    }
    
    getTransactionsDownload.request(selectedButtonType, q, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery).then((res) => {
      if (res.status === 200) {
        setTransactionsDownloadData(res.data);
      }
    });
  };

  const handleResponse = (res: any) => {
    if (res.status === 200) {
      const { totalcount } = res.headers;
      setTotalCount(parseInt(totalcount));
      setIsEnableCheckbox(_.findIndex(res.data, (s: any) => (s.type === 'WITHDRAW') && s.batchNo && s.status !== 'CLEARED' && (s.status === 'WITHDRAWAL_REQUESTED' || s.status === 'QUEUED')) !== -1);
      setEnableSelectAllCheckbox(_.uniq(_.compact((_.map(res.data, (s: any) => { 
        if ((s.type === 'WITHDRAW') && (s.status === 'WITHDRAWAL_REQUESTED' || s.status === 'QUEUED')) {
          return s.status;
        }
      })))).length === 1 && (reportBatchIds.length === 1));

      const checkboxListItems = _.filter(res.data, (item: any) => {
        if (item.status === 'WITHDRAWAL_REQUESTED' || item.status === 'QUEUED') {
          return item;
        }
      });

      setCheckboxList(checkboxListItems);
      let data = _.map(res.data, (d) => {
        if ((selectedAllChecks || indeterminateCheck) && uncheckedTxnIds.length && !_.some(uncheckedTxnIds, {'txnNumber': d.txnNumber})) {
          if (d.batchNo !== fifoReportBatchId) {
            d.isDisabled = true;
          } else if (d.status === mostAppearedTransactionStatus) {
            d.isChecked = true;
          }
        } else if ((indeterminateCheck) && !enableSelectAllCheckbox && uncheckedTxnIds.length && !_.some(uncheckedTxnIds, (u) => d.batchNo === fifoReportBatchId)) {
          d.isDisabled = true;
        } else if (selectedAllChecks && !indeterminateCheck && enableSelectAllCheckbox) {
          if (isSelectAllCheckboxClicked && (d.status === mostAppearedTransactionStatus)) {
            d.isChecked = true;
          } else {
            d.isDisabled = true;
          }
        } else if (selectedAllChecks && !indeterminateCheck && !enableSelectAllCheckbox) {
          if (isSelectAllCheckboxClicked && (d.status === mostAppearedTransactionStatus)) {
            d.isChecked = true;
          } else {
            d.isDisabled = true;
          }
        } else if (checkedTxnIds.length && !_.some(checkedTxnIds, (u) => d.batchNo === fifoReportBatchId)) {
          d.isDisabled = true;
        } else if (checkedTxnIds.length) {
          if (_.some(checkedTxnIds, (u) => d.txnNumber === u.txnNumber)) {
            d.isChecked = true;
          }
          if (d.status !== checkedTransactionStatus && _.some(checkedTxnIds, (u) => d.txnNumber !== u.txnNumber)) {
            d.isDisabled = true;
          }
        }
        return d;
      });

      getUpdateInprogressTxns.request().then((res) => {
        if (res.status === 200) {
          if (res.data.length) {
            setUpdateInprogressTxns(res.data);
            data = _.map(data, (d) => {
              if (res.data.includes(d.batchNo)) {
                d.isDisabled = true;
              }
              return d;
            });
          }
        }
        setData(data);
      }).catch((e) => {
        console.error(e);
        setData(data);
      });
      setIsDateFilterApplied(false);

      if (isFilterApplied) {
        enqueueSnackbar('Filters have been applied successfully.', {
          variant: 'successWithUndo',
          onUndo: onClearFilters
        });
        setIsFilterApplied(false);
      }
    }
    if (isUpdatePage) setIsUpdatePage(false);
    if (isUpdatedStatus) setIsUpdatedStatus(false);
    if (isFilterCleared) setIsFilterCleared(false);
    if (isFiltersCleared) setIsFiltersCleared(false);
    if (isChangeReportBatchIds) setIsChangeReportBatchIds(false);
  };

  const handleResponseOnSearch = (res: any) => {
    if (res.status === 200) {
      const { totalcount } = res.headers;
      setTotalCount(parseInt(totalcount));
      setData(res.data);
      setIsDateFilterApplied(false);

      if (isFilterApplied) {
        enqueueSnackbar('Filters have been applied successfully.', {
          variant: 'successWithUndo',
          onUndo: onClearFilters
        });
        setIsFilterApplied(false);
      }
    }
    if (isUpdatePage) setIsUpdatePage(false);
    if (isUpdatedStatus) setIsUpdatedStatus(false);
    if (isFilterCleared) setIsFilterCleared(false);
    if (isFiltersCleared) setIsFiltersCleared(false);
    if (isChangeReportBatchIds) setIsChangeReportBatchIds(false);
  };

  const onLinkClick = (data: any) => {
    setClientId(data.clientId);
    setPaymentFilterDates({ fromDate: '', toDate: '', statusUpdatedFromDate: '',
    statusUpdatedToDate: '' });
    setIsFiltersCleared(true);
    setTransactionFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);

    if (from === 'FINOPS') {
      setFinopsTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
        c.filters = _.map(c.filters, (f: any) => {
          if (f.isChecked) {
            f.isChecked = false;
          }
          return f;
        });
        return c;
      }));
    } else {
      setTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
        c.filters = _.map(c.filters, (f: any) => {
          if (f.isChecked) {
            f.isChecked = false;
          }
          return f;
        });
        return c;
      }));
    }
    setIsFilterCleared(true);
    setReportBatchIds([]);
    setFilterType([]);
    setUncheckedTxnIds([]);
    setCheckedTxnIds([]);
    setIsSelectAllCheckboxClicked(false);
    setCheckedTransactionStatus('');
    setEnableSelectAllCheckbox(false);
    setSearchQuery('');
    setIsSearchClosed(false);
    setEnableSearchField(false);
    navigate('/profile', { state: {...data, from: 'TRANSACTIONS'} });
  };

  const checkTransactionPermissions = () => {
    const resourceName = from === 'TRANSACTIONS' ? 'PAYMENTS' : (from === 'FINOPS' ? 'DASHBOARD_FIN_OPS' : '');

    return checkPermissions(userPermissions, resourceName);
  };
  
  const convertUtc = (value: string) => {
    return moment(value, 'YYYY-MM-DD h:m:s A').utc(false).format('YYYY-MM-DD HH:mm:ss');
  };

  const onNameCheckStatusScreenClose = (event: {},
  reason: 'backdropClick') => {
    setActiveReviewStatus('APPROVED');
    setOpenNameCheckStatusScreen(false);
  };

  const onReviewStatusButton = (e: MouseEvent<HTMLDivElement>) => {
    const filter = (e.target as HTMLDivElement).dataset.filter;
    setActiveReviewStatus(filter);
  };

  const onReviewStatusUpdateConfirm = () => {
    const params = {
      bankAccountStatus: activeReviewStatus
    };

    updateManualReviewStatus.request(customerBankAccountID, params).then((res) => {
      if (res.status === 200) {
        setIsUpdatedStatus(true);
        setActiveReviewStatus('APPROVED');
        setOpenNameCheckStatusScreen(false);
        setCustomerBankAccountID(null);
        setBankAccountClientName('');
        setClientName('');
      }
    });
  };

  const onNameCheckReviewUpdate = (record: any) => {
    setCustomerBankAccountID(record.customerBankAccountID);
    setBankAccountClientName(record.bankAccountClientName);
    setClientName(record.clientName);
    setOpenNameCheckStatusScreen(true);
  };

  const getTableHeaders = () => {
    if (selectedButtonType === 'WITHDRAW') {
      return TransactionsWithdrawalHeaders;
    } else {
      return TransactionsHeaders;
    }
  };

  useEffect(() => {
    const newFilters = _.map(from === "FINOPS" ? finopsTransactionsStatusFilters : transactionsStatusFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(true);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else if (paymentFilterDates && (paymentFilterDates.fromDate && paymentFilterDates.toDate) || (paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate)) {
      setEnableButtons(false);
    } else if (isChangeReportBatchIds === true) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [transactionsStatusFilters, finopsTransactionsStatusFilters, isFiltersCleared, paymentFilterDates, isChangeReportBatchIds]);

  useEffect(() => {
    const fromDate = paymentFilterDates.fromDate && paymentFilterDates['fromDateTime'] ? convertUtc(`${paymentFilterDates.fromDate} ${paymentFilterDates['fromDateTime']}`) : (paymentFilterDates.fromDate && !paymentFilterDates['fromDateTime'] ? `${paymentFilterDates.fromDate}` : '');

    const toDate = paymentFilterDates.toDate && paymentFilterDates['toDateTime'] ? convertUtc(`${paymentFilterDates.toDate} ${paymentFilterDates['toDateTime']}`) : (paymentFilterDates.toDate && !paymentFilterDates['toDateTime'] ? `${paymentFilterDates.toDate}` : '');

    const statusUpdatedFromDate = paymentFilterDates['statusUpdatedFromDate'] && paymentFilterDates['statusUpdatedFromDateTime'] ? convertUtc(`${paymentFilterDates['statusUpdatedFromDate']} ${paymentFilterDates['statusUpdatedFromDateTime']}`) : (paymentFilterDates['statusUpdatedFromDate'] && !paymentFilterDates['statusUpdatedFromDateTime'] ? `${paymentFilterDates['statusUpdatedFromDate']}` : '');

    const statusUpdatedToDate = paymentFilterDates['statusUpdatedToDate'] && paymentFilterDates['statusUpdatedToDateTime'] ? convertUtc(`${paymentFilterDates['statusUpdatedToDate']} ${paymentFilterDates['statusUpdatedToDateTime']}`) : (paymentFilterDates['statusUpdatedToDate'] && !paymentFilterDates['statusUpdatedToDateTime'] ? `${paymentFilterDates['statusUpdatedToDate']}` : '');

    if (from === 'FINOPS') {
      if (!searchQuery && selectedButtonType) {
        setPage(isUpdatePage ? 0 : page);

        if (notificationFilterQuery) {
          getFinOpsTransactions.request(selectedButtonType, page + 1, recordCount || rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, notificationFilterQuery).then((res) => {
            handleResponse(res);
          });
        } else {
          getFinOpsTransactions.request(selectedButtonType, page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, transactionFilterQuery).then((res) => {
            handleResponse(res);
          });
        }
      } else if (searchQuery !== '' && !isSearchClosed) {
        setPage(isUpdatePage ? 0 : page);
        if (notificationFilterQuery) {
          getFinOpsTransactions.request(selectedButtonType, page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, notificationFilterQuery, searchQuery).then((res) => {
            handleResponseOnSearch(res);
          });
        } else {
          getFinOpsTransactions.request(selectedButtonType, page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, transactionFilterQuery, searchQuery).then((res) => {
            handleResponseOnSearch(res);
          });
        }
      } else if (searchQuery !== '' && highlightNotificationRecords) {
        setPage(isUpdatePage ? 0 : page);
        if (notificationFilterQuery) {
          getFinOpsTransactions.request(selectedButtonType, page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, notificationFilterQuery, searchQuery).then((res) => {
            handleResponseOnSearch(res);
          });
        } else {
          getFinOpsTransactions.request(selectedButtonType, page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, transactionFilterQuery, searchQuery).then((res) => {
            handleResponseOnSearch(res);
          });
        }
      }
    } else if (from === 'TRANSFERS') {
      getTransactionsByClientId.request(clientId, selectedButtonType, page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);
        }
      });
    } else if (from === 'TRANSACTIONS') {
      if (!searchQuery && selectedButtonType) {
        setPage(isUpdatePage ? 0 : page);

        getTransactions.request(selectedButtonType, page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, transactionFilterQuery).then((res) => {
          handleResponse(res);
        });
        getTransactionsDownloadCall({ q: searchQuery, fromDate: fromDate, toDate: toDate, statusUpdatedFromDate: statusUpdatedFromDate, statusUpdatedToDate: statusUpdatedToDate, filterQuery: transactionFilterQuery });
      } else if (searchQuery !== '' && !isSearchClosed && selectedButtonType) {
        setPage(isUpdatePage ? 0 : page);
        getTransactions.request(selectedButtonType, page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, transactionFilterQuery, searchQuery).then((res) => {
          handleResponseOnSearch(res);
        });
        getTransactionsDownloadCall({ q: searchQuery, fromDate: fromDate, toDate: toDate, statusUpdatedFromDate: statusUpdatedFromDate, statusUpdatedToDate: statusUpdatedToDate, filterQuery: transactionFilterQuery });
      }
    }
  }, [page, rowsPerPage, order, orderBy, searchQuery, isSearchClosed, isFilterCleared, transactionFilterQuery, isFiltersCleared, isUpdatePage, isDateFilterApplied, isUpdatedStatus, notificationFilterQuery, selectedButtonType]);

  useEffect(() => {
    if (transactionStatus === 'WITHDRAWAL_REQUESTED') {
      setActiveTransactionStatus('QUEUED');
    }
  }, [transactionStatus]);

  useEffect(() => {
    getWithdrawalReports.request(reportBatchMenuPage + 1, reportBatchMenuLimit).then((res) => {
      if (res.status === 200 && res.data.length > 0) {
        setReportBatchMenu((rps: any) => {
          let o: string[] = [];
          _.map(res.data, (item, i) => {
            if (!o.includes(item.batchNo)) {
              o.push(item.batchNo);
            }
          });

          rps = rps.concat(_.map(o, (item, i) => {
            return {value: item, displayName: item};
          }));

          const rpsByUniq = _.uniqBy(rps, 'value');
          const rpsOrderBy = _.orderBy(rpsByUniq, ['displayName'], ['desc']);

          return rpsOrderBy;
        });
      }
    });
  }, [reportBatchMenuPage]);

  return (
    <>
      <CssTable
        headers={getTableHeaders()}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: {md: 3, xs: 0} }}
        onFilters={handleFilters}
        from={from}
        {...(from !== 'TRANSFERS' && checkTransactionPermissions() && {hasCheckbox: isEnableCheckbox, onStatusAction: onWithdrawalUpdate, onReviewStatusAction: onNameCheckReviewUpdate, onRowCheckbox: onTransactionCheckbox, onSelectAll: onTransactionsSelectAll, rowClassName: 'no-row-click'})}
        {...(from !== 'TRANSFERS' && {isDesktopFilters: true, highlightText: searchQuery, onLinkClick: onLinkClick})}
      />
      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          {from === 'FINOPS' && finopsTransactionsStatusFilters && finopsTransactionsStatusFilters.map((item: any, index: number) => (
            <>
              {(['paymentProviderName', 'paymentMethod'].includes(item.parentKey) && selectedButtonType === 'WITHDRAW') || (['status'].includes(item.parentKey) && selectedButtonType === 'DEPOSIT') ? <></> : <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
                <FormGroup>
                  {item.filters.map((filter: any, i: number) => {
                    if (filterType.length === 1 && filterType.includes('WITHDRAW') && FinopsTransactionsWithdrawalHeaders.finOpsWithdrawFilters.includes(filter.key)) {
                      return (
                        <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                      )
                    } else if ((filterType.length > 1 || filterType.length === 0) && TransactionsHeaders.finOpsFilters.includes(filter.key)) {
                      return (
                        <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                      )
                    } else if (item.parentKey === 'paymentProviderName') {
                        return (
                        <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                      )
                    } else if (item.parentKey === 'paymentMethod') {
                        return (
                        <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                      )
                    }
                    
                    return null;
                  })}
                </FormGroup>
              </CssAccordion>}
            </>
          ))}

          {from === 'TRANSACTIONS' && transactionsStatusFilters && transactionsStatusFilters.map((item: any, index: number) => (
            <>
              {(['paymentProviderName', 'paymentMethod'].includes(item.parentKey) && selectedButtonType === 'WITHDRAW') ? <></> : <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
                <FormGroup>
                  {item.filters.map((filter: any, i: number) => {
                    if (selectedButtonType === 'DEPOSIT' && TransactionsHeaders.depositFilters.includes(filter.key)) {
                      return (
                        <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                      )
                    } else if (selectedButtonType === 'WITHDRAW' &&TransactionsWithdrawalHeaders.withdrawFilters.includes(filter.key)) {
                      return (
                        <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                      )
                    } else if (selectedButtonType === 'DEPOSIT' && item.parentKey === 'paymentProviderName') {
                        return (
                        <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                      )
                    } else if (selectedButtonType === 'DEPOSIT' && item.parentKey === 'paymentMethod') {
                        return (
                        <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                      )
                    }

                    return null;
                  })}
                </FormGroup>
              </CssAccordion>}
            </>
          ))}

          {from === 'TRANSACTIONS' && <>
            {selectedButtonType === 'WITHDRAW' && <CssAccordion title='Report batch' isExpand={reportBatchIds.length > 0}>
              <CssAutocomplete label='Select report batch' placeholder='Select report batch' options={reportBatchMenu} onScroll={onReportBatchScroll} />
            </CssAccordion>}
            <CssAccordion title='Transaction date' isExpand={paymentFilterDates && paymentFilterDates.fromDate && paymentFilterDates.toDate}>
              <DateTimeFilters fromLabel={'From'} toLabel={'To'} />
            </CssAccordion>
            <CssAccordion title='Status updated' isExpand={paymentFilterDates && paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate}>
              <DateTimeFilters fromLabel={'From'} toLabel={'To'} id='statusUpdated' />
            </CssAccordion>
          </>}

          {from === 'FINOPS' && <>
            {selectedButtonType === 'WITHDRAW' && <CssAccordion title='Report batch' isExpand={reportBatchIds.length > 0}>
              <CssAutocomplete label='Select report batch' placeholder='Select report batch' options={reportBatchMenu} isDisabled={!(filterType.length === 1 && filterType.includes('WITHDRAW'))} onScroll={onReportBatchScroll} />
            </CssAccordion>}
            <CssAccordion title='Transaction date' isExpand={paymentFilterDates && paymentFilterDates.fromDate && paymentFilterDates.toDate}>
              <DateTimeFilters fromLabel={'From'} toLabel={'To'} />
            </CssAccordion>
            <CssAccordion title='Status updated' isExpand={paymentFilterDates && paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate}>
              <DateTimeFilters fromLabel={'From'} toLabel={'To'} id='statusUpdated' />
            </CssAccordion>
          </>}

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>

      <ScreenDialog
        title={'Transaction status'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openTransactionStatusScreen}
        onScreenClose={onTransactionStatusScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Transaction status</Typography>
        </StyledBox>

        <Box sx={{pl: 4, pr: 4}}>
          {transactionStatus === 'WITHDRAWAL_REQUESTED' && <>
            <FilterButton className={activeTransactionStatus === 'CANCELLED' ? 'active btn-filter btn-flat custom btn-transaction-status' : 'btn-filter btn-flat custom btn-transaction-status'} sx={{pt: 1, pb: 1}} filter='CANCELLED' onClick={onFilterButton}>Cancel <CheckTick /></FilterButton>
            <FilterButton className={activeTransactionStatus === 'QUEUED' ? 'active btn-filter btn-flat custom btn-transaction-status' : 'btn-filter btn-flat custom btn-transaction-status'} filter='QUEUED' onClick={onFilterButton}>Queued <CheckTick /></FilterButton>
          </>}
          {transactionStatus === 'QUEUED' && <>
            <FilterButton className={activeTransactionStatus === 'WITHDRAWAL_REQUESTED' ? 'active btn-filter btn-flat custom btn-transaction-status' : 'btn-filter btn-flat custom btn-transaction-status'} sx={{pt: 1, pb: 1}} filter='WITHDRAWAL_REQUESTED' onClick={onFilterButton}>Requested <CheckTick /></FilterButton>
            <FilterButton className={activeTransactionStatus === 'SUCCESSFUL' ? 'active btn-filter btn-flat custom btn-transaction-status' : 'btn-filter btn-flat custom btn-transaction-status'} filter='SUCCESSFUL' onClick={onFilterButton}>Credited <CheckTick /></FilterButton>
          </>}

          {batchIds && <Typography variant='body2' sx={{mt: 2.5}}><b>{selectedRecordsLength} of {batchLength}</b> from batch <b>{batchIds}</b> are selected.</Typography>}

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5, justifyContent: 'space-between', display: 'flex' }}>
              <CssButton variant='outlined' sx={{width: '35%', mr: 1, ml: 0}} onClick={onTransactionStatusScreenClose}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '35%', mr: 0, ml: 1}} onClick={onStatusUpdateConfirm} className='active' disabled={enableUpdatedConfirmButton}>Confirm</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>

      <CssDialog
        disableEscapeKeyDown={true}
        open={openNameCheckStatusScreen}
        onScreenClose={onNameCheckStatusScreenClose}
      >
        <Box sx={{mt: 4.375, ml: 5.625, mr: 5.625, mb: 1.5}}>
          <Typography variant='h6'>Name check</Typography>
        </Box>

        <Box sx={{pl: 5.625, pr: 5.625, pb: 4}}>
          <ViewField label={'Transaction name'} value={bankAccountClientName} labelSx={{color: '#5B5959'}} valueSx={{ borderBottom: 0, color: '#949494', fontSize: '1.875rem', lineHeight: '36px', mb: 1.5 }} />
          <ViewField label={'Profile name'} value={clientName} labelSx={{color: '#5B5959'}} valueSx={{ borderBottom: 0, color: '#949494', fontSize: '1.875rem', lineHeight: '36px' }} />
        </Box>

        <Box sx={{pl: 5.625, pr: 5.625, pb: 5.25}}>
          <FilterButton className={activeReviewStatus === 'APPROVED' ? 'active btn-filter btn-flat custom btn-review-status' : 'btn-filter btn-flat custom btn-review-status'} sx={{pt: 1, pb: 1}} filter='APPROVED' onClick={onReviewStatusButton}>Approved <CheckTick /></FilterButton>
          <FilterButton className={activeReviewStatus === 'REJECTED' ? 'active btn-filter btn-flat custom btn-review-status' : 'btn-filter btn-flat custom btn-review-status'} sx={{pt: 1, pb: 1}} filter='REJECTED' onClick={onReviewStatusButton}>Rejected <CheckTick /></FilterButton>
          
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 4, justifyContent: 'space-between', display: 'flex' }}>
              <CssButton variant='outlined' sx={{width: '35%', mr: 1, ml: 0}} onClick={onNameCheckStatusScreenClose}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '35%', mr: 0, ml: 1}} onClick={onReviewStatusUpdateConfirm} className='active'>Confirm</CssButton>
            </Grid>
          </Grid>
        </Box>
      </CssDialog>
    </>
  );
};

export default Transactions;