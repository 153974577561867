/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Box } from '@mui/material';
import CssContainer from 'components/CssContainer';
import ViewField from 'components/ViewField';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { GlobalContext } from 'context';
import { processClientAccountDetailsData, processCashBalanceTitle, processSignValue } from 'utils';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default
}));

const StyledAccountDataGrid = styled(Grid)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: '20px 55px',
  '&:last-child': {
    borderRight: 0
  }
}));

const StyledBalanceTypography = styled(Typography)(({ theme}) => ({
  fontSize: '1.875rem',
  lineHeight: '2',
  '& span': {
    fontSize: '1.375rem'
  }
}));

const Accounts = () => {
  const { clientAccountDetails } = useContext(GlobalContext);
  const { getClientAccountDetailsV2Api, getClientCashBalanceApi } = useRequests();
  const getClientAccountDetailsV2 = useApi(getClientAccountDetailsV2Api);
  const getClientCashBalance = useApi(getClientCashBalanceApi);
  const [clientId] = useStorage<number | null>('clientId', null);
  const [accountDetailsData, setAccountDetailsData] = useState<any>([]);
  const [cashBalanceData, setCashBalanceData] = useState<any>([]);

  useEffect(() => {
    getClientAccountDetailsV2.request(clientId).then((res) => {
      if (res.status === 200) {
        setAccountDetailsData(processClientAccountDetailsData(res.data, clientAccountDetails));
      }
    });
    getClientCashBalance.request(clientId).then((res) => {
      if (res.status === 200) {
        setCashBalanceData(res.data);
      }
    });
  }, []);


  return (
    <CssContainer sx={{p: '0 !Important', mt: 0}}>
      <Grid container sx={{pb: 10}} justifyContent={'center'}>
        {cashBalanceData && cashBalanceData.length > 0 && cashBalanceData.map((item: any, index: number) => (
          <StyledAccountDataGrid item xs={3} key={index}>
            <Typography variant='body2' sx={{lineHeight: 2, color: '#000'}}>{processCashBalanceTitle(item.title)}</Typography>
            <StyledBalanceTypography>
              <span>$</span> {`${item.balance}`}
            </StyledBalanceTypography>
          </StyledAccountDataGrid>
        ))}
      </Grid>
      <StyledBox>
        <Grid container spacing={3} sx={{pb: 10}}>
          {accountDetailsData && accountDetailsData.length > 0 && accountDetailsData.map((item: any, index: number) => (
            <Fragment key={index}>
              {item && item.label && 
                <Grid item xs={4} sx={{pt: '0 !important', mb: 3.625, ...(item.wrapElement && {maxWidth: '100% !important', flexBasis: '100% !important'})}} key={index}>
                  <ViewField key={index} label={item.label} value={item.value} verified={item.verified?.value} {...(item.showEllipses && {ellipses: true})} {...(item.isSign && {signType: processSignValue(item.value)})} {...(item.wrapElement && {boxSx: {maxWidth: '48.5%'}})} {...(item.conversion && {conversion: item.conversion})} {...(item.valueSx && {valueSx: item.valueSx})} {...(item.isCountry && {isCountry: item.isCountry})} {...(item.pickLable && {pickLable: true})} name={item.name} {...(item.isChip && {isChip: item.isChip, processStatus: item.processStatus})} />
                </Grid>
              }

              <>
                {item && item.section && 
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant='h6' sx={{mb: 2.5}}>{item.title}</Typography>
                    </Grid>
                    {item.fields && item.fields.map((f: any, i: number) => (
                      <Fragment key={i}>
                        {f && f.label && 
                          <Grid item xs={4} sx={{pt: '0 !important', mb: 3}}>
                            <ViewField key={i} label={item[f.label] ? item[f.label] : f.label} value={f.value} {...(item.showEllipses && {ellipses: true})} {...(f.isCountry && {isCountry: f.isCountry})} name={f.name} />
                          </Grid>
                        }
                      </Fragment>
                    ))}
                  </Grid>
                }
              </>
            </Fragment>
          ))}
        </Grid>
      </StyledBox>
    </CssContainer>
  );
};

export default Accounts;