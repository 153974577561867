
export const InstrumentStatusFilters = [
  {
    parentKey: 'instrumentSubType',
    title: '2nd instrument type',
    filters: [
      {
        key: 'EQUITY',
        label: 'Equity',
        isChecked: false
      },
      {
        key: 'ETF',
        label: 'ETF',
        isChecked: false
      },
      {
        key: 'ADR',
        label: 'ADR',
        isChecked: false
      }
    ]
  },
  {
    parentKey: 'tradingVenueStatus',
    title: 'Market status',
    filters: [
      {
        key: 'ACTIVE',
        label: 'Active',
        isChecked: false
      },
      {
        key: 'CLOSE_ONLY',
        label: 'Close only',
        isChecked: false
      },
      {
        key: 'HALTED',
        label: 'Halted',
        isChecked: false
      }
    ]
  },
  {
    parentKey: 'internalStatus',
    title: 'Internal status',
    filters: [
      {
        key: 'ACTIVE',
        label: 'Visible',
        isChecked: false
      },
      {
        key: 'INACTIVE',
        label: 'Not visible',
        isChecked: false
      }
    ]
  }
];