import Icon from './Icon';

const CaretBackActive = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="15" r="13" fill="#04F8DA"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.7879 20.6629C18.4218 21.029 17.8282 21.029 17.4621 20.6629L12.4621 15.6629C12.096 15.2968 12.096 14.7032 12.4621 14.3371L17.4621 9.33709C17.8282 8.97097 18.4218 8.97097 18.7879 9.33709C19.154 9.7032 19.154 10.2968 18.7879 10.6629L14.4508 15L18.7879 19.3371C19.154 19.7032 19.154 20.2968 18.7879 20.6629Z" fill="white"/>
      </svg>
    </Icon>
  );
};

export default CaretBackActive;