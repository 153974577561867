/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, ChangeEvent, MouseEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid, FormGroup, FormControlLabel, TextField } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssAccordion from 'components/CssAccordion';
import CssCheckbox from 'components/CssCheckbox';
import CssButton from 'components/CssButton';
import Filters from 'components/Filters';
import ButtonGroup from 'components/ButtonGroup';
import { OrdersHeaders } from 'constants/orders-table-head-columns';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { GlobalContext } from 'context';
import { setAccordionExpandByFilters } from 'utils';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 5,
}));

const StyledTextField = styled (TextField)(({ theme }) => ({
  width: '85%',
  '& .MuiOutlinedInput-input': {
    padding: '10px 14px'
  }
}));

const StyledTitleWrapperBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const Orders = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { searchQuery, setSearchQuery, isSearchClosed, setIsSearchClosed, setEnableSearchField, isUpdatePage, setIsUpdatePage, ordersFilters, setOrdersFilters, filterDates, setFilterDates, isFilterCleared, setIsFilterCleared, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, selectedButtonType, setSelectedButtonType, setSelectedTabType } = useContext(GlobalContext);
  const { getOrdersApi } = useRequests();
  const getOrders = useApi(getOrdersApi);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFiltersScreen, setOpenFiltersScreen] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [venueAccountNumber, setVenueAccountNumber] = useState('');
  const [symbol, setSymbol] = useState('');
  const [isDateFilterApplied, setIsDateFilterApplied] = useState<boolean>(false);
  const [clientId, setClientId] = useStorage<number | null>('clientId', null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onClearFilters = () => {
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFiltersCleared(true);
    setFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setOrdersFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setVenueAccountNumber('');
    setSymbol('');
    setIsFilterCleared(true);
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>, key: string, index: number) => {
    const selectedFilters = _.find(ordersFilters, (o) => o.parentKey === key).filters;
    const newFilters = selectedFilters.map((item: any) => {
      if (item.key === event.target.value) {
        item.isChecked = event.target.checked;
      }

      return item;
    });

    setOrdersFilters((csf: any) => _.map(csf, (c) => {
      if (c.parentKey === key) {
        c.filters = newFilters;
      }
      return c;
    }));
  };

  const onApply = () => {
    let query = '';
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    _.map(ordersFilters, (o: any) => {
      _.map(o.filters, (f: any) => {
        if (f.isChecked) {
          query += `&${o.parentKey}=${f.key}`;
        }
      });
    });

    if (venueAccountNumber !== '') {
      query += `&venueAcctNumber=${venueAccountNumber}`;
    }

    if (symbol !== '') {
      query += `&symbol=${symbol}`;
    }

    if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setIsDateFilterApplied(true);
    } else if (filterDates && !filterDates.fromDate && filterDates.toDate) {
      setIsDateFilterApplied(false);
      return;
    } else if (filterDates && filterDates.fromDate && !filterDates.toDate) {
      setIsDateFilterApplied(false);
      return;
    }

    if (query) {
      setFilterQuery(query);
    } else {
      setFilterQuery('');
    }
    setOpenFiltersScreen(false);
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const onVenueAccount = (event: ChangeEvent<HTMLInputElement>) => {
    setVenueAccountNumber(event.target.value);
  };

  const onSymbol = (event: ChangeEvent<HTMLInputElement>) => {
    setSymbol(event.target.value);
  };

  const resetPageProperties = () => {
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFiltersCleared(true);
    setFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    setOrdersFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setVenueAccountNumber('');
    setSymbol('');
    setIsFilterCleared(true);
    setSearchQuery('');
    setIsSearchClosed(false);
    setEnableSearchField(false);
  };

  const onLinkClick = (data: any) => {
    setClientId(data.customerId);
    resetPageProperties();
    setSelectedTabType('ORDERS');
    navigate('/profile', { state: {...data, from: 'ORDERS'} });
  };

  const onOrderType = (e: MouseEvent<HTMLDivElement>) => {
    const type = (e.target as HTMLDivElement).dataset.filter;
    setSelectedButtonType(type);
    resetPageProperties();
  };

  useEffect(() => {
    if (location && location.pathname === '/orders') {
      setSelectedButtonType('EQUITY');
    }
  }, []);

  useEffect(() => {
    const newFilters = _.map(ordersFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(false);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else if (symbol !== '' || venueAccountNumber !== '') {
      setEnableButtons(false);
    } else if (filterDates && filterDates.fromDate && filterDates.toDate) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [ordersFilters, isFiltersCleared, symbol, venueAccountNumber, filterDates]);

  useEffect(() => {
    if (!selectedButtonType) return;
    if (!searchQuery) {
      setPage(isUpdatePage ? 0 : page);
      getOrders.request(selectedButtonType, page + 1, rowsPerPage, filterDates.fromDate, filterDates.toDate, filterQuery).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);
          setIsDateFilterApplied(false);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
      });
    } else if (searchQuery !== '' && !isSearchClosed) {
      setPage(isUpdatePage ? 0 : page);
      getOrders.request(selectedButtonType, page + 1, rowsPerPage, filterDates.fromDate, filterDates.toDate, filterQuery, searchQuery).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);
          setIsDateFilterApplied(false);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
      });
    }
  }, [selectedButtonType, page, rowsPerPage, searchQuery, isSearchClosed, isUpdatePage, isFilterCleared, filterQuery, isDateFilterApplied]);

  return (
    <CssContainer>
      <StyledTitleWrapperBox>
        <CssTitle variant='h4' underline>Orders</CssTitle>
        <ButtonGroup data={[
          {
            label: 'Cash equities',
            type: 'EQUITY',
            onClick: onOrderType
          },
          {
            label: 'Crypto',
            type: 'CRYPTO',
            onClick: onOrderType
          },
          {
            label: 'CFD',
            type: 'CFD',
            onClick: onOrderType
          }
        ]} />
      </StyledTitleWrapperBox>
      <CssTable
        headers={OrdersHeaders}
        totalCount={totalCount}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: {md: 6, xs: 0} }}
        rowClassName={'no-row-click'}
        onLinkClick={onLinkClick}
        highlightText={searchQuery}
        isDesktopFilters={true}
        onFilters={handleFilters}
      />

      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          <CssAccordion title='Venue account number' isExpand={venueAccountNumber !== ''}>
            <StyledTextField variant='outlined' placeholder='Venue account number' value={venueAccountNumber} onChange={onVenueAccount} />
          </CssAccordion>

          {ordersFilters && ordersFilters.map((item: any, index: number) => (
            <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
              <FormGroup>
                {item.filters.map((filter: any, i: number) => {
                  if (selectedButtonType === 'EQUITY' && OrdersHeaders.equitiesFilters.includes(filter.key)) {
                    return (
                      <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                    );
                  } else if ((selectedButtonType === 'CRYPTO' || selectedButtonType === 'CFD') && OrdersHeaders.cryptoFilters.includes(filter.key)) {
                    return (
                      <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                    );
                  } else if (item.parentKey === 'orderSide') {
                    return (
                      <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                    );
                  }
                })}
              </FormGroup>
            </CssAccordion>
          ))}

          <CssAccordion title='Symbol' isExpand={symbol !== ''}>
            <StyledTextField variant='outlined' placeholder='Symbol' value={symbol} onChange={onSymbol} />
          </CssAccordion>

          <CssAccordion title='Date' isExpand={filterDates && filterDates.fromDate && filterDates.toDate}>
            <Filters from={'POP_IN_FILTER'} fromLabel={'Entry start date'} toLabel={'Entry end date'} />
          </CssAccordion>

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
    </CssContainer>
  );
};

export default Orders;