import { processDisplayZero } from 'utils';

export const WalletReportsHeaders = {
  columns:  [
    {
      key: 'customerId',
      label: 'Client ID'
    },
    {
      key: 'firstName',
      label: 'First name'
    },
    {
      key: 'lastName',
      label: 'Last name'
    },
    {
      key: 'txnDate',
      label: 'Status updated',
      withTimestamp: true
    },
    {
      key: 'walletBalance',
      label: 'USD wallet balance',
      processCellData: processDisplayZero
    },
  ],
  sortableColumns: ['txnDate'],
  dateColumns: ['txnDate'],
  searchColumns: ['customerId', 'firstName', 'lastName'],
  filterAlign: 'right'
};

export const WalletSnapshotHeaders = {
  columns:  [
    {
      key: 'customerId',
      label: 'Client ID'
    },
    {
      key: 'firstName',
      label: 'First name'
    },
    {
      key: 'lastName',
      label: 'Last name'
    },
    {
      key: 'walletBalance',
      label: 'Wallet snapshot USD',
      processCellData: processDisplayZero
    },
  ],
  sortableColumns: ['txnDate'],
  dateColumns: ['txnDate'],
  searchColumns: ['customerId', 'firstName', 'lastName'],
  filterAlign: 'right'
};