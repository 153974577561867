import Icon from './Icon';

const Save = ({ className, direction, fill, sx }: any) => {
  return (
    <Icon className={className} direction={direction} sx={sx}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.48929 6.2367C3.65511 4.81893 4.85635 3.75 6.28379 3.75H17.0265C17.2086 3.75 17.3845 3.81626 17.5213 3.93641L20.2818 6.3602C20.9024 6.90511 21.2452 7.70008 21.2156 8.52543L20.8716 18.0988C20.8184 19.5781 19.6037 20.75 18.1233 20.75H6.11309C4.78347 20.75 3.66094 19.762 3.4921 18.4432C2.9956 14.565 2.97421 10.6407 3.42841 6.75728L3.48929 6.2367ZM6.28379 5.25C5.61737 5.25 5.05655 5.74905 4.97914 6.41096L4.91825 6.93153C4.47823 10.6937 4.49895 14.4955 4.97995 18.2527C5.05295 18.8229 5.53825 19.25 6.11309 19.25H6.75018V15C6.75018 14.0335 7.53368 13.25 8.50018 13.25H15.5002C16.4667 13.25 17.2502 14.0335 17.2502 15V19.25H18.1233C18.7962 19.25 19.3484 18.7173 19.3725 18.0449L19.7165 8.47157C19.73 8.09641 19.5742 7.73505 19.2921 7.48737L16.7502 5.2555V7.59998C16.7502 8.56647 15.9667 9.34998 15.0002 9.34998H9.00018C8.03368 9.34998 7.25018 8.56647 7.25018 7.59998V5.25H6.28379ZM8.75018 5.25V7.59998C8.75018 7.73805 8.86211 7.84998 9.00018 7.84998H15.0002C15.1383 7.84998 15.2502 7.73805 15.2502 7.59998V5.25H8.75018ZM15.7502 19.25H8.25018V15C8.25018 14.8619 8.36211 14.75 8.50018 14.75H15.5002C15.6383 14.75 15.7502 14.8619 15.7502 15V19.25Z" fill="white"/>
      </svg>
    </Icon>
  );
};

export default Save;