import Icon from './Icon';

const EyeClosed = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.0303 5.03033C21.3232 4.73744 21.3232 4.26256 21.0303 3.96967C20.7374 3.67678 20.2626 3.67678 19.9697 3.96967L3.96967 19.9697C3.67678 20.2626 3.67678 20.7374 3.96967 21.0303C4.26256 21.3232 4.73744 21.3232 5.03033 21.0303L8.06535 17.9953C9.38317 18.6032 10.8919 19 12.5 19C15.1178 19 17.472 17.9486 19.1684 16.6466C20.0177 15.9948 20.7146 15.2709 21.2035 14.5663C21.6831 13.8752 22 13.1485 22 12.5C22 11.8515 21.6831 11.1248 21.2035 10.4337C20.7146 9.72913 20.0177 9.00519 19.1684 8.35335C18.8978 8.14567 18.6104 7.94437 18.308 7.75269L21.0303 5.03033ZM15.6287 10.432L14.5307 11.5299C14.6713 11.8237 14.75 12.1526 14.75 12.5C14.75 13.7426 13.7426 14.75 12.5 14.75C12.1526 14.75 11.8237 14.6713 11.5299 14.5307L10.432 15.6287C11.0248 16.0213 11.7357 16.25 12.5 16.25C14.5711 16.25 16.25 14.5711 16.25 12.5C16.25 11.7357 16.0213 11.0248 15.6287 10.432Z" fill="#E5E5E5"/>
      <path d="M13.1692 8.80953C13.253 8.82462 13.3395 8.7998 13.3997 8.7396L15.3493 6.79007C15.4816 6.65772 15.4267 6.43281 15.2464 6.38252C14.379 6.14055 13.4571 6 12.5 6C9.88223 6 7.52801 7.05139 5.83162 8.35335C4.98232 9.00519 4.28544 9.72913 3.79649 10.4337C3.31686 11.1248 3 11.8515 3 12.5C3 13.1485 3.31686 13.8752 3.79649 14.5663C4.2138 15.1676 4.78257 15.7831 5.46829 16.3558C5.56614 16.4375 5.70993 16.4294 5.80008 16.3393L8.7396 13.3997C8.7998 13.3395 8.82462 13.253 8.80953 13.1692C8.77042 12.952 8.75 12.7284 8.75 12.5C8.75 10.4289 10.4289 8.75 12.5 8.75C12.7284 8.75 12.952 8.77042 13.1692 8.80953Z" fill="#E5E5E5"/>
      </svg>
    </Icon>
  );
};

export default EyeClosed;