import { styled } from '@mui/material/styles';
import {
  Typography,
  Box
} from '@mui/material';
import CssEllipse from './CssEllipse';
import { processKycStatus } from 'utils';

interface Props {
  statusType: string
  kyc: string
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&.ALERT': {
    color: '#FF4534'
  },
  '&.WARNING': {
    color: '#FF802C'
  },
  '&.REVIEW': {
    color: '#FFC32A'
  },
  '&.VERIFIED': {
    color: '#02B9A2'
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    position: 'relative',
    top: 3,
    marginLeft: 10,
  }
}));

const StyledEllipse = styled(Box)(({ theme }) => ({
  display: 'inline-flex'
}));

const KycStatus = ({ statusType, kyc }: Props) => {
  const status = processKycStatus(statusType, kyc)?.split('|')[1];
  const displayValue = processKycStatus(statusType, kyc)?.split('|')[0];

  return (
    <StyledEllipse>
      <CssEllipse className={status} /><StyledTypography variant='body2' className={status}>{displayValue}</StyledTypography>
    </StyledEllipse>
  );
};

export default KycStatus;