import React, { useEffect, useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Tabs, Tab, useMediaQuery } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { GlobalContext } from 'context';

interface TabData {
  label: string
  value: number
  tabPanelComponent?: any
  extraNode?: any
  isDisabled?: boolean
  onTabClick?: () => void
};

interface TabsProps {
  tabs: TabData[],
  children?: any,
  isFullWidth?: boolean,
  sx?: any,
  tabBoxSx?: any,
  defaultIndex?: string
};

const StyledTabList = styled(Tabs)(({ theme }) => {
  return ({
  overflow: 'visible',
  '& .MuiTabs-scroller': {
    overflow: 'visible !important'
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.light,
    height: 5,
    zIndex: 99,
    bottom: '-1px'
  }
})});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: '1rem',
  fontWeight: '700',
  padding: 0,
  minWidth: 'auto',
  marginRight: 56,
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: '.75rem',
    minWidth: 'initial',
  }
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0
}));

const CssTabs = ({ tabs, isFullWidth, sx, tabBoxSx, children, defaultIndex = '1' }: TabsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { reportTabValue, setReportTabValue, isWalletTabSelected } = useContext(GlobalContext);
  const [value, setValue] = useState(defaultIndex);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setReportTabValue(null);
  };

  useEffect(() => {
    setValue(reportTabValue || defaultIndex);
  }, [defaultIndex, reportTabValue]);

  return (
    <Box sx={{ width: '100%', typography: 'body1', mt: isMobile ? 3 : 5 }}>
      <TabContext value={value}>
        <Box sx={tabBoxSx || { borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabList onChange={handleChange} aria-label='Tabs' value={value} {...(isFullWidth && {variant: 'fullWidth'})} sx={sx || {}}>
            {tabs && tabs.map((tab, i) => <StyledTab label={tab.label} value={`${tab.value}`} key={i} disabled={tab.isDisabled || false} {...(tab.onTabClick && {onClick: tab.onTabClick})} {...(isWalletTabSelected && tab.extraNode && {icon: tab.extraNode, iconPosition: 'end', sx: {minHeight: 46}})} />)}
          </StyledTabList>
        </Box>
        {!children && tabs && tabs.map((tab, i) => <StyledTabPanel value={`${tab.value}`} key={i} >{tab.tabPanelComponent}</StyledTabPanel>)}
        {children}
      </TabContext>
    </Box>
  );
};
export default CssTabs;
