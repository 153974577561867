import { forwardRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Dialog, Slide, AppBar, Toolbar, IconButton, Typography, useMediaQuery } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import BackArrow from 'components/icons/BackArrow';
import Close from './icons/Close';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='left' ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  width: '25%',
  left: 'unset',

  '& .MuiAppBar-root': {
    width: '25%'
  },
  '& .dialog-title': {
    display: 'none'
  },
  '&.comments-dialog': {
    width: '26.786%',
    '& .MuiAppBar-root': {
      width: '26.786%'
    }
  },
  '&.notification-dialog': {
    width: '30%',
    '& .MuiAppBar-root': {
      width: '30%'
    }
  }
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  '& .icon svg': {
    position: 'relative',
    top: 2
  },
  [theme.breakpoints.up('md')]: {
    borderBottom: 0
  }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '& .close-icon': {
    width: 16,
    height: 16,
    top: '-5px',
    left: '-1px'
  }
}));

const ScreenDialog = ({ open, onScreenClose, children, title, hideCloseBtn, rightActionTitle, rightAction, ...rest }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  return (
    <StyledDialog
      fullScreen
      open={open}
      onClose={onScreenClose}
      TransitionComponent={Transition}
      {...rest}
    >
      <StyledAppBar>
        <Toolbar>
          {!hideCloseBtn && <StyledIconButton
            edge='start'
            color='inherit'
            onClick={onScreenClose}
            aria-label='back'
          >
            <Close className={'close-icon'} />
          </StyledIconButton>}
          {isMobile && <StyledIconButton
            edge='start'
            color='inherit'
            onClick={onScreenClose}
            aria-label='back'
          >
            <BackArrow />
          </StyledIconButton>}
          <Typography sx={{ flex: 1 }} variant='body1' component='div' className={'dialog-title'}>
            {title}
          </Typography>
          {rightActionTitle && <Typography variant='body1' {...(rightAction && {onClick: rightAction})}>
            {rightActionTitle}
          </Typography>}
        </Toolbar>
      </StyledAppBar>
      {children}
    </StyledDialog>
  );
};

export default ScreenDialog;