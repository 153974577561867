import Icon from './Icon';

const Time = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.75 4.5C9.75 4.08579 9.41421 3.75 9 3.75C8.58579 3.75 8.25 4.08579 8.25 4.5V9.5C8.25 9.75859 8.38321 9.99895 8.6025 10.136L11.6025 12.011C11.9538 12.2305 12.4165 12.1238 12.636 11.7725C12.8555 11.4212 12.7488 10.9585 12.3975 10.739L9.75 9.08431V4.5Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 0.75C4.16751 0.75 0.25 4.66751 0.25 9.5C0.25 14.3325 4.16751 18.25 9 18.25C13.8325 18.25 17.75 14.3325 17.75 9.5C17.75 4.66751 13.8325 0.75 9 0.75ZM1.75 9.5C1.75 5.49594 4.99594 2.25 9 2.25C13.0041 2.25 16.25 5.49594 16.25 9.5C16.25 13.5041 13.0041 16.75 9 16.75C4.99594 16.75 1.75 13.5041 1.75 9.5Z" fill="black"/>
      </svg>
    </Icon>
  );
};

export default Time;