import Icon from './Icon';

const ActiveNotification = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 7.5C14.4473 6.79877 12.6658 6.83364 11.4992 7.0003C7.49918 6.0003 6.5 9.50023 7 12.0002C7.4 14.0002 5.83333 17.5002 5 19.0002L9.99918 20.0002C9.66584 21.1669 9.59918 23.5002 11.9992 23.5002C14.3992 23.5002 14.3325 21.1669 13.9992 20.0002L18.9992 19.0002L17.9992 17.0002C17.3325 14.5003 17.0008 8.4997 15.5 7.5Z" fill="#04F8DA"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9992 5.5C12.9992 4.94772 12.5515 4.5 11.9992 4.5C11.4469 4.5 10.9992 4.94772 10.9992 5.5V6.25H10.4418C8.21672 6.25 6.37512 7.98001 6.23622 10.2007L6.01522 13.7342C5.93096 15.0814 5.47867 16.3797 4.70761 17.4876C4.01093 18.4886 4.63115 19.8712 5.84208 20.0165L9.24921 20.4254V21.5C9.24921 23.0188 10.4804 24.25 11.9992 24.25C13.518 24.25 14.7492 23.0188 14.7492 21.5V20.4254L18.1563 20.0165C19.3673 19.8712 19.9875 18.4886 19.2908 17.4876C18.5197 16.3797 18.0674 15.0814 17.9832 13.7342L17.7622 10.2007C17.6233 7.98001 15.7817 6.25 13.5566 6.25H12.9992V5.5ZM10.4418 7.75C9.00879 7.75 7.82275 8.86417 7.7333 10.2944L7.5123 13.8278C7.4109 15.449 6.86664 17.0112 5.9388 18.3444C5.88844 18.4168 5.93327 18.5167 6.0208 18.5272L9.75846 18.9757C11.2469 19.1543 12.7515 19.1543 14.2399 18.9757L17.9776 18.5272C18.0651 18.5167 18.11 18.4168 18.0596 18.3444C17.1318 17.0112 16.5875 15.449 16.4861 13.8278L16.2651 10.2944C16.1757 8.86417 14.9896 7.75 13.5566 7.75H10.4418ZM11.9992 22.75C11.3089 22.75 10.7492 22.1904 10.7492 21.5V20.75H13.2492V21.5C13.2492 22.1904 12.6896 22.75 11.9992 22.75Z" fill="black"/>
      <path d="M21 9.5C21 7.29086 19.2091 5.5 17 5.5" stroke="black" strokeWidth="1.5"/>
      <path d="M25 9.5C25 5.08172 21.4183 1.5 17 1.5" stroke="black" strokeWidth="1.5"/>
      </svg>
    </Icon>
  );
};

export default ActiveNotification;