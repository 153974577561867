import Icon from './Icon';

const Skip = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6069 10.7475C15.4262 11.3815 15.4262 12.6185 14.6069 13.2525C12.4478 14.9231 10.0369 16.2399 7.4644 17.1535L6.99835 17.3191C5.97483 17.6826 4.89802 16.9901 4.76004 15.938C4.42036 13.3479 4.42036 10.6521 4.76004 8.06199C4.89802 7.00991 5.97482 6.31743 6.99835 6.68095L7.4644 6.84647C10.0369 7.76013 12.4478 9.07691 14.6069 10.7475ZM13.6889 12.0661C13.7322 12.0327 13.7322 11.9673 13.6889 11.9339C11.6556 10.3605 9.38508 9.12042 6.96238 8.25997L6.49633 8.09444C6.39612 8.05885 6.2644 8.12674 6.24731 8.25704C5.9246 10.7176 5.9246 13.2824 6.24731 15.743C6.26439 15.8733 6.39612 15.9411 6.49633 15.9056L6.96238 15.74C9.38508 14.8796 11.6556 13.6395 13.6889 12.0661Z" fill={fill || 'black'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M18 6.25C18.4142 6.25 18.75 6.58579 18.75 7V17C18.75 17.4142 18.4142 17.75 18 17.75C17.5858 17.75 17.25 17.4142 17.25 17V7C17.25 6.58579 17.5858 6.25 18 6.25Z" fill={fill || 'black'} />
      </svg>
    </Icon>
  );
};

export default Skip;