import Icon from './Icon';

const CaretGreen = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.9096 0.716491C11.2269 1.03379 11.2269 1.54824 10.9096 1.86554L6.57631 6.19887C6.25901 6.51617 5.74457 6.51617 5.42727 6.19887L1.09393 1.86554C0.776632 1.54824 0.776632 1.03379 1.09393 0.716492C1.41123 0.399191 1.92568 0.399191 2.24298 0.716492L6.00179 4.4753L9.7606 0.716491C10.0779 0.39919 10.5923 0.39919 10.9096 0.716491Z" fill="#04F8DA"/>
      </svg>
    </Icon>
  );
};

export default CaretGreen;