import Icon from './Icon';

const Hamburger = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 7H3C2.44772 7 2 6.55229 2 6C2 5.44772 2.44772 5 3 5H22C22.5523 5 23 5.44772 23 6C23 6.55228 22.5523 7 22 7Z" fill="black"/>
      <path d="M22 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13Z" fill="black"/>
      <path d="M22 19H3C2.44772 19 2 18.5523 2 18C2 17.4477 2.44772 17 3 17H22C22.5523 17 23 17.4477 23 18C23 18.5523 22.5523 19 22 19Z" fill="black"/>
      </svg>
    </Icon>
  );
};

export default Hamburger;