import Icon from './Icon';

const CheckboxChecked = ({ className, direction, fill, sx }: any) => {
  return (
    <Icon className={className} direction={direction} sx={sx}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z" fill="#04F8DA"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.7413 8.25831C17.3963 7.9139 16.8371 7.9139 16.4921 8.25831L10.858 13.8838L7.50785 10.5388C7.16291 10.1944 6.60365 10.1944 6.25871 10.5388C5.91376 10.8832 5.91376 11.4416 6.25871 11.786L10.0061 15.5277C10.0575 15.5789 10.1135 15.6226 10.1729 15.6586C10.1954 15.6874 10.2199 15.7152 10.2464 15.7417C10.5914 16.0861 11.1506 16.0861 11.4956 15.7417L17.7413 9.50554C18.0862 9.16113 18.0862 8.60272 17.7413 8.25831Z" fill="black"/>
      </svg>
    </Icon>
  );
};

export default CheckboxChecked;