import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';
import Button from '@mui/material/Button';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SnackbarProps {
  open: boolean
  message: string
  severityType?: string | undefined
  onClose?: () => void
  onUndo?: () => void
}

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction='up' />;
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: 15,
  '&.MuiAlert-filledSuccess': {
    backgroundColor: '#B9FFF7'
  },
  '&.MuiAlert-filledInfo': {
    backgroundColor: '#F7F3F3',
    color: '#000'
  },
  '& .MuiAlert-action': {
    marginLeft: 0,
    paddingLeft: 0
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#4021F5',
  textTransform: 'capitalize'
}));

const CssSnackbar = ({ open, message, severityType, onClose, onUndo } : SnackbarProps) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      autoHideDuration={5000}
      sx={{left: '182px !important'}}
    >
      {severityType === 'ERROR' ? <Alert onClose={onClose} severity='error'>
        {message}
      </Alert> :
       <StyledAlert icon={false} onClose={onClose} {...(severityType === 'INFO' ? {severity: 'info'} : {severity:'success'})} {...(onUndo && {action: <StyledButton size='small' onClick={onUndo}>Undo</StyledButton>})}>
        {message}
      </StyledAlert>}
    </Snackbar>
  );
};

export default CssSnackbar;