import { processMarketStatus } from 'utils';

export const InstrumentsHeaders = {
  columns:  [
    {
      key: 'symbol',
      label: 'Symbol'
    },
    {
      key: 'instrumentDisplayName',
      label: 'Company name'
    },
    {
      key: 'instrumentType',
      label: 'Instrument type'
    },
    {
      key: 'instrumentSubType',
      label: '2nd instrument type'
    },
    {
      key: 'tags',
      label: 'Tags',
      isArray: true,
      separator: ','
    },
    {
      key: 'tradingVenueStatus',
      label: 'Market status'
    },
    {
      key: 'internalStatus',
      label: 'Internal status'
    }
  ],
  statusData: [
    {
      key: 'tradingVenueStatus',
      type: 'oval',
      valid: ['Active|VERIFIED', 'Halted|REVIEW', 'Close only|ALERT', 'Full|VERIFIED', 'Long only|REVIEW', 'Short only|REVIEW'],
      processStatus: processMarketStatus
    },
    {
      key: 'internalStatus',
      type: 'switch-icon',
      valid: ['ACTIVE|ENABLED', 'INACTIVE|DISABLED'],
      labelStyles: {
        textAlign: 'center'
      },
      dataCellStyles: {
        textAlign: 'center'
      }
    }
  ],
  searchColumns: ['symbol', 'instrumentDisplayName', 'instrumentType', 'instrumentSubType', 'tags']
};