import Icon from './Icon';

const Exchange = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.41291 8.16291C9.77903 7.7968 9.77903 7.2032 9.41291 6.83709C9.0468 6.47097 8.4532 6.47097 8.08709 6.83709L5.58709 9.33709C5.22097 9.7032 5.22097 10.2968 5.58709 10.6629L8.08709 13.1629C8.4532 13.529 9.0468 13.529 9.41291 13.1629C9.77903 12.7968 9.77903 12.2032 9.41291 11.8371L8.51333 10.9375H21.25C21.7678 10.9375 22.1875 10.5178 22.1875 10C22.1875 9.48223 21.7678 9.0625 21.25 9.0625H8.51333L9.41291 8.16291Z" fill="currentColor" />
      <path d="M20.5871 16.8371C20.221 17.2032 20.221 17.7968 20.5871 18.1629L21.4867 19.0625H8.75C8.23223 19.0625 7.8125 19.4822 7.8125 20C7.8125 20.5178 8.23223 20.9375 8.75 20.9375H21.4867L20.5871 21.8371C20.221 22.2032 20.221 22.7968 20.5871 23.1629C20.9532 23.529 21.5468 23.529 21.9129 23.1629L24.4129 20.6629C24.779 20.2968 24.779 19.7032 24.4129 19.3371L21.9129 16.8371C21.5468 16.471 20.9532 16.471 20.5871 16.8371Z" fill="currentColor" />
      </svg>
    </Icon>
  );
};

export default Exchange;