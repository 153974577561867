import Icon from './Icon';

const RightCaret = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.21209 0.337088C1.5782 -0.0290289 2.1718 -0.0290289 2.53791 0.337088L7.53791 5.33709C7.90403 5.7032 7.90403 6.2968 7.53791 6.66291L2.53791 11.6629C2.1718 12.029 1.5782 12.029 1.21209 11.6629C0.845971 11.2968 0.845971 10.7032 1.21209 10.3371L5.54917 6L1.21209 1.66291C0.84597 1.2968 0.84597 0.703203 1.21209 0.337088Z" fill="#04F8DA"/>
      </svg>
    </Icon>
  );
};

export default RightCaret;