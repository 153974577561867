import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import CssEllipse from 'components/CssEllipse';
import CssAvatar from './CssAvatar';
import { getFirstLetters, getTimeElapsed, formatMessagePayloadStatus } from 'utils';

const StyledGrid = styled(Grid)(({ theme }) => ({
  borderBottom: '1px solid #F7F3F3',
  padding: '16px 17.5px',
  '&:hover': {
    backgroundColor: '#04F8DA0D',
    cursor: 'pointer'
  },
  '&.unread': {
    backgroundColor: '#04F8DA0D'
  }
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: 10,
  fontSize: '0.75rem',
  lineHeight: 1.2
}));

interface NotificationsListProps {
  onCallback: (messagePayload: any) => void
}

const NotificationsList = (props: NotificationsListProps) => {
  const { onCallback } = props;
  const { getNotificationsApi, readNotificationApi } = useRequests();
  const getNotifications = useApi(getNotificationsApi);
  const readNotification = useApi(readNotificationApi);
  const [notificationsData, setNotificationsData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasReachLastPage, setHasReachLastPage] = useState<boolean>(false);

  // Group notifications by date
  const groupedNotifications = notificationsData.reduce((acc: any, notification: any) => {
    const date = moment(notification.createdAt).format('MM.DD.YYYY');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(notification);
    return acc;
  }, {});

  // Create an array of objects representing each date and its notifications
  const notificationsByDate = Object.keys(groupedNotifications).map((date) => {
    const notifications = groupedNotifications[date];
    const day = new Date(date).toLocaleDateString();
    const today = new Date().toLocaleDateString();
    const yesterday = new Date(Date.now() - 86400000).toLocaleDateString();
    const formattedDate = day === today ? 'Today' : day === yesterday ? 'Yesterday' : date;
    return {
      date: formattedDate,
      notifications,
      day
    };
  });

  // Sort the array in descending order of date
  notificationsByDate.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const fetchNotifications = (page: number) => {
    getNotifications.request(page).then((res) => {
      if (res.status === 200) {
        if (res.data.length < 10) {
          setHasReachLastPage(true);
        }
        setNotificationsData((prevNotifications: any) => [...prevNotifications, ...res.data]);
      }
    });
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom = Math.round(scrollTop) + clientHeight >= scrollHeight;
      if (isAtBottom && !hasReachLastPage) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  const onMessage = (n: any, date: string) => {
    if (n.isRead === false) {
      readNotification.request(n.id, {isRead: !n.isRead}).then((res) => {
        if (res.status === 200) {
          setNotificationsData((prevNotifications: any) => prevNotifications.map((item: any) => {
            if (item.date === date) {
              item.notifications = item.notifications.map((o: any) => {
                if (o.id === n.id) {
                  o.isRead = !n.isRead;
                }
                return o;
              })
            }
            return item;
          }));
        }
      });
    }
    onCallback(n);
  };

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Box ref={containerRef} sx={{ maxHeight: 730, overflowY: 'auto' }} className='notification-list-wrapper'>
      {notificationsByDate.length > 0 && notificationsByDate.map(({ date, notifications }) => (
        <Box key={date}>
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
            {date}
          </Typography>
          <Grid container>
            {notifications.map((n: any, index: number) => (
              <StyledGrid item xs={12} {...(n.isRead === false && {className: 'unread'})} onClick={() => onMessage(n, date)} key={index}>
                <Grid container>
                  <Grid item xs={1}>
                    <CssEllipse className={n.resourceType} sx={{top: 9}} />
                  </Grid>
                  <Grid item xs={11}>
                    {n.messagePayload && <Typography variant='body1'>
                      <b>{n.messagePayload.actionType}</b> {n.messagePayload.message} <b>{formatMessagePayloadStatus(n.messagePayload.status)}</b>
                      {n.resourceFilter && n.resourceFilter.includes('withdrawals') && ` (${n.resourceFilter})`}
                    </Typography>}
                    <Grid container sx={{mt: 1}}>
                      <Grid item xs={1.25}>
                        <CssAvatar className={`notification-avatar ${n.resourceType}`}>{getFirstLetters(n.resourceType)}</CssAvatar>
                      </Grid>
                      <Grid item xs={10.75}>
                        <StyledSubtitle variant='subtitle2'>{n.resource}</StyledSubtitle>
                        <StyledSubtitle variant="subtitle2" color="textSecondary">
                        {getTimeElapsed(new Date(n.createdAt))}
                      </StyledSubtitle>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledGrid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default NotificationsList;