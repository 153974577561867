import React, { useEffect, useState, ChangeEvent, useCallback, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, IconButton, TextField, Button, Tooltip } from '@mui/material';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Plus from 'components/icons/Plus';
import TransparentPlus from 'components/icons/TransparentPlus';
import BackCaret from 'components/icons/BackCaret';
import CssTitle from 'components/CssTitle';
import LinkModule from 'components/LinkModule';
import CssDialog from 'components/CssDialog';
import CssButton from 'components/CssButton';
import Permissions from './Permissions';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { GlobalContext } from 'context';
import { isAdminRole, checkPermissions } from 'utils';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  paddingBottom: 14
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  lineHeight: '1rem',
  fontSize: '0.875rem',
  fontWeight: 400,
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.primary.light}`
  },
  '& .MuiFormHelperText-root': {
    border: 0
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  marginTop: 53,
  padding: 0,
  justifyContent: 'left',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: `underline solid ${theme.palette.primary.light} 3px`
  }
}));

const Roles = (props: any) => {
  const { brandId, onClickBack } = props;
  const { userRole, userPermissions } = useContext(GlobalContext);
  const { getRolesByBrandApi, postRoleByBrandApi, updateRoleByRoleIdApi, updatePermissionsByRoleIdApi } = useRequests();
  const updatePermissionsByRoleId = useApi(updatePermissionsByRoleIdApi);
  const getRolesByBrand = useApi(getRolesByBrandApi);
  const postRoleByBrand = useApi(postRoleByBrandApi);
  const updateRoleByRoleId = useApi(updateRoleByRoleIdApi);
  const [roles, setRoles] = useState([]);
  const [openAddRoleModal, setOpenAddRoleModal] = useState<boolean>(false);
  const [roleValue, setRoleValue] = useState<string>('');
  const [showComponent, setShowComponent] = useState<string>('DEFAULT');
  const [roleData, setRoleData] = useState<any>(null);
  const [isButtonHoverEnabled, setIsButtonHoverEnabled] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const onAddRoleModalClose = (event: {},
    reason: 'backdropClick') => {
    setOpenAddRoleModal(false);
    setErrorText('');
  };

  const onNewRole = () => {
    setOpenAddRoleModal(true);
  };

  const onRoleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setRoleValue(event.target.value);
  };

  const onRoleSave = () => {
    setErrorText('');
    postRoleByBrand.request(brandId, { roleName: roleValue }).then((res) => {
      if (res.status === 200) {
        setOpenAddRoleModal(false);
        setShowComponent('PERMISSION');
        setRoleData(res.data);
      }
    }).catch(e => {
      setErrorText(e.response.data.error.message);
    });;
  };

  const handleSwitch = (name: string, id: string | number, data: any) => {
    setShowComponent(name);
    setRoleData(data);
  };

  const handleBackButton = (name: string) => {
    setShowComponent(name);
    callRolesApi();
  };

  const callRolesApi = useCallback(async () => {
    getRolesByBrand.request(brandId).then((res) => {
      if (res.status === 200) {
        setRoles(res.data);
      }
    });
  }, []);

  const checkRolesPermissions = () => {
    if (!isAdminRole(userRole)) {
      return checkPermissions(userPermissions, 'ROLES_PERMISSION');
    } else {
      return true;
    }
  };

  const toggleButton = (isToggle: boolean) => {
    setIsButtonHoverEnabled(isToggle);
  };

  type PermissionsParams = {
    resourceName: string;
    permissions: string;
    isVisible: boolean
  };

  const updateNotificationEnabled = (roleId: string | number, isNotificationEnabled: boolean) => {
    const payload: PermissionsParams[] = [{
      resourceName: 'NOTIFICATIONS',
      permissions: 'READ',
      isVisible: isNotificationEnabled
    }];
    updateRoleByRoleId.request(roleId, {isNotificationEnabled: isNotificationEnabled}).then((res) => {
      if (res.status === 200) {
        callRolesApi();
      }
    });
    updatePermissionsByRoleId.request(roleId, payload);
  };

  useEffect(() => {
    callRolesApi();
  }, [callRolesApi]);

  return (
    <>
      {showComponent === 'DEFAULT' && <StyledButton startIcon={<BackCaret />} onClick={onClickBack}>Back</StyledButton>}

      <SwitchTransition mode='out-in'>
        <CSSTransition
          key={showComponent}
          classNames='fade'
          timeout={500}
        >
          <>
          {showComponent === 'DEFAULT' && 
            <>
              <Grid container>
                <Grid item xs={12} justifyContent={'space-between'} sx={{ display: 'flex', mt: 10 }}>
                  <CssTitle variant='h6' sx={{fontSize: '1.25rem'}}>
                    Roles
                    {roles.length > 0 && <Typography variant='subtitle2' sx={{display: 'inline-block', color: '#5B5959', ml: 1.875}}>{roles.length} roles</Typography>}
                  </CssTitle>
                  {checkRolesPermissions() && <Tooltip title={'Add role'}><IconButton sx={{ width: 23, height: 23, mt: 1 }} onClick={onNewRole} onMouseEnter={() => toggleButton(true)} onMouseLeave={() => toggleButton(false)}>
                    {isButtonHoverEnabled ? <TransparentPlus /> : <Plus />}
                  </IconButton></Tooltip>}
                </Grid>
                <Grid item xs={12} sx={{mt: 5.625}}>
                  {roles.length > 0 && roles.map((r: any, i: number) => (r.roleName !== userRole && <LinkModule name={r.roleName} count={r.members} onModuleClick={() => handleSwitch('PERMISSION', r.roleId, r)} key={i} onNotificationClick={updateNotificationEnabled} record={r} />))}
                  {roles.length === 0 && <Typography sx={{textAlign
                  : 'center'}}>No roles found</Typography>}
                </Grid>
              </Grid>

              <CssDialog
                disableEscapeKeyDown={true}
                open={openAddRoleModal}
                onScreenClose={onAddRoleModalClose}
                maxWidth={'sm'}
              >
                <StyledBox sx={{mt: 4.375, ml: 5.625, mr: 5.625}}>
                  <Typography variant='h6'>Add new role</Typography>
                </StyledBox>

                <Box sx={{pl: 5.625, pr: 5.625, pb: 5.25}}>
                  <Grid container>
                    <Grid item xs={12} sx={{justifyContent: 'start', display: 'flex' }}>
                      <StyledTextField value={roleValue} onChange={onRoleChangeValue} placeholder='Type a name' {...(errorText && {error: true, helperText: errorText})} />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 4, justifyContent: 'end', display: 'flex' }}>
                      <CssButton variant='contained' sx={{width: '35%', mr: 0, ml: 1, pt: 1.5, pb: 1.5}} className='active' {...(roleValue !== '' ? {} : {disabled: true})} onClick={onRoleSave}>Edit permissions</CssButton>
                    </Grid>
                  </Grid>
                </Box>
              </CssDialog>
            </>
          }
          {showComponent === 'PERMISSION' &&
            <Permissions data={roleData} onBack={() => handleBackButton('DEFAULT')} />
          }
          </>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

export default Roles;