import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';

const CssAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  cursor: 'pointer',
  textTransform: 'uppercase',

  '&.notification-avatar': {
    width: 28,
    height: 28,
    fontSize: '.875rem',
    paddingTop: 2,
    fontWeight: 500,
    '&.FIN_OPS': {
      backgroundColor: '#E8F5F5',
      color: '#019482'
    },
    '&.COMPLIANCE': {
      backgroundColor: '#EAEDFD',
      color: '#2A48EA'
    },
    '&.ACCOUNT_OPENING': {
      backgroundColor: '#FFEBF6',
      color: '#FF34A2'
    },
  }
}));

export default CssAvatar;