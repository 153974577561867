/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, Slide, Paper, Grid, FormGroup, FormControlLabel } from '@mui/material';
import FilterButton from 'components/FilterButton';
import Caret from 'components/icons/Caret';
import CssButton from 'components/CssButton';
import CssCheckbox from 'components/CssCheckbox';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '70vh',
  boxShadow: 'none',
  borderRadius: 0,
  display: 'none',
  backgroundColor: theme.palette.background.default,
  '&.active': {
    display: 'block'
  }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    padding: 18,
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiInput-root': {
      fontSize: '1.25rem',
      marginTop: 15
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0 !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0 !important'
    }
  }
}));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  margin: 24,
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 30,
}));

const KycFilters = (props: any) => {
  const { onCancel } = props;
  const containerRef = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [statusChecked, setStatusChecked] = useState(false);
  const [riskRatingChecked, setRiskRatingChecked] = useState(false);
  const [idVerificationChecked, setIdVerificationChecked] = useState(false);

  const handleFilter = (e: MouseEvent<HTMLDivElement>) => {
    const filter = (e.target as HTMLDivElement).dataset.filter;
    setSelectedFilter(filter || '');

    if (filter === 'status') {
      openStatus();
    } else if (filter === 'risk_rating') {
      openRiskRating();
    } else if (filter === 'id_verification') {
      openIdVerification();
    }
  };

  const openStatus = () => {
    setStatusChecked((prev) => !prev);
  };

  const openRiskRating = () => {
    setRiskRatingChecked((prev) => !prev);
  };

  const openIdVerification = () => {
    setIdVerificationChecked((prev) => !prev);
  };

  const onApply = () => {
    
  };

  return (
    <Box ref={containerRef} sx={{position: 'relative'}}>
      <Stack direction='column' sx={{ display: 'flex' }}>
        <FilterButton className={selectedFilter === 'status' ? 'active btn-filter btn-flat custom' : 'btn-filter btn-flat custom'} filter='status' onClick={handleFilter}>Status <Caret /></FilterButton>
        <FilterButton className={selectedFilter === 'risk_rating' ? 'active btn-filter btn-flat custom' : 'btn-filter btn-flat custom'} filter='risk_rating' onClick={handleFilter}>Risk Rating <Caret /></FilterButton>
        <FilterButton className={selectedFilter === 'id_verification' ? 'active btn-filter btn-flat custom' : 'btn-filter btn-flat custom'} filter='id_verification' onClick={handleFilter}>Id Verification <Caret /></FilterButton>
      </Stack>

      <Slide direction='up' in={statusChecked} container={containerRef.current}>
        <StyledPaper {...(statusChecked ? {className: 'active'} : {className: ''})}>
          <FilterButton className={selectedFilter === 'status' ? 'active btn-filter btn-flat custom-active' : 'btn-filter btn-flat custom-active'} filter='status' onClick={handleFilter}>Status <Caret /></FilterButton>
          <StyledFormGroup>
            <StyledFormControlLabel control={<CssCheckbox />} label='Registered' />
            <StyledFormControlLabel control={<CssCheckbox />} label='Verified' />
            <StyledFormControlLabel control={<CssCheckbox />} label='Traded' />
          </StyledFormGroup>
        </StyledPaper>
      </Slide>

      <Slide direction='up' in={riskRatingChecked} container={containerRef.current}>
        <StyledPaper {...(riskRatingChecked ? {className: 'active'} : {className: ''})}>
          <FilterButton className={selectedFilter === 'risk_rating' ? 'active btn-filter btn-flat custom-active' : 'btn-filter btn-flat custom-active'} filter='risk_rating' onClick={handleFilter}>Risk Rating <Caret /></FilterButton>
          <StyledFormGroup>
            <StyledFormControlLabel control={<CssCheckbox />} label='Low' />
            <StyledFormControlLabel control={<CssCheckbox />} label='Medium' />
            <StyledFormControlLabel control={<CssCheckbox />} label='High' />
            <StyledFormControlLabel control={<CssCheckbox />} label='Very high' />
          </StyledFormGroup>
        </StyledPaper>
      </Slide>

      <Slide direction='up' in={idVerificationChecked} container={containerRef.current}>
        <StyledPaper {...(idVerificationChecked ? {className: 'active'} : {className: ''})}>
          <FilterButton className={selectedFilter === 'id_verification' ? 'active btn-filter btn-flat custom-active' : 'btn-filter btn-flat custom-active'} filter='id_verification' onClick={handleFilter}>Id Verification <Caret /></FilterButton>
          <StyledFormGroup>
            <StyledFormControlLabel control={<CssCheckbox />} label='Verified' />
            <StyledFormControlLabel control={<CssCheckbox />} label='Rejected' />
            <StyledFormControlLabel control={<CssCheckbox />} label='Resubmission required' />
            <StyledFormControlLabel control={<CssCheckbox />} label='Manual review required' />
          </StyledFormGroup>
        </StyledPaper>
      </Slide>

      <StyledGrid item xs={12} sx={{ textAlign: 'center', mt: 19.1 }}>
        <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={() => onCancel()}>Cancel</CssButton>
        <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={() => onApply()}>Apply</CssButton>
      </StyledGrid>
    </Box>
  );
};

export default KycFilters;