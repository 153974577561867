import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckboxDefault from 'components/icons/CheckboxDefault';
import CheckboxChecked from 'components/icons/CheckboxChecked';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  height: 40,
  '& .icon': {
    position: 'relative',
    top: 4
  },
  '&.Mui-disabled': {
    '& .icon svg': {
      color: theme.palette.divider
    }
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.primary.light,
    top: 0
  }
}));

const CssButton = ({ ...rest }: any) => {
  return (
    <StyledCheckbox {...rest} icon={<CheckboxDefault />} checkedIcon={<CheckboxChecked />} />
  );
};

export default CssButton;