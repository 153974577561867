import { styled } from '@mui/material/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import Caret from 'components/icons/Caret';

interface AccordionProps {
  title: string
  isExpand?: boolean
  children: any
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: 0,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.primary.main}33`,
  '&.Mui-expanded': {
    margin: 0
  },
  '&:before': {
    backgroundColor: 'transparent'
  },
  '&:last-of-type': {
    borderRadius: 0
  }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginTop: 12,
    marginBottom: 12
  }
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingBottom: 20
}));

const CssAccordion = (props: AccordionProps) => {
  const { title, isExpand, children } = props;

  return (
    <StyledAccordion {...(isExpand && {defaultExpanded: isExpand})}>
      <StyledAccordionSummary
        expandIcon={<Caret />}
      >
        <Typography>{title}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default CssAccordion;