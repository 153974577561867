import Icon from './Icon';

const Close = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.99993 16.0001L16.108 4.00024" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M3.99993 3.99987L16.108 15.9998" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
      </svg>
    </Icon>
  );
};

export default Close;