/* eslint-disable react-hooks/exhaustive-deps */
import { useState, MouseEvent, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, InputAdornment, Stack, Grid, Divider, IconButton, useMediaQuery } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import CssButton from './CssButton';
import CssTitle from './CssTitle';
import CssInputField from './CssInputField';
import FilterButton from './FilterButton';
import ScreenDialog from 'components/ScreenDialog';
import { GlobalContext } from 'context';
import Calendar from './icons/Calendar';

interface FiltersProps {
  from?: string
  fromLabel?: string
  toLabel?: string
}

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    height: 54,
    display: 'flex',
    paddingBottom: 10,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .btn-filter': {
      width: 140,
      textAlign: 'center',
      '&.all': {
        width: 72
      }
    }
  }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    padding: 18,
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiInput-root': {
      fontSize: '1.25rem',
      marginTop: 15
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0 !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0 !important'
    }
  }
}));

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: theme.palette.primary.main
  }
}));

const Filters = (props: FiltersProps) => {
  const { from, fromLabel, toLabel } = props;
  const { setDateRange, filterDates, setFilterDates, setIsFilterCleared, setIsUpdatePage } = useContext(GlobalContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const [valueFrom, setValueFrom] = useState<string | null>(null);
  const [valueTo, setValueTo] = useState<string | null>(null);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [openDateScreen, setOpenDateScreen] = useState(false);
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  const [minDate, setMinDate] = useState('');

  const handleChangeFrom = (newValue: Date | null) => {
    const value = moment(newValue).format('MM.DD.yyyy');
    setValueFrom(value);
    setMinDate(value);

    if (valueTo && value > valueTo) {
      setValueTo(null);
    }
    
    if (!isMobile) {
      setSelectedFilter('all');
    }

    const v = (filterDates && filterDates.toDate) || valueTo;
    setFilterDates({
      fromDate: moment(value).format('yyyy-MM-DD'),
      isFromDateError: false,
      toDate: v ? moment(v).format('yyyy-MM-DD') : '',
      isToDateError: v ? false : true
    });
  };

  const handleChangeTo = (newValue: Date | null) => {
    const value = moment(newValue).format('MM.DD.yyyy');
    setValueTo(value);
    if (!isMobile) {
      setSelectedFilter('all');
    }

    const v = (filterDates && filterDates.fromDate) || valueFrom;
    setFilterDates({
      fromDate: v ? moment(v).format('yyyy-MM-DD') : '',
      isFromDateError: v ? false : true,
      toDate: moment(value).format('yyyy-MM-DD'),
      isToDateError: false
    });
  };

  const handleFilter = (e: MouseEvent<HTMLDivElement>) => {
    const filter = (e.target as HTMLDivElement).dataset.filter;
    setSelectedFilter(filter || 'all');

    if (filter === 'custom') {
      setOpenDateScreen(true);
    } else {
      if (filter && filter !== 'all') {
        setFilterDates({
          fromDate: moment().subtract(parseInt(filter),'d').format('yyyy-MM-DD'),
          toDate: moment().format('yyyy-MM-DD')
        });
        setIsUpdatePage(true);
      } else {
        setFilterDates({ fromDate: '', toDate: '' });
        setIsFilterCleared(true);
        setIsUpdatePage(false);
      }

      setValueFrom(null);
      setValueTo(null);

      if (isMobile) {
        setDateRange('');
      }
    }
  };

  const onApply = (isMobile?: boolean | false) => {
    setFilterDates({ 
      fromDate: moment(valueFrom).format('yyyy-MM-DD'),
      toDate: moment(valueTo).format('yyyy-MM-DD')
    });
    if (isMobile) {
      setDateRange(`${valueFrom} - ${valueTo}`);
      handleDateScreenClose();
    }
    setIsUpdatePage(true);
  };

  const onClear = (isMobile?: boolean | false) => {
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFilterCleared(true);
    setValueFrom(null);
    setValueTo(null);
    setMinDate('');

    if (isMobile) {
      setDateRange('');
    }
    setIsUpdatePage(false);
  };

  const handleDateScreenClose = () => {
    setOpenDateScreen(false);
  };

  const handleOpenFrom = () => setOpenFrom(true);
  const handleCloseFrom = () => setOpenFrom(false);
  const handleOpenTo = () => {
    const v = (filterDates && filterDates.fromDate) || valueFrom;
    setMinDate(moment(v).format('MM.DD.yyyy'));
    setOpenTo(true);
  };
  const handleCloseTo = () => setOpenTo(false);

  useEffect(() => {
    if (filterDates && filterDates.fromDate === '' && filterDates.toDate === '') {
      setValueFrom(null);
      setValueTo(null);
      setMinDate('');
    }
  }, [filterDates]);

  return (
    <StyledBox sx={{ mt: {md: (from !== 'POP_IN_FILTER' ? 4.375 : 1), xs: 3}, mb: {md: 1.25, xs: 0} }} className='filters-wrapper'>
      {(from !== 'POP_IN_FILTER') && 
        <Stack direction='row' spacing={{md: 1.625, xs: 1}} sx={{ display: 'inline-flex' }}>
          <FilterButton className={selectedFilter === 'all' ? 'active btn-filter all' : 'btn-filter all'} filter='all' onClick={handleFilter}>All</FilterButton>
          <FilterButton className={selectedFilter === '7days' ? 'active btn-filter' : 'btn-filter'} filter='7days' onClick={handleFilter}>Last 7 days</FilterButton>
          <FilterButton className={selectedFilter === '30days' ? 'active btn-filter' : 'btn-filter'} filter='30days' onClick={handleFilter}>Last 30 days</FilterButton>

          <FilterButton className={selectedFilter === 'custom' ? 'active btn-filter' : 'btn-filter'} filter='custom' onClick={handleFilter} sx={{ display: { xs: 'block', md: 'none' }}}>Custom</FilterButton>
        </Stack>
      }

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/* DESKTOP */}
        <Box sx={{ display: { xs: 'none', md: from !== 'POP_IN_FILTER' ? 'inline-flex' : 'block' }, ...(from !== 'POP_IN_FILTER' ? {width: '60%', position: 'relative', top: '-6px'} : {width: '73.313%'}) }}>
          {from !== 'POP_IN_FILTER' && <CssTitle variant='h6' sx={{display: 'inline-block', ml: (from !== 'POP_IN_FILTER') ? 5.25 : 0, position: 'relative', top: '5px'}}>Date</CssTitle>}
          {fromLabel && <CssTitle variant={'body1'} sx={{mb: .5}}>{fromLabel}</CssTitle>}
          <DatePicker
            label='From'
            inputFormat='MM.dd.yyyy'
            value={(filterDates && filterDates.fromDate) || valueFrom}
            open={openFrom}
            onOpen={handleOpenFrom}
            onClose={handleCloseFrom}
            onChange={handleChangeFrom}
            renderInput={(params) => <CssInputField
              {...params}
              size='small'
              {...(filterDates && filterDates.isFromDateError) && {error: true}}
              sx={{...(from !== 'POP_IN_FILTER' ? {width: '40%', ml: 1.625} : {width: 'auto', mb: 2.5})}}
              InputProps={{
                placeholder: 'From',
                ...(from !== 'POP_IN_FILTER' && {startAdornment: <StyledInputAdornment position='start'>{ 'From' }</StyledInputAdornment>}),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' sx={{ top: 3 }} onClick={handleOpenFrom}>
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />}
          />
          {toLabel && <CssTitle variant={'body1'} sx={{mb: .5}}>{toLabel}</CssTitle>}
          <DatePicker
            label='To'
            inputFormat='MM.dd.yyyy'
            value={(filterDates && filterDates.toDate) || valueTo}
            open={openTo}
            onOpen={handleOpenTo}
            onClose={handleCloseTo}
            onChange={handleChangeTo}
            minDate={new Date(minDate)}
            renderInput={(params) => <CssInputField
              {...params}
              size='small'
              {...(filterDates && filterDates.isToDateError) && {error: true}}
              sx={{...(from !== 'POP_IN_FILTER' ? {width: '40%', ml: 1.625} : {width: 'auto'})}}
              InputProps={{
                placeholder: '',
                ...(from !== 'POP_IN_FILTER' && {startAdornment: <StyledInputAdornment position='start'>{ 'To' }</StyledInputAdornment>}),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' sx={{ top: 3 }} onClick={handleOpenTo}>
                      <Calendar />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />}
          />
          {from !== 'POP_IN_FILTER' && <>
          <CssButton variant='contained' className='btn-apply' disabled={!valueFrom || !valueTo} onClick={() => onApply()}>Apply</CssButton>
          <CssButton variant='outlined' className='btn-clear' disabled={!valueFrom || !valueTo} onClick={() => onClear()}>Clear</CssButton>
          </>}
        </Box>

        {/* MOBILE */}
        <ScreenDialog open={openDateScreen} onScreenClose={handleDateScreenClose} title='Date Range'>
          <Box sx={{ mt: 7 }}>
            <Grid container>
              <StyledGrid item xs={12}>
                <CssTitle variant='body1'>From</CssTitle>
                <MobileDatePicker
                  label='From'
                  inputFormat='MM.dd.yyyy'
                  value={valueFrom}
                  open={openFrom}
                  onOpen={handleOpenFrom}
                  onClose={handleCloseFrom}
                  onChange={handleChangeFrom}
                  renderInput={(params) => <CssInputField
                    {...params}
                    size='small'
                    variant='standard'
                    placeholder='Select a date'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton edge='end' onClick={handleOpenFrom}>
                            <Calendar />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />}
                />
              </StyledGrid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <StyledGrid item xs={12}>
                <CssTitle variant='body1'>To</CssTitle>
                <MobileDatePicker
                  label='To'
                  inputFormat='MM.dd.yyyy'
                  value={valueTo}
                  open={openTo}
                  onOpen={handleOpenTo}
                  onClose={handleCloseTo}
                  onChange={handleChangeTo}
                  minDate={new Date(minDate)}
                  renderInput={(params) => <CssInputField
                    {...params}
                    size='small'
                    variant='standard'
                    placeholder='Select a date'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton edge='end' onClick={handleOpenTo}>
                            <Calendar />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />}
                />
              </StyledGrid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <StyledGrid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
                <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={() => onClear(true)} disabled={!valueFrom || !valueTo}>Clear</CssButton>
                <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={() => onApply(true)} disabled={!valueFrom || !valueTo}>Apply</CssButton>
              </StyledGrid>
            </Grid>
          </Box>
        </ScreenDialog>
      </LocalizationProvider>
    </StyledBox>
  );
};

export default Filters;