import React, { useEffect, useState, useCallback, useContext, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, IconButton, Button, Select, MenuItem, TextField, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import Plus from 'components/icons/Plus';
import TransparentPlus from 'components/icons/TransparentPlus';
import BackCaret from 'components/icons/BackCaret';
import CssTitle from 'components/CssTitle';
import CssDialog from 'components/CssDialog';
import CssButton from 'components/CssButton';
import SelectDropdown from 'components/icons/SelectDropdown';
import Caret from 'components/icons/Caret';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { GlobalContext } from 'context';
import { isAdminRole, checkPermissions } from 'utils';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  marginTop: 53,
  padding: 0,
  justifyContent: 'left',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: `underline solid ${theme.palette.primary.light} 3px`
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '18px 14px',
  fontSize: '1rem',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: '#04F8DA0D'
  }
}));

const StyledTitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '18px 14px',
  fontSize: '1rem',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: 10,
  '&.count-members': {
    marginRight: 40,
    fontSize: '.75rem',
    paddingTop: 2
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: '#5B5959',
  fontSize: '0.875rem',
  borderWidth: 0,
  width: 'auto',
  '& .MuiSelect-select': {
    padding: '12px 16px'
  },
  '& fieldset': {
    borderWidth: 0,
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline ': {
      borderColor: `${theme.palette.primary.light}`,
    }
  },
  '& .MuiSelect-icon': {
    top: 11,
    right: 0
  }
}));

const StyledRoleSelect = styled(Select)(({ theme }) => ({
  color: '#5B5959',
  fontSize: '0.875rem',
  borderWidth: 0,
  width: '60%',
  height: 48,
  '& .MuiSelect-select': {
    padding: '12px 16px',
  },
  '& fieldset': {
    borderColor: `${theme.palette.divider}`
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline ': {
      border: `1px solid ${theme.palette.primary.light}`,
    }
  },
  '& .MuiSelect-icon': {
    top: 14,
    right: 16
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  lineHeight: '1rem',
  fontSize: '0.875rem',
  fontWeight: 400,
  '& .MuiInputBase-input': {
    padding: '12px 16px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: `${theme.palette.divider}`
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.primary.light}`
  }
}));

const Members = (props: any) => {
  const { brandId, onClickBack } = props;
  const { userRole, userInfo, userPermissions } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { getMembersByBrandApi, getRolesByBrandApi, updateRoleForMemberApi, postMemberByBrandApi } = useRequests();
  const getMembersByBrand = useApi(getMembersByBrandApi);
  const getRolesByBrand = useApi(getRolesByBrandApi);
  const updateRoleForMember = useApi(updateRoleForMemberApi);
  const postMemberByBrand = useApi(postMemberByBrandApi);
  const [members, setMembers] = useState([]);
  const [rolesMenu, setRolesMenu] = useState([]);
  const [openAddMemberModal, setOpenAddMemberModal] = useState<boolean>(false);
  const [memberValue, setMemberValue] = useState<string>('');
  const [roleValue, setRoleValue] = useState<string>('');
  const [isButtonHoverEnabled, setIsButtonHoverEnabled] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const onRoleChange = (event: ChangeEvent<HTMLInputElement>, userId: string | number) => {
    const payload = {
      roleId: event.target.value,
      brandId: brandId,
      userId: userId
    }
    updateRoleForMember.request(payload).then((res) => {
      if (res.status === 200) {
        callMembersAndRolesApi();
        enqueueSnackbar('Member role has been updated successfully.', {
          variant: 'successAlert'
        });
      }
    });
  };

  const callMembersAndRolesApi = useCallback(async () => {
    getMembersByBrand.request(brandId).then((res) => {
      if (res.status === 200) {
        setMembers(res.data);
      }
    });

    getRolesByBrand.request(brandId).then((res) => {
      if (res.status === 200) {
        setRolesMenu(res.data);
      }
    });
  }, [brandId]);

  const onAddMemberModalClose = (event: {},
    reason: 'backdropClick') => {
    setOpenAddMemberModal(false);
    setMemberValue('');
    setRoleValue('');
    setErrorText('');
  };

  const onNewMember = () => {
    setOpenAddMemberModal(true);
  };

  const onMemberChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setMemberValue(event.target.value);
  };

  const onRoleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setRoleValue(event.target.value);
  };

  const onMemberInvite = () => {
    const payload = {
      email: memberValue,
      brandId: brandId, 
      roleId: roleValue
    }
    setErrorText('');
    postMemberByBrand.request(payload).then((res) => {
      if (res.status === 200) {
        setOpenAddMemberModal(false);
        setMemberValue('');
        setRoleValue('');
        callMembersAndRolesApi();
      }
    }).catch(e => {
      setErrorText(e.response.data.error.message);
    });
  };

  const checkRolesPermissions = () => {
    if (!isAdminRole(userRole)) {
      return checkPermissions(userPermissions, 'ROLES_PERMISSION');
    } else {
      return true;
    }
  };

  const toggleButton = (isToggle: boolean) => {
    setIsButtonHoverEnabled(isToggle);
  };

  useEffect(() => {
    callMembersAndRolesApi();
  }, [callMembersAndRolesApi]);

  return (
    <>
      <StyledButton startIcon={<BackCaret />} onClick={onClickBack}>Back</StyledButton>
      <Grid container>
        <Grid item xs={12} justifyContent={'space-between'} sx={{ display: 'flex', mt: 10 }}>
          <CssTitle variant='h6' sx={{fontSize: '1.25rem'}}>
            Members
            {members.length > 0 && <Typography variant='subtitle2' sx={{display: 'inline-block', color: '#5B5959', ml: 1.875}}>{members.length} members</Typography>}
          </CssTitle>
          {checkRolesPermissions() && <Tooltip title={'Add member'}><IconButton sx={{ width: 23, height: 23, mt: 1 }} onClick={onNewMember} onMouseEnter={() => toggleButton(true)} onMouseLeave={() => toggleButton(false)}>
          {isButtonHoverEnabled ? <TransparentPlus /> : <Plus />}
          </IconButton></Tooltip>}
        </Grid>
        <Grid item xs={12} sx={{mt: 5.625}}>
          {members && members.length > 0 && members.map((m: any, i: number) => (userInfo && userInfo.email !== m.email &&
            <StyledBox key={i}>
              <StyledTypography>{m.email}</StyledTypography>
              <StyledSelect
                onChange={(event: any) => {onRoleChange(event, m.userId)}}
                value={(m && m.roleId) || ''}
                IconComponent={Caret}
                {...(!checkRolesPermissions() && {disabled: true})}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .MuiMenuItem-root:hover': {
                        backgroundColor: '#04f8da0d',
                      },
                    },
                  },
                }}
              >
                {rolesMenu.length && rolesMenu.map((item: any, index: number) => (
                  <MenuItem value={item.roleId} key={index}>{item.roleName}</MenuItem>
                ))}
              </StyledSelect>
            </StyledBox>
          ))}
        </Grid>
      </Grid>

      <CssDialog
        disableEscapeKeyDown={true}
        open={openAddMemberModal}
        onScreenClose={onAddMemberModalClose}
        maxWidth={'sm'}
      >
        <StyledTitleBox sx={{mt: 4.375, ml: 5.625, mr: 5.625, pl: 0, pr: 0}}>
          <Typography variant='h6'>Add new member</Typography>
        </StyledTitleBox>

        <Box sx={{pl: 5.625, pr: 5.625, pb: 5.25}}>
          <Grid container>
            <Grid item xs={12} sx={{ justifyContent: 'start', display: 'flex' }}>
              <StyledTextField value={memberValue} onChange={onMemberChangeValue} placeholder='Enter email' {...(errorText && {error: true, helperText: errorText})} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 4, justifyContent: 'space-between', display: 'flex' }}>
              <StyledRoleSelect
                onChange={(event: any) => {onRoleChangeValue(event)}}
                value={roleValue}
                IconComponent={SelectDropdown}
                placeholder='Role'
                displayEmpty
              >
                <MenuItem value={''} disabled>{'Role'}</MenuItem>
                {rolesMenu.length && rolesMenu.map((item: any, index: number) => (
                  <MenuItem value={item.roleId} key={index}>{item.roleName}</MenuItem>
                ))}
              </StyledRoleSelect>
              <CssButton variant='contained' sx={{width: '30%', mr: 0, ml: 1}} className='active' {...((memberValue !== '' && roleValue !== '') ? {} : {disabled: true})} onClick={onMemberInvite}>Add</CssButton>
            </Grid>
          </Grid>
        </Box>
      </CssDialog>
    </>
  );
};

export default Members;