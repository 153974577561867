/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useTheme, styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useNavigate } from 'react-router-dom';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import CssTabs from 'components/CssTabs';
import CssFlexBox from 'components/CssFlexBox';
import Download from 'components/icons/Download';
import Withdrawal from './Withdrawal';
import TravelRules from './TravelRules';
import Settlement from './Settlement';
import Wallet from './Wallet';
import { GlobalContext } from 'context';
import { checkPermissions } from 'utils';

const StyledIconButton = styled(IconButton)({
  top: '-10px',
  '&:hover': {
    backgroundColor: 'transparent'
  }
});

const WalletSnapshotIcon = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const onWalletSnapshotClick = () => {
    navigate('/wallet-snapshot');
  };

  return (
    <StyledIconButton onClick={onWalletSnapshotClick} sx={{position: 'relative', top: 0, width: 30, height: 30, ml: 3}}>
      <AccountBalanceWalletIcon sx={{color:`${theme.palette.primary.light}`}} />
    </StyledIconButton>
  );
}

const Reports = () => {
  const { setFilterDates, setIsFilterApplied, setIsFilterCleared, setReportBatchIds, setIsReportFilterApplied, setIsChangeReportBatchIds, setIsUpdatePage, setSearchQuery, setIsSearchClosed, setEnableSearchField, setIsWithDrawalTabSelected, setIsWalletTabSelected, userPermissions, isWalletTabSelected, walletReportDownloadData, setWalletReportDownloadData } = useContext(GlobalContext);
  const downloadFileHeaders = [
    { label: 'Client ID', key: 'customerId' },
    { label: 'First name', key: 'firstName' },
    { label: 'Last name', key: 'lastName' },
    { label: 'Status updated', key: 'txnDate' },
    { label: 'USD wallet balance', key: 'walletBalance' }
  ];
  const downloadFilename = `reports-wallet-${moment().format('MM-DD-yyyy')}`;

  const clearGlobalContext = () =>  {
    setFilterDates({ fromDate: '', toDate: '' });
    setIsFilterApplied(false);
    setIsFilterCleared(false);
    setReportBatchIds([]);
    setIsReportFilterApplied(false);
    setIsChangeReportBatchIds(false);
    setIsUpdatePage(false);
    setSearchQuery('');
    setIsSearchClosed(true);
    setEnableSearchField(false);
  };

  const handleWithdrawalTab = () => {
    setIsWithDrawalTabSelected(true);
    setIsWalletTabSelected(false);
    setWalletReportDownloadData(null);
    clearGlobalContext();
  };

  const handleTravelRulesTab = () => {
    setIsWithDrawalTabSelected(false);
    setIsWalletTabSelected(false);
    setWalletReportDownloadData(null);
    clearGlobalContext();
  };

  const handleSettlementTab = () => {
    setIsWithDrawalTabSelected(false);
    setIsWalletTabSelected(false);
    setWalletReportDownloadData(null);
    clearGlobalContext();
  };

  const handleWalletTab = () => {
    setIsWithDrawalTabSelected(false);
    setIsWalletTabSelected(true);
    clearGlobalContext();
  };

  const checkReportsPermissions = () => {
    return checkPermissions(userPermissions, 'REPORTS');
  };

  const tabs = [
    {
      label: 'Withdrawal',
      value: 1,
      onTabClick: handleWithdrawalTab,
      tabPanelComponent: <Withdrawal />
    },
    {
      label: 'Travel rules',
      value: 2,
      onTabClick: handleTravelRulesTab,
      tabPanelComponent: <TravelRules />
    },
    {
      label: 'Settlement',
      value: 3,
      onTabClick: handleSettlementTab,
      tabPanelComponent: <Settlement />
    },
    {
      label: 'Wallet',
      value: 4,
      onTabClick: handleWalletTab,
      tabPanelComponent: <Wallet />,
      extraNode: <WalletSnapshotIcon />
    }
  ];

  return (
    <CssContainer>
      <CssFlexBox sx={{justifyContent: 'space-between'}}>
        <CssTitle variant='h4' underline>Reports</CssTitle>
        {isWalletTabSelected && (
          walletReportDownloadData && checkReportsPermissions() ? <CSVLink data={walletReportDownloadData} headers={downloadFileHeaders} filename={downloadFilename} style={{position: 'relative', top: 39, width: 30, height: 30}}>
            <Download />
          </CSVLink> :
          <StyledIconButton disabled style={{position: 'relative', top: 40, width: 30, height: 30}}>
            <Download fill={'#E5E5E5'} />
          </StyledIconButton>
        )}
      </CssFlexBox>
      <CssTabs tabs={tabs} tabBoxSx={{ borderBottom: 1, borderColor: '#5B5959' }} />
    </CssContainer>
  );
};

export default Reports;