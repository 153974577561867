import { MouseEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Stack, IconButton, Tooltip } from '@mui/material';
import _ from 'lodash';
import FilterButton from 'components/FilterButton';
import HistoryDefault from 'components/icons/HistoryDefault';
import HistoryHover from 'components/icons/HistoryHover';
import HistoryClicked from 'components/icons/HistoryClicked';
import { GlobalContext } from 'context';

interface RoleFiltersProps {}

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    height: 54,
    display: 'flex',
    paddingBottom: 10,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .btn-filter': {
      textAlign: 'center'
    }
  }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  height: 40,
  top: 4,
  marginLeft: 40
}));

const RoleFilters = (props: RoleFiltersProps) => {
  const navigate = useNavigate();
  const { dashboardSelectedFilter, setDashboardSelectedFilter, setCheckboxList, userPermissions, setIsPermissionGranted, notificationFilterQuery, setNotificationFilterQuery, highlightNotificationRecords, setHighlightNotificationRecords, searchQuery, setSearchQuery, setRecordCount, setSelectedButtonType } = useContext(GlobalContext);
  const [isButtonHoverEnabled, setIsButtonHoverEnabled] = useState<boolean>(false);
  const [isButtonClickedEnabled, setIsButtonClickedEnabled] = useState<boolean>(false);

  const handleFilter = (e: MouseEvent<HTMLDivElement>) => {
    const filter = (e.target as HTMLDivElement).dataset.filter;
    setDashboardSelectedFilter(filter || 'DASHBOARD_COMP');

    if (filter !== dashboardSelectedFilter && notificationFilterQuery !== '') {
      setRecordCount(null);
      setNotificationFilterQuery('');
      setHighlightNotificationRecords(false);
    }
    if ((filter !== dashboardSelectedFilter) && highlightNotificationRecords && (searchQuery !== '')) {
      setSearchQuery('');
      setHighlightNotificationRecords(false);
    }
  };

  const toggleButton = (isToggle: boolean) => {
    setIsButtonHoverEnabled(isToggle);
  };

  const handleTransactionHistory = () => {
    setIsButtonHoverEnabled(false);
    setIsButtonClickedEnabled(true);
    setCheckboxList(null);
    setSelectedButtonType('DW_TRANSACTIONS');

    if (notificationFilterQuery !== '') {
      setRecordCount(null);
      setNotificationFilterQuery('');
      setHighlightNotificationRecords(false);
    }
    if (highlightNotificationRecords && (searchQuery !== '')) {
      setSearchQuery('');
      setHighlightNotificationRecords(false);
    }

    if (userPermissions.length && _.find(userPermissions, (up) => up.resourceName === 'DASHBOARD_FIN_OPS').isVisible) {
      setIsPermissionGranted(false);
      navigate('/transaction-history');
    } else {
      setIsPermissionGranted(true);
    }
  };

  return (
    <StyledBox sx={{ mt: {md: 3.25, xs: 3}, mb: {md: 0, xs: 0}, display: 'flex' }} justifyContent={'space-between'}>
      <Stack direction='row' spacing={{md: 1.625, xs: 1}} sx={{ display: 'inline-flex' }}>
        <FilterButton className={dashboardSelectedFilter === 'DASHBOARD_COMP' ? 'active btn-filter' : 'btn-filter'} filter='DASHBOARD_COMP' onClick={handleFilter}>Compliance</FilterButton>
        <FilterButton className={dashboardSelectedFilter === 'DASHBOARD_ACC' ? 'active btn-filter' : 'btn-filter'} filter='DASHBOARD_ACC' onClick={handleFilter}sx={{width: 175}}>Account opening</FilterButton>
        <FilterButton className={dashboardSelectedFilter === 'DASHBOARD_FIN_OPS' ? 'active btn-filter' : 'btn-filter'} filter='DASHBOARD_FIN_OPS' onClick={handleFilter}>FinOps</FilterButton>
      </Stack>
      {dashboardSelectedFilter === 'DASHBOARD_FIN_OPS' && <Tooltip title='Daily Reconciliation'><StyledIconButton onMouseEnter={() => toggleButton(true)} onMouseLeave={() => toggleButton(false)} onClick={handleTransactionHistory}>
        {!isButtonClickedEnabled && <>
          {isButtonHoverEnabled ? <HistoryHover /> : <HistoryDefault />}
        </>}
        {isButtonClickedEnabled && <HistoryClicked />}
      </StyledIconButton></Tooltip>}
    </StyledBox>
  );
};

export default RoleFilters;