import Icon from './Icon';

const EditPencil = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.4063 0.96967C11.2725 0.829018 11.0909 0.75 10.9016 0.75C10.7123 0.75 10.5307 0.829018 10.3969 0.96967L1.64826 10.1621C1.55859 10.2563 1.49442 10.3739 1.46237 10.5028L0.510646 14.3313C0.446605 14.5889 0.517355 14.8629 0.696541 15.0511C0.875726 15.2394 1.13648 15.3138 1.38166 15.2465L5.02527 14.2465C5.14797 14.2128 5.25993 14.1454 5.3496 14.0511L14.0982 4.85876C14.377 4.56586 14.377 4.09099 14.0982 3.7981L11.4063 0.96967ZM2.79467 11.0788L10.9016 2.56066L12.584 4.32843L4.47709 12.8466L2.19984 13.4716L2.79467 11.0788Z" fill="currentColor"/>
      <path d="M0.807055 16.75C0.412838 16.75 0.0932617 17.0858 0.0932617 17.5C0.0932617 17.9142 0.412838 18.25 0.807055 18.25H15.0829C15.4771 18.25 15.7967 17.9142 15.7967 17.5C15.7967 17.0858 15.4771 16.75 15.0829 16.75H0.807055Z" fill="currentColor"/>
      </svg>
    </Icon>
  );
};

export default EditPencil;