import Icon from './Icon';

const HistoryDefault = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="21.5" stroke="#000000"/>
      <path d="M16.7227 15.7426C18.2004 14.2599 20.2424 13.3438 22.5 13.3438C27.0046 13.3438 30.6562 16.9954 30.6562 21.5C30.6562 26.0046 27.0046 29.6562 22.5 29.6562C17.9954 29.6562 14.3438 26.0046 14.3438 21.5C14.3438 21.034 13.966 20.6562 13.5 20.6562C13.034 20.6562 12.6562 21.034 12.6562 21.5C12.6562 26.9366 17.0634 31.3438 22.5 31.3438C27.9366 31.3438 32.3438 26.9366 32.3438 21.5C32.3438 16.0634 27.9366 11.6562 22.5 11.6562C19.7758 11.6562 17.3088 12.7639 15.5274 14.5515C15.4988 14.5802 15.4726 14.6105 15.449 14.642L14.0342 13.2273C13.8799 13.0729 13.6502 13.0218 13.445 13.0961C13.2398 13.1704 13.0961 13.3567 13.0763 13.5741L12.6786 17.9493C12.6634 18.1155 12.723 18.2799 12.841 18.398C12.959 18.516 13.1234 18.5755 13.2897 18.5604L17.6649 18.1627C17.8823 18.1429 18.0686 17.9992 18.1429 17.794C18.2172 17.5887 18.166 17.3591 18.0117 17.2047L16.6301 15.8231C16.6624 15.7989 16.6934 15.7721 16.7227 15.7426Z" fill="#000000"/>
      <path d="M23.3438 15.875C23.3438 15.409 22.966 15.0312 22.5 15.0312C22.034 15.0312 21.6562 15.409 21.6562 15.875V21.5C21.6562 21.7909 21.8061 22.0613 22.0528 22.2155L25.4278 24.3249C25.823 24.5718 26.3435 24.4517 26.5905 24.0566C26.8375 23.6614 26.7173 23.1409 26.3222 22.8939L23.3438 21.0324V15.875Z" fill="#000000"/>
      </svg>
    </Icon>
  );
};

export default HistoryDefault;