/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Box } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';
import Notes from './Notes';
import LegalEntity from './LegalEntity';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { processIdVerification } from 'utils';
import CssChip from 'components/CssChip';
import KycStatus from 'components/KycStatus';

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  marginBottom: 5,
  [theme.breakpoints.between('xs', 'sm')]: {
    marginRight: 30,
    width: 100,
    marginBottom: 0
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingRight: 10,
  [theme.breakpoints.between('xs', 'sm')]: {
    paddingRight: 0,
    overflowY: 'visible',
    maxHeight: 'auto',
    marginTop: 25
  }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    display: 'inline-flex',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: 24,
    marginBottom: 32,

    '&.section-grid': {
      display: 'block',
      '& .icon': {
        transform: 'rotate(270deg)'
      }
    }
  }
}));

const StyleTimestampInfo = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontSize: 12,
  color: `#00000073`,
  marginTop: 8
}));

const Kyc = () => {
  const { getClientKycStatusApi } = useRequests();
  const getClientKycStatus = useApi(getClientKycStatusApi);
  const [clientId] = useStorage<number | null>('clientId', null);
  const [kycStatusData, setKycStatusData] = useState<any>(null);
  const [statusClassName, setStatusClassName] = useState<string>('');
  const valid = ['RESUBMISSION_REQUIRED|NEW', 'VERIFIED|VERIFIED', 'MANUAL_REVIEW_REQUIRED|WARNING', 'REJECTED|ALERT', 'PENDING|REVIEW'];

  const formatLastUpdateDate = (date: string) => {
    return moment(date).format('DD MMM YYYY h:mm a') + ' GMT' + moment(date).format('ZZ');
  };

  useEffect(() => {
    getClientKycStatus.request(clientId).then((res) => {
      if (res.status === 200) {
        setStatusClassName(_.split(_.find(valid, (v) => v.includes(res.data.idVerification)), '|')[1]);
        setKycStatusData(res.data);
      }
    });
  }, []);

  return (
    <StyledBox>
      <Grid container>
        <StyledGrid item xs={12} sx={{mb: 4}}>
          <StyledLabel variant='h6'>ID verification</StyledLabel>
          <Box>{kycStatusData?.idVerification ? <CssChip label={processIdVerification(kycStatusData?.idVerification)} className={statusClassName} /> : '-'}</Box>
          {kycStatusData?.idVerification && <StyleTimestampInfo>{formatLastUpdateDate(kycStatusData?.lastUpdatedAt)} | SumSub</StyleTimestampInfo>}
        </StyledGrid>
        <StyledGrid item xs={12} sx={{mb: 4}}>
          <StyledLabel variant='h6'>Screening</StyledLabel>
          <Typography>{kycStatusData?.screening ? <KycStatus statusType='SCREENING' kyc={kycStatusData?.screening} /> : '-'}</Typography>
          {kycStatusData?.screening && <StyleTimestampInfo>{formatLastUpdateDate(kycStatusData?.lastUpdatedAt)} | SumSub</StyleTimestampInfo>}
        </StyledGrid>
        <StyledGrid item xs={12} sx={{mb: 4}}>
          <StyledLabel variant='h6'>PEP</StyledLabel>
          <Typography>{(kycStatusData?.pep === false || kycStatusData?.pep === true) ? (kycStatusData?.pep === true ? 'Yes' : 'No') : '-'}</Typography>
          {(kycStatusData?.pep === false || kycStatusData?.pep === true) && <StyleTimestampInfo>{formatLastUpdateDate(kycStatusData?.lastUpdatedAt)} | SumSub</StyleTimestampInfo>}
        </StyledGrid>
        <StyledGrid item xs={12} sx={{mb: 4}}>
          <StyledLabel variant='h6'>Risk rating</StyledLabel>
          <Box>{kycStatusData?.riskRating >= 0 ? <KycStatus statusType='RISK_RATING' kyc={kycStatusData?.riskRating} /> : '-'}</Box>
          {kycStatusData?.riskRating >= 0 && kycStatusData?.lastUpdatedAt && <StyleTimestampInfo>{formatLastUpdateDate(kycStatusData?.lastUpdatedAt)}</StyleTimestampInfo>}
        </StyledGrid>
        <StyledGrid item xs={12} sx={{mb: 7}}>
          <StyledLabel variant='h6'>Appropriate test score</StyledLabel>
          <Box>{kycStatusData?.appropriatenessTestResult ? <KycStatus statusType='APPROPRIATE_TEST' kyc={kycStatusData?.appropriatenessTestResult} /> : '-'}</Box>
          {kycStatusData?.appropriatenessTestResult && kycStatusData?.appropriatenessSubmittedTime && <StyleTimestampInfo>{formatLastUpdateDate(kycStatusData?.appropriatenessSubmittedTime)}</StyleTimestampInfo>}
        </StyledGrid>
        <StyledGrid item xs={12} sx={{mb: 7}} className={'section-grid'}>
          <Notes />
        </StyledGrid>
        <StyledGrid item xs={12} sx={{mb: 4, borderBottom: '0 !important'}} className={'section-grid'}>
          <LegalEntity />
        </StyledGrid>
      </Grid>
    </StyledBox>
  );
};

export default Kyc;