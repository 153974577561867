
export const ClientOrdersHeaders = {
  columns:  [
    {
      key: 'orderTXNID',
      label: 'Order TXNID',
      dataCellStyles: {
        wordBreak: 'break-all',
        '& span': {
          width: 100,
          display: 'block'
        }
      }
    },
    {
      key: 'orderNumber',
      label: 'Venue Order Number'
    },
    {
      key: 'customerId',
      label: 'Customer ID'
    },
    {
      key: 'venueAcctNumber',
      label: 'Venue acct number'
    },
    {
      key: 'orderType',
      label: 'Order Type'
    },
    {
      key: 'side',
      label: 'Side'
    },
    {
      key: 'symbol',
      label: 'Symbol'
    },
    {
      key: 'amountCash',
      label: 'Amount Cash'
    },
    {
      key: 'quantity',
      label: 'QTY'
    },
    {
      key: 'averageprice',
      label: 'Avg Price'
    },
    {
      key: 'totalAmount',
      label: 'Total Amount'
    },
    {
      key: 'orderStatus',
      label: 'Order Status'
    },
    {
      key: 'statusmessage',
      label: 'Status'
    },
    {
      key: 'priceAtRequest',
      label: 'Price At Request'
    },
    {
      key: 'entryDate',
      label: 'Entry Date',
      withTimestamp: true
    },
    {
      key: 'createdDate',
      label: 'Created Date',
      withTimestamp: true
    },
    {
      key: 'updateddate',
      label: 'Updated Date',
      withTimestamp: true
    },
    {
      key: 'apiRequestId',
      label: 'API Request ID'
    }
  ],
  dateColumns: ['entryDate', 'createdDate', 'updateddate'],
  ellipseColumns: ['statusmessage'],
  equitiesFilters: ['NEW', 'PARTIAL_FILL', 'CANCELED', 'REJECTED', 'FILLED'],
  cryptoFilters: ['WORKING', 'FILLED', 'CANCELED', 'REJECTED'],
  cfdFilters: ['WORKING', 'FILLED', 'CANCELED', 'REJECTED']
};