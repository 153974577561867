/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, MouseEvent, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, FormGroup, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { TransfersHeaders } from 'constants/transfers-table-head-columns';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssAccordion from 'components/CssAccordion';
import CssCheckbox from 'components/CssCheckbox';
import DateTimeFilters from 'components/DateTimeFilters';
import CssButton from 'components/CssButton';
import { Order, setAccordionExpandByFilters, getQueryMap } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import useStorage from 'hooks/storage-hook';
import { GlobalContext } from 'context';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 5,
}));

const Transfers = (props: any) => {
  const { from, isClientTab } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery, isSearchClosed, setIsSearchClosed, setEnableSearchField, isUpdatePage, setIsUpdatePage, setTransactionsDownloadData, selectedButtonType, transfersStatusFilters, setTransfersStatusFilters, finopsTransfersStatusFilters, setFinopsTransfersStatusFilters, paymentFilterDates, setPaymentFilterDates, isFilterCleared, setIsFilterCleared, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay, setTransactionsDownloadParams } = useContext(GlobalContext);
  const { getTransfersApi, getTransfersDownloadApi, getTransfersByClientIdApi, getFinopsTransfersApi } = useRequests();
  const getTransfers = useApi(getTransfersApi);
  const getTransfersDownload = useApi(getTransfersDownloadApi);
  const getTransfersByClientId = useApi(getTransfersByClientIdApi);
  const getFinopsTransfers = useApi(getFinopsTransfersApi);
  const [clientId, setClientId] = useStorage<number | null>('clientId', null);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('txnCreatedAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [openFiltersScreen, setOpenFiltersScreen] = useState(false);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState<boolean>(false);
  const [source, setSource] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [sourceFilters, setSourceFilters] = useState<string[]>([]);
  const [destinationFilters, setDestinationFilters] = useState<string[]>([]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  type DownloadParams = {
    q?: string,
    fromDate?: string,
    toDate?: string,
    statusUpdatedFromDate? : string,
    statusUpdatedToDate? : string,
    filterQuery?: string
  };

  const getTransfersDownloadCall = (params: DownloadParams) => {
    const { q, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery } = params;

    const data = params;
    delete data.filterQuery;
    if (filterQuery) {
      const filters = _.omitBy(getQueryMap(filterQuery), _.isNil);
      setTransactionsDownloadParams({...data, ...filters});
    } else {
      setTransactionsDownloadParams(data);
    }

    getTransfersDownload.request(q, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery).then((res) => {
      if (res.status === 200) {
        setTransactionsDownloadData(res.data);
      }
    });
  };

  const handleResponse = (res: any) => {
    if (res.status === 200) {
      const { totalcount } = res.headers;
      setTotalCount(parseInt(totalcount));
      setData(res.data);

      if (isFilterApplied) {
        enqueueSnackbar('Filters have been applied successfully.', {
          variant: 'successWithUndo',
          onUndo: onClearFilters
        });
        setIsFilterApplied(false);
      }
    }

    if (isUpdatePage) setIsUpdatePage(false);
    if (isFilterCleared) setIsFilterCleared(false);
    if (isFiltersCleared) setIsFiltersCleared(false);
  };

  const handleResponseOnSearch = (res: any) => {
    if (res.status === 200) {
      const { totalcount } = res.headers;
      setTotalCount(parseInt(totalcount));
      setData(res.data);

      if (isFilterApplied) {
        enqueueSnackbar('Filters have been applied successfully.', {
          variant: 'successWithUndo',
          onUndo: onClearFilters
        });
        setIsFilterApplied(false);
      }
    }
    if (isUpdatePage) setIsUpdatePage(false); 
    if (isFilterCleared) setIsFilterCleared(false);
    if (isFiltersCleared) setIsFiltersCleared(false);
  };

  const onLinkClick = (data: any) => {
    setClientId(data.clientId);
    setSearchQuery('');
    setIsSearchClosed(false);
    setEnableSearchField(false);
    navigate('/profile', { state: {...data, from: 'TRANSACTIONS'} });
  };

  const onClearFilters = () => {
    setPaymentFilterDates({ fromDate: '', toDate: '', statusUpdatedFromDate: '',
    statusUpdatedToDate: '' });
    setIsFiltersCleared(true);
    setFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    setSource('');
    setDestination('');
    setSourceFilters([]);
    setDestinationFilters([]);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    if (from === 'FINOPS') {
      setFinopsTransfersStatusFilters((csf: any) => _.map(csf, (c) => {
        c.filters = _.map(c.filters, (f: any) => {
          f.isChecked = false;
          if (c.parentKey === 'status') {
            f.isDisabled = true;
          } else {
            f.isDisabled = false;
          }
          return f;
        });
        return c;
      }));
    } else {
      setTransfersStatusFilters((csf: any) => _.map(csf, (c) => {
        c.filters = _.map(c.filters, (f: any) => {
          f.isChecked = false;
          if (c.parentKey === 'status') {
            f.isDisabled = true;
          } else {
            f.isDisabled = false;
          }
          return f;
        });
        return c;
      }));
    }
    setIsFilterCleared(true);
  };

  const onApply = () => {
    let query = '';
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    setTransactionsDownloadData(null);
    setTransactionsDownloadParams(null);

    if (from === 'FINOPS') {
      _.map(finopsTransfersStatusFilters, (o: any) => {
        _.map(o.filters, (f: any) => {
          if (f.isChecked) {
            query += `&${o.parentKey}=${f.key}`;
            if (f.key === 'WITHDRAWAL_REQUESTED') {
              query += '&status=DW_PENDING&status=DW_APPROVED'
            } else if (f.key === 'APPROVED_BY_PAYMENT_PROVIDER') {
              query += '&status=APPROVED_BY_PAYMENT_PROVIDER&status=ACCEPTED_BY_BANK&status=PENDING_WITH_BANK'
            }
          }
        });
      });
    } else {
      _.map(transfersStatusFilters, (o: any) => {
        _.map(o.filters, (f: any) => {
          if (f.isChecked) {
            query += `&${o.parentKey}=${f.key}`;
            if (f.key === 'WITHDRAWAL_REQUESTED') {
              query += '&status=DW_PENDING&status=DW_APPROVED'
            } else if (f.key === 'APPROVED_BY_PAYMENT_PROVIDER') {
              query += '&status=APPROVED_BY_PAYMENT_PROVIDER&status=ACCEPTED_BY_BANK&status=PENDING_WITH_BANK'
            }
          }
        });
      });
    }

    if (query) {
      setFilterQuery(query);
    }
    if (paymentFilterDates && (paymentFilterDates.fromDate && paymentFilterDates.toDate) || (paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate)) {
      setIsDateFilterApplied(true);
    }
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>, key: string, index: number) => {
    if (key === 'source' && event.target.checked) {
      setSource(event.target.value);
    } else if (key === 'destination' && event.target.checked) {
      setDestination(event.target.value);
    } else if (key === 'source' && !event.target.checked) {
      setSource('');
    } else if (key === 'destination' && !event.target.checked) {
      setDestination('');
    }

    const updatedFilters = _.map(from === 'FINOPS' ? finopsTransfersStatusFilters : transfersStatusFilters, ((tsf: any) => {
      if (tsf.type === 'RADIO') {
        if (tsf.parentKey === key) {
          tsf.filters = tsf.filters.map((f: any) => {
            if (f.key === event.target.value && event.target.checked) {
              f.isChecked = true;

              if (key === 'source') {
                setSourceFilters((sf: string[]) => sf.concat(event.target.value));
              } else if (key === 'destination') {
                setDestinationFilters((df: string[]) => df.concat(event.target.value));
              }
            } else if (f.key === event.target.value && !event.target.checked) {
              f.isChecked = false;

              if (key === 'source') {
                setSourceFilters((sf: string[]) => _.without(sf, event.target.value));
              } else if (key === 'destination') {
                setDestinationFilters((df: string[]) => _.without(df, event.target.value));
              }
            }
            return f;
          });
        }  
      } else {
        tsf.filters = tsf.filters.map((f: any) => {
          if (f.key === event.target.value) {
            f.isChecked = event.target.checked;
          }
          return f;
        });
      }
      return tsf;
    }));

    if (from === 'FINOPS') {
      setFinopsTransfersStatusFilters(updatedFilters);
    } else {
      setTransfersStatusFilters(updatedFilters);
    }
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const convertUtc = (value: string) => {
    return moment(value, 'YYYY-MM-DD h:m:s A').utc(false).format('YYYY-MM-DD HH:mm:ss');
  };

  useEffect(() => {
    const ntfs = _.map(from === 'FINOPS' ? finopsTransfersStatusFilters :  transfersStatusFilters, (tsf) =>  {
      if (tsf.type === 'RADIO') {
        tsf.filters = tsf.filters.map((f: any) => {
          if (tsf.parentKey === 'source' && sourceFilters.length > 0) {
            if ((sourceFilters.includes('EQUITY_DRIVEWEALTH') || sourceFilters.includes('CFD_METATRADER5') || sourceFilters.includes('CRYPTO_METATRADER5')) && f.key === 'WALLET') {
              f.isDisabled = true
            } else if (sourceFilters.includes('WALLET') && (f.key === 'EQUITY_DRIVEWEALTH' || f.key === 'CFD_METATRADER5' || f.key === 'CRYPTO_METATRADER5')) {
              f.isDisabled = true
            }
          } else if (tsf.parentKey === 'source' && destinationFilters.length > 0) {
            if ((destinationFilters.includes('EQUITY_DRIVEWEALTH') || destinationFilters.includes('CFD_METATRADER5') || destinationFilters.includes('CRYPTO_METATRADER5')) && (f.key === 'EQUITY_DRIVEWEALTH' || f.key === 'CFD_METATRADER5' || f.key === 'CRYPTO_METATRADER5')) {
              f.isDisabled = true
            } else if (destinationFilters.includes('WALLET') && f.key === 'WALLET') {
              f.isDisabled = true
            }
          } else if (tsf.parentKey === 'source' && sourceFilters.length === 0) {
            f.isDisabled = false;
          } else if (tsf.parentKey === 'destination' && sourceFilters.length > 0) {
            if ((sourceFilters.includes('EQUITY_DRIVEWEALTH') || sourceFilters.includes('CFD_METATRADER5') || sourceFilters.includes('CRYPTO_METATRADER5')) && (f.key === 'EQUITY_DRIVEWEALTH' || f.key === 'CFD_METATRADER5' || f.key === 'CRYPTO_METATRADER5')) {
              f.isDisabled = true
            } else if (sourceFilters.includes('WALLET') && f.key === 'WALLET') {
              f.isDisabled = true
            }
          } else if (tsf.parentKey === 'destination' && destinationFilters.length > 0) {
            if ((destinationFilters.includes('EQUITY_DRIVEWEALTH') || destinationFilters.includes('CFD_METATRADER5') || destinationFilters.includes('CRYPTO_METATRADER5')) && f.key === 'WALLET') {
              f.isDisabled = true
            } else if (destinationFilters.includes('WALLET') && (f.key === 'EQUITY_DRIVEWEALTH' || f.key === 'CFD_METATRADER5' || f.key === 'CRYPTO_METATRADER5')) {
              f.isDisabled = true
            }
          } else if (tsf.parentKey === 'destination' && destinationFilters.length === 0) {
            f.isDisabled = false;
          }
          return f;
        });
      }

      if (tsf.parentKey === 'status') {
        if (source && destination) {
          tsf.filters = _.map(tsf.filters, (f) => {
            if (source === 'WALLET' && destination === 'EQUITY_DRIVEWEALTH' && ['PENDING', 'APPROVED', 'CREDIT_ERROR'].includes(f.key)) {
              f.isDisabled = false; 
            } else if (source === 'EQUITY_DRIVEWEALTH' && destination === 'WALLET' && ['TRANSFER_INIT', 'DW_CLEARED', 'DW_REJECTED'].includes(f.key)) {
              f.isDisabled = false; 
            } else if (source === 'WALLET' && (destination === 'CFD_METATRADER5' || destination === 'CRYPTO_METATRADER5') && ['TRANSFER_INIT', 'SUCCESSFUL', 'REQUEST_FAILED'].includes(f.key)) {
              f.isDisabled = false; 
            } else if ((source === 'CFD_METATRADER5' || source === 'CRYPTO_METATRADER5') && destination === 'WALLET' && ['TRANSFER_INIT', 'SUCCESSFUL', 'REQUEST_FAILED'].includes(f.key)) {
              f.isDisabled = false; 
            } else {
              f.isDisabled = true;
            }

            return f;
          });
        } else {
          tsf.filters = _.map(tsf.filters, (f) => {
            f.isDisabled = true;
            f.isChecked = false;
            return f;
          });
        }
      }
      return tsf;
    });

    if (from === 'FINOPS') {
      setFinopsTransfersStatusFilters((csf: any) => ntfs);
    } else {
      setTransfersStatusFilters((csf: any) => ntfs);
    }
  }, [source, destination, sourceFilters, destinationFilters]);

  useEffect(() => {
    const newFilters = _.map(from === 'FINOPS' ? finopsTransfersStatusFilters : transfersStatusFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(true);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else if (paymentFilterDates && (paymentFilterDates.fromDate && paymentFilterDates.toDate) || (paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate)) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [transfersStatusFilters, finopsTransfersStatusFilters, isFiltersCleared, paymentFilterDates]);

  useEffect(() => {
    const fromDate = paymentFilterDates.fromDate && paymentFilterDates['fromDateTime'] ? convertUtc(`${paymentFilterDates.fromDate} ${paymentFilterDates['fromDateTime']}`) : (paymentFilterDates.fromDate && !paymentFilterDates['fromDateTime'] ? `${paymentFilterDates.fromDate}` : '');

    const toDate = paymentFilterDates.toDate && paymentFilterDates['toDateTime'] ? convertUtc(`${paymentFilterDates.toDate} ${paymentFilterDates['toDateTime']}`) : (paymentFilterDates.toDate && !paymentFilterDates['toDateTime'] ? `${paymentFilterDates.toDate}` : '');

    const statusUpdatedFromDate = paymentFilterDates['statusUpdatedFromDate'] && paymentFilterDates['statusUpdatedFromDateTime'] ? convertUtc(`${paymentFilterDates['statusUpdatedFromDate']} ${paymentFilterDates['statusUpdatedFromDateTime']}`) : (paymentFilterDates['statusUpdatedFromDate'] && !paymentFilterDates['statusUpdatedFromDateTime'] ? `${paymentFilterDates['statusUpdatedFromDate']}` : '');

    const statusUpdatedToDate = paymentFilterDates['statusUpdatedToDate'] && paymentFilterDates['statusUpdatedToDateTime'] ? convertUtc(`${paymentFilterDates['statusUpdatedToDate']} ${paymentFilterDates['statusUpdatedToDateTime']}`) : (paymentFilterDates['statusUpdatedToDate'] && !paymentFilterDates['statusUpdatedToDateTime'] ? `${paymentFilterDates['statusUpdatedToDate']}` : '');

    if (from === 'TRANSFERS_MAIN' && isClientTab) {
      getTransfersByClientId.request(clientId, page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery).then((res) => {
        handleResponseOnSearch(res);
      });
    } else {
      if (!searchQuery) {
        setPage(isUpdatePage ? 0 : page);

        if (from === 'FINOPS') {
          getFinopsTransfers.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery).then((res) => {
            handleResponse(res);
          });
        } else {
          getTransfers.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery).then((res) => {
            handleResponse(res);
          });
          getTransfersDownloadCall({ q: searchQuery, fromDate: fromDate, toDate: toDate, statusUpdatedFromDate: statusUpdatedFromDate, statusUpdatedToDate: statusUpdatedToDate, filterQuery: filterQuery });
        }
      } else if (searchQuery !== '' && !isSearchClosed && selectedButtonType) {
        setPage(isUpdatePage ? 0 : page);

        if (from === 'FINOPS') {
          getFinopsTransfers.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery, searchQuery).then((res) => {
            handleResponseOnSearch(res);
          });
        } else {
          getTransfers.request(page + 1, rowsPerPage, order === 'asc' ? 1 : -1, orderBy, fromDate, toDate, statusUpdatedFromDate, statusUpdatedToDate, filterQuery, searchQuery).then((res) => {
            handleResponseOnSearch(res);
          });
          getTransfersDownloadCall({ q: searchQuery, fromDate: fromDate, toDate: toDate, statusUpdatedFromDate: statusUpdatedFromDate, statusUpdatedToDate: statusUpdatedToDate, filterQuery: filterQuery });
        }
      }
    }
  }, [page, rowsPerPage, order, orderBy, searchQuery, isSearchClosed, isFilterCleared, filterQuery, isFiltersCleared,  isUpdatePage, isDateFilterApplied]);

  return (
    <>
      <CssTable
        headers={TransfersHeaders}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: {md: 3, xs: 0} }}
        from={from}
        highlightText={searchQuery}
        onLinkClick={onLinkClick}
        isDesktopFilters={true}
        onFilters={handleFilters}
      />
      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          {(transfersStatusFilters || finopsTransfersStatusFilters) && _.map(from === 'FINOPS' ? finopsTransfersStatusFilters : transfersStatusFilters, (item: any, index: number) => (
            <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
              <FormGroup>
                {item.filters.map((filter: any, i: number) => filter && item &&
                  <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} {...(filter.isChecked && {checked: filter.isChecked})} key={i} value={filter.key} {...(filter.isDisabled && {disabled: filter.isDisabled})}  />
                )}
              </FormGroup>
            </CssAccordion>
          ))}

          <CssAccordion title='Transaction date' isExpand={paymentFilterDates && paymentFilterDates.fromDate && paymentFilterDates.toDate}>
            <DateTimeFilters fromLabel={'From'} toLabel={'To'} />
          </CssAccordion>
          <CssAccordion title='Status updated' isExpand={paymentFilterDates && paymentFilterDates.statusUpdatedFromDate && paymentFilterDates.statusUpdatedToDate}>
            <DateTimeFilters fromLabel={'From'} toLabel={'To'} id='statusUpdated' />
          </CssAccordion>

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
    </>
  );
};

export default Transfers;