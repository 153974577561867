import React, { useContext, useEffect, MouseEvent } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { IconButton, Box } from '@mui/material';
import _ from 'lodash';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import Transactions from 'components/Transactions';
import Transfers from 'components/Transfers';
import Referrals from 'components/Referrals';
import Download from 'components/icons/Download';
import CssFlexBox from 'components/CssFlexBox';
import ButtonGroup from 'components/ButtonGroup';
import { GlobalContext } from 'context';
import { checkPermissions } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  top: 36,
  width: 30,
  height: 30,
  border: '1px solid #00000042',
  borderRadius: 30,
  marginLeft: 40
}));

const TransactionsList = () => {
  const { transactionsDownloadData, setTransactionsDownloadData, transactionsDownloadParams, setTransactionsDownloadParams, userPermissions, selectedButtonType, setSelectedButtonType, setTransactionFilterQuery, setDateRange, setSearchQuery, setIsSearchClosed, setFilterDates, setPaymentFilterDates, setIsFilterApplied, setIsFilterCleared, setEnableSearchField, setTransactionsStatusFilters, setGiftsStatusFilters, setIndeterminateCheck, setReportBatchIds, setIsReportBatchIdDeleted, setIsReportFilterApplied, setCheckboxList, setSelectedAllChecks, setDefaultFilterDates, setIsChangeReportBatchIds, setIsWithDrawalTabSelected, setDateSelectedFilter, setIsFilterIconDisplay, setEnableSelectAllCheckbox, setUncheckedTxnIds, setCheckedTxnIds, setFifoReportBatchId, setIsSelectAllCheckboxClicked, setMostAppearedTransactionStatus, setCheckedTransactionStatus, setClientFilterQuery, setAutocompleteIds, setIsAutocompleteIdDeleted, setIsAutocompleteFilterApplied, setIsChangeAutocompleteIds, setTransfersStatusFilters } = useContext(GlobalContext);
  const { postActivityLogApi } = useRequests();
  const postActivityLog = useApi(postActivityLogApi);

  const downloadDepositFileHeaders = [
    { label: 'Beneficiary Account Number', key: 'beneficiaryAccountNumber' },
    { label: 'Beneficiary Name', key: 'beneficiaryName' },
    { label: 'Depositor Name', key: 'clientName' },
    { label: 'Client ID', key: 'clientId' },
    { label: 'Remitter Account Number', key: 'remitterAccountNumber' },
    { label: 'Transaction No', key: 'txnNumber' },
    { label: 'Transaction Type', key: 'type' },
    { label: 'Local Currency', key: 'currency' },
    { label: 'Local Currency Amount', key: 'amount' },
    { label: 'Amount In $', key: 'amountInUSD' },
    { label: 'Exchange Rate', key: 'exchangeRate' },
    { label: 'Bank Transaction Reference', key: 'bankTransactionReference' },
    { label: 'Status', key: 'status' },
    { label: 'Last Status Updated At', key: 'txnStatusLastUpdatedAt' },
    { label: 'Transaction Timestamp', key: 'txnCreatedAt' },
    { label: 'Payment Provider', key: 'paymentProviderName' },
    { label: 'Payment Method', key: 'paymentMethod' }
  ];

  const downloadWithdrawalFileHeaders = [
    { label: 'Beneficiary Account Number', key: 'beneficiaryAccountNumber' },
    { label: 'SWIFT Code', key: 'swiftCode' },
    { label: 'Beneficiary Name', key: 'beneficiaryName' },
    { label: 'Client Name', key: 'clientName' },
    { label: 'Client ID', key: 'clientId' },
    { label: 'Remitter Account Number', key: 'remitterAccountNumber' },
    { label: 'Transaction No', key: 'txnNumber' },
    { label: 'Transaction Type', key: 'type' },
    { label: 'Local Currency', key: 'currency' },
    { label: 'Local Currency Amount', key: 'amount' },
    { label: 'Amount In $', key: 'amountInUSD' },
    { label: 'Exchange Rate', key: 'exchangeRate' },
    { label: 'Status', key: 'status' },
    { label: 'Last Status Updated At', key: 'txnStatusLastUpdatedAt' },
    { label: 'Transaction Timestamp', key: 'txnCreatedAt' },
    { label: 'Payment Method', key: 'paymentMethod' }
  ];

  const downloadTransferFileHeaders = [
    { label: 'Transfer ID', key: 'transferId' },
    { label: 'Date of Transfer', key: 'transactionDate' },
    { label: 'Status Updated Time', key: 'txnStatusLastUpdatedAt' },
    { label: 'Source', key: 'source' },
    { label: 'Destination', key: 'destination' },
    { label: 'USD Amount', key: 'amount' },
    { label: 'Client ID', key: 'clientId' },
    { label: 'Client Name', key: 'clientName' },
    { label: 'Transfer Status', key: 'transferStatus' }
  ];

  const downloadGiftsFileHeaders = [
    { label: 'Impact Reward ID', key: 'impactRewardId' },
    { label: 'Txn Number', key: 'txnNumber' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Status Updated Time', key: 'txnStatusLastUpdatedAt' },
    { label: 'Status', key: 'status' },
    { label: 'Reason', key: 'rewardType' },
    { label: 'USD Amount', key: 'amountInUSD' },
    { label: 'Client ID', key: 'customerId' },
    { label: 'Client Name', key: 'name' },
    { label: 'Total Approved Gifts', key: 'totalApprovedGifts' },
    { label: 'Max Approved Gifts', key: 'maxApproveGifts' }
  ];

  const downloadFilename = `payments-${moment().format('MM-DD-yyyy')}`;

  const checkTransactionPermissions = () => {
    return checkPermissions(userPermissions, 'PAYMENTS');
  };

  const triggerActivityLog = () => {
    let payload = {
      page: 'PAYMENTS',
      action: 'DOWNLOAD'
    };
    if (transactionsDownloadParams) {
      payload = {...payload, ...transactionsDownloadParams};
    }
    postActivityLog.request(_.omitBy(payload, _.isEmpty));
  };

  const onPaymentsType = (e: MouseEvent<HTMLDivElement>) => {
    const type = (e.target as HTMLDivElement).dataset.filter;
    setSelectedButtonType(type);
    setTransactionsDownloadData(null);
    setTransactionsDownloadParams(null);
    setTransactionFilterQuery('');
    setDateRange('');
    setSearchQuery('');
    setIsSearchClosed(true);
    setFilterDates({ fromDate: '', toDate: ''});
    setPaymentFilterDates({ fromDate: '', toDate: '', statusUpdatedFromDate: '',
    statusUpdatedToDate: '' });
    setIsFilterApplied(false);
    setIsFilterCleared(false);
    setEnableSearchField(false);
    setTransactionsStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setGiftsStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return c;
    }));
    setIndeterminateCheck(false);
    setReportBatchIds([]);
    setIsReportBatchIdDeleted(false);
    setIsReportFilterApplied(false);
    setCheckboxList(null);
    setSelectedAllChecks(false);
    setDefaultFilterDates({ fromDate: '', toDate: ''});
    setIsChangeReportBatchIds(false);
    setIsWithDrawalTabSelected(false);
    setDateSelectedFilter('all');
    setIsFilterIconDisplay(false);
    setEnableSelectAllCheckbox(false);
    setUncheckedTxnIds([]);
    setCheckedTxnIds([]);
    setFifoReportBatchId('');
    setIsSelectAllCheckboxClicked(false);
    setMostAppearedTransactionStatus('');
    setCheckedTransactionStatus('');
    setClientFilterQuery('');
    setAutocompleteIds([]);
    setIsAutocompleteIdDeleted(false);
    setIsAutocompleteFilterApplied(false);
    setIsChangeAutocompleteIds(false);
    setTransfersStatusFilters((csf: any) => _.map(csf, (c) => {
      c.filters = _.map(c.filters, (f: any) => {
        f.isChecked = false;
        if (c.parentKey === 'status') {
          f.isDisabled = true;
        } else {
          f.isDisabled = false;
        }
        return f;
      });
      return c;
    }));
  };

  const getTransactionsView = () => {
    if (selectedButtonType === 'TRANSFERS_MAIN' ) {
      return <Transfers from={'TRANSFERS_MAIN'} />;
    } else if (selectedButtonType === 'REFERRAL_GIFTS') {
      return <Referrals from={'REFERRAL_GIFTS'} />
    } else {
      return <Transactions from={'TRANSACTIONS'} />;
    }
  };

  const getFileHeaders = () => {
    if (selectedButtonType === 'TRANSFERS_MAIN') {
      return downloadTransferFileHeaders;
    } else if (selectedButtonType === 'DEPOSIT') {
      return downloadDepositFileHeaders;
    } else if (selectedButtonType === 'REFERRAL_GIFTS') {
      return downloadGiftsFileHeaders;
    } else {
      return downloadWithdrawalFileHeaders;
    }
  };

  useEffect(() => {
    setSelectedButtonType('DEPOSIT');
  }, []);

  return (
    <CssContainer>
      <CssFlexBox sx={{justifyContent: 'space-between'}}>
        <CssTitle variant='h4' underline>Payments</CssTitle>
        <Box display={'flex'}>
          <ButtonGroup data={[
            {
              label: 'Deposits',
              type: 'DEPOSIT',
              onClick: onPaymentsType
            },
            {
              label: 'Withdrawals',
              type: 'WITHDRAW',
              onClick: onPaymentsType
            },
            {
              label: 'Transfers',
              type: 'TRANSFERS_MAIN',
              onClick: onPaymentsType
            },
            {
              label: 'Gifts',
              type: 'REFERRAL_GIFTS',
              onClick: onPaymentsType
            }
          ]} />
          {transactionsDownloadData && checkTransactionPermissions() ? 
            <CSVLink data={transactionsDownloadData || ''} headers={getFileHeaders()} filename={downloadFilename} style={{position: 'relative', top: 36, width: 30, height: 30, border: '1px solid #000', borderRadius: 30, marginLeft: 40}} onClick={triggerActivityLog}>
              <Download className={'download'} />
            </CSVLink> : 
            <StyledIconButton disabled>
              <Download fill={'#E5E5E5'} className={'download-disabled'} />
            </StyledIconButton>
          }
        </Box>
      </CssFlexBox>
      {getTransactionsView()}
    </CssContainer>
  );
};

export default TransactionsList;