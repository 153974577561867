import Icon from './Icon';

const Edit = ({ className, direction, fill, sx }: any) => {
  return (
    <Icon className={className} direction={direction} sx={sx}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="11.8734" cy="12" rx="11.8734" ry="12" fill={fill || "#F7F3F3"} />
      <path d="M15.0193 6C15.1188 6 15.2141 6.03951 15.2845 6.10983L17.4058 8.23116C17.5522 8.3776 17.5522 8.61504 17.4058 8.76149L10.5115 15.6558C10.4644 15.7029 10.4056 15.7366 10.3411 15.7534L7.46978 16.5034C7.34097 16.5371 7.20398 16.4999 7.10984 16.4058C7.01571 16.3116 6.97854 16.1746 7.01218 16.0458L7.76218 13.1745C7.77902 13.1101 7.81273 13.0512 7.85984 13.0041L14.7541 6.10983C14.8245 6.03951 14.9198 6 15.0193 6Z" fill="white"/>
      <rect x="11.6133" y="6" width="8" height="1.46391" transform="rotate(45.2447 11.6133 6)" fill={fill || "#F7F3F3"} />
      </svg>
    </Icon>
  );
};

export default Edit;