import jwt_decode from 'jwt-decode';
import keyCloakOptions from '../keycloak.json';
import CustomKeycloak from './CustomKeycloak';
import { getExactRole } from 'utils';

export const orgRealm = `${process.env.REACT_APP_ORG_REALM}`;

const keyCloakInitOptions = {
  ...keyCloakOptions,
  realm: orgRealm,
  url: process.env.REACT_APP_KEYCLOAK_AUTH,
  resource: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`
};

export const _kc = new CustomKeycloak(keyCloakInitOptions);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: any) => {
  _kc.init({
    onLoad: 'login-required',
    enableLogging: true
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();

      // TODO: Need to remove in future, for testing purpose only
      localStorage.setItem('react-token', (_kc && _kc.token ? _kc.token : ''));
      localStorage.setItem('react-refresh-token', (_kc && _kc.refreshToken ? _kc.refreshToken : ''));
    })
    .catch((error) => {
      console.error(error);
      onAuthenticatedCallback({ isError: true, error });
    });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getRefreshToken = () => _kc.refreshToken;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: any) =>
  _kc.customUpdateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles: any) => roles.some((role: any) => _kc.hasRealmRole(role));

const getProfile = async () => {
  let profile: any = null;

  profile = await _kc.loadUserProfile()
    .then((p) => p).catch(() => {
      console.log('Failed to load user profile');
    });

  return profile;
};

const getInfo = async () => {
  let profile: any = null;

  profile = await _kc.loadUserInfo()
    .then((p) => p).catch(() => {
      console.log('Failed to load user info');
    });

  return profile;
};

const onAuthSuccess = () => _kc.onAuthSuccess && _kc.onAuthSuccess();

const onAuthRefreshSuccess = () => _kc.onAuthRefreshSuccess && _kc.onAuthRefreshSuccess();

const tokenDecode = () => _kc.token && jwt_decode(_kc.token);

const getRole = () => {
  const decode: any = tokenDecode();

  return getExactRole(decode.realm_access.roles)
};

const Auth = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getRefreshToken,
  updateToken,
  getUsername,
  hasRole,
  getProfile,
  getInfo,
  onAuthSuccess,
  onAuthRefreshSuccess,
  tokenDecode,
  getRole
};

export default Auth;