import Icon from './Icon';

const UpArrow = ({ className, direction, sx, fill }: any) => {
  return (
    <Icon className={className} direction={direction} sx={sx}>
      <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.25 3.31066L1.53033 6.03033C1.23744 6.32322 0.762563 6.32322 0.46967 6.03033C0.176777 5.73744 0.176777 5.26256 0.46967 4.96967L4.46967 0.96967C4.76256 0.676776 5.23744 0.676776 5.53033 0.96967L9.53033 4.96967C9.82322 5.26256 9.82322 5.73744 9.53033 6.03033C9.23744 6.32322 8.76256 6.32322 8.46967 6.03033L5.75 3.31066L5.75 13C5.75 13.4142 5.41421 13.75 5 13.75C4.58579 13.75 4.25 13.4142 4.25 13L4.25 3.31066Z" fill={fill || "#02B9A2"} />
      </svg>
    </Icon>
  );
};

export default UpArrow;