import { Routes as Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Dashboard from 'modules/Dashboard';
import Profile from 'modules/Profile';
import Clients from 'modules/Clients';
import Transactions from 'modules/Transactions';
import Markets from 'modules/Markets';
import MarketProfile from 'modules/MarketProfile';
import MarketCfdProfile from 'modules/MarketCfdProfile';
import Reports from 'modules/Reports';
import ExchangeHolidays from 'modules/ExchangeHolidays';
import Orders from 'modules/Orders';
import TransactionsHistory from 'modules/TransactionHistory';
import Admin from 'modules/Admin';
import WalletSnapshot from 'modules/WalletSnapshot';

const Routes = ({ isAdmin }: any) => {
  return (
    <Switch>
      <Route element={<PrivateRoute />}>
        <Route path='/' element={isAdmin ? <Admin /> : <Dashboard />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/clients' element={<Clients />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/profile' element={<Profile />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/transactions' element={<Transactions />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/markets' element={<Markets />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/market/profile' element={<MarketProfile />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/market/cfd/profile' element={<MarketCfdProfile />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/reports' element={<Reports />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/exchange/holidays' element={<ExchangeHolidays />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/orders' element={<Orders />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/transaction-history' element={<TransactionsHistory />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/wallet-snapshot' element={<WalletSnapshot />} />
      </Route>
    </Switch>
  );
};

export default Routes;