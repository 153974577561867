import { useContext } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { GlobalContext } from 'context';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const useFirebase = () => {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  const { setFcmToken } = useContext(GlobalContext);

  const requestForToken = () => {
    return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY })
      .then((currentToken: any) => {
        if (currentToken) {
          setFcmToken(currentToken);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err: any) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  const onMessageListener = () => {
    return new Promise((resolve) => {
      onMessage(messaging, (payload: any) => {
        console.log("payload", payload)
        resolve(payload);
      });
    });
  };

  return {
    requestForToken,
    onMessageListener
  };
};

export default useFirebase;