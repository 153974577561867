import Icon from './Icon';

const SelectDropdown = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.84092 0.595771C10.1216 0.87646 10.1216 1.33155 9.84092 1.61224L6.00758 5.44557C5.72689 5.72626 5.27181 5.72626 4.99112 5.44557L1.15778 1.61224C0.877093 1.33155 0.877093 0.876461 1.15778 0.595771C1.43847 0.315082 1.89356 0.315082 2.17425 0.595771L5.49935 3.92087L8.82445 0.595771C9.10514 0.315082 9.56023 0.315082 9.84092 0.595771Z" fill="#E5E5E5"/>
      </svg>
    </Icon>
  );
};

export default SelectDropdown;