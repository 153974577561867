import React, { MouseEvent, useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import CssContainer from 'components/CssContainer';
import Transactions from 'components/Transactions';
import ButtonGroup from 'components/ButtonGroup';
import Transfers from 'components/Transfers';
import Referrals from 'components/Referrals';
import { GlobalContext } from 'context';

const PaymentsTab = ({ state }: any) => {
  const { selectedButtonType, setSelectedButtonType } = useContext(GlobalContext);

  const onPaymentsType = (e: MouseEvent<HTMLDivElement>) => {
    const type = (e.target as HTMLDivElement).dataset.filter;
    setSelectedButtonType(type);
  };

  const getTransactionsView = () => {
    if (selectedButtonType === 'TRANSFERS_MAIN' ) {
      return <Transfers from={'TRANSFERS_MAIN'} isClientTab={true} />;
    } else if (selectedButtonType === 'REFERRAL_GIFTS') {
      return <Referrals from='TRANSFERS' />
    } else {
      return <Transactions from={'TRANSFERS'} />;
    }
  };

  useEffect(() => {
    if (!selectedButtonType) {
      setSelectedButtonType('DEPOSIT');
    }
  }, [selectedButtonType]);

  return (
    <CssContainer sx={{p: '0 !important', mt: 0}}>
      <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
        <ButtonGroup variant='light' data={[
          {
            label: 'Deposits',
            type: 'DEPOSIT',
            onClick: onPaymentsType
          },
          {
            label: 'Withdrawals',
            type: 'WITHDRAW',
            onClick: onPaymentsType
          },
          {
            label: 'Transfers',
            type: 'TRANSFERS_MAIN',
            onClick: onPaymentsType
          },
          {
            label: 'Gifts',
            type: 'REFERRAL_GIFTS',
            onClick: onPaymentsType
          }
        ]} />
      </Box>
      {getTransactionsView()}
    </CssContainer>
  );
};

export default PaymentsTab;