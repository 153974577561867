import { processAccountKycStatus, processAccountStatus } from 'utils';

export const ClientAccountDetails = [
  {
    section: "cashEquities",
    title: "Cash equities",
  },
  {
    name: "tradingVenue",
    label: "Trading venue",
    value: "",
    pickValuesFrom: ["equity.tradingVenue"]
  },
  {
    name: "accKYCStatus",
    label: "Acc KYC status",
    value: "",
    isChip: true,
    valueSx: {
      padding: '7px 0'
    },
    pickValuesFrom: ["equity.accKYCStatus"],
    processStatus: processAccountKycStatus
  },
  {
    name: "dateOfApproval",
    label: "Date of approval",
    value: "",
    isDate: true,
    pickValuesFrom: ["equity.dateOfApproval"]
  },
  {
    name: "accountNumber",
    label: "Account no.",
    value: "",
    pickValuesFrom: ["equity.accountNumber"]
  },
  {
    name: "status",
    label: "Account status",
    value: "",
    isChip: true,
    valueSx: {
      padding: '7px 0'
    },
    pickValuesFrom: ["equity.status"],
    processStatus: processAccountStatus
  },
  {
    name: "legalEntity",
    label: "Legal entity",
    value: "",
    pickValuesFrom: ["equity.legalEntity"]
  },
  {
    name: "type",
    label: "Type",
    value: "",
    pickValuesFrom: ["equity.type"]
  },
  {
    name: "currency",
    label: "Currency",
    value: "",
    pickValuesFrom: ["equity.currency"]
  },
  {
    name: "availableFunds",
    label: "Buying power",
    value: "",
    currency: '$',
    pickValuesFrom: ["equity.availableFunds"]
  },
  {
    name: "invested",
    label: "Invested amount",
    value: "",
    currency: '$',
    pickValuesFrom: ["equity.invested"]
  },
  {
    name: "unrealizedPL",
    label: "PnL $ amount",
    value: "",
    currency: '$',
    pickValuesFrom: ["equity.unrealizedPL"]
  },
  {
    name: "unrealizedPLPercentage",
    label: "% PnL",
    value: "",
    isSign: true,
    pickValuesFrom: ["equity.unrealizedPLPercentage"]
  },
  {
    name: "portfolioValue",
    label: "Portfolio value",
    value: "",
    currency: '$',
    pickValuesFrom: ["equity.portfolioValue"]
  },
  {
    name: "availableToWithdraw",
    label: "Available to withdraw",
    value: "",
    currency: '$',
    pickValuesFrom: ["equity.availableToWithdraw"]
  },
  {
    section: "crypto",
    title: "Crypto"
  },
  {
    name: "tradingVenue",
    label: "Trading venue",
    value: "",
    pickValuesFrom: ["crypto.tradingVenue"]
  },
  {
    name: "dateUnlocked",
    label: "Date unlocked",
    value: "",
    isDate: true,
    pickValuesFrom: ["crypto.dateUnlocked"]
  },
  {
    name: "accountNumber",
    label: "Account number",
    value: "",
    pickValuesFrom: ["crypto.accountNumber"]
  },
  {
    name: "status",
    label: "Acc status",
    value: "",
    pickValuesFrom: ["crypto.status"],
    processStatus: processAccountKycStatus
  },
  {
    name: "legalEntity",
    label: "Legal entity",
    value: "",
    pickValuesFrom: ["crypto.legalEntity"]
  },
  {
    name: "currency",
    label: "Currency",
    value: "",
    pickValuesFrom: ["crypto.currency"]
  },
  {
    name: "balance",
    label: "Balance",
    value: "",
    pickValuesFrom: ["crypto.balance"]
  },
  {
    name: "equity",
    label: "Equity",
    value: "",
    pickValuesFrom: ["crypto.equity"]
  },
  {
    name: "margin",
    label: "Margin",
    value: "",
    pickValuesFrom: ["crypto.margin"]
  },
  {
    name: "freeMargin",
    label: "Free margin",
    value: "",
    pickValuesFrom: ["crypto.freeMargin"]
  },
  {
    name: "marginLevel",
    label: "Margin level",
    value: "",
    pickValuesFrom: ["crypto.marginLevel"]
  },
  {
    name: "marginCallLevel",
    label: "Margin call level",
    value: "",
    pickValuesFrom: ["crypto.marginCallLevel"]
  },
  {
    name: "marginCloseOutLevel",
    label: "Margin close out level",
    value: "",
    pickValuesFrom: ["crypto.marginCloseOutLevel"]
  },
  {
    name: "unrealizedPL",
    label: "Unrealized P&L",
    value: "",
    pickValuesFrom: ["crypto.unrealizedPL"]
  },
  {
    section: "cfd",
    title: "CFD"
  },
  {
    name: "tradingVenue",
    label: "Trading venue",
    value: "",
    pickValuesFrom: ["cfd.tradingVenue"]
  },
  {
    name: "dateUnlocked",
    label: "Date unlocked",
    value: "",
    isDate: true,
    pickValuesFrom: ["cfd.dateUnlocked"]
  },
  {
    name: "accountNumber",
    label: "Account number",
    value: "",
    pickValuesFrom: ["cfd.accountNumber"]
  },
  {
    name: "status",
    label: "Acc status",
    value: "",
    pickValuesFrom: ["cfd.status"],
    processStatus: processAccountKycStatus
  },
  {
    name: "legalEntity",
    label: "Legal entity",
    value: "",
    pickValuesFrom: ["cfd.legalEntity"]
  },
  {
    name: "currency",
    label: "Currency",
    value: "",
    pickValuesFrom: ["cfd.currency"]
  },
  {
    name: "balance",
    label: "Balance",
    value: "",
    pickValuesFrom: ["cfd.balance"]
  },
  {
    name: "equity",
    label: "Equity",
    value: "",
    pickValuesFrom: ["cfd.equity"]
  },
  {
    name: "margin",
    label: "Margin",
    value: "",
    pickValuesFrom: ["cfd.margin"]
  },
  {
    name: "freeMargin",
    label: "Free margin",
    value: "",
    pickValuesFrom: ["cfd.freeMargin"]
  },
  {
    name: "marginLevel",
    label: "Margin level",
    value: "",
    pickValuesFrom: ["cfd.marginLevel"]
  },
  {
    name: "marginCallLevel",
    label: "Margin call level",
    value: "",
    pickValuesFrom: ["cfd.marginCallLevel"]
  },
  {
    name: "marginCloseOutLevel",
    label: "Margin close out level",
    value: "",
    pickValuesFrom: ["cfd.marginCloseOutLevel"]
  },
  {
    name: "unrealizedPL",
    label: "Unrealized P&L",
    value: "",
    pickValuesFrom: ["cfd.unrealizedPL"]
  }
];