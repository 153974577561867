/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography, Grid, IconButton, useMediaQuery, DialogContent } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Search from './Search';
import CssContainer from './CssContainer';
import Auth from 'services/auth';
import ScreenDialog from './ScreenDialog';
import CssDrawer from './CssDrawer';
import CssButton from './CssButton';
import CssDivider from './CssDivider';
import CssAvatar from './CssAvatar';
import CssChip from './CssChip';
import LinkModule from './LinkModule';
import CssDialog from './CssDialog';
import NotificationsList from './NotificationsList';
import Roles from 'modules/Admin/components/Roles';
import Members from 'modules/Admin/components/Members';
import Hamburger from './icons/Hamburger';
import BrandNemo from './icons/BrandNemo';
import Notification from './icons/Notification';
import ActiveNotification from './icons/ActiveNotification';
import { GlobalContext } from 'context';
import { getFirstLetters, checkPermissions } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';

const StyledOuterBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  height: 76,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  padding: '16px 4% 16px 9.5%',
  [theme.breakpoints.down('xl')]: {
    padding: `16px 4% 16px 11%`
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
 
}));

const StyledIconButton = styled(IconButton)({
  height: 28,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&.notification': {
    marginRight: 20,
    top: 3,
    width: 25
  }
});

const StyledDialogContent = styled(DialogContent)({
  padding: 100,
  paddingTop: 0
});

const StyledBoxNotification = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  paddingBottom: 14
}));

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { userInfo, userRole, setDashboardSelectedFilter, searchQuery, setSearchQuery, notificationFilterQuery, setNotificationFilterQuery, reportTabValue, setReportTabValue, setHighlightNotificationRecords, userPermissions, setRecordCount } = useContext(GlobalContext);
  const { getBrandsApi, getNotificationsUnreadCountApi } = useRequests();
  const getBrands = useApi(getBrandsApi);
  const getNotificationsUnreadCount = useApi(getNotificationsUnreadCountApi);
  const [openProfileScreen, setOpenProfileScreen] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isPermissionGranted, setIsPermissionGranted] = useState<boolean>(false);
  const [openRolesModal, setOpenRolesModal] = useState<boolean>(false);
  const [showComponent, setShowComponent] = useState<string>('ROLES');
  const [brandId, setBrandId] = useState<string>('');
  const [openNotificationModal, setOpenNotificationModal] = useState<boolean>(false);
  const [hasUnreadCount, setHasUnreadCount] = useState<boolean>(false);

  const handleLogout = () => {
    Auth.doLogout();
  };

  const onProfileScreenOpen = () => {
    setOpenProfileScreen(true);
  };

  const onProfileScreenClose = () => {
    setOpenProfileScreen(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const getUserName = () => {
    if (userInfo && userInfo.firstName && userInfo.lastName) {
      return `${userInfo.firstName} ${userInfo.lastName}`;
    } else if (userInfo && userInfo.email) {
      return `${userInfo.email}`;
    } else {
      return '';
    }
  };

  const handleSwitch = (name: string) => {
    setShowComponent(name);
    setOpenRolesModal(true);
  };

  const handleBack = (name: string) => {
    setOpenRolesModal(false);
    setShowComponent(name);
  };

  const onRoleModalClose = (event: {},
    reason: 'backdropClick') => {
    setOpenRolesModal(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotificationModal(!openNotificationModal);
  };

  const onNotificationScreenClose = () => {
    setOpenNotificationModal(false);
  };

  const onNotificationClicked = (mPayload: any) => {
    let route = '';
    onNotificationScreenClose();
    if (['DASHBOARD_COMP', 'DASHBOARD_ACC', 'DASHBOARD_FIN_OPS'].includes(mPayload.resourceName)) {
      setHighlightNotificationRecords(true);
      route = '/';
      setDashboardSelectedFilter(mPayload.resourceName);

      if (reportTabValue) {
        setReportTabValue(null);
      }

      if (mPayload.resourceFilter.includes('withdrawals')) {
        let query = `&type=WITHDRAW&batchNo=${mPayload.resourceFilter}`;
        if (searchQuery !== '') {
          setSearchQuery('');
        }

        if (mPayload.messagePayload.status) {
          query = query + `&status=${mPayload.messagePayload.status}`;
        }
        setRecordCount(mPayload.messagePayload.count || null);
        setNotificationFilterQuery(query);
      } else if (mPayload.resourceFilter) {
        if (notificationFilterQuery !== '') {
          setRecordCount(null);
          setNotificationFilterQuery('');
        }
        setSearchQuery(mPayload.resourceFilter);
      }
    } else if (['REPORTS_TRAVEL_RULES'].includes(mPayload.resourceName)) {
      route = '/reports';
      setReportTabValue('2');
    }
    if (route) {
      navigate(route, {state: {isNotification: true}});
    }
  };

  const checkNotificationPermissions = () => {
    return checkPermissions(userPermissions, 'NOTIFICATIONS', true);
  };

  useEffect(() => {
    getBrands.request().then((res) => {
      if (res.status === 200) {
        setBrandId(res.data[0].id);
      }
    });
  }, []);

  useEffect(() => {
    if (userPermissions.length && _.find(userPermissions, (up) => up.resourceName === 'ROLES_PERMISSION').isVisible) {
      setIsPermissionGranted(true);
    } else {
      setIsPermissionGranted(false);
    }

    if (checkNotificationPermissions()) {
      getNotificationsUnreadCount.request().then((res) => {
        if (res.status === 200) {
          if (typeof res.data.count === 'string' && res.data.count === '0') {
            setHasUnreadCount(false);
          } else if (res.data.count && (res.data.count !== '0' || res.data.count !== 0)) {
             setHasUnreadCount(true);
           } else {
            setHasUnreadCount(false);
          }
        }
      });
    }
  }, [userPermissions]);

  return (
    <StyledOuterBox>
    <StyledBox>
      <CssContainer sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', margin: 0, padding: '0 !important' }}>
        {isMobile && <StyledIconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
        >
          <Hamburger />
        </StyledIconButton>}
        <Search />
        {checkNotificationPermissions() && <StyledIconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleNotificationsOpen}
          edge='start'
          className='notification'
        >
          {hasUnreadCount ? <ActiveNotification /> : <Notification />}
        </StyledIconButton>}
        <CssAvatar onClick={onProfileScreenOpen} sx={{fontSize: '0.9375rem'}}>{getFirstLetters(getUserName())}</CssAvatar>
      </CssContainer>
      <ScreenDialog 
        open={openProfileScreen}
        onScreenClose={onProfileScreenClose}
        title='Back'
        className={'comments-dialog'}
      >
        <Box sx={{mt: 4.375, pt: 4.375, pb: 4.375, pl: {xs: 0, md: 4.375}, pr: {xs: 0, md: 4.375}}}>
          <Grid container>
            <Grid item xs={12} justifyContent={'end'} sx={{ display: 'flex', mb: 3 }}>
              <BrandNemo />
            </Grid>
            <Grid item md={2.5} sx={{ pl: {xs: 2, md: 0}}}>
              <CssAvatar onClick={onProfileScreenOpen} sx={{ width: 54, height: 54 }}>{getFirstLetters(getUserName())}</CssAvatar>
            </Grid>
            <Grid item md={9.5} sx={{pt: .5}}>
              {userInfo && userInfo.firstName && userInfo.lastName &&<Typography variant='body1' sx={{ fontWeight: 600 }}>{`${userInfo.firstName} ${userInfo.lastName}`}</Typography>}
              {userInfo && userInfo.email && <Typography variant='body1' sx={{ fontWeight: 500, mt: 1.5, fontSize: '1.125rem' }}>{userInfo.email}</Typography>}
            </Grid>
            <Grid item md={2.5} sx={{ pl: {xs: 2, md: 0} }}>
            </Grid>
            <Grid item md={9.5} sx={{mt: 3, display: 'flex'}}>
              <Typography variant='body1' sx={{ fontWeight: 600, mr: 1 }}>Role</Typography>
              <CssChip label={userRole} className='ROLE' />
            </Grid>
            <Grid item xs={12} sx={{mt: 4, ...(!isPermissionGranted && {mb: 9})}}>
              <CssDivider sx={{borderColor: '#E5E5E5'}} />
            </Grid>
            {isPermissionGranted && <Grid item xs={12} sx={{mb: 9}}>
              <LinkModule name={'Roles'} onModuleClick={() => handleSwitch('ROLES')} />
              <LinkModule name={'Members'} onModuleClick={() => handleSwitch('MEMBERS')} />
            </Grid>}
            <Grid item xs={12}>
              <CssButton variant='outlined' sx={{width: '46.5%', m: 'auto', display: 'block'}} onClick={handleLogout}>Logout</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
      {isMobile && <CssDrawer anchor='left' open={drawerOpen} onClose={handleDrawerClose} />}

      <CssDialog
        disableEscapeKeyDown={true}
        open={openRolesModal}
        onScreenClose={onRoleModalClose}
        maxWidth={'md'}
      >
        <StyledDialogContent>
          {showComponent === 'ROLES' && 
            <Roles brandId={brandId} onClickBack={() => handleBack('ROLES')} />
          }
          {showComponent === 'MEMBERS' && 
            <Members brandId={brandId} onClickBack={() => handleBack('ROLES')} />
          }
        </StyledDialogContent>
      </CssDialog>
      {checkNotificationPermissions() && <ScreenDialog 
        open={openNotificationModal}
        onScreenClose={onNotificationScreenClose}
        title={'Notifications'}
        className={'notification-dialog'}
        disableEscapeKeyDown={true}
      >
        <StyledBoxNotification>
          <Typography variant='body2' sx={{fontWeight: 500, fontSize: '1rem', fontFamily: 'Roboto Medium'}}>Notifications</Typography>
        </StyledBoxNotification>
        <Box sx={{pl: 4, pr: 4}}>
          <NotificationsList onCallback={onNotificationClicked} />
        </Box>
      </ScreenDialog>}
    </StyledBox>
    </StyledOuterBox>
  );
};

export default Header;