/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, FormGroup, FormControlLabel, TextField } from '@mui/material';
import { GlobalContext } from 'context';
import CssCheckbox from 'components/CssCheckbox';

const StyledLabel = styled(Typography)(({ theme }) => ({
  lineHeight: 2,
  fontSize: '.875rem',
  fontFamily: 'Roboto'
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
    borderColor: '#04F8DA'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#04F8DA'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#04F8DA'
  },
  '& .MuiOutlinedInput-input': {
    paddingTop: 10,
    paddingBottom: 10
  },
  '&.phone': {
    width: '82%'
  }
}));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  marginBottom: 16
}));

const PepInfoForm = (props: any) => {
  const { data } = props;
  const { setPepPayloadData } = useContext(GlobalContext);
  const [pepPayload, setPepPayload] = useState<any>({
    pepRelevance: data.pepRelevance.length ? data.pepRelevance : [],
    plcName: data.plcName || '',
    plcTickerSymbol: data.plcTickerSymbol || '',
    publicOfficialName: data.publicOfficialName || ''
  });

  const onChangeValue = (event: any, name: string) => {
    setPepPayload((prevState: any) => {
      if (name === 'pepRelevance') {
        if (!prevState[name]) {
          prevState[name] = [];
        }
        if (event.target.value === 'NONE') {
          prevState[name] = [event.target.value];
        } else {
          prevState[name] = prevState[name].filter((item: string) => item !== 'NONE');
          if (!prevState[name].includes(event.target.value)) {
            prevState[name].push(event.target.value);
          } else {
            prevState[name] = prevState[name].filter((item: string) => item !== event.target.value);
          }
        }
      } else if (!prevState['pepRelevance'].includes('NONE')) {
        prevState[name] = event.target.value;
      } else if (prevState['pepRelevance'].includes('NONE')) {
        prevState['plcName'] = '';
        prevState['plcTickerSymbol'] = '';
        prevState['publicOfficialName'] = '';
      }
      return { ...prevState };
    });
  };

  useEffect(() => {
    setPepPayloadData(pepPayload);
  }, [pepPayload]);

  return (
    <Grid item xs={12} sx={{pl: 3}}>
      <StyledFormGroup>
        <FormControlLabel
          control={
            <CssCheckbox checked={pepPayload.pepRelevance.length && pepPayload.pepRelevance.includes('PTC_DIRECTOR_OR_SHAREHOLDER')} onChange={(event: any) => onChangeValue(event, 'pepRelevance')} value="PTC_DIRECTOR_OR_SHAREHOLDER" name="pdos" />
          }
          label="A director or 10% shareholder of a publicly traded corporation"
        />
        <Grid container sx={{mt: 2}}>
          <Grid item xs={5.88} sx={{mr: 2.5}}>
            <StyledLabel>{'Company name'}</StyledLabel>
            <StyledTextField
              variant='outlined'
              value={pepPayload.plcName || ''}
              onChange={(event: any) => onChangeValue(event, 'plcName')}
            />
          </Grid>
          <Grid item xs={5.88}>
            <StyledLabel>{'Ticker symbol'}</StyledLabel>
            <StyledTextField
              variant='outlined'
              value={pepPayload.plcTickerSymbol || ''}
              onChange={(event: any) => onChangeValue(event, 'plcTickerSymbol')}
            />
          </Grid>
        </Grid>
      </StyledFormGroup>
      <StyledFormGroup>
        <FormControlLabel
          control={
            <CssCheckbox checked={pepPayload.pepRelevance.length && pepPayload.pepRelevance.includes('BROKERAGE_FIRM_EMPLOYEE')} onChange={(event: any) => onChangeValue(event, 'pepRelevance')} value="BROKERAGE_FIRM_EMPLOYEE" name="bfe" />
          }
          label="Employed by a brokerage firm or securities exchange"
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <FormControlLabel
          control={
            <CssCheckbox checked={pepPayload.pepRelevance.length && pepPayload.pepRelevance.includes('HIGH_LEVEL_PUBLIC_OFFICIAL')} onChange={(event: any) => onChangeValue(event, 'pepRelevance')} value="HIGH_LEVEL_PUBLIC_OFFICIAL" name="hlpo" />
          }
          label="A current or former Politically Exposed Person or Public Official"
        />
         <Grid container sx={{mt: 2}}>
          <Grid item xs={5.88}>
            <StyledLabel>{'Immediate family member names'}</StyledLabel>
            <StyledTextField
              variant='outlined'
              value={pepPayload.publicOfficialName || ''}
              onChange={(event: any) => onChangeValue(event, 'publicOfficialName')}
            />
          </Grid>
        </Grid>
      </StyledFormGroup>
      <StyledFormGroup>
        <FormControlLabel
          control={
            <CssCheckbox checked={pepPayload.pepRelevance.length && pepPayload.pepRelevance.includes('NONE')} onChange={(event: any) => onChangeValue(event, 'pepRelevance')} value="NONE" name="none" />
          }
          label="None of these apply"
        />
      </StyledFormGroup>
    </Grid>
  );
};

export default PepInfoForm;