import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemIcon, Drawer, Tooltip } from '@mui/material';
import LogoInitial from './icons/LogoInitial';

const listItems = [
  { icon: <LogoInitial />, label: 'Dashboard', to: '/', isLogo: true, toolTipSx: { left: '-1px' }}
];

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  minHeight: 37,
  justifyContent: 'center',
  paddingRight: 13,
  paddingLeft: 20,
  display: 'inline-flex',
  marginTop: 4
}));

const drawerWidth = 77;

const StyledDrawer = styled(Drawer)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.primary.main
    }
  })
);

const CssAdminDrawer = ({...rest}) => {
  return (
    <StyledDrawer {...rest}>
      <List>
        {listItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <Tooltip title={item.label} placement={'right'} componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.black',
                  position: 'relative',
                  ...(item.toolTipSx && item.toolTipSx)
                },
              },
            }}>
              <StyledNavLink
                to={item.to}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              </StyledNavLink>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </StyledDrawer>
  );
};

export default CssAdminDrawer;