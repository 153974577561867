import React from 'react';
import { styled } from '@mui/material/styles';
import { SnackbarContent } from 'notistack';
import Button from '@mui/material/Button';

interface SuccessWithUndoProps {
  message: string,
  onUndo?: () => void
}

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  borderRadius: 15,
  padding: 14,
  fontSize: 14,
  backgroundColor: '#F2FFFD',
  boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.15)'
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#4021F5',
  textTransform: 'capitalize',
  paddingTop: 0,
  paddingBottom: 0
}));

const SuccessWithUndoSnackbar = React.forwardRef<HTMLDivElement, SuccessWithUndoProps>((props, ref) => {
  const {
    message,
    onUndo,
    ...other
  } = props;

  return (
    <StyledSnackbarContent ref={ref} role='alert' {...other} className={'success'}>
      {message} <StyledButton size='small' onClick={onUndo}>Undo</StyledButton>
    </StyledSnackbarContent>
  )
});

export default SuccessWithUndoSnackbar;