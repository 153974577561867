import Icon from './Icon';

const ActiveComments = ({ className, direction, fill }: any) => {
  return (
    <Icon className={className} direction={direction}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.59182 15.3042C2.34396 9.78675 6.40306 3.75 12.3609 3.75H12.6823C17.1379 3.75 20.7499 7.36201 20.7499 11.8176C20.7499 16.7785 16.7284 20.8 11.7676 20.8H3.94731C3.62905 20.8 3.34545 20.5991 3.23982 20.2989C3.13419 19.9987 3.22954 19.6645 3.47769 19.4652L5.44918 17.8819C5.53538 17.8127 5.56587 17.6951 5.52416 17.5927L4.59182 15.3042Z" fill="#04F8DA" stroke="#04F8DA" strokeLinejoin="round"/>
      <path d="M12.5801 11.6611H15.3555V12.8574H12.5801V16.002H11.3086V12.8574H8.5332V11.6611H11.3086V8.75586H12.5801V11.6611Z" fill="white"/>
      </svg>
    </Icon>
  );
};

export default ActiveComments;