
export const TravelRuleReportsHeaders = {
  columns:  [
    {
      key: 'batchNo',
      label: 'Report ID'
    },
    {
      key: 'txnType',
      label: 'Type'
    },
    {
      key: 'createdAt',
      label: 'Report date',
      withTimestamp: true
    }
  ],
  sortableColumns: ['batchNo', 'txnType', 'createdAt'],
  dateColumns: ['createdAt'],
  searchColumns: ['batchNo', 'txnType'],
  actions: [
    {
      key: 'DOWNLOAD',
      action: () => {}
    }
  ],
  actionCellStyles: {
    width: '50%'
  },
  paginationColSpan: 5,
  filterAlign: 'right'
};