
export const SettlementReportsFilters = [
  {
    parentKey: 'type',
    title: 'Type',
    filters: [
      {
        key: 'DEPOSIT',
        label: 'DW deposit',
        isChecked: false
      },
      {
        key: 'WITHDRAW',
        label: 'DW withdrawal',
        isChecked: false
      }
    ]
  }
];