/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ChangeEvent, useContext, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography, FormGroup, FormControlLabel, Grid } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import CssTable from 'components/CssTable';
import ScreenDialog from 'components/ScreenDialog';
import CssAccordion from 'components/CssAccordion';
import CssCheckbox from 'components/CssCheckbox';
import CssButton from 'components/CssButton';
import { InstrumentsHeaders } from 'constants/instruments-table-head-columns';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { GlobalContext } from 'context';
import useStorage from 'hooks/storage-hook';
import { setAccordionExpandByFilters, processMarketsFilterData, processMarketsSelectedFilter } from 'utils';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 5,
}));

const Markets = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { searchQuery, setSearchQuery, isSearchClosed, setIsSearchClosed, setEnableSearchField, isUpdatePage, setIsUpdatePage, isFilterApplied, setIsFilterApplied, setIsFilterIconDisplay } = useContext(GlobalContext);
  const { getInstrumentsApi, getStockFiltersApi } = useRequests();
  const getInstruments = useApi(getInstrumentsApi);
  const getStockFilters = useApi(getStockFiltersApi);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [instrumentId, setInstrumentId] = useStorage<number | null>('instrumentId', null);
  const [instrumentSymbol, setInstrumentSymbol] = useStorage<string | null>('instrumentSymbol', null);
  const [openFiltersScreen, setOpenFiltersScreen] = useState(false);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [enableButtons, setEnableButtons] = useState(true);
  const [filterQuery, setFilterQuery] = useState('');
  const [instrumentFiltersData, setInstrumentFiltersData] = useState<any>(null);
  const [instrumentFilters, setInstrumentFilters] = useState<any>(null);
  const [filterType, setFilterType] = useState<string[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (event: MouseEvent<unknown>, instrument: any) => {
    setInstrumentId(instrument.instrumentId);
    setInstrumentSymbol(instrument.symbol);
    setSearchQuery('');
    setIsSearchClosed(true);
    setEnableSearchField(false);
    if (instrument.instrumentType === 'CFD') {
      navigate('/market/cfd/profile', { state: instrument });
    } else {
      navigate('/market/profile', { state: instrument });
    }
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onClearFilters = () => {
    setIsFiltersCleared(true);
    setFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setInstrumentFilters((isf: any) => _.map(isf, (i) => {
      i.filters = _.map(i.filters, (f: any) => {
        if (f.isChecked) {
          f.isChecked = false;
        }
        return f;
      });
      return i;
    }));
    setFilterType([]);
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>, key: string, index: number) => {
    const selectedFilters = _.find(instrumentFilters, (o) => o.parentKey === key).filters;
    const newFilters = selectedFilters.map((item: any) => {
      if (item.key === event.target.value) {
        item.isChecked = event.target.checked;

        if (item.key === 'CFD') {
          if (item.isChecked) {
            setFilterType(fs => fs.concat('CFD'));
          } else {
            setFilterType(fs => _.filter(fs, (f) => f === 'EQUITY'));
          }
        } else if (item.key === 'EQUITY') {
          if (item.isChecked) {
            setFilterType(fs => fs.concat('EQUITY'));
          } else {
            setFilterType(fs => _.filter(fs, (f) => f === 'CFD'));
          }
        }
      }

      return item;
    });

    setInstrumentFilters((isf: any) => _.map(isf, (i) => {
      if (i.parentKey === key) {
        i.filters = newFilters;
      }
      return i;
    }));

    // setInstrumentStatusFilters((prevData: any) => processMarketsSelectedFilter(instrumentFiltersData, key, newFilters, prevData));
  };

  const onApply = () => {
    let query = '';
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    _.map(instrumentFilters, (o: any) => {
      _.map(o.filters, (f: any) => {
        if (f.isChecked) {
          query += `&${o.parentKey}=${f.key}`;
        }
      });
    });

    if(query) {
      setFilterQuery(query);
    }

    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  useEffect(() => {
    if (!searchQuery) {
      setPage(isUpdatePage ? 0 : page);
      getInstruments.request(page + 1, rowsPerPage, filterQuery).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
      });
    } else if (searchQuery !== '' && !isSearchClosed) {
      setPage(isUpdatePage ? 0 : page);
      getInstruments.request(page + 1, rowsPerPage, searchQuery, filterQuery).then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);

          if (isFilterApplied) {
            enqueueSnackbar('Filters have been applied successfully.', {
              variant: 'successWithUndo',
              onUndo: onClearFilters
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
      });
    }
  }, [page, rowsPerPage, searchQuery, isSearchClosed, filterQuery, isFiltersCleared, isUpdatePage]);

  useEffect(() => {
    const newFilters = _.map(instrumentFilters, o => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(false);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [instrumentFilters, isFiltersCleared]);

  useEffect(() => {
    getStockFilters.request().then((res) => {
      if (res.status === 200) {
        setInstrumentFiltersData(res.data);
        setInstrumentFilters(processMarketsFilterData(res.data));
      }
    })
  }, []);

  return (
    <CssContainer>
      <CssTitle variant='h4' underline>Markets</CssTitle>
      <CssTable
        headers={InstrumentsHeaders}
        totalCount={totalCount}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        highlightText={searchQuery}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        onRowClick={handleRowClick}
        isDesktopFilters={true}
        onFilters={handleFilters}
        sx={{ mt: {md: 3, xs: 0} }}
      />
      <ScreenDialog
        title={'Filters'}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant='body2' sx={{fontWeight: 500}}>Filters</Typography>
          <Typography variant='body2' sx={{cursor: 'pointer'}} onClick={onClearFilters}>&mdash; Clear filters</Typography>
        </StyledBox>
        <Box sx={{m: 4, mt: 0}}>
          {instrumentFilters && instrumentFilters.map((item: any, index: number) => (
            <CssAccordion title={item.title} key={index} isExpand={setAccordionExpandByFilters(item.filters)}>
              <FormGroup>
                {item.filters.map((filter: any, i: number) => (
                  <StyledFormControlLabel control={<CssCheckbox onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxClick(e, item.parentKey, i)} />} label={filter.label} checked={filter.isChecked} key={i} value={filter.key}  />
                ))}
              </FormGroup>
            </CssAccordion>
          ))}

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 6.5 }}>
              <CssButton variant='outlined' sx={{width: '46.5%', mr: 1, ml: 0}} onClick={onCancel} disabled={enableButtons}>Cancel</CssButton>
              <CssButton variant='contained' sx={{width: '46.5%', mr: 0, ml: 1}} onClick={onApply} disabled={enableButtons}>Apply</CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
    </CssContainer>
  );
};

export default Markets;