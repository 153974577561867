/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, MouseEvent, useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import CssTitle from 'components/CssTitle';
import CssTable from 'components/CssTable';
import { Order } from 'utils';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { GlobalContext } from 'context';

const SearchResults = (props: any) => {
  const { headers, onRowClick } = props;
  const theme = useTheme();
  const { searchQuery, isSearchClosed } = useContext(GlobalContext);
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { getClientsApi } = useRequests();
  const getClients = useApi(getClientsApi);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('registrationDate');
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  type Params = {
    page: number,
    limit: number,
    q?: string,
    sort?: number,
    sortBy?: string
  }

  const getClientsCall = (params: Params) => {
    const { page, limit, q, sort, sortBy } = params;
    getClients.request(page + 1, limit, q, '', '', sort, sortBy).then((res) => {
      if (res.status === 200) {
        const { totalcount } = res.headers;
        setTotalCount(parseInt(totalcount));
        setData(res.data);
      }
    });
  };

  useEffect(() => {
    getClientsCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, q: searchQuery });
  }, [page, rowsPerPage, order, orderBy, searchQuery, isSearchClosed]);

  return (
    <>
      {isMobile ? 
        <CssTitle variant='subtitle2'>Search results</CssTitle>
       : <CssTitle variant='h4' underline>Search results</CssTitle> 
      }
      <CssTable
        headers={headers}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        highlightText={searchQuery}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        onRowClick={onRowClick}
        sx={{ mt: {md: 3, xs: 0} }}
      />
    </>
  );
};

export default SearchResults;