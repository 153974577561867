import { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import FilterButton from 'components/FilterButton';
import { GlobalContext } from 'context';

interface ButtonGroupProps {
  data: any,
  variant?: string
}

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    height: 54,
    display: 'flex',
    paddingBottom: 10,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .btn-filter': {
      textAlign: 'center'
    }
  }
}));

const ButtonGroup = (props: ButtonGroupProps) => {
  const { data, variant } = props;
  const { selectedButtonType } = useContext(GlobalContext);

  return (
    <StyledBox sx={{ mt: {md: 3.5, xs: 3}, mb: {md: 0, xs: 0}, display: 'flex' }} justifyContent={'space-between'}>
      <Stack direction='row' spacing={{md: 1.625, xs: 1}} sx={{ display: 'inline-flex' }}>
        {data && data.length > 0 && data.map((button: any, index: number) => <FilterButton key={index} className={selectedButtonType === button.type ? `active btn-filter ${variant}` : `btn-filter ${variant}`} filter={button.type} onClick={button.onClick}>{button.label}</FilterButton>)}
      </Stack>
    </StyledBox>
  );
};

export default ButtonGroup;